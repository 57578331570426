import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import styles from './styles';
import { FormGroup, FormTextField } from 'components/_Common/Form';

const Form = (props) => {
  const {
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldTouched,
    setFieldValue,
    classes,
  } = props;

  const change = async (name, e) => {
    e.persist();
    const value = e.target.value;
    setFieldTouched(name, true, false);
    setFieldValue(name, value);
    handleChange(e);
  };

  const formProps = {
    errors,
    touched,
    values: props.values,
    change,
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <FormGroup>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Dados da sua conta
            <Divider />
          </Typography>
        </Grid>
        <FormTextField
          label="Nome"
          field="nome"
          xs={12}
          md={8}
          formik={formProps}
          type="text"
        />

        <FormTextField
          label="Email"
          field="email"
          xs={12}
          md={8}
          formik={formProps}
          type="email"
        />

        <FormTextField
          label="Senha"
          field="password"
          xs={12}
          md={6}
          formik={formProps}
          type="password"
        />

        <FormTextField
          label="Repita sua senha"
          field="password2"
          xs={12}
          md={6}
          formik={formProps}
          type="password"
        />
      </FormGroup>
      <Divider className={classes.divider} />
      <Button
        size="large"
        variant="contained"
        onClick={handleSubmit}
        color="primary"
        type="submit"
        className={classes.button}>
        <FontAwesomeIcon
          icon={faUserEdit}
          className={classes.fIconButton}
          fixedWidth
          size="lg"
        />
        Salvar
      </Button>
    </form>
  );
};

export default withStyles(styles)(Form);
