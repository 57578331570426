import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStyles from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function Tab(props) {
  const { label, icon, data, action, disabled, index, setActive } = props;
  const hasData = data !== null && data !== undefined && data !== '';
  const isMobile = useMediaQuery('(max-width:600px)');
  const classes = useStyles();

  const handleClick = () => {
    setActive(index);
    if (action) {
      action();
    }
  };
  return (
    <div className={isMobile ? classes.mobile : classes.container} onClick={handleClick}>
      <div className={classes.top}>
        {icon ? (
          <div className={classes.icon}>
            <FontAwesomeIcon icon={icon} fixedWidth size='lg' />
          </div>
        ) : null}
        {hasData ? <div className={classes.data}>{data}</div> : null}
      </div>
      <div className={classes.label}>{label}</div>
      {props.children}
    </div>
  );
}

export default Tab;
