import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 240;

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  appBar: {
    background: 'white !important',
    zIndex: `${theme.zIndex.drawer + 1} !important`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerMenuButton: {
    marginLeft: 12,
    marginRight: 36,
  },

  hide: {
    display: 'none',
  },

  drawerColor: {
    background: '#4BABFF',
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 0px',
    ...theme.mixins.toolbar,
  },

  content: {
    marginTop: '1em',
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(3),
    },
  },

  grow: {
    flexGrow: 1,
  },

  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },

  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },

  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  iconColor: {
    color: '#FFFFFF',
  },

  iconBlack: {
    color: 'black',
  },

  itemColor: {
    color: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
    '&:active': {
      background: theme.palette.secondary.main,
    },
  },

  disabled: {
    pointerEvents: 'none',
  },

  iconColorDisabled: {
    color: '#FFFFFF',
    opacity: '0.5 ',
  },

  itemColorDisabled: {
    color: '#FFFFFF',
    opacity: '0.5 ',
    fontWeight: '500',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },

  apelido: {
    fontSize: '1em',
    color: 'black',
    paddingTop: '14px',
  },

  logo: {
    marginTop: '0.1em',
    marginBottom: '0.3em',
  },
}));
