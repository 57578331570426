export const SHOW_CREATE = 'NOTIFICACAO_SHOW_CREATE';
export const CREATE_REQUEST = 'NOTIFICACAO_CREATE_REQUEST';
export const CREATE_SUCCESS = 'NOTIFICACAO_CREATE_SUCCESS';
export const CREATE_FAILURE = 'NOTIFICACAO_CREATE_FAILURE';

export const SHOW_EDIT = 'NOTIFICACAO_SHOW_EDIT';
export const EDIT_REQUEST = 'NOTIFICACAO_EDIT_REQUEST';
export const EDIT_SUCCESS = 'NOTIFICACAO_EDIT_SUCCESS';
export const EDIT_FAILURE = 'NOTIFICACAO_EDIT_FAILURE';

export const SHOW_TRASH = 'NOTIFICACAO_SHOW_TRASH';
export const TRASH_REQUEST = 'NOTIFICACAO_TRASH_REQUEST';
export const TRASH_SUCCESS = 'NOTIFICACAO_TRASH_SUCCESS';
export const TRASH_FAILURE = 'NOTIFICACAO_TRASH_FAILURE';

export const CANCEL = 'NOTIFICACAO_CANCEL';
