import React, { useRef } from 'react';
import Card from '@material-ui/core/Card';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import Servicos from './Servicos';
import Condicoes from './Condicoes';
import Avatar from './Avatar';
import Menu from './Menu';
import Beneficios from './Beneficios';
import { useSelector } from 'hooks';
import Overlay from 'components/Planos/Overlay';

function PlanoCard(props) {
  const classes = useStyles();
  const { plano } = props;
  const { compact, loading } = useSelector('planos');
  const cardRef = useRef(null);

  console.log('plano', plano);

  return (
    <Card className={classes.card} ref={cardRef}>
      <Overlay loading={loading.id} item={plano._id} parent={cardRef} />
      <Avatar plano={plano} />

      <Menu plano={plano} />

      {compact ? null : (
        <Grow in={true}>
          <span>
            <Beneficios plano={plano} />
            <Servicos data={plano.servicos} />
          </span>
        </Grow>
      )}

      <div className={classes.footer}>
        <Typography variant='h4' className={classes.valor} align='center'>
          R$ {plano.valor.toFixed(2)}
        </Typography>
        <Condicoes plano={plano} />
      </div>
    </Card>
  );
}

export default PlanoCard;
