import { CANCEL, REQUEST, SUCCESS, FAILURE, CHANGE_VIEW, LINK_IS_UNIQUE } from './types';
import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';

export const cancel = () => ({ type: CANCEL });
export const changeView = (view, link, url) => ({
  type: CHANGE_VIEW,
  payload: { view, link, url },
});

export const loadLinks = (url) => {
  const loader = { list: true };
  return async (dispatch) => {
    dispatch({ type: REQUEST, payload: { loader } });
    // ***SERVICE CALL***
    const res = await service.load();
    loader.list = false;
    if (res.success) {
      const { links } = res.data;
      dispatch({ type: SUCCESS, payload: { url, loader, links } });
    } else {
      dispatch({ type: FAILURE, payload: { url, loader } });
      dispatch(feedback.error(res.error));
    }
  };
};

export const checkLink = (url) => {
  const loader = { checkLink: true };
  return async (dispatch) => {
    dispatch({ type: REQUEST, payload: { loader } });
    // ***SERVICE CALL***
    const res = await service.checkUnique(url);
    loader.checkLink = false;
    if (res.success) {
      const { url } = res.data;
      dispatch({ type: LINK_IS_UNIQUE, payload: { url, loader, success: true } });
    } else {
      dispatch({ type: LINK_IS_UNIQUE, payload: { url, loader, success: false } });
      dispatch(feedback.error(res.error));
    }
  };
};

export const create = (link) => {
  const loader = { create: true };
  return async (dispatch) => {
    dispatch({ type: REQUEST, payload: { loader } });
    // ***SERVICE CALL***
    const res = await service.create(link);
    loader.create = false;
    if (res.success) {
      const { links } = res.data;
      dispatch({ type: SUCCESS, payload: { links, loader, view: 'MEUS LINKS' } });
      dispatch(feedback.success(res.data.msg));
    } else {
      dispatch({ type: FAILURE, payload: { loader } });
      dispatch(feedback.error(res.error));
    }
  };
};

export const shareMsg = (url) => {
  return async (dispatch) => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Compartilhar Link de Cobrança',
          url: url,
        })
        .then(dispatch(feedback.success('Cobrança compartilhada com sucesso!')))
        .catch(dispatch(feedback.success('Cobrança compartilhada com sucesso!')));
    } else {
      navigator.clipboard.writeText(url).then(
        () => dispatch(feedback.success('Link copiado para a área de transferência!')),
        () => dispatch(feedback.error('Não foi possível copiar o Link!')),
      );
    }
  };
};

export const edit = (link) => {
  const ID = link._id;
  return async (dispatch) => {
    dispatch({ type: REQUEST, payload: { ID } });
    // ***SERVICE CALL***
    const res = await service.edit(link);
    if (res.success) {
      const { links } = res.data;
      dispatch({ type: SUCCESS, payload: { links, ID, view: 'MEUS LINKS' } });
      dispatch(feedback.success(res.data.msg));
    } else {
      dispatch({ type: FAILURE, payload: { ID } });
      dispatch(feedback.error(res.error));
    }
  };
};

export const trash = (link) => {
  const ID = link._id;
  return async (dispatch) => {
    dispatch({ type: REQUEST, payload: { ID } });
    // ***SERVICE CALL***
    const res = await service.trash(link);
    if (res.success) {
      const { links } = res.data;
      dispatch({ type: SUCCESS, payload: { links, ID, view: 'MEUS LINKS' } });
      dispatch(feedback.success(res.data.msg));
    } else {
      dispatch({ type: FAILURE, payload: { ID } });
      dispatch(feedback.error(res.error));
    }
  };
};

export const pause = (link) => {
  const ID = link._id;
  return async (dispatch) => {
    dispatch({ type: REQUEST, payload: { ID } });
    // ***SERVICE CALL***
    const res = await service.pause(link);
    if (res.success) {
      const { links } = res.data;
      dispatch({ type: SUCCESS, payload: { links, ID, view: 'MEUS LINKS' } });
      dispatch(feedback.success(res.data.msg));
    } else {
      dispatch({ type: FAILURE, payload: { ID } });
      dispatch(feedback.error(res.error));
    }
  };
};

export const start = (link) => {
  const ID = link._id;
  return async (dispatch) => {
    dispatch({ type: REQUEST, payload: { ID } });
    // ***SERVICE CALL***
    const res = await service.start(link);
    if (res.success) {
      const { links } = res.data;
      dispatch({ type: SUCCESS, payload: { links, ID, view: 'MEUS LINKS' } });
      dispatch(feedback.success(res.data.msg));
    } else {
      dispatch({ type: FAILURE, payload: { ID } });
      dispatch(feedback.error(res.error));
    }
  };
};
