import {
  CANCEL,
  REQUEST,
  SUCCESS,
  FAILURE,
  SHOW_LISTA,
  SHOW_PERFIL,
  SHOW_EDIT,
  CHANGE_LIST,
  ORDENA,
  FILTRO_CURSO,
  FILTRO_LOCALIZACAO,
} from './types';
import { getHandledData } from './helpers';

const INITIAL_STATE = {
  loading: false,
  lista: true,
  perfil: false,
  showEdit: false,
  showVerified: false,
  showFarol: false,
  associados: [],
  listData: [],
  ordem: 'AZ',
  curso: 'todos',
  localizacao: 'todos',
  associado: {},
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SHOW_LISTA:
      return {
        ...state,
        showEdit: false,
        lista: true,
        perfil: false,
        associado: {},
      };

    case CHANGE_LIST: {
      let { listData } = payload;
      if (listData === state.entidades) {
        listData = getHandledData({ ...state });
      }
      return { ...state, listData, view: 'LISTA' };
    }

    case SHOW_PERFIL:
      return {
        ...state,
        showEdit: false,
        lista: false,
        perfil: true,
        associado: payload,
      };

    case SHOW_EDIT:
      return { ...state, showEdit: true };

    case CANCEL:
      return {
        ...state,
        lista: true,
        perfil: false,
        associado: {},
      };

    case ORDENA: {
      const ordem = payload;
      const listData = getHandledData({ ...state, ordem });
      // console.log('listData', listData);
      return { ...state, ordem, listData };
    }

    case FILTRO_CURSO: {
      const { curso } = payload;
      const listData = getHandledData({
        ...state,
        curso,
        status: '',
      });
      return { ...state, curso, listData };
    }

    case FILTRO_LOCALIZACAO: {
      const { localizacao } = payload;
      const listData = getHandledData({
        ...state,
        localizacao,
        status: '',
      });
      return { ...state, localizacao, listData };
    }

    case REQUEST:
      return { ...state, loading: true };

    case SUCCESS:
      return { ...state, associados: payload, listData: payload, loading: false };

    case FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};
