/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getSafe } from '_helpers/utils';

const MoneyTextField = (props) => {
  const { label, field, formik, xs, md, prefix } = props;
  const value = getSafe(formik.values, field, null);
  const touched = true;
  const error = getSafe(formik.errors, field, false);
  const change = formik.change;
  const helperText = props.helperText ? props.helperText : '';

  return useMemo(
    () => (
      <Grid item xs={xs} md={md}>
        <TextField
          id={field}
          name={field}
          helperText={touched ? error : helperText}
          error={touched && Boolean(error)}
          label={label}
          fullWidth
          value={value}
          onChange={change.bind(null, field)}
          variant='outlined'
          placeholder={props.placeholder}
          type='tel'
          InputProps={{
            startAdornment: <InputAdornment position='start'>{prefix}</InputAdornment>,
          }}
          autoComplete='new-password'
        />
      </Grid>
    ),
    [value, error],
  );
};

export default MoneyTextField;
