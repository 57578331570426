import { send } from '_helpers/api.config';
import { formataData } from '_helpers/utils';
import store from '_helpers/store';
import * as feedback from 'components/_Common/Feedback/redux/actions';

const apiURL = '/api/admin';

export const fetchTrash = async () => {
  const endpoint = `${apiURL}/trash`;
  const verb = 'GET';
  const data = null;
  const res = await send(verb, endpoint, data, false);
  const {
    planos,
    cupons,
    notificacoes,
    tickets,
    parceiros,
    entidades,
  } = res.data;
  return {
    planos: mapObj(planos, 'Plano', 'titulo'),
    cupons: mapObj(cupons, 'Cupom', 'codigo'),
    notificacoes: mapObj(notificacoes, 'Notificação', 'titulo'),
    tickets: mapObj(tickets, 'Ticket', 'numero'),
    parceiros: mapObj(parceiros, 'Parceiro', 'nome'),
    entidades: mapObj(entidades, 'Entidade', 'nome'),
  };
};

export const recoverPlano = async (id) => {
  const endpoint = `${apiURL}/plano/recover/${id}`;
  const verb = 'PUT';
  const data = null;
  const res = await send(verb, endpoint, data);
  giveFeedback(res);
  return res;
};

export const recoverCupom = async (id) => {
  const endpoint = `${apiURL}/cupom/recover/${id}`;
  const verb = 'PUT';
  const data = null;
  const res = await send(verb, endpoint, data);
  giveFeedback(res);
  return res;
};

export const recoverAssociado = async (id) => {
  const endpoint = `${apiURL}/associado/recover/${id}`;
  const verb = 'PUT';
  const data = null;
  const res = await send(verb, endpoint, data);
  giveFeedback(res);
  return res;
};

export const recoverNotificacao = async (id) => {
  const endpoint = `${apiURL}/notificacoes/recover/${id}`;
  const verb = 'PUT';
  const data = null;
  const res = await send(verb, endpoint, data);
  giveFeedback(res);
  return res;
};

export const recoverTicket = async (id) => {
  const endpoint = `${apiURL}/suporte/recover/${id}`;
  const verb = 'PUT';
  const data = null;
  const res = await send(verb, endpoint, data);
  giveFeedback(res);
  return res;
};

export const recoverParceiro = async (id) => {
  const endpoint = `${apiURL}/parceiro/recover/${id}`;
  const verb = 'PUT';
  const data = null;
  const res = await send(verb, endpoint, data);
  giveFeedback(res);
  return res;
};

export const recoverEntidade = async (id) => {
  const endpoint = `${apiURL}/entidade/recover/${id}`;
  const verb = 'PUT';
  const data = null;
  const res = await send(verb, endpoint, data);
  giveFeedback(res);
  return res;
};

function mapObj(data, tipo, identificador) {
  return data.map((obj) => ({
    ...obj,
    deltype: tipo,
    item: obj[identificador],
    updatedAt: formataData(obj.updatedAt),
    createdAt: formataData(obj.createdAt),
  }));
}

function giveFeedback(res) {
  if (res.success) {
    store.dispatch(feedback.success(res.msg));
  } else {
    store.dispatch(feedback.error(res.error));
  }
}
