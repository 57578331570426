import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default ({ props }) => {
  const { beneficios, setFieldValue, touched, errors, classes } = props;

  const [benef, setBenef] = useState([...beneficios]);

  const handleNovoBeneficio = () => {
    // nao adicionar se o benefício anterior está vazio
    if (benef[benef.length - 1] === '') {
      return false;
    }
    const copy = [...benef];
    copy.push('');
    setFieldValue('beneficios', copy);
    setBenef(copy);
  };

  const removeBeneficio = (index) => {
    if (index === 0) return false;
    const copy = [...benef];
    copy.splice(index, index);
    setFieldValue('beneficios', copy);
    setBenef(copy);
  };

  const setNovoBeneficio = (index, e) => {
    e.persist();
    const copy = [...benef];
    copy[index] = e.target.value;
    setFieldValue('beneficios', copy);
    setBenef(copy);
  };

  const endAdornment = (index) => {
    const show = index === 0 ? true : false;
    return (
      <InputAdornment position='end'>
        <IconButton
          disabled={show}
          aria-label='Excluir'
          onClick={() => removeBeneficio(index)}>
          <FontAwesomeIcon icon={faTrash} fixedWidth />
        </IconButton>
      </InputAdornment>
    );
  };

  const handleKeyDown = (e) => {
    e.persist();
    if (e.key === 'Enter') {
      handleNovoBeneficio();
    }
  };

  const handleBlur = (index, e) => {
    e.persist();
    if (e.target.value.length === 0) {
      removeBeneficio(index);
    }
  };

  return (
    <Grid item xs={12}>
      {benef.map((beneficio, index) => {
        const focus = index === 0 ? false : true;
        return (
          <TextField
            key={index}
            autoFocus={focus}
            id={index === 0 ? 'beneficios' : 'beneficio' + index}
            name={index === 0 ? 'beneficios' : 'beneficio' + index}
            label={`Benefício ${index + 1}`}
            helperText={touched.beneficios ? errors.beneficios : ''}
            error={touched.beneficios && Boolean(errors.beneficios)}
            fullWidth
            value={beneficio}
            onChange={setNovoBeneficio.bind(null, index)}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur.bind(null, index)}
            variant='outlined'
            className={classes.beneficio}
            InputProps={{ endAdornment: endAdornment(index) }}
          />
        );
      })}

      {Boolean(errors.beneficios) ? (
        <div className={classes.benefError}>{errors.beneficios}</div>
      ) : null}

      <Button
        variant='outlined'
        size='small'
        color='primary'
        onClick={() => handleNovoBeneficio()}>
        adicionar mais
      </Button>
    </Grid>
  );
};
