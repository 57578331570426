import { CANCEL, REQUEST, SUCCESS, FAILURE, CHANGE_VIEW, LINK_IS_UNIQUE } from './types';

const INITIAL_STATE = {
  links: [],
  link: {},
  view: 'MEUS LINKS',
  views: ['MEUS LINKS', 'PAGAMENTOS RECEBIDOS', 'LINKS EXPIRADOS', 'NOVO'],
  linkIsUnique: false,
  url: null,
  loader: {
    checkLink: false,
    create: false,
    list: false,
  },
  loadingId: [],
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  const ID = payload?.ID;
  const loadingId = [...state.loadingId];

  switch (action.type) {
    case CHANGE_VIEW: {
      return {
        ...state,
        view: payload.view,
        link: payload.link || state.link,
        url: payload.url || null,
        linkIsUnique: payload.url ? true : false,
      };
    }
    case LINK_IS_UNIQUE: {
      const loader = { ...state.loader, ...payload.loader };
      const { url } = payload.url;
      return { ...state, loader, url, linkIsUnique: payload.success };
    }

    case REQUEST: {
      if (ID) loadingId.push(ID);
      const loader = { ...state.loader, ...payload.loader };
      return { ...state, loader, loadingId };
    }

    case SUCCESS: {
      if (ID) loadingId.splice(loadingId.indexOf(ID), 1);
      const loader = { ...state.loader, ...payload.loader };
      const links = payload.links ?? state.links;
      return {
        ...state,
        view: payload.view ?? state.view,
        loader,
        links,
        loadingId,
        link: {},
        linkIsUnique: false,
        url: null,
      };
    }

    case FAILURE: {
      if (ID) loadingId.splice(loadingId.indexOf(ID), 1);
      const loader = { ...state.loader, ...payload.loader };
      return {
        ...state,
        view: payload.view ?? state.view,
        loader,
        loadingId,
        link: {},
        linkIsUnique: false,
        url: null,
      };
    }

    case CANCEL: {
      return { ...INITIAL_STATE, loader: state.loader, links: state.links };
    }

    default:
      return state;
  }
};
