import { LOAD_LOJAS_REQUEST, LOAD_LOJAS_SUCCESS, LOAD_LOJAS_FAILURE } from './types';
import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';

export const loadLojas = () => {
  return async (dispatch) => {
    dispatch({ type: LOAD_LOJAS_REQUEST });
    const res = await service.loadLojas();
    if (res.success) {
      dispatch({
        type: LOAD_LOJAS_SUCCESS,
        payload: {
          lojas: res.data.lojas,
          produtos: res.data.produtos,
        },
      });
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: LOAD_LOJAS_FAILURE, payload: res.error });
    }
  };
};
