import React, { useState, useEffect, useRef } from 'react';
import useStyles from './styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector, useActions } from 'hooks';
import * as Actions from 'components/Entidades/redux/actions';

export default function Filtro(props) {
  const classes = useStyles();

  const actions = useActions(Actions);
  const { plano } = useSelector('entidades');

  const { planos } = useSelector('planos');

  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = event => {
    actions.filtraPlano(event.target.value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel ref={inputLabel} id='filtra-plano'>
          Plano
        </InputLabel>
        <Select
          labelId='filtra-plano'
          id='planos'
          value={plano}
          onChange={handleChange}
          labelWidth={labelWidth}
        >
          <MenuItem value={'pagosAtivos'}>Pagos Ativos</MenuItem>
          <MenuItem value={'pagosExpirados'}>Pagos Expirados</MenuItem>
          {planos.map(p => (
            <MenuItem key={p._id} value={p._id}>
              {p.titulo}
            </MenuItem>
          ))}
          <MenuItem value={'apenasFree'}>Sem Plano Pago</MenuItem>
          <MenuItem value={'todos'}>Todos</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
