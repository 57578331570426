import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    zIndex: 100,
    boxShadow: '2px 2px 20px -4px hsla(0, 0%, 0%, 0.2)',
    padding: '1em 1em 0 1em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '1em 0.5em 1em 1em',
    },
  },

  top: {
    display: 'flex',
    flex: 1,
  },

  children: {
    flex: 1,
    order: 1,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },

  search: {
    flex: 0.5,
    order: 0,
    marginBottom: '0.5em',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      order: 0,
    },
  },

  button: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1em',
    cursor: 'pointer',
  },

  childrenMobile: {
    marginLeft: '-1em',
  },
}));
