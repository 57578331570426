import React from 'react';
import Button from '@material-ui/core/Button';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Grow from '@material-ui/core/Grow';

function Save(props) {
  const classes = useStyles();
  const { isValid, loading, submit, label, icon } = props;
  const submitText = label || 'salvar';
  const handleSubmit = () => submit();

  if (loading) {
    return (
      <Grow in={true}>
        <Button
          variant='contained'
          color='primary'
          disabled={true}
          onClick={handleSubmit}>
          <div className={classes.spinner}>
            <FontAwesomeIcon icon={faSpinner} fixedWidth size='lg' pulse />
          </div>
        </Button>
      </Grow>
    );
  }

  if (icon) {
    return (
      <Grow in={true}>
        <Button
          variant='contained'
          color='primary'
          disabled={!isValid}
          onClick={handleSubmit}>
          <FontAwesomeIcon
            icon={submit.icon}
            className={classes.icon}
            fixedWidth
            size='lg'
            pulse
          />
          {submitText}
        </Button>
      </Grow>
    );
  }

  return (
    <Grow in={true}>
      <Button
        variant='contained'
        color='primary'
        disabled={!isValid}
        onClick={handleSubmit}>
        {submitText}
      </Button>
    </Grow>
  );
}

export default Save;
