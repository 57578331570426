import { CANCEL, REQUEST, SUCCESS, FAILURE } from './types';

const INITIAL_STATE = {
  boletos: [],
  loader: {
    checkLink: false,
    create: false,
    list: false,
  },
  loadingId: [],
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  const ID = payload?.ID;
  const loadingId = [...state.loadingId];

  switch (action.type) {
    case REQUEST: {
      if (ID) loadingId.push(ID);
      const loader = { ...state.loader, ...payload.loader };
      return { ...state, loader, loadingId };
    }

    case SUCCESS: {
      if (ID) loadingId.splice(loadingId.indexOf(ID), 1);
      const loader = { ...state.loader, ...payload.loader };
      const boletos = payload.boletos ?? state.boletos;
      return {
        ...state,
        loader,
        boletos,
        loadingId,
      };
    }

    case FAILURE: {
      if (ID) loadingId.splice(loadingId.indexOf(ID), 1);
      const loader = { ...state.loader, ...payload.loader };
      return {
        ...state,
        loader,
        loadingId,
      };
    }

    case CANCEL: {
      return { ...INITIAL_STATE, loader: state.loader, boletos: state.boletos };
    }

    default:
      return state;
  }
};
