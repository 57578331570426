import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faSignInAlt,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { formataDataHora } from '_helpers/utils';

function PerfilLeft(props) {
  const { classes, entidade } = props;

  // Get Last Access
  let lastAccess = entidade?.user?.lastAccess ?? null;
  if (lastAccess) {
    lastAccess = formataDataHora(lastAccess);
  }

  const showAvatar = () => {
    if (entidade.avatar) {
      return (
        <img
          src={entidade.avatar}
          alt={`Avatar ${entidade.nome}`}
          className={classes.img}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faUser}
          className={classes.avatarPlaceholder}
          fixedWidth
          size='4x'
        />
      );
    }
  };

  return (
    <div className={classes.left}>
      <div className={classes.avatar}>{showAvatar()}</div>
      <Typography variant='h5' className={classes.nome} align='center'>
        {entidade.nome}
      </Typography>
      <Typography variant='caption' align='center'>
        {entidade.instituicao}
      </Typography>
      <Typography variant='h6' className={classes.nivel} align='center'>
        {entidade.tipo}{' '}
        {entidade.tipo === 'Outros' ? ' - ' + entidade.outros : null}
      </Typography>

      {entidade.url ? (
        <div className={classes.url}>
          <a
            className={classes.link}
            href={`https://farol.fun/${entidade.url}`}
            target='_blank'
            rel='noopener noreferrer'>
            <FontAwesomeIcon icon={faExternalLinkAlt} fixedWidth />/
            {entidade.url}
          </a>
        </div>
      ) : null}
      {lastAccess && (
        <div className={classes.lastAccess}>
          <FontAwesomeIcon
            icon={faSignInAlt}
            className={classes.lastAccessIcon}
            fixedWidth
            size='sm'
          />
          {lastAccess}
        </div>
      )}
    </div>
  );
}

export default PerfilLeft;
