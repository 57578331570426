import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { formataDataHora } from '_helpers/utils';

function PerfilLeft(props) {
  const { classes, associado } = props;

  // Get Last Access
  let lastAccess = associado?.user?.lastAccess ?? null;
  if (lastAccess) {
    lastAccess = formataDataHora(lastAccess);
  }

  const showAvatar = () => {
    if (associado.avatar) {
      return (
        <img
          src={associado.avatar}
          alt={`Avatar ${associado.nome}`}
          className={classes.img}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faUser}
          className={classes.avatarPlaceholder}
          fixedWidth
          size='4x'
        />
      );
    }
  };

  return (
    <div className={classes.left}>
      <div className={classes.avatar}>{showAvatar()}</div>
      <Typography variant='h5' className={classes.nome} align='center'>
        {associado.nome}
      </Typography>
      <Typography variant='h6' className={classes.nivel} align='center'>
        {associado.nivel}
      </Typography>
      <Typography variant='caption' align='center'>
        {associado.instituicao}
      </Typography>
      <Typography variant='overline' align='center' className={classes.obs}>
        Observações
      </Typography>
      <Typography variant='body2' align='center'>
        {associado.observacoes}
      </Typography>
      {lastAccess && (
        <div className={classes.lastAccess}>
          <FontAwesomeIcon
            icon={faSignInAlt}
            className={classes.lastAccessIcon}
            fixedWidth
            size='sm'
          />
          {lastAccess}
        </div>
      )}
    </div>
  );
}

export default PerfilLeft;
