import React from 'react';
import useStyles from './styles';
import Pesos from './Pesos';

function Score(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Pesos />
    </div>
  );
}

export default Score;
