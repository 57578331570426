import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';
import styles from './styles';
import { formataDataHora } from '_helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment,
  faCaretDown,
  faCaretUp,
  faEdit,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector, useFetch } from 'hooks';
const Notificacao = props => {
  const { classes, notificacao } = props;
  const { titulo, nivel, tipo, mensagem } = notificacao;
  const { entidades, associados } = nivel;
  const { simples, popup } = tipo;
  const actions = bindActionCreators(Actions, useDispatch());
  const [show, setShow] = useState(false);
  const { planos } = useSelector('planos');
  useFetch({ planos });
  let plans = false;

  const renderMsg = () => {
    return <span dangerouslySetInnerHTML={{ __html: mensagem }} />;
  };

  return (
    <div className={classes.notificacao}>
      <Collapse in={show} collapsedHeight='70px'>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.container}>
              <Tooltip
                title={show ? 'Recolher' : 'Exibir'}
                placement='right'
                onClick={() => setShow(!show)}
              >
                <div className={classes.top}>
                  <div className={classes.topInfo}>
                    <div className={classes.ballon}>
                      <FontAwesomeIcon
                        icon={faComment}
                        className={classes.commentColor}
                        fixedWidth
                        size='1x'
                      />
                    </div>

                    <div className={classes.data}>
                      <Typography
                        variant='caption'
                        gutterBottom
                        align='right'
                        className={classes.dataInfo}
                      >
                        {formataDataHora(notificacao.createdAt)}
                      </Typography>
                    </div>

                    <FontAwesomeIcon
                      icon={show ? faCaretUp : faCaretDown}
                      className={show ? classes.open : classes.closed}
                      fixedWidth
                      size='lg'
                    />
                  </div>
                  <div className={classes.titulo}>
                    <Typography variant='h6' gutterBottom align='left'>
                      {titulo}
                    </Typography>
                  </div>
                </div>
              </Tooltip>

              <div className={classes.mensagem}>
                <Typography>{renderMsg()}</Typography>
              </div>
              <Divider />
              <div className={classes.bottom}>
                <div className={classes.nivel}>
                  <Typography variant='caption'>Alvo</Typography>
                  <Typography>
                    {entidades ? 'Entidades' : null}
                    {entidades && associados ? ' & ' : null}
                    {associados ? 'Associados' : null}
                  </Typography>
                </div>
                <div className={classes.tipo}>
                  <Typography variant='caption'>Tipo</Typography>
                  <Typography>
                    {simples ? 'Simples' : null}
                    {simples && popup ? ' & ' : null}
                    {popup ? 'PopUp' : null}
                  </Typography>
                </div>
              </div>
            </div>
          </CardContent>

          <CardActions className={classes.actions}>
            <Button
              size='small'
              variant='outlined'
              onClick={() => actions.showEdit(notificacao)}
            >
              <FontAwesomeIcon
                icon={faEdit}
                className={classes.mr}
                fixedWidth
                size='lg'
              />
              editar
            </Button>

            <Button
              size='small'
              variant='outlined'
              color='secondary'
              onClick={() => actions.showTrash(notificacao)}
            >
              <FontAwesomeIcon
                className={classes.mr}
                icon={faTimes}
                fixedWidth
                size='lg'
              />
              excluir
            </Button>
          </CardActions>
        </Card>
      </Collapse>
    </div>
  );
};

export default withStyles(styles)(Notificacao);
