export default (theme) => ({
  container: {
    display: 'flex',
    marginTop: '2em',
    flexFlow: 'row wrap',
    alignItens: 'stretch',
  },

  edit: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    // padding: theme.spacing.unit,
  },

  profile: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '3em',
    },
  },

  avatarPlaceholder: {
    color: 'lightgrey',
    marginTop: '0.8em',
  },

  avatar: {
    textAlign: 'center',
    background: '#fafafa',
    width: '150px',
    height: '150px',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '50%',
    top: '-2em',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '0',
    boxShadow:
      '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(193, 0, 99,.4)',
  },

  img: {
    display: 'inline',
    margin: '0 auto',
    marginLeft: '-25%', //centers the image
    height: '150px',
    width: 'auto',
  },

  assina: {
    marginTop: '1em',
  },
  plano: {
    marginBottom: '1em',
  },

  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },

  divider: {
    marginTop: '1em',
  },

  fIconButton: {
    marginRight: '0.5em',
  },

  button: {
    marginTop: '1em',
    alignSelf: 'flex-end',
  },

  access: {
    display: 'flex',
    alignItems: 'center',
  },

  blocked: {
    textAlign: 'center',
    flex: 2,
    color: theme.palette.secondary.main,
  },

  switch: {
    textAlign: 'right',
    flex: 1,
  },

  open: {
    flex: 2,
    textAlign: 'center',
    color: theme.palette.primary.dark,
  },
});
