import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';
import styles from './styles';
import { formataData } from '_helpers/utils';

const Cupom = (props) => {
  const { classes, cupom } = props;
  const {
    status,
    codigo,
    validade,
    tipo,
    desconto,
    quantidade,
    utilizados,
  } = cupom;

  const actions = bindActionCreators(Actions, useDispatch());

  return (
    <div className={classes.cupom}>
      <div>
        {utilizados ? (
          <>
            <Badge
              color="default"
              badgeContent={utilizados}
              className={classes.badgeUtil}
            />
            <span className={classes.quantidade}>Utilizados </span>
          </>
        ) : null}

        <Badge
          color="primary"
          badgeContent={quantidade - utilizados}
          className={classes.badgeDisp}
        />
        <span className={classes.quantidade}>Disponíveis</span>
      </div>
      <Card className={status ? classes.card : classes.cardInativo}>
        <LinearProgress
          variant="determinate"
          value={(utilizados / quantidade) * 100}
        />
        <CardContent>
          <Typography variant="h6" component="h2" gutterBottom align="center">
            {codigo}
            <Divider variant="middle" />
          </Typography>
          <div className={classes.container}>
            <div className={classes.left}>
              <Typography variant="body1" gutterBottom>
                Plano <br /> <strong>{cupom.plano.titulo}</strong>
              </Typography>
            </div>

            <div className={classes.right}>
              <Chip
                label={
                  tipo === 'Valor Fixo'
                    ? `$ ${desconto.toFixed(2)} OFF`
                    : `${desconto.toFixed(2)} % OFF`
                }
                className={classes.chip}
                color="secondary"
              />
              <Typography variant="caption">válido até</Typography>
              <Typography variant="button" gutterBottom>
                {formataData(validade)}
              </Typography>
            </div>
          </div>
        </CardContent>

        <CardActions className={classes.actions}>
          <Badge
            color={status ? 'secondary' : 'primary'}
            variant="dot"
            className={classes.margin}>
            <Button
              size="small"
              variant="outlined"
              color={status ? 'secondary' : 'primary'}
              onClick={() => actions.mudarStatus(cupom)}>
              {status ? 'DESATIVAR' : 'ATIVAR'}
            </Button>
          </Badge>

          <Button
            size="small"
            variant="outlined"
            onClick={() => actions.showEdit(cupom)}>
            editar
          </Button>

          <Button
            size="small"
            variant="outlined"
            onClick={() => actions.showTrash(cupom)}>
            excluir
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default withStyles(styles)(Cupom);
