import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { isValidCep } from '@brazilian-utils/validators';
import { formatCnpj, formatCpf } from '@brazilian-utils/formatters';
import styles from '../styles';
import {
  FormGroup,
  FormTextField,
  FormSelectField,
  PhoneInput,
} from 'components/_Common/Form';
import { consultaCep, formataCep, formataCelular } from '_helpers/utils';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../redux/actions';

const Form = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldTouched,
    setFieldValue,
    setErrors,
    classes,
  } = props;

  const actions = bindActionCreators(Actions, useDispatch());

  const change = async (name, e) => {
    e.persist();
    const value = e.target.value;
    setFieldTouched(name, true, false);
    if (name === 'cep') {
      const cep = formataCep(value);
      setFieldValue('cep', cep);
      if (isValidCep(cep)) {
        const endereco = await consultaCep(cep);
        if (endereco.erro) {
          errors.cep = 'CEP não existe';
          setErrors(errors);
        } else {
          setFieldValue('bairro', endereco.bairro);
          setFieldTouched('bairro', true, false);
          setFieldValue('uf', endereco.uf);
          setFieldTouched('uf', true, false);
          setFieldValue('rua', endereco.logradouro);
          setFieldTouched('rua', true, false);
          setFieldValue('cidade', endereco.localidade);
          setFieldTouched('cidade', true, false);
        }
      }
    } else if (name === 'cnpj') {
      setFieldValue(name, formatCnpj(value));
    } else if (name === 'cpfRep') {
      setFieldValue(name, formatCpf(value));
    } else if (name === 'telefone1') {
      setFieldValue(name, formataCelular(value));
    } else if (name === 'telefone2') {
      setFieldValue(name, formataCelular(value));
    } else if (name === 'whatsapp') {
      setFieldValue(name, formataCelular(value));
    } else if (name === 'contatoCadastro') {
      setFieldValue(name, formataCelular(value));
    } else {
      handleChange(e);
    }
  };

  const formProps = {
    errors,
    touched,
    values: props.values,
    change,
  };

  const outrosEntidade = () => {
    if (values.tipo === 'Outros') {
      return (
        <FormTextField
          label="Tipo da Entidade Acadêmica"
          field="outros"
          xs={12}
          md={4}
          formik={formProps}
        />
      );
    }
    return <Grid item xs={12} md={4} />;
  };

  return (
    <form className={classes.root}>
      <FormGroup>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Dados da Entidade Acadêmica
            <Divider />
          </Typography>
        </Grid>
        <FormTextField
          label="Nome"
          field="nome"
          xs={12}
          md={8}
          formik={formProps}
          type="text"
        />

        <FormTextField
          label="Apelido"
          field="apelido"
          xs={12}
          md={4}
          formik={formProps}
          type="text"
        />

        <FormSelectField
          label="Tipo"
          field="tipo"
          xs={12}
          md={8}
          formik={formProps}
          options={[
            'Atlética',
            'Bateria',
            'Centro ou Diretório Acadêmico',
            'Equipe de Cheerleaders',
            'Equipe Acadêmica',
            'Equipe Acadêmica Esportiva',
            'Liga Acadêmica',
            'República',
            'Outros',
          ]}
        />

        {outrosEntidade()}

        <FormTextField
          label="Instituição"
          field="instituicao"
          xs={12}
          md={8}
          formik={formProps}
          type="text"
        />

        <FormTextField
          label="Quantidade de Alunos"
          field="qtdAlunos"
          xs={12}
          md={4}
          formik={formProps}
          type="tel"
        />

        <FormTextField
          label="Email"
          field="user.email"
          xs={12}
          md={8}
          formik={formProps}
          type="email"
        />

        <FormTextField
          label="CNPJ"
          field="cnpj"
          xs={12}
          md={4}
          formik={formProps}
          type="tel"
        />

        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Dados de contato
            <Divider />
          </Typography>
        </Grid>

        <FormTextField
          label="CEP"
          field="endereco.cep"
          xs={12}
          md={3}
          formik={formProps}
          type="tel"
        />
        <Grid item xs={0} md={8} />

        <FormTextField
          label="Logradouro"
          field="endereco.rua"
          xs={12}
          md={10}
          formik={formProps}
          type="text"
        />

        <FormTextField
          label="Número"
          field="endereco.numero"
          xs={12}
          md={2}
          formik={formProps}
          type="tel"
        />

        <FormTextField
          label="Complemento"
          field="endereco.complemento"
          xs={12}
          md={3}
          formik={formProps}
          type="text"
        />

        <FormTextField
          label="Bairro"
          field="endereco.bairro"
          xs={12}
          md={3}
          formik={formProps}
          type="text"
        />

        <FormTextField
          label="Município"
          field="endereco.cidade"
          xs={12}
          md={4}
          formik={formProps}
          type="text"
        />
        <FormTextField
          label="UF"
          field="endereco.uf"
          xs={12}
          md={2}
          formik={formProps}
          type="text"
        />

        <PhoneInput
          label="Telefone principal"
          field="fone.telefone1"
          xs={12}
          md={4}
          formik={formProps}
          type="tel"
        />

        <PhoneInput
          label="Telefone alternativo"
          field="fone.telefone2"
          xs={12}
          md={4}
          formik={formProps}
          type="tel"
        />

        <PhoneInput
          label="WhatsApp"
          field="fone.whatsapp"
          xs={12}
          md={4}
          formik={formProps}
          type="tel"
        />

        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Dados do Representante
            <Divider />
          </Typography>
        </Grid>

        <FormTextField
          label="Nome Completo"
          field="representante.nome"
          xs={12}
          md={8}
          formik={formProps}
          type="text"
        />

        <FormTextField
          label="CPF"
          field="representante.cpf"
          xs={12}
          md={4}
          formik={formProps}
          type="tel"
        />

        <FormTextField
          label="Email"
          field="representante.email"
          xs={12}
          md={8}
          formik={formProps}
          type="email"
        />

        <PhoneInput
          label="Telefone principal"
          field="fone.contatoCadastro"
          xs={12}
          md={4}
          formik={formProps}
          type="tel"
        />
      </FormGroup>
      <Divider className={classes.divider} />
      <Button
        size="large"
        variant="contained"
        onClick={() => actions.edit(values)}
        color="primary"
        className={classes.button}>
        <FontAwesomeIcon
          icon={faUserEdit}
          className={classes.fIconButton}
          fixedWidth
          size="lg"
        />
        Salvar
      </Button>
    </form>
  );
};

export default withStyles(styles)(Form);
