/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { LineChart } from 'react-chartkick';
import 'chart.js';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Grafico = (props) => {
  const { classes, theme } = props;
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const createRand = (month, end, max) => {
    const data = {};
    for (let index = 1; index <= end; index++) {
      const key = `${index}/${month}`;
      data[key] = Math.random() * (max - 0) + 0;
    }
    return data;
  };

  const receitas = createRand('08', 31, 0);
  const despesas = createRand('08', 31, 0);

  const caixa = {};
  let total = 0;
  for (const key in receitas) {
    if (receitas.hasOwnProperty(key)) {
      caixa[key] = total + receitas[key] - despesas[key];
      total = total + receitas[key] - despesas[key];
    }
  }

  const data = [
    {
      name: 'Receitas',
      data: receitas,
    },
    {
      name: 'Despesas',
      data: despesas,
    },
    {
      name: 'Caixa',
      data: caixa,
    },
  ];

  return (
    <div className={classes.container}>
      <LineChart
        data={data}
        height={matches ? '300px' : '200px'}
        width={matches ? '41vh' : '100%'}
        colors={['#30ca32', '#ff0000', '#5497e3']}
        legend="bottom"
        prefix={matches ? '' : 'R$ '}
        points={false}
      />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Grafico);
