export default (theme) => ({
  metodo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },

  metdText: {
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5em',
    },
  },
});
