/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PlanoCard from './PlanoCard';
import styles from './styles';

const Planos = (props) => {
  const { classes, planos } = props;
  return (
    <>
      <div className={classes.container} elevation={1}>
        {planos.map((plano, index) => (
          <PlanoCard plano={plano} key={plano._id} />
        ))}
      </div>
    </>
  );
};

export default withStyles(styles)(Planos);
