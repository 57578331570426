import { send } from '_helpers/api.config';

const apiURL = '/api/admin/financeiro';

// sellers
export const listSellers = async () => {
  const endpoint = `${apiURL}/sellers`;
  const verb = 'GET';
  const data = null;
  return send(verb, endpoint, data);
};

// REMOVE SELLER
export const trashSeller = async (seller) => {
  const endpoint = `${apiURL}/sellers/${seller._id}`;
  const verb = 'DELETE';
  const data = null;
  return send(verb, endpoint, data);
};
