import * as Yup from 'yup';

const validationSchema = Yup.object({
  nome: Yup.string('')
    .min(2, 'Muito curto')
    .max(30, 'Muito longo')
    .required('* Obrigatório'),

  email: Yup.string('')
    .email('Email incorreto')
    .max(60, 'Muito longo')
    .required('* Obrigatório'),

  password: Yup.string('')
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
      'A senha deve conter no mínimo uma letra MAIÚSCULA, uma MINÚSCULA, e um NÚMERO'
    )
    .min(8, 'A senha deve conter no mínimo 8 caracteres'),

  password2: Yup.string().when('password', {
    is: (value) => value && value !== '',
    then: Yup.string('')
      .oneOf([Yup.ref('password'), null], 'As senhas precisam ser iguais')
      .min(8, 'A senha deve ter no mínimo 8 caracteres')
      .required('* Obrigatório'),
  }),
});

export default validationSchema;
