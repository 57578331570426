import * as Yup from 'yup';
import isValidPhone from '@brazilian-utils/is-valid-phone';

const validationSchema = Yup.object({
  nome: Yup.string('')
    .min(1, 'Muito curto')
    .max(80, 'Muito longo')
    .required('* Obrigatório'),

  telefone: Yup.string('').test('test-name', 'Telefone inválido', function(
    value
  ) {
    if (value) {
      return value.includes('+') && value.length > 7
        ? true
        : isValidPhone(value);
    } else {
      return true;
    }
  }),

  whatsapp: Yup.string('').test('test-name', 'Telefone inválido', function(
    value
  ) {
    if (value) {
      return value.includes('+') && value.length > 7
        ? true
        : isValidPhone(value);
    } else {
      return true;
    }
  }),
});

export default validationSchema;
