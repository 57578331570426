import React from 'react';
import useStyles from './styles';
import { FormTextField } from 'components/_Common/Form';
import Button from 'components/_Common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLink, faRandom } from '@fortawesome/free-solid-svg-icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useSelector, useActions } from 'hooks';
import * as Actions from 'components/LinkCobranca/redux/actions';

function ValidaLink(props) {
  const { form } = props;
  const classes = useStyles();
  const { url } = form.values;
  const link = url !== '' ? url : 'seu-link';
  const actions = useActions(Actions);
  const { loader } = useSelector('linkCobranca');

  const handleKeyDown = (e) => {
    e.persist();
    if (e.key === 'Enter') {
      actions.checkLink(url);
    }
  };

  const geraCodigoRand = () => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    form.setFieldTouched('url', true, false);
    form.setFieldValue('url', result);
  };

  const startAdornment = () => {
    return (
      <InputAdornment position='start'>
        <FontAwesomeIcon icon={faLink} fixedWidth size='sm' className={classes.gg} />
        <span className={classes.urlValue}>/</span>
      </InputAdornment>
    );
  };
  const endAdornment = () => {
    return (
      <InputAdornment position='end'>
        <Button
          label=''
          icon={faRandom}
          onClick={geraCodigoRand}
          variant='text'
          color='secondary'
          tooltip='Gerar código aletório'
        />
      </InputAdornment>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.text}>
        Para começar, vamos definir um link único e exclusivo, ou seja, ele não pode ter sido
        cadastrado por outra Atlética ou Entidade Acadêmica.
      </div>

      <div className={classes.link}>
        <FormTextField
          label='Link'
          field='url'
          placeholder='seu-link'
          xs={12}
          md={8}
          formik={form}
          onKeyDown={handleKeyDown}
          type='url'
          styleProps={{
            InputProps: { startAdornment: startAdornment(), endAdornment: endAdornment() },
          }}
        />

        <div className={classes.linkText}>
          A URL final do link será <strong>{`https://farol.fun/checkout/${link}`}</strong>
        </div>
      </div>

      <Button
        disabled={!url || url === ''}
        label='Próximo'
        icon={faArrowRight}
        iconPosition='after'
        loading={loader.checkLink}
        onClick={() => actions.checkLink(url)}
        className={classes.button}
      />
    </div>
  );
}

export default ValidaLink;
