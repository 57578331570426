/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const ResumoGeral = (props) => {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <FontAwesomeIcon
          icon={faEye}
          // className={classes.pullLeft}
          fixedWidth
        />{' '}
        Overview
      </div>
      <div className={classes.infos}>
        <div className={classes.ttlFarol}>créditos farol</div>
        <div className={classes.credFarol}>0</div>
        <div className={classes.ttlAcc}>Saldo em Conta</div>
        <div className={classes.credAcc}>R$ 0</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ResumoGeral);
