export default (theme) => ({
  dashboard: {
    marginBottom: '10em',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-2em',
  },

  top: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  bottom: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
});
