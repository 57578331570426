export default (theme) => ({
  container: {
    display: 'flex',
    marginTop: '1em',
    flexFlow: 'row wrap',
    alignItens: 'stretch',
  },

  edit: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    // padding: theme.spacing.unit,
  },

  profile: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '3em',
    },
  },

  avatar: {
    position: 'relative',
    borderRadius: '50%',
    background: '#fafafa',
    padding: '20px',
    width: '150px',
    height: '150px',
    zIndex: '3 !important',
    top: '-2em',
    margin: '1em',
    marginBottom: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    boxShadow:
      '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(193, 0, 99,.4)',
  },

  avatarPlaceholder: {
    color: 'lightgrey',
  },

  assina: {
    marginTop: '1em',
  },
  plano: {
    // marginBottom: '1em',
  },

  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },

  divider: {
    marginTop: '1em',
  },

  fIconButton: {
    marginRight: '0.5em',
  },

  button: {
    marginTop: '1em',
    alignSelf: 'flex-end',
  },
});
