import React from 'react';
import Button from '@material-ui/core/Button';
import useStyles from './styles';
import { useHistory } from 'react-router-dom';

function Cancel(props) {
  const classes = useStyles();
  const { loading, cancel, label } = props;
  const history = useHistory();

  const handleCancel = () => {
    if (cancel) cancel();
    else history.goBack();
  };

  const text = label || 'cancelar';

  return (
    <Button
      disabled={loading}
      className={classes.cancel}
      variant='outlined'
      color='default'
      onClick={handleCancel}>
      {text}
    </Button>
  );
}

export default Cancel;
