import {
  REPLY_REQUEST,
  REPLY_SUCCESS,
  REPLY_FAILURE,
  SHOW_TRASH,
  TRASH_REQUEST,
  TRASH_SUCCESS,
  TRASH_FAILURE,
  LIST_REQUEST,
  LIST_SUCCESS,
  LIST_FAILURE,
  SHOW_ENVIADOS,
  SHOW_ENCERRADOS,
  SHOW_ANALISANDO,
  CANCEL,
} from './types';
import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';

export const cancel = () => ({ type: CANCEL });
export const showTrash = (ticket) => ({ type: SHOW_TRASH, payload: ticket });
export const showEnviados = () => ({ type: SHOW_ENVIADOS });
export const showEncerrados = () => ({ type: SHOW_ENCERRADOS });
export const showAnalisando = () => ({ type: SHOW_ANALISANDO });

export const list = () => {
  return async (dispatch) => {
    dispatch({ type: LIST_REQUEST });
    // ***SERVICE CALL***
    const res = await service.list();
    if (res.success) {
      dispatch({ type: LIST_SUCCESS, payload: res.data.tickets });
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: LIST_FAILURE });
    }
  };
};

export const reply = (ticket) => {
  return async (dispatch) => {
    dispatch({ type: REPLY_REQUEST });
    // ***SERVICE CALL***
    const res = await service.reply(ticket);
    if (res.success) {
      dispatch(feedback.success(res.msg));
      dispatch({ type: REPLY_SUCCESS, payload: res.data.tickets });
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: REPLY_FAILURE });
    }
  };
};

export const trash = (ticket) => {
  return async (dispatch) => {
    dispatch({ type: TRASH_REQUEST });
    // ***SERVICE CALL***
    const res = await service.trash(ticket);
    if (res.success) {
      dispatch(feedback.success(res.msg));
      dispatch({ type: TRASH_SUCCESS, payload: res.data.tickets });
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: TRASH_FAILURE });
    }
  };
};

// export const rate = (ticket) => {
//   return async (dispatch) => {
//     dispatch({ type: RATE_REQUEST });
//     // ***SERVICE CALL***
//     const res = await service.rate(ticket);
//     if (res.success) {
//       dispatch(feedback.success(res.msg));
//       dispatch({ type: RATE_SUCCESS, payload: res.data.tickets });
//     } else {
//       dispatch(feedback.error(res.error));
//       dispatch({ type: RATE_FAILURE });
//     }
//   };
// };
