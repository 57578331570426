import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grow from '@material-ui/core/Grow';
import { SnackbarProvider } from 'notistack';
import Router from './Router';

function GrowTransition(props) {
  return <Grow {...props} />;
}

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          disableWindowBlurListener={true}
          TransitionComponent={(props) => GrowTransition(props)}>
          <CssBaseline />
          <Router />
        </SnackbarProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
