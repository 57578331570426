/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { capitalizeFirstLetter } from '_helpers/utils';
// do not forget to include actions in array
import * as loaders from './loaders';

export default function useFetch(data, deps) {
  const dispatch = useDispatch();

  return useMemo(
    () => {
      // if no data, do nothing
      if (!data) return false;
      if (data === true) {
        // load all data from all actions
        Object.values(loaders).forEach((loader) => dispatch(loader()));
      } else {
        // load data from one specific action
        const key = Object.keys(data)[0];
        const value = Object.values(data)[0];
        const name = `load${capitalizeFirstLetter(key)}`;
        // conditions to check
        if (!value) {
          dispatch(loaders[name]());
        } else {
          const zeroLen = value.length === 0;
          const emptyVal = value === '';
          const isEmptyObj = !Object.keys(value).length;
          const noLen = !value.length && isEmptyObj;
          if (noLen || zeroLen || emptyVal) {
            dispatch(loaders[name]());
          }
        }
      }
      return false;
    },
    deps ? [dispatch, ...deps] : [dispatch],
  );
}
