import React from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImage,
  faSpinner,
  faPaperPlane,
  faCheck,
  faCheckDouble,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import ImageUpload from 'components/_Common/ImageUpload';
import { getSafe } from '_helpers/utils';
import SelectOptions from './SelectOptions';
import PreviewMessage from './preview';
import useStyles from './styles';
import { useSelector } from 'hooks';

export default function ChatForm(props) {
  const {
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleCancel,
    setFieldTouched,
    setFieldValue,
    isValid,
    values,
    ticket,
  } = props;

  const { loadingReply } = useSelector('suporte');

  const change = (name, e) => {
    e.persist();
    setFieldTouched(name, true, false);
    if (name === 'mensagem') {
      const value = e.target.value;
      setFieldValue(name, value);
    }
    handleChange(e);
  };

  const formProps = {
    errors,
    touched,
    isValid,
    setFieldTouched,
    setFieldValue,
    values: props.values,
    change,
  };

  const classes = useStyles();
  const selectValue = getSafe(values, 'status');
  const image = getSafe(values, 'imagem');
  const labelImage = image ? 'Mudar imagem' : 'Anexar imagem';

  const selectOptions = [
    {
      id: 0,
      value: 'Enviado',
      icon: faCheck,
    },
    {
      id: 1,
      value: 'Analisando',
      icon: faCheckDouble,
    },
    {
      id: 2,
      value: 'Encerrado',
      icon: faCheckCircle,
    },
  ];

  const setIcon = (icon, color) => {
    if (loadingReply) {
      return (
        <FontAwesomeIcon
          className={color && classes[color]}
          icon={faSpinner}
          fixedWidth
          pulse
          size='sm'
        />
      );
    }
    return (
      <FontAwesomeIcon
        className={color && classes[color]}
        icon={icon}
        fixedWidth
        size='sm'
      />
    );
  };

  return (
    <form>
      <PreviewMessage formik={formProps} />
      <Divider />
      <Grid container justify='center'>
        {ticket.status !== 'Encerrado' && (
          <div component='form' className={classes.root}>
            <Paper className={classes.form}>
              <InputBase
                field={'mensagem'}
                className={classes.input}
                placeholder={'mensagem'}
                onChange={change.bind(null, 'mensagem')}
                inputProps={{ 'aria-label': 'mensagem' }}
                multiline={true}
              />
              <div className={classes.actionsIcons}>
                <ImageUpload
                  field='imagem'
                  alt='preview-image'
                  formik={{ ...formProps }}
                  styles=''>
                  <Tooltip title={labelImage}>
                    <IconButton
                      className={classes.iconButton}
                      color={image ? 'primary' : 'default'}
                      aria-label={labelImage}>
                      {setIcon(faImage)}
                    </IconButton>
                  </Tooltip>
                </ImageUpload>
                <Divider className={classes.divider} orientation='vertical' />
                <Tooltip title='Enviar'>
                  <IconButton
                    disabled={!isValid}
                    color='primary'
                    onClick={handleSubmit}
                    className={classes.iconButton}
                    aria-label='enviar'>
                    {setIcon(faPaperPlane)}
                  </IconButton>
                </Tooltip>
                <SelectOptions
                  formik={{ ...formProps }}
                  field={'status'}
                  options={[...selectOptions]}
                />
              </div>
            </Paper>
            <div className={classes.footerform}>
              {handleCancel && (
                <Typography
                  onClick={handleCancel}
                  className={classes.remove}
                  variant='caption'
                  align='left'>
                  excluir este ticket
                </Typography>
              )}
              <Typography
                className={classes.status}
                variant='caption'
                align='right'>
                status : {selectValue.toLowerCase()}
              </Typography>
            </div>
          </div>
        )}
      </Grid>
    </form>
  );
}
