import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { formataData, formataHMS } from '_helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Dialog from 'components/_Common/Dialog';

function PerfilRight(props) {
  const { classes, associado, actions } = props;
  const assinatura = associado.assinaturas[0];

  const [open, setOpen] = useState(false);

  const trashDialog = () => {
    if (!open) return null;
    return (
      <Dialog
        title={`Mover ${associado.nome} para a lixeira?`}
        message='O associado será movido para a lixeira, não terá mais acesso ao sistema e receberá e-mails e notificações.'
        no='Cancelar'
        yes='SIM'
        action={() => actions.trash(associado)}
        close={() => setOpen(false)}
      />
    );
  };

  return (
    <div className={classes.right}>
      <div className={classes.group}>
        <div className={classes.group}>
          <Typography variant='subtitle1' className={classes.label}>
            Acesso ao sistema
          </Typography>

          <div className={classes.info}>
            {associado.user ? (
              <Typography
                variant='subtitle1'
                color={associado.user.blocked ? 'secondary' : 'primary'}>
                {associado.user.blocked ? 'BLOQUEADO' : 'GARANTIDO'}
              </Typography>
            ) : (
              <Typography variant='subtitle1' color='secondary'>
                Usuário não registrado
              </Typography>
            )}
          </div>
        </div>
      </div>

      <div className={classes.group}>
        <Typography variant='subtitle1' className={classes.label}>
          Status e-mail
        </Typography>
        <Typography variant='subtitle1' className={classes.info}>
          {associado.user && associado.user.isVerified
            ? 'Verificado'
            : 'Verificação pendente'}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant='subtitle1' className={classes.label}>
          Tipo de Cadastro
        </Typography>
        <Typography variant='subtitle1' className={classes.info}>
          {associado.tipoCadastro}
        </Typography>
      </div>

      <Divider className={classes.divider} />

      <div className={classes.group}>
        <Typography variant='subtitle1' className={classes.label}>
          Plano vigente
        </Typography>
        <Typography variant='subtitle1' className={classes.info}>
          {assinatura.plano.titulo.toUpperCase()}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant='subtitle1' className={classes.label}>
          Assinado em
        </Typography>
        <Typography variant='subtitle1' className={classes.info}>
          {formataData(assinatura.createdAt)}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant='subtitle1' className={classes.label}>
          Válido até
        </Typography>
        <Typography variant='subtitle1' className={classes.info}>
          {formataData(assinatura.validade)}
        </Typography>
      </div>

      <Divider className={classes.divider} />

      <div className={classes.group}>
        <Typography variant='caption' className={classes.label}>
          Criado em
        </Typography>
        <Typography variant='caption' className={classes.info}>
          {formataData(associado.createdAt)} às{' '}
          {formataHMS(associado.createdAt)}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant='caption' className={classes.label}>
          Última atualização
        </Typography>
        <Typography variant='caption' className={classes.info}>
          {formataData(associado.updatedAt)} às{' '}
          {formataHMS(associado.updatedAt)}
        </Typography>
      </div>

      <div className={classes.action}>
        <Button
          variant='outlined'
          onClick={() => setOpen(!open)}
          color='secondary'
          className={classes.button}>
          <FontAwesomeIcon
            icon={faTrash}
            className={classes.buttonIcon}
            fixedWidth
            size='lg'
          />
          excluir associado
        </Button>
      </div>

      {trashDialog()}
    </div>
  );
}

export default PerfilRight;
