import React from "react";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import {
  FormGroup,
  FormTextField,
  FormSelectField,
  PhoneInput,
  DateField
} from "components/_Common/Form";
import { formataDataInput, formataCelular } from "_helpers/utils";
import { formatCpf } from "@brazilian-utils/formatters";
import useStyles from "./styles";

const Form = props => {
  const {
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldTouched,
    setFieldValue
  } = props;
  const classes = useStyles();

  const change = (name, e) => {
    e.persist();
    const value = e.target.value;
    setFieldTouched(name, true, false);
    if (name === "cpf") {
      setFieldValue(name, formatCpf(value));
    } else if (name === "celular") {
      setFieldValue(name, formataCelular(value));
    } else {
      handleChange(e);
    }
  };

  const formProps = {
    errors,
    touched,
    values: props.values,
    change,
    setFieldTouched,
    setFieldValue
  };

  return (
    <form className={classes.root}>
      <FormGroup>
        <FormTextField
          label="Nome"
          field="nome"
          xs={12}
          md={8}
          formik={formProps}
          type="text"
        />

        <FormSelectField
          label="Nível"
          field="nivel"
          xs={12}
          md={4}
          formik={formProps}
          options={[
            "Associado",
            "Atleta",
            "Colaborador",
            "Ex-Aluno",
            "Diretor"
          ]}
        />

        <FormTextField
          label="Email"
          field="email"
          xs={12}
          md={8}
          formik={formProps}
          type="email"
        />

        <PhoneInput
          label="Celular"
          field="celular"
          xs={12}
          md={4}
          formik={formProps}
          type="tel"
        />

        <FormTextField
          label="CPF"
          field="cpf"
          xs={12}
          md={3}
          formik={formProps}
          type="tel"
        />

        <FormTextField
          label="RG"
          field="rg"
          xs={12}
          md={3}
          formik={formProps}
          type="tel"
        />

        <FormTextField
          label="Expeditor RG"
          field="expeditorRG"
          xs={12}
          md={3}
          formik={formProps}
          type="text"
        />
        <DateField
          label="Nascimento"
          field="nascimento"
          xs={12}
          md={3}
          formik={formProps}
        />
        <FormTextField
          disabled
          label="Instituição"
          field="instituicao"
          xs={12}
          md={6}
          formik={formProps}
          type="text"
        />

        <FormTextField
          label="Curso"
          field="curso"
          xs={12}
          md={3}
          formik={formProps}
          type="text"
        />

        <FormTextField
          label="Matrícula"
          field="matricula"
          xs={12}
          md={3}
          formik={formProps}
          type="text"
        />
      </FormGroup>
      <Divider className={classes.divider} />
      <Button
        size="large"
        variant="contained"
        onClick={() => handleSubmit()}
        color="primary"
        className={classes.button}
      >
        <FontAwesomeIcon
          icon={faUserEdit}
          className={classes.fIconButton}
          fixedWidth
          size="lg"
        />
        Salvar
      </Button>
    </form>
  );
};

export default Form;
