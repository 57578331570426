import React, { useState, useEffect } from 'react';
import classNames from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignOutAlt,
  faUserCircle,
  faEnvelope,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import useStyles from './styles';
import AppBar from './AppBar';
import MenuList from './MenuList';
import { useSelector, useActions } from 'hooks';
import * as Actions from '../Login/redux/actions.js';
import { useTheme } from '@material-ui/core/styles';

function MenuDrawer(props) {
  const theme = useTheme();
  const classes = useStyles();
  const actions = useActions(Actions);
  const { tickets } = useSelector('suporte');
  const [newMsgs, setNewMsgs] = useState(
    tickets.filter((ticket) => ticket.status === 'Enviado'),
  );

  useEffect(() => {
    setNewMsgs(tickets.filter((ticket) => ticket.status === 'Enviado'));
  }, [tickets]);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <MenuItem onClick={handleMenuClose}>Perfil</MenuItem>
      <MenuItem onClick={handleMenuClose}>Minha Conta</MenuItem>
      <MenuItem onClick={actions.logout}>
        <IconButton color='inherit'>
          <FontAwesomeIcon icon={faSignOutAlt} fixedWidth size='sm' />
        </IconButton>
        Sair
      </MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMenuClose}>
      <Link to='/suporte' component={RouterLink} underline='none'>
        <MenuItem onClick={handleMobileMenuClose}>
          <IconButton color='inherit'>
            <Badge badgeContent={newMsgs.length} color='secondary'>
              <FontAwesomeIcon icon={faEnvelope} fixedWidth size='sm' />
            </Badge>
          </IconButton>
          <p>Mensagens</p>
        </MenuItem>
      </Link>

      <Link to='/conta' component={RouterLink} underline='none'>
        <MenuItem onClick={handleMobileMenuClose}>
          <IconButton color='inherit'>
            <FontAwesomeIcon icon={faUserCircle} fixedWidth size='sm' />
          </IconButton>
          <p>Perfil</p>
        </MenuItem>
      </Link>

      <MenuItem onClick={() => props.history.push('/')}>
        <IconButton color='inherit'>
          <FontAwesomeIcon icon={faSignOutAlt} fixedWidth size='sm' />
        </IconButton>
        <p>Sair</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        isMenuOpen={isMenuOpen}
        handleMobileMenuOpen={handleMobileMenuOpen}
        handleProfileMenuOpen={handleProfileMenuOpen}
        newTickets={newMsgs.length}
      />
      <Drawer
        variant='permanent'
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            [classes.drawerColor]: true,
          }),
        }}
        open={open}>
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <FontAwesomeIcon icon={faChevronRight} fixedWidth size='xs' />
            ) : (
              <FontAwesomeIcon icon={faChevronLeft} fixedWidth size='xs' />
            )}
          </IconButton>
        </div>
        <Divider />
        <MenuList closeMenu={handleDrawerClose} />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
      {renderMenu}
      {renderMobileMenu}
    </div>
  );
}

export default MenuDrawer;
