import React from 'react';
import useStyles from './styles';
import { FormGroup, FormTextField } from 'components/_Common/Form';
import Button from '@material-ui/core/Button';
import { onlyNumbers } from '_helpers/utils';

const Form = (props) => {
  const { values, errors, touched, handleSubmit, setFieldTouched, setFieldValue } = props;

  const classes = useStyles();

  const change = (name, e) => {
    e.persist();
    const val = onlyNumbers(e.target.value);
    setFieldTouched(name, true, false);
    setFieldValue(name, val);
  };

  const formProps = { errors, touched, values, change };

  const inputProps = {
    InputLabelProps: {
      classes: {
        root: classes.cssLabel,
        focused: classes.cssFocused,
      },
    },
    InputProps: {
      classes: {
        root: classes.cssOutlinedInput,
        focused: classes.cssFocused,
        notchedOutline: classes.notchedOutline,
      },
    },
  };

  return (
    <form className={classes.form}>
      <FormGroup>
        <FormTextField
          styleProps={inputProps}
          label='Acessou nos últimos 7 dias'
          field='acesso'
          xs={12}
          md={3}
          formik={formProps}
        />
        <FormTextField
          styleProps={inputProps}
          label='Enviou Logo'
          field='logo'
          xs={12}
          md={3}
          formik={formProps}
        />
        <FormTextField
          styleProps={inputProps}
          label='Enviou Capa no Site'
          field='capaSite'
          xs={12}
          md={3}
          formik={formProps}
        />
        <FormTextField
          styleProps={inputProps}
          label='Colocou Capa na Loja'
          field='capaLoja'
          xs={12}
          md={3}
          formik={formProps}
        />
        <FormTextField
          styleProps={inputProps}
          label='Por Parceiro'
          field='parceiros'
          xs={12}
          md={3}
          formik={formProps}
        />
        <FormTextField
          styleProps={inputProps}
          label='Por Vendedor'
          field='seller'
          xs={12}
          md={3}
          formik={formProps}
        />

        <FormTextField
          styleProps={inputProps}
          label='Por CGF'
          field='fluxos'
          xs={12}
          md={3}
          formik={formProps}
        />

        <FormTextField
          styleProps={inputProps}
          label='Por Produto Ativo'
          field='produtos'
          xs={12}
          md={3}
          formik={formProps}
        />
        <FormTextField
          styleProps={inputProps}
          label='Por Plano Ativo'
          field='planos'
          xs={12}
          md={3}
          formik={formProps}
        />

        <FormTextField
          styleProps={inputProps}
          label='Por Associado'
          field='associados'
          xs={12}
          md={3}
          formik={formProps}
        />

        <FormTextField
          styleProps={inputProps}
          label='Por Notificação enviada'
          field='notificacoes'
          xs={12}
          md={3}
          formik={formProps}
        />

        <FormTextField
          styleProps={inputProps}
          label='Por Atividade criada'
          field='atividades'
          xs={12}
          md={3}
          formik={formProps}
        />

        <FormTextField
          styleProps={inputProps}
          label='Por Venda Manual'
          field='vendaManual'
          xs={12}
          md={3}
          formik={formProps}
        />

        <FormTextField
          styleProps={inputProps}
          label='Por cada Compra na Farol'
          field='comprasFarol'
          xs={12}
          md={3}
          formik={formProps}
        />

        <FormTextField
          styleProps={inputProps}
          label='Por cada Venda na Loja'
          field='vendaOnline'
          xs={12}
          md={3}
          formik={formProps}
        />

        <FormTextField
          styleProps={inputProps}
          label='Por cada Venda de Plano'
          field='vendaPlano'
          xs={12}
          md={3}
          formik={formProps}
        />

        <FormTextField
          styleProps={inputProps}
          label='Montante Total Vendido por R$'
          field='totalVendido'
          xs={12}
          md={3}
          formik={formProps}
        />

        <FormTextField
          styleProps={inputProps}
          label='Split que a Farol recebeu por R$'
          field='splitFarol'
          xs={12}
          md={3}
          formik={formProps}
        />

        <FormTextField
          styleProps={inputProps}
          label='Por Carteirinha Criada'
          field='carteirinhas'
          xs={12}
          md={3}
          formik={formProps}
        />

        <Button
          fullWidth
          onClick={handleSubmit}
          className={classes.forgot}
          color='primary'
          variant='contained'>
          Salvar & Calcular
        </Button>
      </FormGroup>
    </form>
  );
};

export default Form;
