import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    position: 'relative',
    opacity: 0.9,
    top: 0,
    left: 0,
    display: 'flex',
    zIndex: 3,
  },

  overlay: {
    background: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  spinner: {
    color: theme.palette.primary.dark,
    flex: 1,
  },
}));
