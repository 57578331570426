/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import SearchField from 'components/_Common/SearchContainer';
import Dialog from 'components/_Common/Dialog';
import LazyList from 'components/_Common/LazyList';
import Entidade from './Entidade';
import * as Actions from '../redux/actions';
import TopBar from './TopBar';
import { useActions, useSelector } from 'hooks';

function ListaEntidades(props) {
  const actions = useActions(Actions);
  const { listData, entidades } = useSelector('entidades');
  const [entidade, setEntidade] = useState(null);
  const [data, setData] = useState(listData);

  useEffect(() => {
    setData(listData);
  }, [listData]);
  return (
    <>
      <SearchField
        data={entidades}
        setData={actions.changeList}
        placeholder='Pesquise qualquer Entidade'
        fields={[
          'nome',
          'apelido',
          'instituicao',
          'url',
          'createdAt',
          'endereco.uf',
          'endereco.rua',
          'endereco.numero',
          'endereco.cidade',
          'endereco.bairro',
          'endereco.cep',
          'fone.contatoCadastro',
          'fone.telefone1',
          'fone.telefone2',
          'fone.whatsapp',
          'representante.nome',
          'representante.email',
          'representante.cpf',
          'user.email',
          'user.nome',
        ]}
        depth={4}>
        <TopBar />
      </SearchField>

      {entidade ? (
        <div>
          <Dialog
            title={`Mover ${entidade.nome} para a lixeira?`}
            message='A Entidade será movida para a lixeira e não receberá e-mails e notificações.'
            no='Cancelar'
            yes='SIM'
            action={() => actions.trash(entidade)}
            close={() => setEntidade(null)}
          />
        </div>
      ) : null}

      <LazyList data={data} Item={Entidade} actions={actions} rest={setEntidade} />
    </>
  );
}

export default ListaEntidades;
