import React, { useState } from 'react';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const SearchField = props => {
  const classes = useStyles();
  const [filter, setFilter] = useState('');

  const placeholder = props.placeholder ? props.placeholder : '';
  const trigger = useScrollTrigger({ disableHysteresis: true });

  const style = trigger
    ? {
        position: 'fixed',
        top: 50,
        left: 75,
        width: '100%',
        background: '#f9fcff',
        padding: '2em 7em 0em 0',
      }
    : {};

  const handleFilter = () => event => {
    const term = event.target.value;
    setFilter(term);
    props.handle(term);
  };

  return (
    <div className={classes.container} style={style}>
      <div className={classes.search}>
        <TextField
          fullWidth
          id='filter'
          label='Pesquisar'
          value={filter}
          onChange={handleFilter()}
          variant='outlined'
          type='search'
          placeholder={placeholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <FontAwesomeIcon icon={faSearch} fixedWidth />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default SearchField;
