import { CANCEL as BACK } from 'components/Associados/redux/types';

export const REQUEST = 'ENTIDADE_REQUEST';
export const SUCCESS = 'ENTIDADE_SUCCESS';
export const FAILURE = 'ENTIDADE_FAILURE';

export const CANCEL = 'ENTIDADE_CANCEL';

export const SHOW_PERFIL = 'ENTIDADE_SHOW_PERFIL';
export const LOAD_ENTIDADES = 'ENTIDADE_LOAD_ENTIDADES';
export const CHANGE_VIEW = 'ENTIDADE_CHANGE_VIEW';
export const CHANGE_LIST = 'ENTIDADE_CHANGE_LIST';

// Ações em massa
export const SHOW_SELECT = 'ENTIDADE_SHOW_SELECT';
export const SET_SELECT = 'ENTIDADE_SET_SELECT';
export const SHOW_CONFIRM = 'ENTIDADE_SHOW_CONFIRM';

export const FILTRA_PLANO = 'ENTIDADE_FILTRA_PLANO';
export const FILTRO_GERENCIAL = 'ENTIDADE_FILTRO_GERENCIAL';
export const FILTRO_LOCALIZACAO = 'ENTIDADE_FILTRO_LOCALIZACAO';
export const FILTRA_STATUS = 'ENTIDADE_FILTRA_STATUS';
export const ORDENA = 'ENTIDADE_ORDENA';

export const SHOW_ASSOCIADOS_BACK = BACK;

export const VIEW = 'ENTIDADE_VIEW';
export const ADD_CREDITOS = 'ENTIDADE_ADD_CREDITOS';
