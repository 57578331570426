import React from 'react';
import Grid from '@material-ui/core/Grid';
import { DateTimePicker } from '@material-ui/pickers';
import { getSafe, propsAreEqual } from '_helpers/utils';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import brLocale from 'date-fns/locale/pt-BR';

const FormDatePicker = (props) => {
  const { label, field, formik, xs, md, disabled } = props;
  const value = getSafe(formik.values, field);
  const touched = true;
  const error = getSafe(formik.errors, field, false);
  const change = formik.change;
  const helperText = props.helperText ? props.helperText : '';

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
      <Grid item xs={xs} md={md}>
        <DateTimePicker
          id={field}
          name={field}
          helperText={touched ? error : helperText}
          error={Boolean(touched) && Boolean(error)}
          label={label}
          fullWidth
          value={value !== null ? value : ''}
          onChange={change.bind(null, field)}
          disablePast={true}
          ampm={false}
          inputVariant="outlined"
          disabled={disabled}
          minDate={new Date()}
          format="dd/MM/yy - HH:mm"
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default React.memo(FormDatePicker, propsAreEqual);
