export default (theme) => ({
  doc: {
    marginTop: '1em',
  },

  fIcon: {
    // color: '#bababa',
    marginRight: '0.5em',
  },

  emAnalise: {
    color: theme.palette.secondary.main,
    fontWeight: '500',
  },
});
