import {
  SHOW_CREATE,
  SHOW_COPY,
  CANCEL,
  SHOW_EDIT,
  REQUEST,
  SUCCESS,
  FAILURE,
  COMPACT,
} from './types';

const INITIAL_STATE = {
  planos: [],
  plano: {},
  create: false,
  copy: false,
  edit: false,
  loading: {
    get: false,
    create: false,
    edit: false,
    id: false,
  },
  compact: true,
};

const loading = { get: false, create: false, edit: false };

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SHOW_CREATE:
      return { ...state, create: true, edit: false, copy: false };

    case SHOW_COPY:
      return {
        ...state,
        copy: true,
        create: false,
        edit: false,
        plano: payload,
      };

    case SHOW_EDIT:
      return {
        ...state,
        copy: false,
        create: false,
        edit: true,
        plano: payload,
      };

    case REQUEST: {
      const loading = { ...state.loading, ...payload.loading };
      return { ...state, loading };
    }

    case SUCCESS: {
      const { planos } = payload;
      return {
        ...state,
        planos,
        loading,
        plano: {},
        edit: false,
        create: false,
        copy: false,
      };
    }

    case FAILURE: {
      return { ...state, loading };
    }
    case COMPACT: {
      return { ...state, compact: !state.compact };
    }

    case CANCEL:
      return {
        ...state,
        loading,
        plano: {},
        edit: false,
        create: false,
        copy: false,
      };

    default:
      return state;
  }
};
