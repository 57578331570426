export default (theme) => ({
  root: {
    padding: theme.spacing(2),
    minHeight: '100%',
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '30%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '20%',
    },
  },

  logo: {
    width: '40%',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '40%',
      marginTop: '2em',
      marginBottom: '2em',
    },
  },

  socialControl: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },

  fab: {
    margin: theme.spacing(1),
  },

  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  center: {
    color: 'white',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },

  forgot: {
    color: 'white',
    fontSize: '10px',
    textDecoration: 'none',
  },

  btnCriarConta: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: 0,
    left: 0,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  pullLeft: {
    position: 'absolute',
    left: theme.spacing(2),
  },

  // cssLabel: {
  //   '&$cssFocused': {
  //     color: 'white',
  //   },
  // },

  // cssFocused: {},

  // cssUnderline: {
  //   '&:after': {
  //     borderBottomColor: 'white',
  //   },
  // },

  // cssOutlinedInput: {
  //   color: 'white',
  //   // '&$cssFocused $notchedOutline': {
  //   //   borderColor: 'white',
  //   // },
  // },
  // notchedOutline: {},
});
