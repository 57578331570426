import { combineReducers } from 'redux';
import login from 'components/Login/redux/reducer';
import feedback from 'components/_Common/Feedback/redux/reducer';
import cupons from 'components/Cupons/redux/reducer';
import planos from 'components/Planos/redux/reducer';
import entidades from 'components/Entidades/redux/reducer';
import associados from 'components/Associados/redux/reducer';
import notificacoes from 'components/Notificacoes/redux/reducer';
import suporte from 'components/Suporte/redux/reducer';
import parceiros from 'components/Parceiros/redux/reducer';
import financeiro from 'components/Financeiro/redux/reducer';
import financeiroEntidade from 'components/Entidades/Financeiro/redux/reducer';
import sellers from 'components/Sellers/redux/reducer';
import linkCobranca from 'components/LinkCobranca/redux/reducer';
import marketplace from 'components/Marketplace/redux/reducer';
import cancelamentos from 'components/Cancelamentos/redux/reducer';
import pagamentos from 'components/Pagamentos/redux/reducer';
import scores from 'components/Dashboard/Score/redux/reducer';

const rootReducer = combineReducers({
  login,
  feedback,
  cupons,
  planos,
  entidades,
  associados,
  notificacoes,
  suporte,
  parceiros,
  financeiro,
  financeiroEntidade,
  sellers,
  linkCobranca,
  marketplace,
  cancelamentos,
  pagamentos,
  scores,
});

export default rootReducer;
