/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './redux/actions';
import Lista from './Lista';
import Editar from './Editar';
import Novo from './Novo';
import Excluir from './Excluir';

const Notificacoes = (props) => {
  const { showCreate, showEdit, showTrash } = props;

  useEffect(() => {
    return () => {
      props.actions.cancel();
    };
  }, []);

  if (showCreate) return <Novo />;
  if (showEdit) return <Editar />;

  return (
    <>
      <Lista />
      <Excluir open={showTrash} />
    </>
  );
};

const mapStateToProps = (state) => {
  const store = state.notificacoes;
  return {
    showCreate: store.showCreate,
    showEdit: store.showEdit,
    showTrash: store.showTrash,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notificacoes);
