import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { bindActionCreators } from 'redux';
import * as Actions from './redux/actions';
import { connect } from 'react-redux';
import styles from './styles';
import { FormGroup, FormTextField } from 'components/_Common/Form';
import Spinner from 'components/_Common/Spinner';

const Form = (props) => {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldTouched,
    classes,
    actions,
  } = props;

  const change = (name, e) => {
    e.persist();
    setFieldTouched(name, true, false);
    handleChange(e);
  };

  const handleKeyDown = (e) => {
    e.persist();
    if (e.key === 'Enter') {
      actions.login(values);
    }
  };

  const formProps = {
    errors,
    touched,
    values: props.values,
    change,
  };

  const styleProps = {
    InputLabelProps: {
      classes: {
        root: classes.cssLabel,
        focused: classes.cssFocused,
      },
    },
    InputProps: {
      classes: {
        root: classes.cssOutlinedInput,
        focused: classes.cssFocused,
        notchedOutline: classes.notchedOutline,
      },
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <FormTextField
          label="Email"
          field="email"
          xs={12}
          md={12}
          formik={formProps}
          type="email"
          styleProps={styleProps}
        />

        <FormTextField
          label="Senha"
          field="password"
          xs={12}
          md={12}
          formik={formProps}
          type="password"
          onKeyDown={handleKeyDown}
          styleProps={styleProps}
        />
      </FormGroup>

      {props.loggingIn ? (
        <Spinner size="2x" noBox />
      ) : (
        <Button
          size="large"
          fullWidth
          variant="contained"
          onClick={() => actions.login(values)}
          color="primary"
          className={classes.button}>
          <FontAwesomeIcon
            icon={faSignInAlt}
            className={classes.pullLeft}
            fixedWidth
            size="lg"
          />
          Entrar
        </Button>
      )}
    </form>
  );
};

const mapStateToProps = (state) => {
  const store = state.login;
  return {
    loggingIn: store.loggingIn,
    loggedIn: store.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

const connectedForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);

export default withStyles(styles)(connectedForm);
