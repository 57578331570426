import React, { useState, useEffect, useRef } from 'react';
import sort from 'fast-sort';
import useStyles from './styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector, useActions, useFetch } from 'hooks';
import * as Actions from 'components/Associados/redux/actions';

export default function Filtro(props) {
  const classes = useStyles();
  const actions = useActions(Actions);
  const { associados, localizacao } = useSelector('associados');

  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = (event) => {
    actions.filtroLocalizacao(event.target.value);
  };

  const options = [];

  for (const associado of associados) {
    const UF = associado?.endereco?.uf ?? null;
    if (UF) {
      if (!options.includes(UF.toUpperCase())) {
        if (UF !== '' && UF !== ' ') options.push(UF.toUpperCase());
      }
    }
  }

  const estados = sort(options).asc();

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel ref={inputLabel} id='filtra-localizacao'>
          Localização
        </InputLabel>
        <Select
          labelId='filtra-localizacao'
          id='localizacao'
          value={localizacao}
          onChange={handleChange}
          labelWidth={labelWidth}>
          <MenuItem value={'todos'}>Todos</MenuItem>
          {estados.map((uf) => {
            return <MenuItem value={uf}>{uf}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </div>
  );
}
