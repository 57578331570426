/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './redux/actions';
import Lista from './Lista';
import Editar from './Editar';
import Novo from './Novo';
import Excluir from './Excluir';
import Spinner from 'components/_Common/Spinner';

const Cupons = (props) => {
  const actions = bindActionCreators(Actions, useDispatch());
  const { cupons, planos, create, edit, trash, loading } = useSelector(
    ({ cupons }) => ({
      ...cupons,
    })
  );

  useEffect(() => {
    if (cupons.length === 0 || planos.length === 0) {
      actions.loadCupons();
    }
  }, []);

  useEffect(() => {
    return () => {
      actions.cancel();
    };
  }, []);

  if (loading) {
    return <Spinner text="Processando..." size="2x" noBox />;
  }
  if (create) return <Novo />;
  if (edit) return <Editar />;

  return (
    <>
      <Lista />
      <Excluir open={trash} />
    </>
  );
};

export default Cupons;
