/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBarcode,
  faCreditCard,
  faExchangeAlt,
  faMobileAlt,
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';

const PayMethodIcon = (props) => {
  const { classes, pedido } = props;
  const { metodo, link } = pedido;

  let metdIcon = faMobileAlt;
  switch (metodo) {
    case 'Cartão':
      metdIcon = faCreditCard;
      break;
    case 'Boleto':
      metdIcon = faBarcode;
      break;
    case 'Conta Digital':
      metdIcon = faMobileAlt;
      break;
    case 'Transferência':
      metdIcon = faExchangeAlt;
      break;

    default:
      break;
  }

  const openLink = () => {
    window.open(link, '_blank');
  };

  return (
    <Tooltip title={`Ver ${metodo}`} placement="top">
      <div className={classes.metodo} onClick={openLink}>
        <FontAwesomeIcon icon={metdIcon} fixedWidth />
        <div className={classes.metdText}>{metodo}</div>
      </div>
    </Tooltip>
  );
};

export default withStyles(styles)(PayMethodIcon);
