import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as local from '_helpers/local';
import * as Actions from '../redux/actions';
import Notificacao from '../Notificacao';
import styles from './styles';
import Spinner from 'components/_Common/Spinner';

const Cupons = (props) => {
  const { classes, actions } = props;

  const [notificacoes, setNotificacoes] = useState(local.notificacoes());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // window.scrollTo(0, 0);
    setNotificacoes(local.notificacoes());
    setLoading(false);
  }, []);

  if (loading) {
    return <Spinner size="lg" style={{ color: 'black' }} />;
  }

  return (
    <div className={classes.container}>
      {notificacoes.map((notificacao) => {
        return <Notificacao key={notificacao._id} notificacao={notificacao} />;
      })}

      <Fab
        color="primary"
        aria-label="Criar novo"
        variant="extended"
        className={classes.fab}
        onClick={() => actions.showCreate()}>
        <FontAwesomeIcon icon={faPlus} fixedWidth />
        Nova notificação
      </Fab>
    </div>
  );
};

const mapStateToProps = (state) => {
  const store = state.notificacoes;
  return {
    showCreate: store.showCreate,
    showEdit: store.showEdit,
    showDelete: store.showDelete,
    showTrash: store.showTrash,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Cupons));
