import React, { useState } from 'react';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faTimes } from '@fortawesome/free-solid-svg-icons';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fab from '@material-ui/core/Fab';
import Tab from '../Tab';

function TabBar(props) {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(props.active || 0);
  const tabs = props.children;

  const renderTabs = () => {
    return (
      <>
        {tabs.map((tab, index) => {
          return (
            <Tab {...tab.props} index={index} setActive={setActive} key={index}>
              {active === index || tab.props.active ? (
                <div className={classes.active}></div>
              ) : (
                <div className={classes.notActive}></div>
              )}
              {isMobile && !active ? <div className={classes.divider}></div> : null}
            </Tab>
          );
        })}
      </>
    );
  };

  if (isMobile) {
    return (
      <>
        {open ? (
          <Fade in={open}>
            <div className={classes.overlay} onClick={() => setOpen(!open)}>
              <Slide direction='left' in={open} mountOnEnter unmountOnExit>
                <div className={classes.mobile} onClick={() => setOpen(!open)}>
                  <div className={classes.grow}></div>
                  {renderTabs()}
                </div>
              </Slide>
            </div>
          </Fade>
        ) : null}

        <Fab
          color={open ? 'secondary' : 'primary'}
          aria-label='Add'
          className={classes.fab}
          onClick={() => setOpen(!open)}>
          <FontAwesomeIcon icon={open ? faTimes : faTools} fixedWidth size='lg' />
        </Fab>
      </>
    );
  }
  return <div className={classes.container}>{renderTabs()}</div>;
}

export default TabBar;
