import React from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './redux/actions';
import validationSchema from './Novo/validation';
import { withStyles } from '@material-ui/core';
import styles from './Novo/styles';
import Form from './Novo/form';

const EditaParceiro = (props) => {
  const { classes } = props;
  const actions = bindActionCreators(Actions, useDispatch());
  const { parceiro } = useSelector(({ parceiros }) => ({
    ...parceiros,
  }));

  const values = { ...parceiro };

  return (
    <div className={classes.formContainer}>
      <Formik
        render={(props) => (
          <Form
            title={'Editar parceiro'}
            handleCancel={actions.cancel}
            {...props}
          />
        )}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          actions.edit(values);
        }}
      />
    </div>
  );
};

export default withStyles(styles)(EditaParceiro);
