import React from 'react';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Grow from '@material-ui/core/Grow';

function LoaderFinanceiro(props) {
  const classes = useStyles();

  const text = props.text ? props.text : 'Aguarde';

  return (
    <Grow in={true}>
      <div className={classes.container}>
        <div className={classes.spinner}>
          <FontAwesomeIcon icon={faSpinner} pulse fixedWidth />
        </div>
        <div className={classes.right}>
          <div className={classes.text}>{text}</div>
        </div>
      </div>
    </Grow>
  );
}

export default LoaderFinanceiro;
