/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './redux/actions';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ParceiroCard from './ParceiroCard';
import Novo from './Novo';
import Editar from './Editar';
import styles from './styles';
import Spinner from 'components/_Common/Spinner';

const Parceiros = (props) => {
  const { classes } = props;

  const actions = bindActionCreators(Actions, useDispatch());
  const { loading, parceiros, create, edit, parceiro } = useSelector(
    ({ parceiros }) => ({
      ...parceiros,
    })
  );

  useEffect(() => {
    if (parceiros.length === 0) {
      actions.loadParceiros();
    }
  }, []);

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
      actions.cancel();
    };
  }, []);

  if (loading) {
    return <Spinner text="Processando..." size="2x" noBox />;
  }

  if (create) return <Novo />;
  if (edit) return <Editar parceiro={parceiro} />;

  return (
    <>
      <div className={classes.container} elevation={1}>
        {parceiros.map((parceiro, index) => (
          <ParceiroCard
            parceiro={parceiro}
            key={parceiro._id}
            actions={actions}
          />
        ))}
      </div>
      <Fab
        variant="extended"
        color="primary"
        aria-label="Criar novo"
        onClick={() => actions.showCreate()}
        className={classes.fab}>
        <FontAwesomeIcon icon={faPlus} fixedWidth />
        novo parceiro
      </Fab>
    </>
  );
};

export default withStyles(styles)(Parceiros);
