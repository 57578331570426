export default theme => ({
  dashboard: {
    marginBottom: '10em',
  },

  ticket: {
    padding: '0.5em',
    width: 'calc(40vw - 73px)',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100vw - 73px)',
    },
  },

  card: {
    marginBottom: '1em',
  },

  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },

  top: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  titulo: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },

  tText: {
    flex: 1,
  },

  number: {
    color: 'gray',
  },

  Enviado: {
    display: 'flex',
    color: theme.palette.error.dark,
    flex: 1,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flex: 2,
    },
  },

  Analisando: {
    display: 'flex',
    color: theme.palette.primary.dark,
    flex: 1,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flex: 2,
    },
  },

  Encerrado: {
    display: 'flex',
    color: '#008819',
    flex: 1,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flex: 2,
    },
  },

  tipo: {
    textAlign: 'center',
  },

  statusIcon: {
    marginTop: '0.2em',
    marginLeft: '0.5em',
  },

  bottom: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '0px',
  },

  ticketsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: '3em',
    flexDirection: 'column',
  },

  extendedIcon: {
    marginRight: theme.spacing(1),
  },

  resposta: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  mensagem: {
    marginTop: '1em',
    marginBottom: '1em',
  },

  mensagemChat: {
    textAlign: 'right',
    background: '#f3f3f3',
    borderRadius: '5px',
    padding: '0.2em',
    color: '#000000ab',
    paddingRight: '2em',
    paddingLeft: '2em',
    marginLeft: '4em',
    marginTop: '0.5em',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0.2em',
      paddingRight: '1em',
      marginLeft: '1em',
    },
  },

  respostaChat: {
    // flex: 1,
    alignSelf: 'start',
    paddingRight: '2em',
    marginRight: '4em',
    textAlign: 'left',
    background: '#c2e3ff36',
    borderRadius: '5px',
    padding: '0.2em',
    paddingLeft: '0.5em',
    marginTop: '0.5em',
    marginBottom: '0.2em',
    color: '#000000ab',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0.5em',
      paddingRight: '1em',
    },
  },

  msgText: {
    color: 'black',
    textAlign: 'left',
    '& > div': {
      width: '100%',
      display: 'flex',
      cursor: 'pointer',
    },

    '& > div > img': {
      maxWidth: '200px',
      maxHeight: '250px',
      margin: '0 auto',
    },
  },

  respApelido: {
    flex: 1,
  },

  respText: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
    textAlign: 'left',
    // fontWeight: '400',
    color: 'black',
  },

  respostaWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },

  mensagemWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  avatarCapitao: {
    marginTop: '1.1em',
    color: 'grey',
    fontSize: '1.1em',
    marginLeft: '0.5em',
  },

  avatar: {
    marginTop: '1em',
    color: 'lightgrey',
  },

  img: {
    marginRight: '0.5em',
    width: '25px',
  },

  containerImage: {
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
  },

  imagePreview: {
    maxWidth: '200px',
    maxHeight: '250px',
    margin: '0 auto',
  },
});
