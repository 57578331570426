import * as Yup from 'yup';
import isValidPhone from '@brazilian-utils/is-valid-phone';
import {
  isValidCpf,
  isValidCnpj,
  isValidCep,
} from '@brazilian-utils/validators';

const validationSchema = Yup.object({
  user: Yup.object().shape({
    email: Yup.string('')
      .email('Email incorreto')
      .min(5, 'Muito curto')
      .max(60, 'Muito longo')
      .required('* Obrigatório'),
  }),

  tipo: Yup.string('').required('* Obrigatório'),

  outros: Yup.string().when('tipo', {
    is: (value) => value === 'Outros',
    then: Yup.string('')
      .min(2, 'Tipo inválido')
      .max(30, 'Tipo inválido')
      .required('Este campo é obrigatório'),
  }),

  nome: Yup.string('')
    .max(60, 'Muito longo')
    .required('* Obrigatório'),

  apelido: Yup.string('')
    .min(2, 'Muito curto')
    .max(60, 'Muito longo')
    .required('* Obrigatório'),

  qtdAlunos: Yup.number()
    .typeError('Quantidade incorreta')
    .positive('Quantidade incorreta')
    .required('* Obrigatório'),

  instituicao: Yup.string('').required('* Obrigatório'),

  fone: Yup.object().shape({
    contatoCadastro: Yup.string('').test(
      'test-name',
      'Telefone inválido',
      function(value) {
        if (value) {
          return value.includes('+') && value.length > 7
            ? true
            : isValidPhone(value);
        }
      }
    ),

    whatsapp: Yup.string('').test('test-name', 'Telefone inválido', function(
      value
    ) {
      if (value) {
        return value.includes('+') && value.length > 7
          ? true
          : isValidPhone(value);
      }
    }),

    telefone1: Yup.string('').test('test-name', 'Telefone inválido', function(
      value
    ) {
      if (value) {
        return value.includes('+') && value.length > 7
          ? true
          : isValidPhone(value);
      }
    }),

    telefone2: Yup.string('').test('test-name', 'Telefone inválido', function(
      value
    ) {
      if (value) {
        return value.includes('+') && value.length > 7
          ? true
          : isValidPhone(value);
      }
    }),
  }),

  cnpj: Yup.string('').test('test-name', 'CNPJ inválido', function(value) {
    if (value === undefined) {
      return true;
    }
    return isValidCnpj(value);
  }),

  endereco: Yup.object().shape({
    cep: Yup.string('')
      .test('test-name', 'CEP inválido', function(value) {
        return isValidCep(value);
      })
      .required('* Obrigatório'),

    rua: Yup.string('')
      .min(3, 'Rua incorreta')
      .max(60, 'Nome da Rua muito longo')
      .required('* Obrigatório'),

    numero: Yup.string('')
      .min(1, 'Número inválido')
      .max(8, 'Número inválido')
      .required('* Obrigatório'),

    complemento: Yup.string('')
      .min(1, 'Complemento inválido')
      .max(20, 'Complemento inválido'),

    uf: Yup.string('')
      .min(2, 'UF inválido')
      .max(2, 'UF inválido')
      .required('* Obrigatório'),

    cidade: Yup.string()
      .min(3, 'Muito curto')
      .max(30, 'Muito longo')
      .required('* Obrigatório'),

    bairro: Yup.string()
      .min(3, 'Muito curto')
      .max(30, 'Muito longo')
      .required('* Obrigatório'),
  }),

  representante: Yup.object().shape({
    nome: Yup.string('')
      .min(3, 'Muito curto')
      .max(30, 'Muito longo')
      .required('* Obrigatório'),
    cpf: Yup.string('')
      .test('test-name', 'CPF inválido', function(value) {
        return isValidCpf(value);
      })
      .required('* Obrigatório'),

    email: Yup.string('')
      .email('Email incorreto')
      .min(5, 'Muito curto')
      .max(60, 'Muito longo')
      .required('* Obrigatório'),
  }),
});

export default validationSchema;
