/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import LazyList from 'components/_Common/LazyList';
import LinkRow from './LinkRow';

function ListaLinks(props) {
  const { links } = props;

  return <LazyList data={links} Item={LinkRow} />;
}

export default ListaLinks;
