/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';
import Spinner from 'components/_Common/Spinner';
import Lista from './Lista';
import Lote from './Lote';
import useStyles from './styles';

function Pedidos(props) {
  const classes = useStyles();
  const { pedidos, loading } = useSelector(({ financeiro }) => ({
    ...financeiro,
  }));
  const actions = bindActionCreators(Actions, useDispatch());

  const [option, setOption] = useState('lista');

  // useEffect(() => {
  //   actions.listPedidos();
  // }, []);

  useEffect(() => {
    setOption('lista');
  }, [pedidos]);

  const showOption = () => {
    switch (option) {
      case 'lista':
        return <Lista />;
      case 'lote':
        return <Lote />;

      default:
        return <Lista />;
    }
  };

  if (loading) {
    return <Spinner noBox text='Carregando Pedidos' />;
  }

  return (
    <>
      <div className={classes.options}>
        <Button
          size='small'
          variant={option === 'lista' ? 'outlined' : null}
          onClick={() => setOption('lista')}
          color='secondary'
          className={classes.button}>
          Ver pedidos
        </Button>
        <Button
          size='small'
          variant={option === 'lote' ? 'outlined' : null}
          onClick={() => setOption('lote')}
          color='secondary'
          className={classes.button}>
          Emitir pedidos em lote
        </Button>
      </div>
      {showOption()}
    </>
  );
}

export default Pedidos;
