import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginBottom: '4em',
    flexDirection: 'column',
  },

  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 10,
  },

  extendedIcon: {
    marginRight: theme.spacing(1),
  },

  avisoCotas: {
    margin: '1em 0 1.5em 0',
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    fontWeight: '300',
    textAlign: 'center !important',
  },

  table: {
    minWidth: '500px',
  },

  compact: {
    width: 275,
    marginBottom: '1em',
    marginLeft: '1em',
  },

  icon: {
    marginRight: '0.5em',
  },
}));
