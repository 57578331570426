/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import validationSchema from './validation';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';
import styles from './styles';
import Form from './form';
import Spinner from 'components/_Common/Spinner';

const NovaNotificacao = props => {
  window.scrollTo(0, 0);
  const { classes } = props;

  const actions = bindActionCreators(Actions, useDispatch());
  const { planos, loading } = useSelector(({ planos }) => ({
    ...planos,
  }));

  useEffect(() => {
    if (planos.length === 0) {
      actions.loadPlanos();
    }
  }, []);

  if (loading.get) {
    return <Spinner text='Atualizando lista de Planos...' size='2x' noBox />;
  }

  return (
    <div className={classes.formContainer}>
      <Formik
        render={props => (
          <Form
            title='Nova Notificação'
            handleCancel={actions.cancel}
            planos={planos}
            {...props}
          />
        )}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={values => {
          actions.create(values);
        }}
      />
    </div>
  );
};

const values = {
  titulo: '',
  tipo: {
    simples: false,
    popup: false,
  },
  metodo: false,
  nivel: {
    entidades: false,
    associados: false,
  },
  planos: [],
  mensagem: '',
};

export default withStyles(styles)(NovaNotificacao);
