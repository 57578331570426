/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useStyles from './styles';
import { useSelector, useActions, useFetch } from 'hooks';
import * as Actions from 'components/Dashboard/Score/redux/actions';
import { Formik } from 'formik';
import Form from './form';
import SimpleModal from 'components/_Common/SimpleModal';

function Score(props) {
  const classes = useStyles();
  const actions = useActions(Actions);
  const { pesos, loading } = useSelector('scores');
  useFetch({ pesos });
  const values = { ...pesos };

  return (
    <div className={classes.formContainer}>
      <SimpleModal
        title='Definir pesos para cálculo de scores'
        button={{
          text: 'Definir pesos',
        }}
        disabled={{
          value: loading,
          text: 'Carregando dados...',
        }}>
        <Formik
          render={(props) => <Form title='Definição de Pesos' {...props} />}
          initialValues={values}
          onSubmit={(values) => {
            actions.setPesos(values);
          }}
        />
      </SimpleModal>
    </div>
  );
}

export default Score;
