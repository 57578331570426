const replacer = (name, value) => {
  return value !== null && value !== undefined ? value : '';
};

const setItem = (name, data) => {
  localStorage.setItem(name, JSON.stringify(data, replacer));
};

const getItem = (name, deflt) => {
  try {
    const res = JSON.parse(localStorage.getItem(name));
    if (res === '' || res === null || res === undefined) {
      return deflt;
    } else {
      return res;
    }
  } catch (error) {
    return deflt;
  }
};

// ***ADMIN
export function setAdmin(admin) {
  setItem('admin', admin);
}
export function admin() {
  return getItem('admin', {});
}

// ***ADMIN TOKEN
export function setAdminToken(adminToken) {
  setItem('adminToken', adminToken);
}
export function adminToken() {
  return getItem('adminToken', {});
}

// ***ENTIDADES
export function setEntidades(entidades) {
  setItem('entidades', entidades);
}
export function entidades() {
  return getItem('entidades', []);
}

// ***PLANOS
export function setPlanos(planos) {
  setItem('planos', planos);
}
export function planos() {
  return getItem('planos', []);
}

// ***CUPONS
export function setCupons(cupons) {
  setItem('cupons', cupons);
}
export function cupons() {
  return getItem('cupons', []);
}

// ***ASSOCIADOS
export function setAssociados(associados) {
  setItem('associados', associados);
}
export function associados() {
  return getItem('associados', []);
}

// ***NOTIFICACOES
export function setNotificacoes(notificacoes) {
  setItem('notificacoes', notificacoes);
}
export function notificacoes() {
  return getItem('notificacoes', []);
}

// ***TICKETS
export function setTickets(tickets) {
  setItem('tickets', tickets);
}
export function tickets() {
  return getItem('tickets', []);
}

// ***PARCEIROS
export function setParceiros(parceiros) {
  setItem('parceiros', parceiros);
}
export function parceiros() {
  return getItem('parceiros', []);
}
