import React, { useState, memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import MiniAvatar from 'components/_Common/MiniAvatar';
import SearchField from 'components/_Common/SearchField';
import { filterData } from '_helpers/utils';
import Edicao from './Edicao';
import styles from './styles';
import { VariableSizeList as LazyList, areEqual } from 'react-window';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function Lote(props) {
  const { classes } = props;

  const [view, setView] = useState('selecao');

  const { entidades } = useSelector(({ entidades }) => ({
    ...entidades,
  }));

  const enabledEntidades = entidades.filter((entidade) => {
    if (entidade.user && !entidade.user.blocked && entidade.user.isVerified) return true;
    return false;
  });

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(enabledEntidades);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleFilter = (term) => {
    if (term && term !== '') {
      setLeft(filterData(term, left));
    } else {
      setLeft(not(enabledEntidades, right));
    }
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  if (leftChecked.length !== 0) {
    handleCheckedRight();
  }

  if (rightChecked.length !== 0) {
    handleCheckedLeft();
  }

  const Row = memo((props) => {
    const { data, index, style } = props;
    const entidade = data[index];
    return (
      <div style={style}>
        <ListItem
          key={entidade._id}
          role="listitem"
          // button
          className={classes.item}
          onClick={handleToggle(entidade)}>
          <ListItemIcon>
            <MiniAvatar entidade={entidade} />
          </ListItemIcon>
          <ListItemText
            id={entidade._id}
            primary={`${entidade.nome} - ${entidade.apelido}`}
            secondary={entidade.user.email}
          />
        </ListItem>
      </div>
    );
  }, areEqual);

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selecionadas' }}
          />
        }
        title={title}
        subheader={`${items.length}`}
      />
      <Divider />
      <LazyList
        className="List"
        height={500}
        itemCount={items.length}
        itemSize={() => 80}
        width={'100%'}
        itemData={items}>
        {Row}
      </LazyList>
    </Card>
  );

  if (view === 'edicao')
    return <Edicao list={right} handleCancel={() => setView('selecao')} />;

  return (
    <>
      <div>
        <SearchField handle={handleFilter} />
      </div>
      <div className={classes.root}>
        <div className={classes.left}>
          {customList('Todas as Entidades', left)}
        </div>
        <div className={classes.right}>
          {customList('Grupo para Emissão', right)}
          <div className={classes.finalizaSelecao}>
            <Button
              disabled={right.length === 0}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              className={classes.button}
              onClick={() => setView('edicao')}
              aria-label="remover selecionadas">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className={classes.pullLeft}
                fixedWidth
                size="lg"
              />
              Finalizar Seleção
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default withStyles(styles)(Lote);
