/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
  faArrowDown,
  faBarcode,
  faCreditCard,
  faExchangeAlt,
  faMobileAlt,
} from '@fortawesome/free-solid-svg-icons';

const Movimento = (props) => {
  const { classes, movimento, index } = props;
  if (!movimento) return null;
  const { tipo, dia, mes, texto, referencia, metodo, valor } = movimento;

  const background = {
    backgroundColor: index % 2 === 0 ? '#fff' : '#f1f1f1',
  };
  const color = {
    color: tipo === 'C' ? 'green' : 'red',
  };

  let metdIcon = faMobileAlt;
  switch (metodo) {
    case 'Cartão':
      metdIcon = faCreditCard;
      break;
    case 'Boleto':
      metdIcon = faBarcode;
      break;
    case 'Conta Digital':
      metdIcon = faMobileAlt;
      break;
    case 'Transferência':
      metdIcon = faExchangeAlt;
      break;

    default:
      break;
  }

  return (
    <div className={classes.container} style={background}>
      <div className={classes.data}>
        <div className={classes.dia}>{dia}</div>
        <div className={classes.mes}>{mes.toUpperCase()}</div>
      </div>

      <div className={classes.titulo}>
        <div className={classes.texto}>{texto}</div>
        <div className={classes.referencia}>Ref: {referencia}</div>
      </div>

      <div className={classes.metodo}>
        <FontAwesomeIcon
          icon={metdIcon}
          // className={classes.pullLeft}
          fixedWidth
        />
        <div className={classes.metdText}>{metodo}</div>
      </div>

      <div className={classes.valor} style={color}>
        <div className={classes.vIco}>
          <FontAwesomeIcon
            icon={tipo === 'C' ? faArrowUp : faArrowDown}
            style={color}
            className={classes.icon}
            fixedWidth
          />
        </div>
        <div className={classes.vNum}>R$ {valor}</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Movimento);
