import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import isAfter from 'date-fns/isAfter';
import addBusinessDays from 'date-fns/addBusinessDays';
import isToday from 'date-fns/isToday';

const PayStatusLabel = (props) => {
  const { classes, pedido } = props;
  const { status, vencimento } = pedido;

  const checkVcto = () => isAfter(new Date(), addBusinessDays(new Date(vencimento), 1));

  const getStatus = () => {
    if (!status || (status === 'erro' && pedido.erro)) {
      return <div className={classes.error}>Erro</div>;
    }

    if (isToday(new Date(vencimento)) && status === 'pending') {
      return <div className={classes.hoje}>VENCE HOJE</div>;
    }

    if (status === 'pending' && checkVcto()) {
      return <div className={classes.failed}>Vencido</div>;
    }
    switch (status) {
      case 'new':
        return <div className={classes.new}>Novo</div>;

      case 'pending':
        return <div className={classes.pending}>Pendente</div>;

      case 'pre_authorized':
        return <div className={classes.pre_authorized}>Pré Autorizado</div>;

      case 'paid':
        return <div className={classes.succeeded}>Pago</div>;

      case 'failed':
        return <div className={classes.failed}>Falhou</div>;

      case 'reversed':
        return <div className={classes.reversed}>Invertido</div>;

      case 'canceled':
        return <div className={classes.canceled}>Cancelado</div>;

      case 'refunded':
        return <div className={classes.refunded}>Reembolsado</div>;

      case 'dispute':
        return <div className={classes.dispute}>Disputa</div>;

      case 'charged_back':
        return <div className={classes.charged_back}>Charge Back</div>;

      default:
        return pedido.status;
    }
  };

  return <div className={classes.container}>{getStatus()}</div>;
};

export default withStyles(styles)(PayStatusLabel);
