/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Movimento from './Movimento';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRandom } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';
import locale from 'date-fns/locale/pt-BR';
import { getComprador } from 'components/Financeiro/_helpers';

const Movimentacoes = (props) => {
  const { classes } = props;

  const { pedidos } = useSelector(({ financeiro }) => ({
    ...financeiro,
  }));

  const last5Paid = pedidos.filter((p) => p.status === 'paid').slice(0, 5);

  const mov = last5Paid.map((p) => {
    const comprador = getComprador(p);
    return {
      tipo: 'C',
      dia: format(new Date(p.updatedAt), 'dd', { locale }),
      mes: format(new Date(p.updatedAt), 'LLL', { locale }),
      texto: `Recebimento do pedido #${p.numero}`,
      referencia: `${comprador.nome} - (${comprador.type})`,
      metodo: p.metodo,
      valor: p.valor.toFixed(2),
    };
  });

  // const mov1 = {
  //   tipo: 'C',
  //   dia: '10',
  //   mes: 'AGO',
  //   texto: 'Recebimento Assinatura Plano Ouro',
  //   metodo: 'Boleto',
  //   referencia: 'João Antunes - Parcela 1 de 6',
  //   valor: '70,00',
  // };

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <FontAwesomeIcon
          icon={faRandom}
          // className={classes.pullLeft}
          fixedWidth
        />{' '}
        Últimas Movimentações
      </div>
      <Movimento movimento={mov[0]} index={1} />
      <Movimento movimento={mov[1]} index={2} />
      <Movimento movimento={mov[2]} index={3} />
      <Movimento movimento={mov[3]} index={4} />
      <Movimento movimento={mov[4]} index={5} />
    </div>
  );
};

export default withStyles(styles)(Movimentacoes);
