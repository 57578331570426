import {
  SHOW_CREATE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  SHOW_EDIT,
  EDIT_REQUEST,
  EDIT_SUCCESS,
  EDIT_FAILURE,
  SHOW_TRASH,
  TRASH_REQUEST,
  TRASH_SUCCESS,
  TRASH_FAILURE,
  CANCEL,
} from './types';
import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';
import { loadPlanos } from 'components/Planos/redux/actions';

export { loadPlanos };
export const cancel = () => ({ type: CANCEL });
export const showCreate = () => ({ type: SHOW_CREATE });
export const showEdit = (notificacao) => ({
  type: SHOW_EDIT,
  payload: notificacao,
});
export const showTrash = (notificacao) => ({
  type: SHOW_TRASH,
  payload: notificacao,
});

export const create = (notificacao) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_REQUEST });
    // ***SERVICE CALL***
    const res = await service.create(notificacao);
    if (res.success) {
      dispatch(feedback.success(res.msg));
      dispatch({ type: CREATE_SUCCESS });
      // local.setNotificacoes(res.data.notificacoes);
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: CREATE_FAILURE });
    }
  };
};

export const edit = (notificacao) => {
  return async (dispatch) => {
    dispatch({ type: EDIT_REQUEST });
    // ***SERVICE CALL***
    const res = await service.edit(notificacao);
    if (res.success) {
      dispatch(feedback.success(res.msg));
      dispatch({ type: EDIT_SUCCESS });
      // local.setNotificacoes(res.data.notificacoes);
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: EDIT_FAILURE });
    }
  };
};

export const trash = (notificacao) => {
  return async (dispatch) => {
    dispatch({ type: TRASH_REQUEST });
    // ***SERVICE CALL***
    const res = await service.trash(notificacao);
    if (res.success) {
      dispatch(feedback.success(res.msg));
      dispatch({ type: TRASH_SUCCESS });
      // local.setNotificacoes(res.data.notificacoes);
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: TRASH_FAILURE });
    }
  };
};
