/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ReactComponent as Brazil } from '../SVG/brazil.svg';
import { ReactComponent as Earth } from '../SVG/earth.svg';
import { getSafe } from '_helpers/utils';

const startAdornment = (value) => {
  if (value && value.includes('+')) {
    return (
      <InputAdornment position='start'>
        <Earth
          style={{
            width: '20px',
          }}
        />
      </InputAdornment>
    );
  } else {
    return (
      <InputAdornment position='start'>
        <Brazil
          style={{
            width: '25px',
          }}
        />
      </InputAdornment>
    );
  }
};

const PhoneInput = (props) => {
  const { label, field, formik, xs, md, type } = props;
  const value = getSafe(formik.values, field);
  // const touched = getSafe(formik.touched, field);
  const touched = true;
  const error = getSafe(formik.errors, field, false);
  const change = formik.change;
  const tipo = type ? type : 'text';

  return useMemo(
    () => (
      <Grid item xs={xs} md={md}>
        <TextField
          id={field}
          name={field}
          helperText={touched ? error : 'Comece com "+" caso estrangeiro'}
          error={Boolean(touched) && Boolean(error)}
          label={label}
          fullWidth
          value={value}
          onChange={change.bind(null, field)}
          variant='outlined'
          type={tipo}
          InputProps={{ startAdornment: startAdornment(value) }}
          autoComplete='new-password'
        />
      </Grid>
    ),
    [value, error],
  );
};

export default PhoneInput;
