import { REQUEST, SUCCESS, FAILURE } from './types';

const INITIAL_STATE = {
  loading: false,
  pedido: null,
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case REQUEST:
      return { ...state, loading: true };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        pedido: payload.pedido || null,
      };
    case FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};
