import contrato from './contrato';

const defaultState = {
  enabled: true,
  cota: '0',
  creditos: '0',
};

const values = {
  titulo: '',
  slogan: '',
  beneficios: [''],

  pagamento: {
    tipo: '',
    frequencia: '',
  },

  assinatura: {
    tipo: '',
    duracao: 0,
    validade: '',
  },

  servicos: {
    carteirinha: { ...defaultState },
    carteirinhaFisica: { ...defaultState },
    lojinha: { ...defaultState },
    parceiros: { ...defaultState },
    planos: { ...defaultState },
    cupons: { ...defaultState },
    clube: { ...defaultState },
    site: { ...defaultState },
    financeiro: { ...defaultState },
    associados: { ...defaultState },
    manual: { ...defaultState },
    atividades: { ...defaultState },
    sms: { ...defaultState },
    mailing: { ...defaultState },
    notifSistema: { ...defaultState },
    notifPopup: { ...defaultState },
    notifPush: { ...defaultState },
  },
  taxas: {
    boleto: {
      enabled: true,
      transacao: '0.40',
      taxa: '0',
    },
    cartao: {
      enabled: true,
      transacao: '0.40',
      avista: '1.98',
      parcelado: '3.23',
    },
    pix: {
      enabled: true,
      transacao: 0,
      taxa: 0,
    },
    saque: {
      enabled: true,
      cortesia: '12',
      ted: '2.5',
    },
  },

  valor: '',
  contrato: contrato,
};

export default values;
