import * as Yup from 'yup';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import isValidPhone from '@brazilian-utils/is-valid-phone';
import { isValidCpf, isValidCep } from '@brazilian-utils/validators';

export const validationSchema = Yup.object({
  nome: Yup.string('').required('* Obrigatório'),

  nivel: Yup.string('').required('* Obrigatório'),

  email: Yup.string('')
    .email('Email incorreto')
    .max(60, 'Muito longo')
    .required('* Obrigatório'),

  celular: Yup.string('').test('test-name', 'Telefone inválido', function(
    value,
  ) {
    if (value) {
      return value.includes('+') && value.length > 7
        ? true
        : isValidPhone(value);
    } else {
      return true;
    }
  }),

  cpf: Yup.string('').test('test-name', 'CPF inválido', function(value) {
    if (!value) return true;
    return isValidCpf(value);
  }),

  rg: Yup.string('')
    .min(5, 'Muito curto')
    .max(18, 'Muito longo'),

  expeditorRG: Yup.string('').max(18, 'Muito longo'),

  nascimento: Yup.string('').test('teste-nascimento', 'Data inválida', function(
    value,
  ) {
    console.log('Vla', value);
    if (!value) return true;
    const date = new Date(value);
    return isValid(date);
  }),

  instituicao: Yup.string('').required('* Obrigatório'),

  curso: Yup.string('').max(60, 'Muito longo'),

  matricula: Yup.string('').max(60, 'Muito longo'),
});

export const validationAddress = Yup.object({
  endereco: Yup.object().shape({
    cep: Yup.string('')
      .test('cep', 'CEP inválido', function(value) {
        return isValidCep(value);
      })
      .required('* Obrigatório'),

    rua: Yup.string('')
      .min(3, 'Rua incorreta')
      .max(60, 'Nome da Rua muito longo')
      .required('* Obrigatório'),

    numero: Yup.string('')
      .min(1, 'Número inválido')
      .max(8, 'Número inválido')
      .required('* Obrigatório'),

    complemento: Yup.string('')
      .min(0, 'Complemento inválido')
      .max(20, 'Complemento inválido'),

    uf: Yup.string('')
      .min(2, 'UF inválido')
      .max(2, 'UF inválido')
      .required('* Obrigatório'),

    cidade: Yup.string('')
      .min(3, 'Muito curto')
      .max(30, 'Muito longo')
      .required('* Obrigatório'),

    bairro: Yup.string()
      .min(3, 'Muito curto')
      .max(30, 'Muito longo')
      .required('* Obrigatório'),
  }),
});
