import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

function MiniAvatar(props) {
  const { classes, entidade, associado, linkCbr } = props;
  let avatar = '';
  if (entidade) avatar = entidade.avatar;
  if (associado) avatar = associado.avatar;
  if (linkCbr) avatar = linkCbr.QRcode;

  return (
    <div className={classes.center}>
      <img src={avatar} alt='' className={classes.avatar} />
    </div>
  );
}

export default withStyles(styles)(MiniAvatar);
