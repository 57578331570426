/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

function Col(props) {
  const { flex, className } = props;
  return (
    <div style={{ flex, alignSelf: 'flex-start' }} className={className}>
      {props.children}
    </div>
  );
}

export default Col;
