import { LOGIN_REQUEST, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from './types';
import * as local from '_helpers/local';

let user = local.adminToken();

const INITIAL_STATE = {
  loggingIn: false,
  loggedIn: user ? true : false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loggingIn: false,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
      };
    case LOGOUT:
      return { ...state, loggedIn: false };
    default:
      return state;
  }
};
