export default (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    marginBottom: '10em',
  },

  paper: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '10em',
      flexDirection: 'column',
    },
  },

  profile: {
    display: 'flex',
    padding: '2em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '10em',
      flexDirection: 'column',
    },
  },

  left: {
    padding: theme.spacing(2),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid lightgrey',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'unset',
      paddingBottom: '1em',
      borderBottom: '1px solid lightgrey',
      padding: 'unset',
    },
  },

  center: {
    padding: theme.spacing(2),
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid lightgrey',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'unset',
      paddingBottom: '1em',
      borderBottom: '1px solid lightgrey',
      padding: 'unset',
    },
  },

  right: {
    padding: theme.spacing(2),
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 'unset',
    },
  },

  action: {
    marginTop: '1em',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },

  btn: {
    flex: 2,
    display: 'flex',
    justifyContent: 'center',
  },

  buttonIcon: {
    marginRight: '0.5em',
  },

  group: {
    // flex: 1,
    display: 'flex',
    verticalAlign: 'middle',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  label: {
    flex: 2,
    fontWeight: '300',
    verticalAlign: 'middle',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5em',
      marginBottom: '-0.5em',
    },
  },

  info: {
    flex: 3,
    fontWeight: '500',
    verticalAlign: 'middle',
    [theme.breakpoints.down('sm')]: {
      // textAlign: 'center',
    },
  },

  nome: {
    marginTop: '1em',
  },

  avatarPlaceholder: {
    color: 'lightgrey',
    marginTop: '0.8em',
  },

  avatar: {
    textAlign: 'center',
    background: '#fafafa',
    width: '150px',
    height: '150px',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '0',
    boxShadow:
      '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(193, 0, 99,.4)',
  },

  img: {
    display: 'inline',
    margin: '0 auto',
    height: '150px',
    width: 'auto',
  },

  assina: {
    marginTop: '1em',
  },

  plano: {
    // marginBottom: '1em',
  },

  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },

  divider: {
    margin: '0.5em',
  },

  fIconButton: {
    marginRight: '0.5em',
  },

  button: {
    marginTop: '1em',
    alignSelf: 'flex-end',
    width: '70%',
  },

  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',

    color: 'grey',
  },

  url: {
    flex: 1,
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
  },

  link: {
    fontWeight: '500',
    textAlign: 'center',
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },

  instituicao: {
    marginTop: '0.5em',
    marginBottom: '0.5em',
  },

  obs: {
    marginTop: '1em',
    color: theme.palette.secondary.main,
  },

  lastAccess: {
    margin: '0 auto',
  },

  lastAccessIcon: {
    marginRight: '0.5em',
  },
});
