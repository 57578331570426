/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useStyles from './styles';
import clsx from 'clsx';

function Box(props) {
  const { label, info } = props;
  const labelClass = props.labelClass ?? null;
  const infoClass = props.infoClass ?? null;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={clsx(classes.label, labelClass)}>{label}</div>
      <div className={clsx(classes.info, infoClass)}>{info}</div>
    </div>
  );
}

export default Box;
