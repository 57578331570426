import React from 'react';
import {
  faGem,
  faTags,
  faUsers,
  faUserPlus,
  faFileExcel,
  faAddressCard,
  faUserTag,
  faEnvelope,
  faSms,
  faBullhorn,
  faCommentDollar,
  faLaptopCode,
  faChartLine,
  faShoppingCart,
  faCalendarCheck,
  faPaperPlane,
  faMobileAlt,
  faCreditCard,
  faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons';
import Comparativo from 'components/Planos/Comparativo';
import Servico from './Servico';
import useStyles from './styles';

function Servicos(props) {
  const { data } = props;
  const classes = useStyles();
  return (
    <div className={classes.servicos}>
      <Servico
        item={data.planos}
        label='Crie, configure e venda diferentes tipos de Planos e Assinaturas'
        icon={faGem}
      />

      <Servico
        item={data.cupons}
        label='Crie cupons de desconto para cadastro de Associados, Diretores, Atletas...'
        icon={faTags}
      />

      <Servico
        item={data.associados}
        label='Gerenciamento prático de Associados'
        icon={faUsers}
      />

      <Servico
        item={data.manual}
        label='Inclusão individual de Associados via sistema'
        icon={faUserPlus}
      />

      <Servico
        item={data.manual}
        label='Importação de Associados via planilha'
        icon={faFileExcel}
      />

      <Servico
        item={data.carteirinha}
        label='Carteirinhas personalizadas da sua Entidade'
        icon={faAddressCard}
      />

      <Servico
        item={data.lojinha}
        label='Lojinha da Entidade com controle de estoque manual e automático'
        icon={faShoppingCart}
      />

      <Servico
        item={data.clube}
        label='Clube de descontos e benefícios para a Atlética e Associados'
        icon={faCommentDollar}
      />

      <Servico
        item={data.parceiros}
        label='Crie e divulgue os parceiros da Atlética'
        icon={faUserTag}
      />

      <Servico
        item={data.atividades}
        label='Crie Atividades públicas ou privadas com controle de presenças'
        icon={faCalendarCheck}
      />

      <Servico
        item={data.sms}
        label='Envio de SMS pela plataforma'
        icon={faSms}
      />

      <Servico
        item={data.mailing}
        label='Envie e-mails com suas novidades e promoções para os Associados'
        icon={faEnvelope}
      />

      <Servico
        item={data.notifSistema}
        label='Notificação de Associados na plataforma'
        icon={faBullhorn}
      />

      <Servico
        item={data.notifPopup}
        label='Notificações pop up e por e-mail'
        icon={faPaperPlane}
      />

      <Servico
        item={data.notifPush}
        label='Notificações PUSH para desktop e celular'
        icon={faMobileAlt}
      />

      <Servico
        item={data.financeiro}
        label='Gerenciador Financeiro completo com fluxo de caixa automático'
        icon={faChartLine}
      />

      <Servico
        label='Venda no Cartão ou Boleto, sem taxas abusivas!'
        icon={faCreditCard}
      />

      <Servico
        label='Transferências GRÁTIS do saldo para sua conta bancária'
        icon={faExchangeAlt}
      />

      <Servico
        label='Crie e envie cobranças avulsas para os Associados'
        icon={faCommentDollar}
      />

      <Servico
        item={data.site}
        label='Crie um incrível site para sua Entidade'
        icon={faLaptopCode}
      />

      <Comparativo />
    </div>
  );
}

export default Servicos;
