import React from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'components/Financeiro/redux/actions';
import validationSchema from './validation';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import Form from './Form';

const FormLote = (props) => {
  const { classes, list, handleCancel } = props;
  const actions = bindActionCreators(Actions, useDispatch());

  const listF = list.map((entidade) => {
    return {
      _id: entidade._id,
      nome: entidade.nome,
      apelido: entidade.apelido,
      email: entidade.user.email,
      avatar: entidade.avatar,
      valor: '',
      vencimento: '',
    };
  });

  const values = {
    descricao: '',
    valor: '',
    vencimento: '',
    list: listF,
  };
  return (
    <div className={classes.formContainer}>
      <Formik
        render={(props) => (
          <Form
            handleCancel={handleCancel}
            title={'Configurações da Cobrança'}
            {...props}
          />
        )}
        validateOnChange={false}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          actions.cobrancaEmLote(values);
        }}
      />
    </div>
  );
};

export default withStyles(styles)(FormLote);
