/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';
import Cupom from '../Cupom';
import styles from './styles';

const Cupons = (props) => {
  const { classes } = props;

  const actions = bindActionCreators(Actions, useDispatch());
  const { cupons, planos } = useSelector(({ cupons }) => ({
    ...cupons,
  }));

  return (
    <div className={classes.container}>
      {cupons.map((cupom) => {
        return <Cupom key={cupom.codigo} cupom={cupom} />;
      })}

      <Fab
        color="primary"
        aria-label="Criar novo"
        variant="extended"
        className={classes.fab}
        onClick={() => actions.showCreate(planos)}>
        <FontAwesomeIcon icon={faPlus} fixedWidth />
        Novo cupom
      </Fab>
    </div>
  );
};

export default withStyles(styles)(Cupons);
