/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import isEnabled from './isEnabled';

function Address(props) {
  const classes = useStyles();
  const { seller } = props;
  const { status, docAddress } = seller;

  const Icon = () => (
    <FontAwesomeIcon
      icon={faMapMarkedAlt}
      className={classes.fIcon}
      fixedWidth
    />
  );

  const Link = (props) => (
    <a
      className={classes.link}
      href={seller.docAddress}
      target='_blank'
      rel='noopener noreferrer'>
      {props.children}
    </a>
  );

  if (docAddress && status === 'Documentos em análise') {
    return (
      <Link>
        <span className={classes.emAnalise}>
          <Icon />
          Comp. de residência em análise
        </span>
      </Link>
    );
  }

  if (docAddress && isEnabled) {
    return (
      <Link>
        <span className={classes.approved}>
          <Icon />
          Comp. de residência aprovado
        </span>
      </Link>
    );
  }

  return (
    <span className={classes.missing}>
      <Icon />
      Comp. de residência não enviado
    </span>
  );
}

export default Address;
