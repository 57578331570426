import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default function ResponsiveDialog(props) {
  const { ticket, open, handleClose } = props;
  const { image, mensagem, numero } = ticket;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'>
        <DialogTitle id='responsive-dialog-title'>{`# ${
          numero ?? ''
        }`}</DialogTitle>
        <DialogContent>
          <img alt='imagem ticket' src={image} />
          <DialogContentText>{mensagem ?? ''}</DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
