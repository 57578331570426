import { send } from '_helpers/api.config';

const apiURL = '/api/admin/parceiro';

// lista
export const list = async () => {
  const endpoint = apiURL;
  const verb = 'GET';
  const data = null;
  return send(verb, endpoint, data, false);
};

// cria novo parceiro
export const create = async (parceiro) => {
  const endpoint = apiURL;
  const verb = 'POST';
  const data = new FormData();
  if (parceiro.avatar) {
    data.append('file', parceiro.avatar);
    data.append('name', 'file');
  }
  data.set('parceiro', JSON.stringify(parceiro));
  return send(verb, endpoint, data, false);
};

// edita parceiro
export const edit = async (parceiro) => {
  const endpoint = `${apiURL}/${parceiro._id}`;
  const verb = 'PUT';
  const data = new FormData();
  if (parceiro.avatar) {
    data.append('file', parceiro.avatar);
    data.append('name', 'file');
  }
  data.set('parceiro', JSON.stringify(parceiro));
  return send(verb, endpoint, data, false);
};

// soft delete
export const trash = async (parceiro) => {
  const endpoint = `${apiURL}/${parceiro._id}`;
  const verb = 'DELETE';
  const data = parceiro;
  return send(verb, endpoint, data, false);
};

// o parceiro atual ganha destaque e os outros perdem
export const destaque = async (parceiro) => {
  const endpoint = `${apiURL}/destaque/${parceiro._id}`;
  const verb = 'PUT';
  const data = parceiro;
  return send(verb, endpoint, data, false);
};

// inverte o status atual
export const mudarStatus = async (parceiro) => {
  const endpoint = `${apiURL}/status/${parceiro._id}`;
  const verb = 'PUT';
  const data = parceiro;
  return send(verb, endpoint, data, false);
};
