/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  faClipboardCheck,
  faUserClock,
  faUserTimes,
} from '@fortawesome/free-solid-svg-icons';
import DashContainer from 'components/_Common/DashContainer';
import DashCard from 'components/_Common/DashCard';
import useStyles from './styles';
import { useSelector, useFetch } from 'hooks';
import Seller from './Seller';

function Sellers(props) {
  const classes = useStyles();
  const { sellers } = useSelector('sellers');
  useFetch({ sellers });
  const statusAtivo = ['enabled', 'activated', 'activo'];
  const statusPendente = ['created', 'pending'];

  const ativos = sellers.filter(s => statusAtivo.includes(s.status));
  const analisando = sellers.filter(s => s.status === 'Documentos em análise');
  const pendentes = sellers.filter(s => statusPendente.includes(s.status));

  const [data, setData] = useState(ativos);

  useEffect(() => {
    // atualiza a lista sem perder a view
    const newData = data.filter(d => sellers.find(s => s._id === d._id));
    setData(newData);
  }, [sellers]);

  return (
    <div className={classes.dashboard}>
      <DashContainer>
        <DashCard
          info='Ativos'
          data={ativos.length}
          text='ver'
          icon={faClipboardCheck}
          action={() => setData(ativos)}
        />
        <DashCard
          info='Em análise'
          data={analisando.length}
          text='ver'
          icon={faUserClock}
          action={() => setData(analisando)}
        />
        <DashCard
          info='Pendentes'
          data={pendentes.length}
          text='gerenciar'
          icon={faUserTimes}
          action={() => setData(pendentes)}
        />
      </DashContainer>

      <div className={classes.container}>
        <div className={classes.sellers}>
          {data.map(elem => {
            return <Seller seller={elem} key={elem._id} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default Sellers;
