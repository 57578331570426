import React, { useState, useEffect, useRef } from 'react';
import useStyles from './styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector, useActions } from 'hooks';
import * as Actions from 'components/Entidades/redux/actions';

export default function Ordernar(props) {
  const classes = useStyles();

  const actions = useActions(Actions);
  const { ordem } = useSelector('entidades');

  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = (event) => {
    actions.ordena(event.target.value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel ref={inputLabel} id='set-order'>
          Ordernar
        </InputLabel>
        <Select
          labelId='set-order'
          id='filter-order'
          value={ordem}
          onChange={handleChange}
          labelWidth={labelWidth}>
          <MenuItem value={'AZ'}>Nome A-Z</MenuItem>
          <MenuItem value={'ZA'}>Nome Z-A </MenuItem>
          <MenuItem value={'desc'}>Associados 9-0</MenuItem>
          <MenuItem value={'asc'}>Associados 0-9</MenuItem>
          <MenuItem value={'new'}>Cadastro recente</MenuItem>
          <MenuItem value={'old'}>Cadastro antigo</MenuItem>
          <MenuItem value={'lastAccess'}>Últimas Logadas</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
