export default (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginBottom: '4em',
  },

  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 10,
  },

  extendedIcon: {
    marginRight: theme.spacing(1),
  },

  avisoCotas: {
    // marginTop: '0.2em',
    marginBottom: '1.5em',
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    // textDecoration: 'underline',
    fontWeight: '300',
  },

  table: {
    minWidth: '500px',
  },

  actionText: { marginTop: '1em' },

  error: {
    color: 'red',
    margin: theme.spacing(1),
  },

  pullLeft: {
    position: 'absolute',
    left: theme.spacing(2),
  },
});
