import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dropContainer: {
    display: 'flex',
    color: 'lightgrey',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
    margin: '1em',
  },

  container: {
    display: 'flex',
    color: 'lightgrey',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
    cursor: 'pointer',
    border: '1px solid lightgrey',
    borderRadius: '4px',
    height: '450px',
    width: '450px',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      fontWeight: '500',
      background: '#f4faff',
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      height: '230px',
      width: '230px',
    },
  },

  hasImage: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    cursor: 'pointer',
    // border: '1px solid lightgrey',
    borderRadius: '4px',
    '&:hover': {
      boxShadow: '0px 2px 10px #999999',
      // background: '#f4faff',
    },
  },

  noImg: {
    display: 'flex',
    color: 'lightgrey',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100px',
    width: '100px',
  },

  uploadText: {
    marginTop: '1em',
    fontSize: '0.875em',
    color: theme.palette.primary.main,
  },

  upIcon: {
    marginRight: '0.5em',
  },

  containerValue: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    alignItems: 'center',
  },

  imageValue: {
    width: 'auto',
    maxWidth: '450px',
    height: 'auto',
    maxHeight: '450px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '250px',
      maxHeight: '250px',
    },
  },

  error: {
    marginTop: '0.5em',
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.3em',
    fontSize: '0.875em',
  },

  star: {
    color: '#FFD700',
    marginTop: '-15px',
    marginLeft: '-70px',
  },

  starOnImg: {
    color: '#FFD700',
    marginTop: '-15px',
    marginLeft: '5px',
  },
}));
