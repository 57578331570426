import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styles from './styles';
import Dialog from 'components/_Common/Dialog';
import TextField from '@material-ui/core/TextField';
import { formataData, formataValor, formataDataInput } from '_helpers/utils';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import isAfter from 'date-fns/isAfter';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

const PayError = (props) => {
  const { classes, pedido, actions } = props;
  const [del, setDel] = useState(false);
  const [reemissao, setReemissao] = useState(false);

  const [values, setValues] = React.useState({
    descricao: pedido.descricao,
    valor: pedido.valor.toFixed(2),
    vencimento: formataData(pedido.vencimento),
  });

  const [errors, setErrors] = React.useState({
    descricao: false,
    valor: false,
    vencimento: false,
  });

  const handleChange = (name) => (event) => {
    let value = event.target.value;

    if (value === '') {
      setErrors({ ...errors, [name]: '* Obrigatório' });
    } else {
      setErrors({ ...errors, [name]: false });
    }

    if (name === 'valor') {
      value = formataValor(value, 6);
      if (value < 5) {
        setErrors({ ...errors, [name]: 'Valor mínimo R$ 5,00' });
      } else {
        setErrors({ ...errors, [name]: false });
      }
    }
    if (name === 'vencimento') {
      value = formataDataInput(value);
      const result = parse(value, 'dd/MM/yyyy', new Date());
      if (isValid(result)) {
        // a data não deve ser hoje ou anterior
        if (!isAfter(result, new Date())) {
          setErrors({ ...errors, [name]: 'Data inválida' });
        } else {
          setErrors({ ...errors, [name]: false });
        }
      } else {
        setErrors({ ...errors, [name]: 'Data inválida' });
      }
    }

    setValues({ ...values, [name]: value });
  };

  const getErrors = () => {
    if (!pedido.status) {
      return <div className={classes.errText}>Erro não identificado</div>;
    }
    if (pedido.erro) {
      return <div className={classes.errText}>{pedido.erro}</div>;
    }
    return <div className={classes.errText}>Erro não identificado</div>;
  };

  const delDialog = () => {
    if (!del) return null;
    return (
      <Dialog
        title={`Mover pedido #${pedido.numero} para a lixeira?`}
        message='O pedido será movido para a lixeira e o cliente não terá mais acesso ao mesmo. Não há como cancelar as cobranças, caso emitidas. Se o cliente realizar o pagamento, o pedido será automaticamente removido da lixeira.'
        no='Cancelar'
        yes='Excluir'
        action={() => actions.trash(pedido)}
        close={() => setDel(false)}
      />
    );
  };

  const reemissaoDialog = () => {
    if (!reemissao) return null;
    return (
      <Dialog
        title={`Reemitir pedido #${pedido.numero}?`}
        no='Cancelar'
        yes='Reemitir'
        action={() =>
          actions.reemitir(
            pedido,
            values.descricao,
            values.valor,
            values.vencimento,
          )
        }
        close={() => setReemissao(false)}>
        <>
          <div>
            O sistema tentará reemitir as cobranças do pedido sem atualizar o
            número ou criar um novo pedido.
            <div className={classes.form}>
              <TextField
                id='descricao'
                fullWidth
                label='Descrição'
                className={classes.textField}
                value={values.descricao}
                onChange={handleChange('descricao')}
                margin='normal'
                variant='outlined'
                error={errors.descricao ? true : false}
                helperText={errors.descricao ? errors.descricao : ''}
              />
              <TextField
                id='valor'
                label='Valor'
                className={classes.textField}
                value={values.valor}
                onChange={handleChange('valor')}
                margin='normal'
                variant='outlined'
                error={errors.valor ? true : false}
                helperText={errors.valor ? errors.valor : ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>R$ </InputAdornment>
                  ),
                }}
              />
              <TextField
                id='vencimento'
                label='Vencimento'
                className={classes.textField}
                value={values.vencimento}
                onChange={handleChange('vencimento')}
                margin='normal'
                variant='outlined'
                error={errors.vencimento ? true : false}
                helperText={errors.vencimento ? errors.vencimento : ''}
              />
            </div>
          </div>
        </>
      </Dialog>
    );
  };

  return (
    <div className={classes.container}>
      {delDialog()} {reemissaoDialog()}
      <div className={classes.status}>
        <div className={classes.label}>Detalhes:</div>
        {getErrors()}
      </div>
      <div className={classes.buttons}>
        <Button
          size='small'
          // fullWidth
          variant='text'
          onClick={() => setDel(true)}
          color='secondary'
          className={classes.button}>
          excluir
        </Button>

        <Button
          size='small'
          // fullWidth
          variant='text'
          onClick={() => setReemissao(true)}
          color='secondary'
          className={classes.button}>
          reemitir
        </Button>

        <Link
          to='/entidades'
          component={RouterLink}
          underline='none'
          className={classes.button}>
          <Button
            size='small'
            // fullWidth
            variant='text'
            // onClick={() => actions.login(values)}
            color='secondary'
            className={classes.button}>
            atualizar cadastro
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default withStyles(styles)(PayError);
