import * as Yup from 'yup';

const validationSchema = Yup.object({
  url: Yup.string('')
    .min(1, 'Muito curto')
    .max(80, 'Muito longo')
    .required('* Obrigatório')
    .test('url', 'Não pode terminar com "-"', function (value) {
      if (!value) return true;
      return value[value.length - 1] !== '-';
    }),

  titulo: Yup.string('').min(5, 'Muito curto').max(60, 'Muito longo').required('* Obrigatório'),

  categoria: Yup.string('').required('* Obrigatório'),

  descricaoPedido: Yup.string('').min(1, 'Muito curto').max(60, 'Muito longo').nullable(true),

  validade: Yup.string('').nullable(true),
  imagem: Yup.string('').nullable(true),
  descricao: Yup.string('').nullable(true),
  cartao: Yup.string('').nullable(true),
  boleto: Yup.string('').nullable(true),
  exibeTotal: Yup.string('').nullable(true),
  pagadorDefine: Yup.string('').nullable(true),

  valorMinimo: Yup.number()
    .min(6, 'O valor mínimo é R$ 6.00')
    .max(9999.99, 'O valor mínimo é R$ 9999.99')
    .nullable(true),

  metaArrecadacao: Yup.number()
    .min(0, 'O valor mínimo é R$ 0')
    .max(9999.99, 'O valor máximo é R$ 9999.99')
    .nullable(true),

  valor: Yup.number()
    .min(6, 'O valor mínimo é R$ 6.00')
    .max(9999.99, 'O valor mínimo é R$ 9999.99')
    .nullable(true),
  parcelas: Yup.string('').nullable(true),
  taxaAdm: Yup.number().min(0, 'A taxa mínima é 0%').max(50, 'A taxa máxima é 50%').nullable(true),
});

export default validationSchema;
