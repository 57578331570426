const categorias = [
  { label: 'Animais e Bichos de Estimação', value: 100 },
  { label: 'Roupas em Geral', value: 201 },
  { label: 'Accessórios em Geral', value: 202 },
  { label: 'Fantasias e Acessórios', value: 203 },
  { label: 'Acessórios para Bolsas e Carteiras', value: 204 },
  { label: 'Bolsas, Carteiras e Malas', value: 205 },
  { label: 'Joias', value: 206 },
  { label: 'Acessórios para Sapatos', value: 207 },
  { label: 'Sapatos', value: 208 },
  { label: 'Outros tipos de Roupas e Acessórios para Vestuário', value: 299 },
  { label: 'Arte e Entretenimento', value: 300 },
  { label: 'Bebês e Recém-nascidos', value: 400 },
  { label: 'Propaganda e Marketing', value: 501 },
  { label: 'Agricultura', value: 502 },
  { label: 'Construção', value: 503 },
  { label: 'Filme e Televisão', value: 504 },
  { label: 'Finanças e Seguros', value: 505 },
  { label: 'Serviços de Alimentação', value: 506 },
  { label: 'Madeireiras', value: 507 },
  { label: 'Maquinário Pesado', value: 508 },
  { label: 'Hotel e Hospedagem', value: 509 },
  { label: 'Armazém Industrial', value: 510 },
  { label: 'Equipamentos Militares', value: 511 },
  { label: 'Manufatura', value: 512 },
  { label: 'Movimentação de Material', value: 513 },
  { label: 'Medicina', value: 514 },
  { label: 'Mineração', value: 515 },
  { label: 'Piercing e tatuagem', value: 516 },
  { label: 'Varejo', value: 517 },
  { label: 'Ciência e Laboratórios', value: 518 },
  { label: 'Placas e Sinais', value: 519 },
  { label: 'Equipamentos de Segurança do Trabalho', value: 520 },
  { label: 'Outros tipos de Produto de Negócios ou Indústria', value: 599 },
  { label: 'Câmeras', value: 601 },
  { label: 'Acessórios de câmeras', value: 602 },
  { label: 'Fotografia', value: 603 },
  { label: 'Outros tipos de acessórios para Câmeras e Fotografia', value: 699 },
  { label: 'Impressoras 3D', value: 701 },
  { label: 'Áudio', value: 702 },
  { label: 'Componentes de Circuito', value: 703 },
  { label: 'Comunicação', value: 704 },
  { label: 'Componentes', value: 705 },
  { label: 'Computadores', value: 706 },
  { label: 'Acessórios Eletrônicos', value: 707 },
  { label: 'Aparelho GPS', value: 708 },
  { label: 'Acessórios de GPS', value: 709 },
  { label: 'Redes', value: 711 },
  { label: 'Impressão, Cópia, Scanner e Fax', value: 712 },
  { label: 'Acessórios de Impressão, Cópia, Scanner e Fax', value: 713 },
  { label: 'Vídeo', value: 715 },
  { label: 'Consoles de Vídeo Game', value: 716 },
  { label: 'Acessórios de Vídeo Game', value: 717 },
  { label: 'Outros', value: 799 },
  { label: 'Comidas, Bebidas e Cigarro', value: 800 },
  { label: 'Móveis', value: 900 },
  { label: 'Ferramentas', value: 1000 },
  { label: 'Saúde e Beleza', value: 1100 },
  { label: 'Casa e Jardim', value: 1200 },
  { label: 'Malas e Bagagens', value: 1300 },
  { label: 'Adulto', value: 1400 },
  { label: 'Armas e Munição', value: 1500 },
  { label: 'Materiais de Escritório', value: 1600 },
  { label: 'Religião e Cerimoniais', value: 1700 },
  { label: 'Software de Computador', value: 1801 },
  { label: 'Bens e Moedas Digitais', value: 1802 },
  { label: 'Serviços Digitais', value: 1803 },
  { label: 'Jogos de Vídeo Games', value: 1804 },
  { label: 'Outros tipos de Eletrônicos', value: 1899 },
  { label: 'Equipamento de Esporte', value: 1900 },
  { label: 'Brinquedos e Jogos', value: 2000 },
  { label: 'Veículos e Peças', value: 2100 },
  { label: 'Livros', value: 2300 },
  { label: 'DVDs e Vídeos', value: 2400 },
  { label: 'Revistas e Jornais', value: 2500 },
  { label: 'CDs e Vinil', value: 2601 },
  { label: 'Instrumentos Musicais', value: 2602 },
  { label: 'Música Digital', value: 2603 },
  { label: 'Outros tipos relacionados à Música e Instrumentos Musicais', value: 2699 },
  { label: 'Outras categorias não especificadas', value: 9999 },
];

export default categorias;
