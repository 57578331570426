/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './redux/actions';
import Spinner from 'components/_Common/Spinner';
import styles from './styles';
import Lista from './Lista';
import Perfil from './Perfil/Resumido';
import Financeiro from './Financeiro';
import Planos from './Planos';
import Editar from './Perfil/Editar';
import Associados from 'components/Associados';
import Dashboard from './Dashboard';

const Entidades = (props) => {
  const { classes } = props;
  const actions = bindActionCreators(Actions, useDispatch());
  const { view, entidades, loading, entidade } = useSelector(
    ({ entidades }) => ({
      ...entidades,
    })
  );

  const { associados } = useSelector(({ associados }) => ({
    ...associados,
  }));

  useEffect(() => {
    if (entidades.length === 0) {
      actions.loadEntidades();
    }
  }, []);

  useEffect(() => {
    return () => {
      actions.cancel();
    };
  }, []);

  if (loading) {
    return <Spinner text="Processando..." size="2x" noBox />;
  }

  function selectView() {
    switch (view) {
      case 'lista':
        return <Lista />;
      case 'perfil':
        return (
          <>
            <Dashboard />
            <Perfil entidade={entidade} />
          </>
        );
      case 'financeiro':
        return <Financeiro entidade={entidade} />;
      case 'planos':
        return (
          <>
            <Dashboard />
            <Planos planos={entidade.planosAssociados} />
          </>
        );
      case 'associados':
        const socios = associados.filter((socio) =>
          socio.entidade ? socio.entidade._id === entidade._id : false
        );
        return (
          <>
            <Dashboard />
            <Associados data={socios} />
          </>
        );
      case 'edit':
        return (
          <>
            <Dashboard />
            <Editar entidade={entidade} />
          </>
        );
      default:
        return <Lista />;
    }
  }

  return <div className={classes.container}> {selectView()}</div>;
};

export default withStyles(styles)(Entidades);
