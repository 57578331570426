/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGem,
  faCalendarCheck,
  faSignInAlt,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import { formataDataHora, formataData } from '_helpers/utils';
import Tooltip from '@material-ui/core/Tooltip';

function Assinaturas(props) {
  let { entidade } = props;
  let { assinaturas } = entidade;

  // Get Last Access
  let lastAccess = entidade?.user?.lastAccess ?? null;
  if (lastAccess) {
    lastAccess = formataDataHora(lastAccess);
  }

  const classes = useStyles();

  if (assinaturas.length > 1) {
    let ativas = [];
    let free = [];
    // remove assinaturas free
    ativas = assinaturas.filter((a) => a.valor.original !== 0 && a.status === 'Ativo');
    if (ativas.length === 0) free = assinaturas.filter((a) => a.status === 'Ativo');
    assinaturas = ativas.length === 0 ? free : ativas;
  }

  return (
    <div className={classes.assinaturas}>
      {assinaturas.map((assinatura, index) => {
        const { plano } = assinatura;
        return (
          <div key={assinatura._id} className={classes.assinatura}>
            <Tooltip title='Plano' placement='left'>
              <div className={classes.plano}>
                <FontAwesomeIcon icon={faGem} className={classes.icon} fixedWidth size='sm' />
                {plano.titulo}
              </div>
            </Tooltip>

            <div className={classes.datas}>
              <Tooltip title='Data de Adesão' placement='left'>
                <div className={classes.validade}>
                  <FontAwesomeIcon
                    icon={faSignInAlt}
                    className={classes.icon}
                    fixedWidth
                    size='sm'
                  />
                  {formataData(assinatura.adesao)}
                </div>
              </Tooltip>

              <Tooltip title='Validade da Assinatura' placement='left'>
                <div className={classes.validade}>
                  <FontAwesomeIcon
                    icon={faCalendarCheck}
                    className={classes.icon}
                    fixedWidth
                    size='sm'
                  />
                  {formataData(assinatura.validade)}
                </div>
              </Tooltip>
            </div>
          </div>
        );
      })}

      {lastAccess && (
        <Tooltip title='Último acesso' placement='left'>
          <div className={classes.dado}>
            <FontAwesomeIcon icon={faUserSecret} className={classes.icon} fixedWidth size='sm' />
            {lastAccess}
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default Assinaturas;
