import React from 'react';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import useStyles from './styles';
import Ordenar from './Ordenar';
import FiltroCurso from './FiltroCurso';
import FiltroLocalizacao from './FiltroLocalizacao';

function TopBar(props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <>
        <div className={classes.ordenar}>
          <Ordenar />
        </div>

        <div className={classes.ordenar}>
          <FiltroCurso />
        </div>
        <div className={classes.localizacao}>
          <FiltroLocalizacao />
        </div>
      </>
    </div>
  );
}

export default TopBar;
