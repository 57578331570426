import React from 'react';
import Cobranca from './Cobranca';

function Cobrancas(props) {
  const { taxas, formProps, setFieldValue } = props;
  const handleChange = (name, servc) => {
    const fieldEnabled = `taxas.${name}.enabled`;
    if (servc.enabled) {
      setFieldValue(fieldEnabled, true);
    } else {
      setFieldValue(fieldEnabled, false);
    }
  };

  const rest = {
    handleChange,
    taxas,
    formProps,
  };

  return (
    <>
      <Cobranca
        label='Boleto'
        name='boleto'
        handleChange={handleChange}
        data={taxas}
        rest={rest}
      />

      <Cobranca
        label='Cartão'
        name='cartao'
        handleChange={handleChange}
        data={taxas}
        rest={rest}
      />

      <Cobranca
        label='Pix'
        name='pix'
        handleChange={handleChange}
        data={taxas}
        rest={rest}
      />

      <Cobranca
        label='Saques & TED'
        name='saque'
        handleChange={handleChange}
        data={taxas}
        rest={rest}
      />

      {/* <Cobranca
        label='Antecipação'
        name='antecipacao'
        handleChange={handleChange}
        data={taxas}
        rest={rest}
      /> */}
    </>
  );
}

export default Cobrancas;
