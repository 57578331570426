/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../redux/actions';
import { Formik } from 'formik';
import validationSchema from './validation';
import Form from './form';
import TitleBar from 'components/_Common/TitleBar';
import * as local from '_helpers/local';
import styles from './styles';

function Editar(props) {
  const { classes } = props;
  const actions = bindActionCreators(Actions, useDispatch());
  const { entidade } = useSelector(({ entidades }) => ({
    ...entidades,
  }));
  window.scrollTo(0, 0);
  const values = { ...entidade };
  const planos = local.planos();

  return (
    <Paper elevation={1} className={classes.container}>
      <TitleBar title={`Editando ${entidade.nome}`} />
      <Formik
        render={(props) => (
          <Form planos={planos} handleCancel={actions.cancel} {...props} />
        )}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          // props.actions.edit(values);
        }}
      />
    </Paper>
  );
}

export default withStyles(styles)(Editar);
