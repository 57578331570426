import React from 'react';
import useStyles from './styles';
import Beneficios from './Beneficios';
import Servicos from './Servicos';
import Cobrancas from './Cobrancas';
import OpcoesCobranca from './OpcoesCobranca';
import DuracaoAssinatura from './DuracaoAssinatura';
import { FormSection, SectionText, FormTextField, DateField } from 'components/_Common/Form';
import Submit, { Save, Cancel } from 'components/_Common/Submit';
import Editor from 'components/_Common/WysiwygEditor';
import { formataValor, onlyNumbers } from '_helpers/utils';

function Form(props) {
  const {
    values: { beneficios, pagamento, assinatura, servicos, taxas },
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleCancel,
    setFieldTouched,
    setFieldValue,
    isValid,
    loading,
  } = props;

  const classes = useStyles();

  const change = (name, e) => {
    e.persist();
    const value = e.target.value;
    setFieldTouched(name, true, false);
    if (name === 'valor') {
      setFieldValue(name, formataValor(value, 6));
    } else if (name === 'titulo') {
      setFieldValue(name, value.slice(0, 80));
    } else if (name === 'slogan') {
      setFieldValue(name, value.slice(0, 80));
    } else if (name === 'assinatura.duracao') {
      setFieldValue(name, onlyNumbers(value).slice(0, 2));
    } else if (name === 'pagamento.tipo' && value === 'Plano Gratuito') {
      setFieldValue('pagamento.tipo', value);
      setFieldTouched('assinatura.tipo');
      setFieldValue('assinatura.tipo', 'Até que o usuário cancele');
    } else if (name.includes('.cota')) {
      setFieldValue(name, onlyNumbers(value).slice(0, 4));
    } else if (name.includes('.creditos')) {
      setFieldValue(name, onlyNumbers(value).slice(0, 4));
    } else if (name.includes('.transacao')) {
      setFieldValue(name, formataValor(value, 6));
    } else if (name.includes('.avista')) {
      setFieldValue(name, formataValor(value, 6));
    } else if (name.includes('.parcelado')) {
      setFieldValue(name, formataValor(value, 6));
    } else {
      handleChange(e);
    }
  };

  const formProps = {
    errors,
    touched,
    values: props.values,
    change,
    setFieldTouched,
    setFieldValue,
  };

  return (
    <form>
      <Submit>
        <Cancel loading={loading} cancel={handleCancel} />
        <Save isValid={isValid} loading={loading} submit={handleSubmit} />
      </Submit>

      <FormSection classes={classes} title={props.title}>
        <SectionText>
          Forneça um título que descreva bem o tipo da associação, por exemplo: "Plano Premium".{' '}
          <br />O slogan é opcional, mas ajuda a convencer a pessoa a assinar o plano.
        </SectionText>
        <FormTextField label='Título' field='titulo' xs={12} md={12} formik={formProps} />

        <FormTextField label='Slogan' field='slogan' xs={12} md={12} formik={formProps} />
      </FormSection>

      <FormSection classes={classes} title='Vantagens de ser assinante'>
        <SectionText>
          Liste todas as vantagens e benefícios que a Entidate terá ao adquirir este plano.
          <br />
          Comece com o mais importante.
        </SectionText>
        <Beneficios
          props={{
            beneficios,
            setFieldValue,
            touched,
            errors,
            classes,
            setFieldTouched,
          }}
        />
      </FormSection>

      <FormSection classes={classes} title='Produtos e Serviços'>
        <SectionText>
          Defina os produtos, serviços e cotas que a Entidade terá acesso neste plano.
        </SectionText>
        <Servicos servicos={servicos} setFieldValue={setFieldValue} formProps={formProps} />
      </FormSection>

      <FormSection classes={classes} title='Serviços Financeiros'>
        <SectionText>
          Defina as taxas que a Entidade vai pagar ao utilizar os serviços financeiros deste plano.
        </SectionText>
        <Cobrancas taxas={taxas} setFieldValue={setFieldValue} formProps={formProps} />
      </FormSection>

      <OpcoesCobranca pagamento={pagamento} form={formProps} />

      <DuracaoAssinatura assinatura={assinatura} pagamento={pagamento} form={formProps} />

      <FormSection classes={classes} title='Encerramento Automático'>
        <SectionText>
          Ao configurar uma data de encerramento, o sistema vai automaticamente mudar o status do
          plano para <strong>Inativo</strong> e o plano não estará mais disponível para novas
          assinaturas. Esta informação não é visível pelas Entidades e não impacta na duração ou
          status das assinaturas realizadas neste plano.
        </SectionText>

        <DateField
          label='Data para Encerrar'
          field='encerramento'
          md={4}
          formik={formProps}
          minDate={new Date()}
        />
      </FormSection>

      <FormSection classes={classes} title='Configure o contrato'>
        <SectionText>
          <div>Defina os termos do contrato.</div>
          <p>
            <strong>ATENÇÃO: </strong> A identificação das partes, cabeçalho e rodapé já estão
            configurados com os dados do cliente na etapa de checkout. Inclua apenas os termos.
          </p>
        </SectionText>

        <Editor
          field='contrato'
          value={props.values.contrato}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
        />
      </FormSection>

      <Submit>
        <Cancel loading={loading} cancel={handleCancel} />
        <Save isValid={isValid} loading={loading} submit={handleSubmit} />
      </Submit>
    </form>
  );
}

export default Form;
