import {
  SHOW_PERFIL,
  CANCEL,
  REQUEST,
  FAILURE,
  SUCCESS,
  CHANGE_VIEW,
} from './types';
import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';

export const showPerfil = (entidade) => ({
  type: SHOW_PERFIL,
  payload: { view: 'perfil', entidade },
});
export const cancel = () => ({ type: CANCEL });
export const changeView = (view) => ({ type: CHANGE_VIEW, payload: view });

export const listSellers = (entidade) => {
  return sendRequest(service.listSellers, entidade, false);
};

// export const changeAccess = (entidade) => {
//   return async (dispatch) => {
//     dispatch({ type: REQUEST });
//     // ***SERVICE CALL***
//     const res = await service.changeAccess(entidade.user);
//     if (res.success) {
//       const { entidades } = res.data;
//       entidade.user.blocked = !entidade.user.blocked;
//       dispatch(feedback.success(res.msg));
//       dispatch({
//         type: SUCCESS,
//         payload: { entidades, entidade },
//       });
//     } else {
//       dispatch(feedback.error(res.error));
//       dispatch({ type: FAILURE });
//     }
//   };
// };

function sendRequest(serviceName, params, notif = true) {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    // ***SERVICE CALL***
    const res = await serviceName({ ...params });
    if (res.success) {
      if (notif) dispatch(feedback.success(res.data.msg));
      dispatch({ type: SUCCESS, payload: res.data });
    } else {
      if (notif) dispatch(feedback.error(res.error));
      dispatch({ type: FAILURE });
    }
  };
}
