import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import styles from './styles';
import Table from '../_Common/Table';
import * as service from './service';
import Dialog from '../_Common/Dialog';

const header = [
  { id: 'deltype', label: 'Tipo' },
  { id: 'item', label: 'Item' },
  { id: 'updatedAt', label: 'Excluído' },
  { id: 'createdAt', label: 'Criado' },
];

function Lixeira(props) {
  const [trash, setTrash] = useState([]);
  const [open, setOpen] = useState(false);
  const [tipo, setTipo] = useState(null);
  const [item, setItem] = useState(null);
  const [action, setAction] = useState(null);
  const [ID, setID] = useState(null);

  useEffect(() => {
    loadData();
    async function loadData() {
      let res = await service.fetchTrash();
      res = [
        ...res.planos,
        ...res.cupons,
        ...res.notificacoes,
        ...res.tickets,
        ...res.parceiros,
        ...res.entidades,
      ];
      setTrash(res);
    }
  }, []);

  const setDefaults = (obj) => {
    setOpen(false);
    const { deltype, _id, item } = obj;
    setOpen(true);
    setTipo(deltype);
    setItem(item);
    setID(_id);
    switch (deltype) {
      case 'Plano':
        setAction(() => service.recoverPlano);
        break;
      case 'Cupom':
        setAction(() => service.recoverCupom);
        break;
      case 'Notificação':
        setAction(() => service.recoverNotificacao);
        break;
      case 'Ticket':
        setAction(() => service.recoverTicket);
        break;
      case 'Parceiro':
        setAction(() => service.recoverParceiro);
        break;
      case 'Entidade':
        setAction(() => service.recoverEntidade);
        break;
      default:
        break;
    }
  };

  const recover = async (confirmation) => {
    if (confirmation) {
      setOpen(false);
      removeItem();
      action(ID);
    }
  };

  const removeItem = () => {
    const newTrash = trash.filter((elem) => elem._id !== ID);
    setTrash(newTrash);
  };

  const confirmacao = () => (
    <Dialog
      title={`Restaurar o item?`}
      no="Cancelar"
      yes="Restaurar"
      action={recover}
      close={() => setOpen(false)}>
      <Typography variant="caption">Tipo:</Typography>
      <Typography variant="subtitle2" gutterBottom>
        {tipo}
      </Typography>
      <Typography variant="caption">Item:</Typography>
      <Typography variant="subtitle1" gutterBottom>
        {item}
      </Typography>
    </Dialog>
  );

  return (
    <>
      <Table
        title="Lixeira"
        text="Clique no item para restaurar"
        header={header}
        data={trash}
        action={setDefaults}
      />
      {open ? confirmacao() : null}
    </>
  );
}

export default withStyles(styles)(Lixeira);
