import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { formatCpf, formatCnpj } from '@brazilian-utils/formatters';
import { formataCelular } from '_helpers/utils';
import Divider from '@material-ui/core/Divider';

function PerfilCenter(props) {
  const { classes, entidade, actions } = props;

  return (
    <div className={classes.center}>
      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          E-mail
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {entidade.user.email}
        </Typography>
      </div>

      {entidade.fone.telefone1 ? (
        <div className={classes.group}>
          <Typography variant="subtitle1" className={classes.label}>
            Fone Principal
          </Typography>
          <Typography variant="subtitle1" className={classes.info}>
            {formataCelular(entidade.fone.telefone1)}
          </Typography>
        </div>
      ) : null}
      {entidade.fone.telefone2 ? (
        <div className={classes.group}>
          <Typography variant="subtitle1" className={classes.label}>
            Fone Secundário
          </Typography>
          <Typography variant="subtitle1" className={classes.info}>
            {formataCelular(entidade.fone.telefone2)}
          </Typography>
        </div>
      ) : null}
      {entidade.fone.whatsapp ? (
        <div className={classes.group}>
          <Typography variant="subtitle1" className={classes.label}>
            WhatsApp
          </Typography>
          <Typography variant="subtitle1" className={classes.info}>
            {formataCelular(entidade.fone.whatsapp)}
          </Typography>
        </div>
      ) : null}

      {entidade.cnpj ? (
        <div className={classes.group}>
          <Typography variant="subtitle1" className={classes.label}>
            CNPJ
          </Typography>
          <Typography variant="subtitle1" className={classes.info}>
            {formatCnpj(entidade.cnpj)}
          </Typography>
        </div>
      ) : null}

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          Potencial
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {entidade.qtdAlunos}
        </Typography>
      </div>

      <Divider className={classes.divider} />

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          CEP
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {entidade.endereco ? entidade.endereco.cep : ''}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          Logradouro
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {entidade.endereco ? entidade.endereco.rua : ''},{' '}
          {entidade.endereco ? entidade.endereco.numero : ''}
        </Typography>
      </div>

      {entidade.complemento && entidade.endereco.complemento ? (
        <div className={classes.group}>
          <Typography variant="subtitle1" className={classes.label}>
            Complemento
          </Typography>
          <Typography variant="subtitle1" className={classes.info}>
            {entidade.endereco.complemento}
          </Typography>
        </div>
      ) : null}

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          Bairro
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {entidade.endereco ? entidade.endereco.bairro : ''}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          Município
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {entidade.endereco ? entidade.endereco.cidade : ''} -{' '}
          {entidade.endereco ? entidade.endereco.uf : ''}
        </Typography>
      </div>

      <Divider className={classes.divider} />

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          Representante
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {entidade.representante.nome}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          CPF
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {formatCpf(entidade.representante.cpf)}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          Email
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {entidade.representante.email}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          Telefone
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {formataCelular(entidade.fone.contatoCadastro)}
        </Typography>
      </div>

      <div className={classes.action}>
        <Button
          variant="outlined"
          onClick={() => actions.changeView('edit')}
          color="primary"
          className={classes.button}>
          <FontAwesomeIcon
            icon={faUserEdit}
            className={classes.buttonIcon}
            fixedWidth
            size="lg"
          />
          editar dados cadastrais
        </Button>
      </div>
    </div>
  );
}

export default PerfilCenter;
