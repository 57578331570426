/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import SearchField from 'components/_Common/SearchField';
import { filterData } from '_helpers/utils';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'components/Financeiro/redux/actions';
import LazyList from 'components/_Common/LazyList';
import Pedido from './Pedido';

function ListaPedidos(props) {
  const actions = bindActionCreators(Actions, useDispatch());
  const { pedidos } = useSelector(({ financeiro }) => ({
    ...financeiro,
  }));

  const [data, setData] = useState([...pedidos]);
  const handleSearch = (term) => {
    if (term && term !== '') {
      setData(filterData(term, pedidos));
    } else {
      setData(pedidos);
    }
  };

  return (
    <>
      <div>
        <SearchField handle={handleSearch} />
      </div>
      <LazyList data={data} Item={Pedido} actions={actions} />
    </>
  );
}

export default ListaPedidos;
