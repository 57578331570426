import React from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../redux/actions';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import styles from './styles';
import PerfilLeft from './Left';
import PerfilCenter from './Center';
import PerfilRight from './Right';

function Perfil(props) {
  const { classes } = props;
  const entidade = { ...props.entidade };
  window.scrollTo(0, 0);
  const actions = bindActionCreators(Actions, useDispatch());
  entidade.plano = entidade.assinaturas[0].plano;

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <div className={classes.profile}>
          <PerfilLeft entidade={entidade} classes={classes} />

          <PerfilCenter
            entidade={entidade}
            classes={classes}
            actions={actions}
          />

          <PerfilRight
            entidade={entidade}
            classes={classes}
            actions={actions}
            plano={entidade.plano}
            assinaturas={entidade.assinaturas}
          />
        </div>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(Perfil);
