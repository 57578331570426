export default (theme) => ({
  formContainer: {
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '30%',
    },
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: 'auto',
    display: 'flex',
    marginBottom: '2em',
  },

  title: {
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '30%',
    },
  },

  button: {
    marginLeft: '0.5em',
  },

  cancelarOuSalvar: {
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      marginRight: '15%',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: '25%',
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: '35%',
    },
  },
});
