/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGem,
  faCalendarCheck,
  faSignInAlt,
} from '@fortawesome/free-solid-svg-icons';
import { formataData } from '_helpers/utils';
import Tooltip from '@material-ui/core/Tooltip';

function Assinaturas(props) {
  const { assinaturas } = props;
  const classes = useStyles();

  return (
    <div className={classes.assinaturas}>
      {assinaturas.map((assinatura, index) => {
        const { plano } = assinatura;

        return (
          <div key={assinatura._id} className={classes.assinatura}>
            <Tooltip title='Plano' placement='left'>
              <div className={classes.plano}>
                <FontAwesomeIcon
                  icon={faGem}
                  className={classes.icon}
                  fixedWidth
                  size='sm'
                />
                {plano.titulo}
              </div>
            </Tooltip>

            <Tooltip title='Data de Adesão' placement='left'>
              <div className={classes.validade}>
                <FontAwesomeIcon
                  icon={faSignInAlt}
                  className={classes.icon}
                  fixedWidth
                  size='sm'
                />
                {formataData(assinatura.adesao)}
              </div>
            </Tooltip>

            <Tooltip title='Validade da Assinatura' placement='left'>
              <div className={classes.validade}>
                <FontAwesomeIcon
                  icon={faCalendarCheck}
                  className={classes.icon}
                  fixedWidth
                  size='sm'
                />
                {formataData(assinatura.validade)}
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
}

export default Assinaturas;
