/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useQuery, useSearch } from 'hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function SearchField(props) {
  const { data, setData, fields } = props;
  const depth = props.depth || 2;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const index = useSearch(data, fields, depth);
  const query = useQuery();
  const matches = useMediaQuery('(max-width:600px)');
  const style = setStyle(matches, open);
  const placeholder = props.placeholder
    ? props.placeholder
    : 'Digite o termo da busca';

  const [filter, setFilter] = useState('');

  // console.log('fields', fields);
  // console.log('data', data);

  const handleFilter = () => event => {
    const term = event.target.value;
    if (term && term !== '') {
      // index.search(term, function(results) {
      //   console.log('results', results);

      //   setFilter(term);
      //   setData(results);
      // });
      const results = index.search(term);
      setFilter(term);
      setData(results);
      console.log(results);
    } else {
      setFilter(term);
      setData(data);
    }
  };

  useEffect(() => {
    const initialTerm = query.get('term');
    // mostra os resultados baseados na url query, normalmente vem de outra rota / tela
    if (initialTerm && initialTerm !== '') {
      index.search(initialTerm, function(results) {
        setData(results);
        setFilter(initialTerm);
      });
    }
  }, []);

  return (
    <div className={classes.container} style={style}>
      <div className={classes.top}>
        <div className={classes.search}>
          <TextField
            id='filter'
            fullWidth
            label='Pesquisar'
            value={filter}
            onChange={handleFilter()}
            variant='outlined'
            type='search'
            placeholder={placeholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <FontAwesomeIcon
                    icon={faSearch}
                    fixedWidth
                    className={classes.icon}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {matches ? (
          <div className={classes.button} onClick={() => setOpen(!open)}>
            <FontAwesomeIcon
              icon={open ? faChevronUp : faChevronDown}
              fixedWidth
              size='lg'
            />
          </div>
        ) : props.children ? (
          <div className={classes.children}>{props.children}</div>
        ) : null}
      </div>
      <div className={classes.bottom}>
        {open ? (
          <div className={classes.childrenMobile}>{props.children}</div>
        ) : null}
      </div>
    </div>
  );
}

function setStyle(trigger, matches, open) {
  if (open && trigger && matches) {
    return {
      position: 'fixed',
      width: '80vw',
      top: 40,
      background: 'white',
      padding: '3em 0.5em 1em 0.5em',
      height: '60vh',
    };
  }

  return {};
}

export default SearchField;
