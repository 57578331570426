import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from 'components/_Common/PrivateRoute';
import Feedback from 'components/_Common/Feedback';
import Login from 'components/Login';
import Conta from 'components/Conta';
import Planos from 'components/Planos';
import Entidades from 'components/Entidades';
import Cupons from 'components/Cupons';
import Associados from 'components/Associados';
import Dashboard from 'components/Dashboard';
import Lixeira from 'components/Lixeira';
import Notificacoes from 'components/Notificacoes';
import Suporte from 'components/Suporte';
import Parceiros from 'components/Parceiros';
import Financeiro from 'components/Financeiro';
import Sellers from 'components/Sellers';
import LinkCobranca from 'components/LinkCobranca';
import Cancelamentos from 'components/Cancelamentos';
import Pagamentos from 'components/Pagamentos';

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={Login} />
          <PrivateRoute exact path='/dashboard' component={Dashboard} />
          <PrivateRoute exact path='/conta' component={Conta} />
          <PrivateRoute exact path='/planos' component={Planos} />
          <PrivateRoute exact path='/cupons' component={Cupons} />
          <PrivateRoute exact path='/entidades' component={Entidades} />
          <PrivateRoute exact path='/associados' component={Associados} />
          <PrivateRoute exact path='/notificacoes' component={Notificacoes} />
          <PrivateRoute exact path='/suporte' component={Suporte} />
          <PrivateRoute exact path='/parceiros' component={Parceiros} />
          <PrivateRoute exact path='/financeiro' component={Financeiro} />
          <PrivateRoute exact path='/sellers' component={Sellers} />
          <PrivateRoute exact path='/sellers' component={Sellers} />
          <PrivateRoute exact path='/link-cobranca' component={LinkCobranca} />
          <PrivateRoute exact path='/cancelamentos' component={Cancelamentos} />
          <PrivateRoute exact path='/pagamentos' component={Pagamentos} />
          <PrivateRoute exact path='/lixeira' component={Lixeira} />
        </Switch>
        <Feedback />
      </BrowserRouter>
    );
  }
}

export default Router;
