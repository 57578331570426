import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Editor from 'components/_Common/WysiwygEditor';
import styles from './styles';
import {
  FormSection,
  SectionText,
  FormTextField,
  FormGroup,
  PhoneInput,
  CancelarOuSalvar,
} from 'components/_Common/Form';
import { formataCelular } from '_helpers/utils';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';
import Switch from 'components/_Common/Switch';
import UploadAvatar from 'components/_Common/UploadAvatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faTwitch,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import InputAdornment from '@material-ui/core/InputAdornment';

const Form = (props) => {
  const {
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleCancel,
    setFieldTouched,
    setFieldValue,
    isValid,
    classes,
  } = props;

  const actions = bindActionCreators(Actions, useDispatch());

  const change = (name, e) => {
    e.persist();
    const value = e.target.value;
    setFieldTouched(name, true, false);
    if (name === 'telefone') {
      setFieldValue(name, formataCelular(value));
    } else if (name === 'whatsapp') {
      setFieldValue(name, formataCelular(value));
    } else if (name === 'entidades' || name === 'associados') {
      setFieldValue(name, e.target.checked);
    } else {
      handleChange(e);
    }
  };

  const saveProps = {
    isValid,
    handleSubmit,
    handleCancel,
  };

  const formProps = {
    errors,
    touched,
    values: props.values,
    change,
    setFieldTouched,
    setFieldValue,
  };

  const startAdornment = (icon) => {
    return (
      <InputAdornment position='start'>
        <FontAwesomeIcon
          icon={icon}
          // className={classes.pullLeft}
          fixedWidth
          size='sm'
        />
      </InputAdornment>
    );
  };

  return (
    <form>
      <CancelarOuSalvar save={saveProps} />

      <FormSection classes={classes} title={props.title}>
        <FormTextField
          label='Nome'
          field='nome'
          xs={12}
          md={12}
          formik={formProps}
        />

        <PhoneInput
          label='Telefone principal'
          field='telefone'
          xs={12}
          md={6}
          formik={formProps}
          type='tel'
        />

        <PhoneInput
          label='WhatsApp'
          field='whatsapp'
          xs={12}
          md={6}
          formik={formProps}
          type='tel'
        />

        <UploadAvatar
          label='Enviar Avatar'
          field='avatar'
          xs={12}
          md={12}
          action={actions.keepAvatar}
          formik={formProps}
        />

        <SectionText>Defina quem verá o Parceiro.</SectionText>
        <FormGroup>
          <Switch
            label='Entidades / Atléticas'
            field='entidades'
            formik={formProps}
          />
        </FormGroup>

        <FormGroup>
          <Switch
            label='Associados / Alunos'
            field='associados'
            formik={formProps}
          />
        </FormGroup>
      </FormSection>

      <FormSection classes={classes} title='Site e Redes Sociais'>
        <SectionText>
          Divulgue onde as pessoas podem encontrar o Parceiro.
        </SectionText>

        <FormTextField
          label='Site'
          field='site'
          xs={12}
          md={12}
          formik={formProps}
          styleProps={{
            InputProps: { startAdornment: startAdornment(faGlobeAmericas) },
          }}
        />

        <FormTextField
          label='facebook.com/'
          field='facebook'
          xs={12}
          md={12}
          formik={formProps}
          styleProps={{
            InputProps: { startAdornment: startAdornment(faFacebookF) },
          }}
        />

        <FormTextField
          label='instagram.com/'
          field='instagram'
          xs={12}
          md={12}
          formik={formProps}
          styleProps={{
            InputProps: { startAdornment: startAdornment(faInstagram) },
          }}
        />

        <FormTextField
          label='youtube.com/channel/'
          field='youtube'
          xs={12}
          md={12}
          formik={formProps}
          styleProps={{
            InputProps: { startAdornment: startAdornment(faYoutube) },
          }}
        />

        <FormTextField
          label='twitch.tv/'
          field='twitch'
          xs={12}
          md={12}
          formik={formProps}
          styleProps={{
            InputProps: { startAdornment: startAdornment(faTwitch) },
          }}
        />

        <FormTextField
          label='twitter.com/'
          field='twitter'
          xs={12}
          md={12}
          formik={formProps}
          styleProps={{
            InputProps: { startAdornment: startAdornment(faTwitter) },
          }}
        />
      </FormSection>

      <FormSection classes={classes} title='Mais detalhes'>
        <SectionText>
          Adicione informações personalizadas sobre o Parceiro.
        </SectionText>
        <Editor
          field='detalhes'
          value={props.values.detalhes}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
        />
      </FormSection>

      <CancelarOuSalvar save={saveProps} />
    </form>
  );
};

export default withStyles(styles)(Form);
