const green = '#59D58D';
const red = '#EA4A25';
const yellow = '#F4A62E';
const blue = '#2C78A4';
const borderRadius = '40px';
const padding = '2px 15px';

export default (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    fontWeight: '500',
  },

  hoje: {
    color: theme.palette.secondary.main,
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius,
    padding,
  },

  error: {
    color: 'white',
    background: 'red',
    border: `2px solid ${red}`,
    borderRadius,
    padding,
  },

  new: {
    color: blue,
    border: `2px solid ${blue}`,
    borderRadius,
    padding,
  },

  pending: {
    color: yellow,
    border: `2px solid ${yellow}`,
    borderRadius,
    padding,
  },

  pre_authorized: {
    color: blue,
    border: `2px solid ${blue}`,
    borderRadius,
    padding,
  },

  succeeded: {
    color: green,
    border: `2px solid ${green}`,
    borderRadius,
    padding,
  },

  failed: {
    color: red,
    border: `2px solid ${red}`,
    borderRadius,
    padding,
  },

  reversed: {
    color: red,
    border: `2px solid ${red}`,
    borderRadius,
    padding,
  },

  canceled: {
    color: red,
    border: `2px solid ${red}`,
    borderRadius,
    padding,
  },

  refunded: {
    color: blue,
    border: `2px solid ${blue}`,
    borderRadius,
    padding,
  },

  dispute: {
    color: red,
    border: `2px solid ${red}`,
    borderRadius,
    padding,
  },

  charged_back: {
    color: red,
    border: `2px solid ${red}`,
    borderRadius,
    padding,
  },
});
