import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  spinner: {
    alignSelf: 'center',
    justifySelf: 'center',
    verticalAlign: 'middle',
    textAlign: 'center',
    marginRight: '2.5em',
    color: theme.palette.primary.main,
  },

  title: {
    fontWeight: '500',
  },

  text: {
    fontWeight: '100',
  },
}));
