import React from 'react';
import { Formik } from 'formik';
import validationSchema from './validation';
import { withStyles } from '@material-ui/core/styles';
import Form from './Form';
import styles from './styles';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';

const NovoCupom = (props) => {
  const actions = bindActionCreators(Actions, useDispatch());
  const { ticket } = props;
  const values = {
    status: ticket.status,
    mensagem: '',
    _id: ticket._id,
  };
  return (
    <Formik
      render={(props) => (
        <Form
          title='Novo Ticket'
          ticket={ticket}
          {...props}
          handleCancel={() => actions.showTrash(ticket)}
        />
      )}
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        actions.reply(values);
      }}
    />
  );
};

export default withStyles(styles)(NovoCupom);
