import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  formContainer: {
    margin: 'auto',
    marginTop: '1em',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '70%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '60%',
    },
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: 'auto',
    display: 'flex',
    marginBottom: '4em',
  },

  title: {
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '70%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '60%',
    },
  },

  formGrid: {
    marginBottom: '4em',
    [theme.breakpoints.down('sm')]: {
      padding: '1em',
    },
  },

  seuLink: {
    fontWeight: '500',
    color: theme.palette.secondary.main,
    marginBottom: '1em',
  },

  gg: {
    marginRight: '0.5em',
  },

  pageTitle: {
    display: 'flex',
    fontSize: '2em',
    marginBottom: '1em',
    alignItems: 'center',
    lineHeight: '1em',
  },

  topFields: {
    margin: '1em 0',
  },

  imagem: {
    width: '100%',
    marginBottom: '2em',
  },

  valores: {
    width: '100%',
    display: 'flex',
  },

  editor: {
    fontSize: '1.5em',
    marginBottom: '0.5em',
  },

  switches: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '2em 0',
  },

  switchBox: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
    },
  },

  switch: {
    flexBasis: '50%',
    padding: '0.5em 0',
    transition: 'all ease 300ms',
    borderRadius: '4px',
    '&:hover': {
      background: '#eef7ff',
      fontWeight: '500',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  switchHelper: {
    flexBasis: '50%',
    marginLeft: '2em',
    lineHeight: '1em',
    fontWeight: '100',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      marginLeft: '1em',
      marginBottom: '1em',
    },
  },
}));
