import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import {
  FormSection,
  SectionText,
  FormTextField,
  CancelarOuSalvar,
} from 'components/_Common/Form';
import Switch from 'components/_Common/Switch';
import { FormGroup } from 'components/_Common/Form';
import Editor from 'components/_Common/WysiwygEditor';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

const Form = props => {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleCancel,
    setFieldTouched,
    setFieldValue,
    isValid,
    classes,
    planos,
  } = props;

  const change = (name, e) => {
    e.persist();
    setFieldTouched(name, true, false);
    if (
      name === 'metodo' ||
      name.includes('tipo.') ||
      name.includes('nivel.')
    ) {
      setFieldValue(name, e.target.checked);
    } else if (name.includes('planos.')) {
      const plano = name.split('.')[1];
      const planosArr = [...values.planos];
      if (planosArr.includes(plano)) {
        setFieldValue(
          'planos',
          planosArr.filter(p => p !== plano),
        );
      } else {
        planosArr.push(plano);
        setFieldValue('planos', planosArr);
      }
    } else if (name === 'todosPlanos') {
      const value = planos.length === values.planos.length;
      const planosArr = !value ? planos.map(p => p._id) : [];
      setFieldValue('planos', planosArr);
    }

    handleChange(e);
  };

  const saveProps = {
    isValid,
    handleSubmit,
    handleCancel,
    saveText: 'Enviar',
  };

  const formProps = {
    errors,
    touched,
    values: props.values,
    change,
  };

  const targetPlanos = () => {
    return (
      <FormSection classes={classes} title='Notificar por plano?'>
        <SectionText>
          {values.nivel.associados ? (
            <Typography variant='subtitle2' color='error' gutterBottom>
              <strong>ATENÇÃO:</strong> os alunos também receberão esta
              notificação. Desmarque o grupo "Associados / Alunos" se for
              notificar por plano.
            </Typography>
          ) : null}
          <strong>
            Se nenhum plano for selecionado, todas as entidades receberão a
            notificação.
          </strong>{' '}
          <br />
          Selecione os planos que devem receber a notificação: <br />
        </SectionText>
        {planos.map(plano => (
          <FormGroup key={plano._id}>
            <Switch
              label={plano.titulo}
              field={`planos.${plano._id}`}
              formik={{
                ...formProps,
                values: {
                  planos: { [plano._id]: values.planos.includes(plano._id) },
                },
              }}
            />
          </FormGroup>
        ))}
        <FormGroup key='todos'>
          <Switch
            label='Para Todos'
            field={`todosPlanos`}
            formik={{
              ...formProps,
              values: {
                todosPlanos: planos.length === values.planos.length,
              },
            }}
          />
        </FormGroup>
      </FormSection>
    );
  };

  return (
    <form>
      <CancelarOuSalvar save={saveProps} />

      <FormSection classes={classes} title={props.title}>
        <SectionText>
          Forneça um título que represente o assunto da notificação.
        </SectionText>
        <FormTextField
          label='Título descritivo'
          field='titulo'
          xs={12}
          md={12}
          formik={formProps}
        />
      </FormSection>

      <FormSection classes={classes} title='Grupo'>
        <SectionText>
          Selecione quem deve receber a notificação. <br />
        </SectionText>
        <FormGroup>
          <Switch
            label='Entidades / Atléticas'
            field='nivel.entidades'
            formik={formProps}
          />
        </FormGroup>

        <FormGroup>
          <Switch
            label='Associados / Alunos'
            field='nivel.associados'
            formik={formProps}
          />
        </FormGroup>
      </FormSection>

      <Collapse in={values.nivel.entidades} timeout={{ exit: 500 }}>
        {values.nivel.entidades ? targetPlanos() : null}
      </Collapse>

      <FormSection classes={classes} title='Tipo'>
        <SectionText>
          <strong>Simples: </strong> notificação via "sininho". <br />
          <strong>Pop Up: </strong> notificação via pop up ao entrar no app e{' '}
          <strong>por e-mail</strong>. <br />É possível selecionar ambos.
        </SectionText>
        <FormGroup>
          <Switch label='Simples' field='tipo.simples' formik={formProps} />
        </FormGroup>

        <FormGroup>
          <Switch label='Pop Up' field='tipo.popup' formik={formProps} />
        </FormGroup>
      </FormSection>

      <FormSection classes={classes} title='Mensagem da notificação'>
        <Editor
          field='mensagem'
          value={props.values.mensagem}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
        />
      </FormSection>

      <CancelarOuSalvar save={saveProps} />
    </form>
  );
};

export default withStyles(styles)(Form);
