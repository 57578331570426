import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    marginTop: '1em',
    zIndex: 100,
  },

  search: {
    display: 'flex',
  },
}));
