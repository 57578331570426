/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Venda from './Vendas/RowVenda';
import useStyles from './styles';
import { useFetch, useActions, useSelector } from 'hooks';
import * as Actions from 'components/Pagamentos/redux/actions';

function PagamentosDoLink(props) {
  const classes = useStyles();
  const actions = useActions(Actions);
  const { boletos } = useSelector('pagamentos');
  useFetch({ boletos });

  return (
    <div className={classes.container}>
      {boletos.map((venda) => {
        return <Venda data={venda} key={venda._id} />;
      })}
    </div>
  );
}

export default PagamentosDoLink;
