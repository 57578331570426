import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  list: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  listItem: {
    padding: '1em 0.5em',
    display: 'flex',
    background: 'white',
    margin: '0.5em 0',
    // alignItems: 'center',
    flexDirection: 'column',
    boxShadow: '2px 2px 7px -4px hsla(0, 0%, 0%, 0.2)',
    '&:hover': {
      background: '#eaeaea',
    },
  },

  top: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginBottom: '1em',
    },
  },

  nome: {
    flex: 1,
    fontWeight: '500',
    marginLeft: '5em',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginLeft: 'unset',
    },
  },

  opcoes: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      // justifyItems: 'space-between',
      justifyContent: 'center',
      marginTop: '1em',
    },
  },

  container: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  containerIdentificador: {
    display: 'flex',
    flex: 2.5,
  },

  buttonOpt: {
    marginRight: '0.5em',
    [theme.breakpoints.down('sm')]: {
      marginRight: '1em',
    },
  },

  containerDados: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    fontSize: '0.9em',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      marginBottom: '2em',
    },
  },

  dadosEntidade: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      margin: '1em 1em 0 1em',
    },
  },

  dadosRepresentante: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1.5',
    [theme.breakpoints.down('sm')]: {
      margin: '0 1em 1em 1em',
    },
  },

  dado: {
    color: 'grey',
    fontWeight: '100',
    fontSize: '0.9em',
  },

  email: {
    fontWeight: '100',
  },

  instituicao: {
    color: 'grey',
    fontSize: '0.675em',
  },

  avatar: {
    display: 'flex',
    marginTop: '5px',
    flexDirection: 'column',
    width: '50px',
    textAlign: 'left',
    marginLeft: '10px',
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50px',
    },
  },

  status: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      marginBottom: '0.5em',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },

  icon: {
    color: 'lightgrey',
    marginRight: '0.5em',
  },

  iconVerified: {
    color: theme.palette.primary.light,
    marginLeft: '0.5em',
    cursor: 'pointer',
  },

  iconNotVerified: {
    color: theme.palette.secondary.dark,
    marginLeft: '0.5em',
    cursor: 'pointer',
  },

  assinaturas: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },

  assinatura: {
    flex: 1,
    display: 'flex',
    marginTop: '0.5em',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: '1em',
      textAlign: 'center',
    },
  },

  plano: {
    flex: 1,
    fontWeight: '400',
    fontSize: '0.9em',
  },

  datas: {
    flex: 1,
    display: 'flex',
  },

  validade: {
    display: 'flex',
    flex: 1,
    fontWeight: '100',
    fontSize: '0.9em',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '4px',
  },

  link: {
    textDecoration: 'none',
    fontWeight: '400',
  },

  buttonOptCredit: {
    marginRight: '0.5em',
    color: '#FFDF00',
    [theme.breakpoints.down('sm')]: {
      marginRight: '1em',
    },
  },
  creditos: {
    marginRight: '0.2em',
    fontWeight: '500',
    fontSize: '0.775em',
    color: '#D4AF37',
  },
}));
