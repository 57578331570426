import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  form: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  iconButton: {
    padding: 10,
  },

  divider: {
    height: 28,
    margin: 4,
  },

  hidden: {
    visibility: 'hidden',
    position: 'absolute',
  },

  Enviado: {
    color: theme.palette.error.dark,
  },

  Analisando: {
    color: theme.palette.primary.dark,
  },

  Encerrado: {
    color: '#008819',
  },

  footerform: {
    margin: '0.2em 0.1em 0.5em 0.1em',
    display: 'flex',
    flex: 1,
    '& > *': {
      flex: 1,
    },
  },
  actionsIcons: {
    display: 'flex',
  },

  remove: {
    color: 'red',
    cursor: 'pointer',
  },

  status: {
    textAlign: 'right',
  },
}));
