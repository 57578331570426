/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import { formataDataHora } from "_helpers/utils";
import Documentos from "./Documentos";
import Grow from "@material-ui/core/Grow";
import Button from "@material-ui/core/Button";
import Name from "./Name";
import Type from "./Type";
import Dialog from "components/_Common/Dialog";
import { useSelector } from "react-redux";
import { useActions } from "hooks";
import * as Actions from "./redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSkullCrossbones } from "@fortawesome/free-solid-svg-icons";
import Loader from "components/_Common/Loader";

function Seller(props) {
  const { seller } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);

  const actions = useActions(Actions);
  const { loading } = useSelector(({ sellers }) => ({ ...sellers }));

  useEffect(() => {
    if (load && !loading) {
      setLoad(false);
    }
  }, [loading]);

  const getStatus = status => {
    if (status === "enabled") return "Habilitado";
    if (status === "activo") return "Ativo";
    if (status === "pending") return "Pendência de Documentos";
    if (status === "created") return "Pendência de Documentos";
    return status;
  };

  const getType = type => {
    if (type === "individual") return "Pessoa Física";
    if (type === "business") return "Pessoa Jurídica";
    return type;
  };

  const handleExclusao = del => {
    if (del) {
      actions.trashSeller(seller);
      setLoad(true);
    }
    setOpen(false);
  };

  const modalExcluir = () => {
    const status = getStatus(seller.status);
    if (status === "Habilitado" || status === "Ativo") {
      return null;
    }
    if (open) {
      return (
        <Dialog
          title={`Excluir o seller permanentemente?`}
          no="Cancelar"
          yes="Excluir"
          message="Todos os registros do seller serão removidos do banco de dados do Farol, do sistema Zoop e os documentos, se enviados, serão excluídos. Esta ação NÃO é reversível!"
          action={() => handleExclusao(true)}
          close={() => handleExclusao(false)}
        />
      );
    }
  };

  const ButtonDelete = props => {
    const status = getStatus(seller.status);
    if (status === "Habilitado" || status === "Ativo") {
      return null;
    }
    return (
      <Button
        size="small"
        disabled={load}
        variant="contained"
        onClick={() => setOpen(true)}
        color="secondary"
        className={classes.buttonDel}
      >
        {load ? (
          <Loader />
        ) : (
          <>
            <FontAwesomeIcon
              icon={faSkullCrossbones}
              className={classes.fIcon}
              fixedWidth
              size="lg"
            />
            excluir
          </>
        )}
      </Button>
    );
  };

  const { email, type, status, entidade } = seller;
  console.log("Seller is ", seller);
  return (
    <Grow in={true}>
      <div className={classes.seller}>
        {modalExcluir()}
        <div className={classes.left}>
          <div className={classes.name}>
            <Name seller={seller} />
          </div>
          <div className={classes.email}>{email}</div>

          <div className={classes.wrapper}>
            <div className={classes.tipo}>Conta {getType(type)}</div>
            <div className={classes.info}>
              <Type seller={seller} />
            </div>
          </div>

          <div className={classes.wrapper}>
            <div className={classes.tipo}>Status</div>
            <div className={classes.info}>{getStatus(status)}</div>
          </div>

          <div className={classes.icon}>
            <Documentos seller={seller} />
          </div>
        </div>
        <div className={classes.right}>
          {entidade ? (
            <div className={classes.entidade}>{entidade.nome}</div>
          ) : (
            <div className={classes.entidadeError}>
              Este seller não possui entidade
            </div>
          )}

          {seller.padrao ? (
            <div className={classes.padrao}>Este é o seller padrão</div>
          ) : null}

          <div className={classes.details}>Detalhes da conta</div>

          <div className={classes.bwrapper}>
            <div className={classes.balance}>
              <div className={classes.blabel}>Saldo atual</div>
              <div className={classes.bvalue}>
                {`R$ ${seller.current_balance.toFixed(2)}`}
              </div>
            </div>

            <div className={classes.balance}>
              <div className={classes.blabel}>À receber</div>
              <div className={classes.bvalue}>
                {`R$ ${seller.current_blocked_balance.toFixed(2)}`}
              </div>
            </div>
          </div>

          <div className={classes.dateWrapper}>
            <div className={classes.dlabel}>Abertura</div>
            <div className={classes.date}>
              {formataDataHora(seller.createdAt)}
            </div>
          </div>

          <div className={classes.dateWrapper}>
            <div className={classes.dlabel}>Última alteração</div>
            <div className={classes.date}>
              {formataDataHora(seller.updatedAt)}
            </div>
          </div>
          <ButtonDelete />
        </div>
      </div>
    </Grow>
  );
}

export default Seller;
