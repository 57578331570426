export default (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    borderTop: `1px solid ${theme.palette.secondary.light}`,
    marginTop: '1em',
    paddingTop: '1em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      textAlign: 'left',
    },
  },

  status: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  label: {
    marginRight: '1em',
    fontWeight: '100',
  },

  errText: {
    color: theme.palette.secondary.dark,
    fontWeight: '500',
  },

  buttons: {
    display: 'flex',
  },

  form: {
    margin: '1em 0',
  },

  textField: {
    margin: '0.5em 0.5em',
  },
});
