import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import Dialog from 'components/_Common/Dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as local from '_helpers/local';

function Comparativo(props) {
  const classes = useStyles();

  const [show, setShow] = useState(false);
  const [planos, setPlanos] = useState([]);

  useEffect(() => {
    setPlanos(local.planos());
  }, []);

  const row = (label, servc) => {
    return (
      <TableRow key={label} padding='checkbox'>
        <TableCell>{label}</TableCell>
        {planos.map(({ servicos }) => {
          return <TableCell align='center'>{servicos[servc].cota}</TableCell>;
        })}
      </TableRow>
    );
  };

  const tabela = () => {
    return (
      <Table className={classes.table} padding='checkbox' size='medium'>
        <TableHead>
          <TableRow padding='none'>
            <TableCell align='right' component='th' padding='none' />
            {planos.map((plano, index) => (
              <TableCell scope='row' align='center' key={plano._id + index}>
                {plano.titulo}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {row('Criar Planos', 'planos')}
          {row('Cupons de desconto', 'cupons')}
          {row('Associados (total)', 'associados')}
          {row('Inclusão de Associado via Sistema', 'associadoManual')}
          {row('Inclusão de Associado via planilha', 'associadoPlanilha')}
          {row('Carteirinhas de Estudante personalizadas', 'carteirinhas')}
          {row('Parceiros (total)', 'parceiros')}
          {row('Perfil de Parceiro com mapa', 'mapaParceiro')}
          {row('SMS (total)', 'sms')}
          {row('Listas de SMS', 'listaSms')}
          {row('Envio de e-mail (marketing)', 'mailing')}
          {row('Listas de e-mail marketing', 'listaMailing')}
          {row('Notificações via Sistema', 'notificacoesSistema')}
          {row('Gerenciador Financeiro', 'gerenciadorFinanceiro')}
          {row('Emissão de Cobrança Avulsa', 'cobrancaAvulsa')}
          {row('Site', 'site')}
        </TableBody>
      </Table>
    );
  };

  return (
    <>
      <div className={classes.avisoCotas} onClick={() => setShow(true)}>
        veja os limites e compare os planos
      </div>
      {show ? (
        <Dialog
          title={`Comparativo dos planos`}
          no='Fechar'
          yes='Ok, obrigado'
          action={() => setShow(false)}
          close={() => setShow(false)}>
          {tabela()}
        </Dialog>
      ) : null}
    </>
  );
}

export default Comparativo;
