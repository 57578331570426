export default (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    margin: '1em',
    padding: '1em',
    paddingLeft: '1.5em',
  },

  title: {
    margin: '0',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
    color: 'grey',
    marginBottom: '0.5em',
  },

  infos: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },

  recLabel: {
    fontWeight: '400',
  },

  recValue: {
    fontWeight: '500',
    fontSize: '2.5em',
    marginBottom: '0.5em',
    color: '#30ca32',
  },

  payLabel: {
    fontWeight: '400',
  },

  payValue: {
    fontWeight: '500',
    fontSize: '2.5em',
    marginBottom: '0.5em',
    color: 'red',
  },
});
