import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const theme = createMuiTheme({
  palette: {
    common: { black: '#000', white: '#fff' },
    background: { paper: '#fff', default: '#f9fcff' },
    primary: {
      light: '#bee1fe',
      main: '#4aadfe',
      dark: '#267edc',
      contrastText: '#fff',
    },
    secondary: {
      light: '#febbd6',
      main: '#fe3186',
      dark: '#c10063',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    green: {
      light: '#54d87d',
      main: '#00a54f',
      dark: '#007523',
      contrastText: '#fff',
    },
  },
});

export default theme;
