import {
  REQUEST,
  SUCCESS,
  FAILURE,
  CANCEL,
  CHANGE_VIEW,
  SET_CONTAS_HOJE,
} from './types';
import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';

export { loadEntidades } from 'components/Entidades/redux/actions';
export const cancel = () => ({ type: CANCEL });
export const credenciamento = (view) => ({ type: CHANGE_VIEW, payload: view });
export const changeView = (view) => ({ type: CHANGE_VIEW, payload: view });

export const setContasHoje = (receber, pagar) => ({
  type: SET_CONTAS_HOJE,
  payload: { receber, pagar },
});

export const listPedidos = () => {
  return sendRequest(service.listPedidos, null, true);
};

export const cobrancaEmLote = (cobrancas) => {
  return sendRequest(service.cobrancaEmLote, cobrancas);
};

export const trash = (pedido) => {
  return sendRequest(service.trash, pedido);
};

export const reemitir = (pedido, descricao, valor, vencimento) => {
  return sendRequest(service.reemitir, {
    pedido,
    descricao,
    valor,
    vencimento,
  });
};

function sendRequest(serviceName, params, noFeedback) {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    // ***SERVICE CALL***
    const res = await serviceName({ ...params });
    if (res.success) {
      dispatch({ type: SUCCESS, payload: res.data });
      if (!noFeedback) dispatch(feedback.success(res.data.msg));
    } else {
      dispatch({ type: FAILURE });
      if (!noFeedback) dispatch(feedback.error(res.error));
    }
  };
}
