import { send } from '_helpers/api.config';

const apiURL = '/api/admin/financeiro';

export const listSellers = async (entidade) => {
  const endpoint = `${apiURL}/sellers/${entidade._id}`;
  const verb = 'GET';
  const data = null;
  return send(verb, endpoint, data, false);
};

// export const edit = async (entidade) => {
//   const endpoint = `${apiURL}/${entidade._id}`;
//   const verb = 'PUT';
//   const data = entidade;
//   return send(verb, endpoint, data, false);
// };

// // soft delete
// export const trash = async (entidade) => {
//   const endpoint = `${apiURL}/${entidade._id}`;
//   const verb = 'DELETE';
//   const data = entidade;
//   return send(verb, endpoint, data, false);
// };

// // HARD DELETE
// export const del = async (entidade) => {
//   const endpoint = `${apiURL}/del/${entidade._id}`;
//   const verb = 'DELETE';
//   const data = entidade;
//   return send(verb, endpoint, data, false);
// };

// export const changeAccess = async (entidade) => {
//   const endpoint = `${apiURL}/changeAccess/${entidade._id}`;
//   const verb = 'PUT';
//   const data = null;
//   return send(verb, endpoint, data, false);
// };
