import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import { Formik } from 'formik';
import { validationSchema, validationAddress } from './validation';
import Form from './form';
import FormAddress from './formAddress';
import { formataData, formataCelular, formataCep } from '_helpers/utils';
import { formatCpf } from '@brazilian-utils/formatters';
import * as local from '_helpers/local';
import PerfilLeft from './Left';
import PerfilCenter from './Center';
import PerfilRight from './Right';
import TitleBar from 'components/_Common/TitleBar';
import * as Actions from '../redux/actions';
import { useActions, useSelector } from 'hooks';
import useStyles from './styles';

function Perfil(props) {
  const classes = useStyles();
  window.scrollTo(0, 0);
  const actions = useActions(Actions);
  const { showEdit, associado } = useSelector('associados');

  const entidade = local
    .entidades()
    .filter((entidade) => entidade._id === associado.entidade);

  if (showEdit) {
    const values = { ...associado };
    values.cpf = values.cpf ? formatCpf(values.cpf) : '';
    values.celular = values.celular ? formataCelular(values.celular) : '';

    return (
      <>
        <div className={classes.container}>
          <Paper className={classes.paper}>
            <TitleBar title='Editar Perfil' />
            <Formik
              render={(props) => <Form {...props} />}
              initialValues={values}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                actions.edit(values);
              }}
            />
          </Paper>
        </div>
        <div className={classes.container}>
          <Paper className={classes.paper}>
            <TitleBar title='Editar Endereço' />
            <Formik
              render={(props) => <FormAddress {...props} />}
              initialValues={values}
              validationSchema={validationAddress}
              onSubmit={(values) => {
                actions.edit(values);
              }}
            />
          </Paper>
        </div>
      </>
    );
  }

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <div className={classes.profile}>
          <PerfilLeft
            associado={associado}
            entidade={entidade}
            classes={classes}
            actions={actions}
          />

          <PerfilCenter
            associado={associado}
            entidade={entidade}
            classes={classes}
            actions={actions}
          />

          <PerfilRight
            associado={associado}
            entidade={entidade}
            classes={classes}
            actions={actions}
          />
        </div>
      </Paper>
    </div>
  );
}

export default Perfil;
