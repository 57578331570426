/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import Grow from '@material-ui/core/Grow';
import Checkbox from '@material-ui/core/Checkbox';
import MiniAvatar from 'components/_Common/MiniAvatar';
import useStyles from './styles';
import Assinaturas from './Assinaturas';
import Opcoes from './Opcoes';
import Email from './Email';
import DadosEntidade from './DadosEntidade';
import DadosRepresentante from './DadosRepresentante';
import { useSelector } from 'hooks';

function Entidade(props) {
  const classes = useStyles();
  const { actions } = props;
  const { setEntidade } = props.rest;
  const entidade = props.data;
  const { showSelect } = useSelector('entidades');
  const { type, value } = showSelect;
  const checked = value.find(({ _id }) => _id.toString() === entidade._id.toString());

  const handleChange = (event) => {
    actions.setSelected(entidade, event.target.checked);
  };

  return (
    <Grow in={true}>
      <div className={classes.listItem}>
        <div className={classes.top}>
          <div className={classes.nome}>{entidade.nome}</div>
          <Opcoes entidade={entidade} handleTrash={setEntidade} actions={actions} />
        </div>
        <div className={classes.container}>
          <div className={classes.containerIdentificador}>
            {type !== '#' && (
              <Checkbox
                checked={checked ? true : false}
                value='secondary'
                onChange={handleChange}
                color='primary'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            )}
            <div className={classes.avatar}>
              <MiniAvatar entidade={entidade} />
            </div>
            <div className={classes.dataText}>
              <Email entidade={entidade} />
              <div className={classes.dado}>
                {entidade.apelido} - {entidade.tipo}
              </div>
              {entidade.url && entidade.url !== '' ? (
                <div className={classes.dado}>
                  <a
                    className={classes.link}
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`https://farol.fun/${entidade.url}`}>
                    /{entidade.url}
                  </a>
                </div>
              ) : null}
              <div className={classes.instituicao}>{entidade.instituicao.toUpperCase()}</div>
            </div>
          </div>
          <DadosEntidade entidade={entidade} />
          <DadosRepresentante entidade={entidade} />
          <Assinaturas entidade={entidade} />
        </div>
      </div>
    </Grow>
  );
}

export default memo(Entidade);
