import React, { useState } from 'react';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import useStyles from './styles';

function WysiwygEditor(props) {
  const [editorState, setEditorState] = useState(() => {
    const { value } = props;
    if (value) {
      const blocksFromHTML = convertFromHTML(value);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      return EditorState.createWithContent(state);
    } else {
      return EditorState.createEmpty();
    }
  });

  const classes = useStyles();

  const onEditorStateChange = (newEditorState) => {
    handleChange(newEditorState);
    setEditorState(newEditorState);
  };

  const handleChange = (editorState) => {
    const { setFieldValue, setFieldTouched, field } = props;
    const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setFieldTouched(field, true, false);
    setFieldValue(field, value);
  };

  return (
    <div>
      <Editor
        handlePastedText={() => false}
        editorClassName={classes.editor}
        editorState={editorState}
        localization={{ locale: 'pt' }}
        onEditorStateChange={onEditorStateChange}
        editorStyle={{
          color: 'rgba(0, 0, 0, 0.87)',
          cursor: 'text',
          fontSize: '1rem',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          lineHeight: '1.1875em',
          alignItems: 'center',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderRadius: '4px',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          paddingLeft: '1em',
          paddingRight: '1em',
          margin: '0',
        }}
        toolbar={{
          options: [
            'inline',
            'fontSize',
            'textAlign',
            'colorPicker',
            'list',
            'link',
            'emoji',
            'embedded',
            'image',
            'remove',
            'history',
          ],
          colorPicker: {
            colors: [
              'rgb(50,0,66)',
              'rgb(255, 49, 134)',
              'rgb(75, 171, 255)',
              'rgb(97,189,109)',
              'rgb(26,188,156)',
              'rgb(84,172,210)',
              'rgb(44,130,201)',
              'rgb(147,101,184)',
              'rgb(71,85,119)',
              'rgb(204,204,204)',
              'rgb(65,168,95)',
              'rgb(0,168,133)',
              'rgb(61,142,185)',
              'rgb(41,105,176)',
              'rgb(85,57,130)',
              'rgb(40,50,78)',
              'rgb(0,0,0)',
              'rgb(247,218,100)',
              'rgb(251,160,38)',
              'rgb(235,107,86)',
              'rgb(226,80,65)',
              'rgb(163,143,132)',
              'rgb(239,239,239)',
              'rgb(255,255,255)',
              'rgb(250,197,28)',
              'rgb(243,121,52)',
              'rgb(209,72,65)',
              'rgb(184,49,47)',
              'rgb(124,112,107)',
              'rgb(209,213,216)',
            ],
          },
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
        }}
      />
    </div>
  );
}

export default WysiwygEditor;
