/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import useStyles from './styles';
import Grow from '@material-ui/core/Grow';
import Tooltip from '@material-ui/core/Tooltip';
import MiniAvatar from 'components/_Common/MiniAvatar';
import { formataDataHora, formataData, formataCelular } from '_helpers/utils';
import { formatCpf } from '@brazilian-utils/formatters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faIdCard,
  faGraduationCap,
  faMobileAlt,
  faTimes,
  faCheck,
  faBirthdayCake,
  faUserFriends,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import Assinaturas from './Assinaturas';
import Opcoes from './Opcoes';

function Associado(props) {
  const classes = useStyles();
  const associado = props.data;
  const { actions } = props;
  const { setAssociado } = props.rest;

  const apelido = associado.entidade ? associado.entidade.apelido : 'ENTIDADE EXCLUÍDA';

  // Get Last Access
  let lastAccess = associado?.user?.lastAccess ?? null;
  if (lastAccess) {
    lastAccess = formataDataHora(lastAccess);
  }

  return (
    <Grow key={associado._id} in={true}>
      <div key={associado._id} className={classes.listItem}>
        <div className={classes.containerIdentificador}>
          <div className={classes.avatar}>
            <MiniAvatar associado={associado} />
          </div>
          <div className={classes.dataText}>
            <div className={classes.nome}>{associado.nome}</div>
            <div className={classes.dado}>{associado.nivel}</div>
            <div className={classes.dado}>
              {associado.email}
              {associado.user && associado.user.isVerified ? (
                <Tooltip title='E-mail verificado' placement='right'>
                  <span>
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={classes.iconVerified}
                      fixedWidth
                      size='sm'
                    />
                  </span>
                </Tooltip>
              ) : (
                <Tooltip title='E-mail não verificado' placement='right'>
                  <span>
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={classes.iconNotVerified}
                      fixedWidth
                      size='sm'
                    />
                  </span>
                </Tooltip>
              )}
            </div>
            {lastAccess && (
              <Tooltip title='Último acesso' placement='left'>
                <div className={classes.dado}>
                  <FontAwesomeIcon
                    icon={faUserSecret}
                    className={classes.icon}
                    fixedWidth
                    size='sm'
                  />
                  {lastAccess}
                </div>
              </Tooltip>
            )}
            <Opcoes associado={associado} handleTrash={setAssociado} actions={actions} />
          </div>
        </div>

        <div className={classes.containerDados}>
          <div className={classes.nome}>
            <FontAwesomeIcon icon={faUserFriends} className={classes.icon} fixedWidth size='sm' />
            {apelido}
          </div>

          <div className={classes.dado}>
            <FontAwesomeIcon icon={faIdCard} className={classes.icon} fixedWidth size='sm' />
            {formatCpf(associado.cpf)}
          </div>

          <div className={classes.dado}>
            <FontAwesomeIcon icon={faGraduationCap} className={classes.icon} fixedWidth size='sm' />
            {associado.curso}
          </div>

          <div className={classes.dado}>
            <FontAwesomeIcon icon={faMobileAlt} className={classes.icon} fixedWidth size='sm' />
            {formataCelular(associado.celular)}
          </div>

          <div className={classes.dado}>
            <FontAwesomeIcon icon={faBirthdayCake} className={classes.icon} fixedWidth size='sm' />
            {formataData(associado.nascimento)}
          </div>
        </div>

        <Assinaturas assinaturas={associado.assinaturas} />
      </div>
    </Grow>
  );
}

export default memo(Associado);
