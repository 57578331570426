import { send } from '_helpers/api.config';

const apiURL = '/api/admin/scores';

export const loadScores = async () => {
  const endpoint = `${apiURL}`;
  const verb = 'GET';
  const data = null;
  return send(verb, endpoint, data);
};

export const loadPesos = async () => {
  const endpoint = `${apiURL}/prefs`;
  const verb = 'GET';
  const data = null;
  return send(verb, endpoint, data);
};

export const setPesos = async (pesos) => {
  const endpoint = `${apiURL}`;
  const verb = 'PUT';
  const data = { pesos };
  return send(verb, endpoint, data);
};
