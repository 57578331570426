/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import validationSchema from './Novo/validation';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './redux/actions';
import styles from './Novo/styles';
import Form from './Novo/form';
import Spinner from 'components/_Common/Spinner';

const EditarNotificacao = props => {
  window.scrollTo(0, 0);
  const { classes } = props;
  const actions = bindActionCreators(Actions, useDispatch());
  const { showEdit, notificacao } = useSelector(({ notificacoes }) => ({
    ...notificacoes,
  }));
  const { planos } = useSelector(({ planos }) => ({
    ...planos,
  }));

  useEffect(() => {
    if (planos.length === 0) {
      actions.loadPlanos();
    }
  }, []);

  if (!showEdit) {
    return <Spinner text='Atualizando lista de Planos...' size='2x' noBox />;
  }

  return (
    <div className={classes.formContainer}>
      <Formik
        render={props => (
          <Form
            title='Editar Notificação'
            handleCancel={actions.cancel}
            planos={planos}
            {...props}
          />
        )}
        initialValues={notificacao}
        validationSchema={validationSchema}
        onSubmit={values => {
          actions.edit(values);
        }}
      />
    </div>
  );
};

export default withStyles(styles)(EditarNotificacao);
