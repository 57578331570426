export default (theme) => ({
  card: {
    width: 285,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2em',
    [theme.breakpoints.up('sm')]: {
      marginRight: '1em',
      marginLeft: '1em',
      marginBottom: '2em',
    },
  },

  mr: {
    marginRight: theme.spacing(1),
  },

  avatarPlaceholder: {
    color: 'lightgrey',
    marginTop: '0.8em',
  },

  avatar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    textAlign: 'center',
    width: '200px',
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  img: {
    display: 'inline',
    margin: '0 auto',
    width: '200px',
    padding: '10px',
  },

  topOptions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },

  avatarAtivo: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    zIndex: 1,
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: theme.spacing(1),
  },

  avatarInativo: {
    color: theme.palette.secondary.light,
    cursor: 'pointer',
    zIndex: 1,
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: theme.spacing(1),
  },

  destaque: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },

  menu: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },

  star: {
    flex: 1,
    color: 'gold',
    cursor: 'pointer',
    justifyContent: 'center',
  },

  starInativo: {
    flex: 1,
    color: 'lightgrey',
    cursor: 'pointer',
    justifyContent: 'center',
  },

  actions: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
  },

  telefone: {
    textAlign: 'center',
  },

  site: {
    marginTop: '1em',
    textAlign: 'center',
  },

  pullLeft: {
    // position: 'absolute',
    right: theme.spacing(2),
  },

  link: {
    color: theme.palette.primary.main,
  },
});
