/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useStyles from './styles';
import { useSelector, useActions } from 'hooks';
import * as Actions from './redux/actions';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PlanoCard from './PlanoCard';
import Novo from './Novo';
import Editar from './Editar';
import Replicar from './Copy';
import Spinner from 'components/_Common/Spinner';
import Switch from 'components/_Common/SimpleSwitch';

const Planos = (props) => {
  const classes = useStyles();

  const actions = useActions(Actions);
  const { planos, create, copy, edit, loading, compact } = useSelector('planos');

  useEffect(() => {
    if (planos.length === 0) {
      actions.loadPlanos();
    }
  }, []);

  useEffect(() => {
    return () => {
      actions.cancel();
    };
  }, []);

  if (loading.get) {
    return <Spinner text='Processando...' size='2x' noBox />;
  }

  if (create) return <Novo />;
  if (edit) return <Editar plano={props.plano} />;
  if (copy) return <Replicar plano={props.plano} />;
  return (
    <>
      <div className={classes.compact}>
        <Switch
          label='Visualização compacta'
          field='compact'
          value={compact}
          action={actions.compact}
          color='primary'
        />
      </div>
      <div className={classes.container} elevation={1}>
        {planos.map((plano, index) => (
          <PlanoCard plano={plano} actions={actions} key={plano._id} />
        ))}
      </div>
      <Fab
        variant='extended'
        color='primary'
        aria-label='Criar novo'
        onClick={() => actions.showCreate()}
        className={classes.fab}>
        <FontAwesomeIcon
          icon={faPlus}
          // className={classes.extendedIcon}
          fixedWidth
        />
        Criar novo plano
      </Fab>
    </>
  );
};

export default Planos;
