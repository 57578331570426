import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  options: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1em',
    marginTop: '0.5em',
  },

  button: {
    marginRight: '1em',
  },
}));
