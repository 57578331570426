import * as Yup from 'yup';

const validationSchema = Yup.object({
  titulo: Yup.string('').required('* Obrigatório'),

  mensagem: Yup.string('')
    .min(5, 'Mensagem muito curta')
    .required('* Obrigatório'),
});

export default validationSchema;
