import { CANCEL, REQUEST, SUCCESS, FAILURE } from './types';
import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';

export const cancel = () => ({ type: CANCEL });

export const loadBoletos = (url) => {
  const loader = { list: true };
  return async (dispatch) => {
    dispatch({ type: REQUEST, payload: { loader } });
    // ***SERVICE CALL***
    const res = await service.load();
    loader.list = false;
    if (res.success) {
      const { boletos } = res.data;
      dispatch({ type: SUCCESS, payload: { url, loader, boletos } });
    } else {
      dispatch({ type: FAILURE, payload: { url, loader } });
      dispatch(feedback.error(res.error));
    }
  };
};

export const start = (link) => {
  const ID = link._id;
  return async (dispatch) => {
    dispatch({ type: REQUEST, payload: { ID } });
    // ***SERVICE CALL***
    const res = await service.start(link);
    if (res.success) {
      const { links } = res.data;
      dispatch({ type: SUCCESS, payload: { links, ID, view: 'MEUS LINKS' } });
      dispatch(feedback.success(res.data.msg));
    } else {
      dispatch({ type: FAILURE, payload: { ID } });
      dispatch(feedback.error(res.error));
    }
  };
};
