import {
  SHOW_CREATE,
  REQUEST,
  SUCCESS,
  FAILURE,
  SHOW_EDIT,
  SHOW_TRASH,
  CANCEL,
} from './types';
import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';

export const cancel = () => ({ type: CANCEL });
export const showCreate = () => ({ type: SHOW_CREATE });
export const showEdit = (cupom) => ({ type: SHOW_EDIT, payload: cupom });
export const showTrash = (cupom) => ({ type: SHOW_TRASH, payload: cupom });

export const loadCupons = () => {
  return sendRequest(service.list, null, false);
};

export const create = (cupom) => {
  return sendRequest(service.create, cupom);
};

export const edit = (cupom) => {
  return sendRequest(service.edit, cupom);
};

export const trash = (cupom) => {
  return sendRequest(service.trash, cupom);
};

export const mudarStatus = (cupom) => {
  return sendRequest(service.mudarStatus, cupom);
};

function sendRequest(serviceName, params, notif = true) {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    // ***SERVICE CALL***
    const res = await serviceName({ ...params });
    if (res.success) {
      if (notif) dispatch(feedback.success(res.data.msg));
      dispatch({ type: SUCCESS, payload: res.data });
    } else {
      if (notif) dispatch(feedback.error(res.error));
      dispatch({ type: FAILURE });
    }
  };
}
