import React from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';
import validationSchema from './validation';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import Form from './form';
import values from './defaultFormValues';

const NovoParceiro = (props) => {
  const { classes } = props;
  const actions = bindActionCreators(Actions, useDispatch());
  return (
    <div className={classes.formContainer}>
      <Formik
        render={(props) => (
          <Form
            handleCancel={actions.cancel}
            title={'Criar novo parceiro'}
            {...props}
          />
        )}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          actions.create(values);
        }}
      />
    </div>
  );
};

export default withStyles(styles)(NovoParceiro);
