import { makeStyles } from '@material-ui/core/styles';
const green = '#59D58D';

export default makeStyles((theme) => ({
  list: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  listItem: {
    padding: '1em 0.5em',
    display: 'flex',
    background: 'white',
    flexDirection: 'column',
    margin: '0.5em 0',
    boxShadow: '2px 2px 7px -4px hsla(0, 0%, 0%, 0.2)',
    '&:hover': {
      background: '#eaeaea',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      // padding: '1em 2em',
    },
  },

  error: {
    flex: 1,
    display: 'flex',
  },

  container: {
    flex: 1,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
  },

  containerNumero: {
    flex: '0.8',
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid #eaeaea',
    textAlign: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      order: 0,
      borderRight: 'unset',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },

  numero: {
    fontSize: '1.5em',
    fontWeight: '600',
    color: 'grey',
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },

  data: {
    color: 'grey',
    fontWeight: '200',
    [theme.breakpoints.down('sm')]: {
      order: 0,
    },
  },

  containerDados: {
    display: 'flex',
    flex: 3,
    [theme.breakpoints.down('sm')]: {
      order: 1,
      marginTop: '1em',
    },
  },

  nome: {
    fontWeight: '500',
  },

  email: {
    color: 'grey',
    [theme.breakpoints.down('sm')]: {
      wordWrap: 'break-word',
      width: 200,
    },
  },

  avatar: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '50px',
    maxHeight: '50px',
    textAlign: 'left',
    marginLeft: '10px',
    marginRight: '10px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50px',
    },
  },

  descricao: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    color: 'grey',
    [theme.breakpoints.down('sm')]: {
      order: 2,
      flexDirection: 'column',
      flexBasis: '100%',
      width: '100%',
      alignItems: 'space-around',
      margin: '0.5em 0',
    },
  },

  containerValor: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      order: 3,
      flexBasis: '100%',
      flexDirection: 'row',
      marginBottom: '0.5em',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },

  valor: {
    fontSize: '1.75em',
    fontWeight: '500',
  },

  vencimento: {
    fontWeight: '200',
    color: 'grey',
  },

  vctoIcon: {
    color: 'lightgrey',
    marginRight: '0.5em',
  },

  metodo: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      order: 5,
      justifyContent: 'flex-end',
    },
  },

  status: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      order: 4,
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },

  pagoEm: {
    color: green,
    marginTop: '0.5em',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },

  paidIcon: {
    color: green,
    marginRight: '0.5em',
  },
}));
