import {
  SUCCESS,
  REQUEST,
  FAILURE,
  SHOW_CREATE,
  SHOW_EDIT,
  SHOW_TRASH,
  CANCEL,
} from './types';

const INITIAL_STATE = {
  loading: false,
  create: false,
  edit: false,
  trash: false,
  cupons: [],
  cupom: {},
  planos: [],
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case REQUEST:
      return { ...state, loading: true };
    case FAILURE:
      return { ...state, loading: false };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        create: false,
        edit: false,
        trash: false,
        planos: [...payload.planos],
        cupons: [...payload.cupons],
      };

    case SHOW_CREATE:
      return { ...state, create: true };

    case SHOW_EDIT:
      return { ...state, edit: true, cupom: payload };

    case SHOW_TRASH:
      return { ...state, trash: true, cupom: payload };

    case CANCEL:
      return {
        ...state,
        loading: false,
        create: false,
        edit: false,
        trash: false,
      };

    default:
      return state;
  }
};
