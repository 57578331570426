import { REQUEST, SUCCESS, FAILURE } from './types';
import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';

export const getPedido = (numero) => {
  const loader = 'getPedido';
  return async (dispatch) => {
    dispatch({ type: REQUEST, payload: { loader } });
    // ***SERVICE CALL***
    const res = await service.getPedido(numero);
    if (res.success) {
      const { pedido } = res.data;
      dispatch({ type: SUCCESS, payload: { pedido, loader } });
    } else {
      dispatch({ type: FAILURE, payload: { loader } });
      dispatch(feedback.error(res.error));
    }
  };
};

export const cancelaPedido = (pedido) => {
  const loader = 'cancelaPedido';
  return async (dispatch) => {
    dispatch({ type: REQUEST, payload: { loader } });
    // ***SERVICE CALL***
    const res = await service.cancelaPedido(pedido);
    if (res.success) {
      const { pedido } = res.data;
      dispatch({ type: SUCCESS, payload: { pedido, loader } });
      dispatch(feedback.success(res.msg));
    } else {
      dispatch({ type: FAILURE, payload: { loader } });
      dispatch(feedback.error(res.error));
    }
  };
};
