import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  fIcon: {
    marginRight: '0.5em',
  },

  missing: {
    color: theme.palette.secondary.main,
    fontWeight: '500',
    marginTop: '0.5em',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '1em',
    },
  },

  emAnalise: {
    color: '#ff5722',
    marginTop: '0.5em',
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '1em',
    },
  },

  approved: {
    color: theme.palette.primary.main,
    marginTop: '0.5em',
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '1em',
    },
  },

  link: {
    textDecoration: 'none',
  },
}));
