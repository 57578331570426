import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';

function PlanoAvatar(props) {
  const classes = useStyles();
  const { plano } = props;
  const { status } = plano;

  return (
    <div className={status ? classes.hActive : classes.hInactive}>
      <div className={classes.titulo}>
        <Typography
          variant='h4'
          align='center'
          className={status ? classes.activeText : classes.inactiveText}>
          {plano.titulo}
        </Typography>
        <div className={classes.slogan}>
          <Typography
            variant='subtitle2'
            align='center'
            className={status ? classes.activeText : classes.inactiveText}>
            {plano.slogan}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default PlanoAvatar;
