import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import {
  FormSection,
  FormTextField,
  MoneyTextField,
  CancelarOuSalvar,
} from 'components/_Common/Form';
import MiniAvatar from 'components/_Common/MiniAvatar';

import { formataValor, formataDataInput } from '_helpers/utils';

const Form = (props) => {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleCancel,
    setFieldTouched,
    setFieldValue,
    isValid,
    classes,
  } = props;

  const [showGrupo, setShowGrupo] = useState(false);

  const setValores = (valor) => {
    values.list.forEach((el, index) => {
      setFieldValue(`list.${index}.valor`, valor, false);
      setFieldTouched(`list.${index}.valor`, valor, false);
    });
  };

  const setVencimentos = (vencimento) => {
    values.list.forEach((el, index) => {
      setFieldValue(`list.${index}.vencimento`, vencimento, false);
      setFieldTouched(`list.${index}.vencimento`, vencimento, false);
    });
  };

  const change = (name, e) => {
    e.persist();
    setFieldTouched(name, true, true);
    const value = e.target.value;
    if (name === 'valor') {
      setFieldValue(name, formataValor(value, 6), true);
    } else if (name.includes('.valor')) {
      setFieldValue(name, formataValor(value, 6));
    } else if (name === 'vencimento') {
      setFieldValue(name, formataDataInput(value), true);
      if (value.length === 10) {
        setVencimentos(formataDataInput(value));
      }
    } else if (name.includes('.vencimento')) {
      setFieldValue(name, formataDataInput(value));
    } else {
      handleChange(e);
    }
  };

  if (
    !showGrupo &&
    !errors.valor &&
    !errors.vencimento &&
    values.valor !== '' &&
    values.vencimento !== ''
  ) {
    setShowGrupo(true);
  }

  const saveProps = {
    isValid,
    handleSubmit,
    handleCancel,
    saveText: 'Enviar cobranças',
  };

  const formProps = {
    errors,
    touched,
    values: props.values,
    change,
    setFieldTouched,
    setFieldValue,
  };

  return (
    <form>
      <CancelarOuSalvar save={saveProps} />

      <FormSection classes={classes} title={props.title}>
        <FormTextField
          label="Descrição"
          field="descricao"
          xs={12}
          md={8}
          formik={formProps}
        />

        <MoneyTextField
          label="Valor"
          field="valor"
          xs={12}
          md={2}
          formik={formProps}
          prefix="R$"
          onBlur={() => setValores(values.valor)}
        />

        <FormTextField
          label="Vencimento"
          field="vencimento"
          xs={12}
          md={2}
          formik={formProps}
          type="tel"
        />
      </FormSection>

      <FormSection classes={classes} title="Grupo para emissão de cobrança">
        {showGrupo ? (
          <div className={classes.list}>
            {values.list.map((entidade, index) => {
              return (
                <div key={entidade._id} className={classes.listItem}>
                  <div className={classes.dados}>
                    <div className={classes.avatar}>
                      <MiniAvatar entidade={entidade} />
                    </div>
                    <div className={classes.dataText}>
                      <div className={classes.nome}>{entidade.nome}</div>
                      <div className={classes.nome}>{entidade.apelido}</div>
                      <div className={classes.email}>{entidade.email}</div>
                    </div>
                  </div>

                  <div className={classes.defaults}>
                    <div className={classes.input}>
                      <MoneyTextField
                        prefix="R$"
                        label="Valor"
                        field={`list.${index}.valor`}
                        xs={12}
                        md={12}
                        formik={formProps}
                      />
                    </div>
                    <div className={classes.input}>
                      <FormTextField
                        label="Vencimento"
                        field={`list.${index}.vencimento`}
                        xs={12}
                        md={12}
                        formik={formProps}
                        type="tel"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            <div />
          </div>
        ) : (
          'Para exibir o grupo, primeiro configure a cobrança'
        )}
      </FormSection>

      <CancelarOuSalvar save={saveProps} />
    </form>
  );
};

export default withStyles(styles)(Form);
