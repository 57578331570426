import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

function Avatar(props) {
  const { classes, parceiro } = props;

  if (parceiro.avatar) {
    return (
      <div className={classes.avatar}>
        <img
          src={parceiro.avatar}
          alt={`Logo ${parceiro.nome}`}
          className={classes.img}
        />
      </div>
    );
  } else {
    return (
      <div className={classes.avatar}>
        <FontAwesomeIcon
          icon={faUser}
          className={classes.avatarPlaceholder}
          fixedWidth
          size="4x"
        />
      </div>
    );
  }
}

export default withStyles(styles)(Avatar);
