import * as Yup from 'yup';

const validationSchema = Yup.object({
  email: Yup.string('')
    .email('Email incorreto')
    .min(5, 'Muito curto')
    .max(60, 'Muito longo')
    .required('* Obrigatório'),
  password: Yup.string('')
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
      'A senha deve conter no mínimo uma letra MAIÚSCULA, uma MINÚSCULA, e um NÚMERO'
    )
    .min(8, 'A senha deve conter no mínimo 8 caracteres')
    .required('* Obrigatório'),
});

export default validationSchema;
