const Contrato = `
<ol>
  <li><strong>DO OBJETO DO CONTRATO</strong></li>
  <ol>
    <li>Pelo presente instrumento e na melhor forma de direito, o CONTRATO tem por objeto a disponibilização de acesso e controle à plataforma criada pela <strong>CONTRATADA</strong> – <strong>FAROL UNIVERSITÁRIO</strong>, cujo intuito é cadastrar e oferecer ferramentas que permitam a gestão dos associados da <strong>CONTRATANTE</strong>, além de outras funcionalidades especificadas e acordadas entre as partes (<strong>CONTRATANTE</strong> e <strong>CONTRATADA</strong>), as quais poderão ser alteradas sem aviso prévio.</li>
    <li>As funcionalidades da plataforma serão de gestão de associados, controle financeiro, site da Entidade Acadêmica. A <strong>CONTRATADA</strong> disponibilizará, no portal, listagem de empresas, possíveis fornecedoras de serviços/produtos à <strong>CONTRATANTE</strong>. A <strong>CONTRATADA</strong> irá negociar descontos e vantagens com tais empresas. As funções da Plataforma poderão ser acrescentadas ou removidas sem aviso prévio.</li>
    <li>A <strong>CONTRATADA</strong> é livre para abrir e fechar parcerias comerciais e negociar descontos junto às empresas fornecedoras. As informações serão disponibilizadas dentro da plataforma e do site.</li>
  </ol>

  <li><strong>PREÇOS E CONDIÇÕES DE PAGAMENTO</strong></li>
  <ol>
    <li>A plataforma do <strong>FAROL UNIVERSITÁRIO</strong>, bem como os descontos nas empresas parceiras somente serão liberados após a confirmação de pagamento que será feita de forma online e automática.</li>
    <li>A <strong>CONTRATANTE</strong> irá escolher o plano que melhor se adequa à sua realidade.</li>
    <li>Nenhum valor extra será cobrada da <strong>CONTRATANTE</strong> sem prévio aceite.</li>
    <li>Os valores dos serviços estarão explícitos no site da <strong>CONTRATADA</strong> onde estarão informadas todas as taxas de acordo com a modalidade de serviço escolhida.</li>
    <li>A cada nova compra, a <strong>CONTRATANTE</strong> receberá uma notificação via e-mail com a confirmação do que foi adquirido.</li>
    <li>Os pagamentos poderão ser feitos via Boleto Bancário ou Cartão deCrédito.</li>
    <li>As taxas serão cobradas automaticamente através da conta digital que a <strong>CONTRATANTE</strong> irá criar dentro da plataforma da <strong>CONTRATADA</strong>.</li>
    <li>Os valores de alguns serviços poderão variar devido a variação e cotações de moedas estrangeiras bem como IOF de serviços.</li>
  </ol>

  <li><strong>OBRIGAÇÕES DA CONTRATANTE</strong></li>
  <ol>
    <li>Durante o prazo de vigência deste CONTRATO, a <strong>CONTRATANTE</strong> se obriga, sem prejuízo das demais obrigações assumidas neste CONTRATO.</li>
    <li>Realizar o pagamento conforme estipulado na Cláusula 2.</li>
    <li>Caberá à <strong>CONTRATANTE</strong> administrar o site oferecido pela <strong>CONTRATADA</strong>, sendo de sua inteira responsabilidade o conteúdo veiculado no mesmo, assim como as negociações de compra e venda decorrentes do uso da plataforma.</li>
    <li>A <strong>CONTRATANTE</strong> não poderá em hipótese alguma passar informações sobre a aplicação para terceiros que não tem CONTRATO assinado igualmente com a <strong>CONTRATADA</strong>.</li>
    <li>A <strong>CONTRATANTE</strong> deverá fornecer à <strong>CONTRATADA</strong> todas as informações que sejam necessárias à realização do serviço prestado, devendo ainda, especificar detalhes que interfiram na perfeita execução do mesmo.</li>
  </ol>

  <li><strong>OBRIGAÇÕES DA CONTRATADA </strong></li>
  <ol>
    <li>É dever da <strong>CONTRATADA</strong> fornecer à <strong>CONTRATANTE</strong> a cópia do presente instrumento via e-mail.</li>
    <li>Fornecer os serviços online.</li>
    <li>Manter o sigilo e privacidade de informações pessoais.</li>
  </ol>

  <li><strong>PRAZO E HIPÓTESE DE RESCISÃO </strong></li>
  <ol>
    <li>O presente CONTRATO terá a duração limitada e poderá ser encerrado a qualquer momento, a partir da assinatura do mesmo. Findo o CONTRATO, não havendo manifestação em contrário de nenhuma parte, estará presumida a prorrogação deste CONTRATO por prazo indeterminado.</li>
    <li>As Partes acordam que o presente CONTRATO ficará rescindido de pleno direito, independentemente de aviso ou notificação judicial ou extrajudicial, na ocorrência de falência, recuperação judicial e extrajudicial, devido ao descumprimento de obrigações previstas neste CONTRATO ou devido à cessação das atividades de qualquer das Partes.</li>
    <li>Ambos os <strong>CONTRATANTES</strong> possuem direito à rescisão unilateral do CONTRATO, sem o pagamento de multa, quando qualquer das partes esteja envolvida juridicamente em questões que contrariam os preceitos éticos e morais da Constituição Brasileira.</li>
    <li>O não pagamento da aplicação pela <strong>CONTRATANTE</strong> implica em rescisão.</li>
    <li>O <strong>Farol Universitário</strong> se resguarda ao direito de em qualquer hipótese cancelar e excluir a conta de entidades acadêmicas que não respeitem nossas regras ou desrespeitem nossos colaboradores, ou então desrespeitam regras de eventuais pedidos coletivos que viemos a organizar, bem como aqueles que nos ameaçarem ou forem grosseiros com os Capitães.</li>
  </ol>

  <li><strong>DISPOSIÇÕES GERAIS</strong></li>
  <ol>
    <li>As Partes concedem reciprocamente uma licença não exclusiva de uso dos seus nomes comerciais, das marcas, dos logotipos e demais itens que possam ser utilizados por ambos – <strong>CONTRATANTE</strong> e <strong>CONTRATADA</strong> para ações de marketing online e off-line. As Partes concordam que este CONTRATO não lhes transfere qualquer direito sobre as Marcas da outra Parte (exceto a licença de uso das Marcas conforme prevista no presente CONTRATO), que as Marcas são de propriedade única e exclusiva das suas titulares e que todo e qualquer ganho decorrente do uso, por uma das Partes, das Marcas da outra deverá ser revertido em benefício exclusivo da sua titular. As Partes concordam que não poderão alterar as Marcas da outra Parte sem o prévio consentimento da sua titular. Este CONTRATO não transfere ou concede a qualquer Parte licença ou outro direito sobre as Marcas da outra Parte, com exceção dos direitos descritos nesta cláusula.</li>
    <li>Fica compactuado entre as partes a total inexistência de vínculo trabalhista entre as partes, inclusive as obrigações previdenciárias e os encargos sociais, não havendo entre <strong>CONTRATADA</strong> e <strong>CONTRATANTE</strong> qualquer tipo de relação de subordinação.</li>
    <li>A <strong>CONTRATADA</strong> está autorizada a utilizar a base de dados dos associados para os fins que julgar de seu interesse, tais como: entrega de carteirinhas dos associados, confecção da carteirinha dos associados e ações pontuais de marketing.</li>
    <li>A <strong>CONTRATANTE</strong> não está obrigada a contratar os serviços/produtos das empresas pertencentes a aplicação da <strong>CONTRATADA</strong>.</li>
    <li>A lista de fornecedores poderá ser alterada a qualquer momento pela <strong>CONTRATADA</strong>, assim como seus benefícios e descontos concedidos.</li>
    <li>Na hipótese de complementação ou alteração dos serviços ou de qualquer condição ou obrigação acordada neste CONTRATO, as Partes, de comum acordo, celebrarão, obrigatoriamente, Termo Aditivo ao presente CONTRATO.</li>
    <li>Qualquer uma das Partes não poderá ceder, no todo ou em parte, quaisquer de seus direitos relativos a este CONTRATO sem o consentimento prévio e por escrito da outra Parte.</li>
    <li>As Partes ficam desde já compromissadas, respondendo por eventuais perdas e danos em caso de descumprimento das obrigações/disposições presentes na presente avença.</li>
    <li>As Partes declaram que agem e agirão norteadas pela boa-fé contratual, preservando a fidúcia e lealdade necessária a todos os atos praticados, em qualquer momento/fase, seja ela pré, durante ou pós-contratual.</li>
  </ol>

  <li><strong>DO FORO</strong></li>
  <ol>
    <li>As Partes elegem o foro da comarca de Belo Horizonte, Estado de Minas Gerais, para dirimir quaisquer dúvidas, demandas ou litígios, oriundos do presente CONTRATO, renunciando a qualquer outro, por mais privilegiado que seja.</li>
  </ol>
</ol>

`;

export default Contrato;
