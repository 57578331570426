import React from 'react';
import { Formik } from 'formik';
import { useActions } from 'hooks';
import * as Actions from '../redux/actions';
import validationSchema from './validation';
import useStyles from './styles';
import Form from './form';
import values from './defaultFormValues';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGg } from '@fortawesome/free-brands-svg-icons';

const NovoLink = (props) => {
  const classes = useStyles();
  const actions = useActions(Actions);
  return (
    <div className={classes.formContainer}>
      <div className={classes.pageTitle}>
        <FontAwesomeIcon icon={faGg} className={classes.gg} fixedWidth size='lg' />
        <div>Novo Link de Cobrança</div>
      </div>
      <Formik
        render={(props) => <Form handleCancel={actions.cancel} {...props} />}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          actions.create(values);
        }}
      />
    </div>
  );
};

export default NovoLink;
