export default (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '1em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  data: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid grey',
    marginRight: '2em',
    [theme.breakpoints.down('sm')]: {
      marginRight: 'unset',
      borderRight: 'unset',
      order: 1,
      flexDirection: 'row',
    },
  },
  dia: {
    alignSelf: 'center',
    fontWeight: '500',
    fontSize: '1.5em',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'left',
      marginRight: '0.5em',
    },
  },
  mes: {
    alignSelf: 'center',
    fontWeight: '500',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'left',
    },
  },

  titulo: {
    flex: 4,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      order: 10,
      marginTop: '0.5em',
    },
  },
  texto: {
    fontWeight: '500',
    fontSize: '1.2em',
    flex: 1,
  },
  referencia: {
    fontWeight: '100',
    // fontSize: '1.2em',
    flex: 1,
  },

  metodo: {
    textAlign: 'center',
    flex: 1,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      order: 11,
      flexBasis: '100%',
      display: 'flex',
      alignItems: 'center',
      marginTop: '0.5em',
    },
  },
  metdText: {
    fontWeight: '100',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1em',
    },
  },

  valor: {
    display: 'flex',
    flex: 2,
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      order: 2,
      flexBasis: '50%',
    },
  },

  vIco: {
    textAlign: 'right',
    flex: 1,
  },

  vNum: {
    textAlign: 'right',
    fontSize: '1.1em',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em',
    },
  },
});
