/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './redux/actions';
import styles from './styles';
import Form from './Form';
import validationSchema from './validation';
import { Redirect } from 'react-router-dom';

const Login = (props) => {
  const { classes } = props;
  const actions = bindActionCreators(Actions, useDispatch());
  const { loggedIn } = useSelector(({ login }) => ({
    ...login,
  }));

  useEffect(() => {
    localStorage.clear();
    actions.logout();
  }, []);

  if (loggedIn) {
    return <Redirect to='/dashboard' />;
  }

  if (process.env.NODE_ENV !== 'production') {
    values.email = process.env.REACT_APP_ADM_USER;
    values.password = process.env.REACT_APP_ADM_PASS;
  }

  if (loggedIn) {
    return <Redirect to='/dashboard' />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.center}>
        <img
          src='/logo.png'
          alt='Farol Universiátio'
          className={classes.logo}
        />
      </div>
      <Formik
        render={(props) => <Form classes={classes} {...props} />}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={null}
      />
      {/* <SocialLogin /> */}
    </div>
  );
};

const values = {
  email: '',
  password: '',
};

export default withStyles(styles)(Login);
