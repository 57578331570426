import React from 'react';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import useStyles from './styles';
import FiltraPlano from './FiltraPlano';
import Ordenar from './Ordenar';
// import AcoesEmMassa from './AcoesMassa';
import FiltroGerencial from './FiltroGerencial';
import FiltroLocalizacao from './FiltroLocalizacao';
import * as Actions from '../../redux/actions';
import { useActions, useSelector } from 'hooks';

function TopBar(props) {
  const classes = useStyles();
  const actions = useActions(Actions);
  const { showSelect } = useSelector('entidades');
  const { type, value } = showSelect;

  return (
    <div className={classes.container}>
      {/* <div className={classes.filtro}>
        <AcoesEmMassa />
      </div> */}
      {type === '#' ? (
        <>
          <div className={classes.filtro}>
            <FiltraPlano />
          </div>

          <div className={classes.ordenar}>
            <Ordenar />
          </div>

          <div className={classes.ordenar}>
            <FiltroGerencial />
          </div>
          <div className={classes.localizacao}>
            <FiltroLocalizacao />
          </div>
        </>
      ) : (
        <>
          <div className={classes.countUsers}>
            <Badge badgeContent={value.length} color='primary'>
              <FontAwesomeIcon icon={faUsers} fixedWidth size='2x' />
            </Badge>
            <Typography>Selecionados</Typography>
          </div>
          <Button
            onClick={() => actions.cancel()}
            className={classes.btnCancelar}
            variant='contained'>
            Cancelar
          </Button>
          <Button
            onClick={() => actions.showConfirm(type)}
            className={classes.btnConfirm}
            variant='contained'
            color={type === 'Excluir' ? 'secondary' : 'primary'}>
            {type}
          </Button>
        </>
      )}
    </div>
  );
}

export default TopBar;
