/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import * as Actions from './redux/actions';
import Resposta from './Resposta';
import ShowImage from './ShowImage';
import styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserNinja,
  faUserCircle,
  faCheck,
  faCheckDouble,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { formataDataHora, isJSON } from '_helpers/utils';
import Spinner from 'components/_Common/Spinner';
import Dialog from 'components/_Common/Dialog';
import DashContainer from 'components/_Common/DashContainer';
import DashCard from 'components/_Common/DashCard';
import { useActions, useSelector } from 'hooks';

function Suporte(props) {
  const { classes } = props;
  const actions = useActions(Actions);
  const {
    tickets,
    loading,
    trash,
    ticketToRide,
    showEnviados,
    showEncerrados,
    showAnalisando,
  } = useSelector('suporte');

  useEffect(() => {
    actions.list();
    return () => {
      actions.cancel();
    };
  }, []);

  const enviados = tickets.filter((ticket) => ticket.status === 'Enviado');
  const encerrados = tickets.filter((ticket) => ticket.status === 'Encerrado');
  const analisando = tickets.filter((ticket) => ticket.status === 'Analisando');

  let data = enviados;
  if (showEnviados) data = enviados;
  if (showEncerrados) data = encerrados;
  if (showAnalisando) data = analisando;

  /**
   * Moda show image
   */
  const [modalContent, setModalContent] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (ticket) => {
    setModalContent(ticket);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * somente se string for um object jSOn
   * @param {STRING} string
   */
  const printMsgImg = (string) => {
    const msg = JSON.parse(string);
    const { image, mensagem } = msg;
    return (
      <>
        <div className={classes.containerImage}>
          <>
            <ShowImage
              ticket={{ ...modalContent }}
              open={open}
              handleClose={handleClose}
            />
            <img
              onClick={() => handleClickOpen({ ...msg })}
              alt='preview imagem ticket'
              title='preview imagem ticket'
              src={image}
              className={classes.imagePreview}
            />
          </>
        </div>
        <Typography className={classes.msgText}>
          {mensagem !== '' ? mensagem : '...'}
        </Typography>
      </>
    );
  };

  const userMsg = (mensagem, entidade) => {
    return entidade ? (
      <div className={classes.respostaWrapper} key={mensagem}>
        <div className={classes.avatar}>
          {entidade.avatar ? (
            <img
              src={entidade.avatar}
              alt={`Logo ${entidade.apelido}`}
              className={classes.img}
            />
          ) : (
            <FontAwesomeIcon icon={faUserCircle} fixedWidth size='lg' />
          )}
        </div>

        <div className={classes.respostaChat}>
          <Typography variant='caption' className={classes.respApelido}>
            {entidade.representante.nome}
          </Typography>
          <Typography variant='caption' className={classes.respApelido}>
            {entidade.apelido}
          </Typography>
          <Typography variant='caption' className={classes.respApelido}>
            {entidade.user.email}
          </Typography>
          <Typography className={classes.respText}> {mensagem}</Typography>
        </div>
      </div>
    ) : (
      <div className={classes.respostaWrapper} key={mensagem}>
        SEM ENTIDADE
      </div>
    );
  };

  const adminMsg = (mensagem) => {
    return (
      <div className={classes.mensagemWrapper} key={mensagem}>
        <div className={classes.mensagemChat}>
          <Typography variant='caption' align='right'>
            Ninja do Suporte
          </Typography>
          {isJSON(mensagem) ? (
            printMsgImg(mensagem)
          ) : (
            <Typography className={classes.msgText}>{mensagem}</Typography>
          )}
        </div>

        <div className={classes.avatarCapitao}>
          <FontAwesomeIcon icon={faUserNinja} fixedWidth size='lg' />
        </div>
      </div>
    );
  };

  if (loading) {
    return <Spinner text='Carregando Tickets de Suporte' size='2x' noBox />;
  }

  const askTrash = () => {
    if (trash) {
      return (
        <Dialog
          title='Excluir o Ticket?'
          no='Cancelar'
          yes='Excluir'
          action={() => actions.trash(ticketToRide)}
          close={() => actions.showTrash(null)}>
          <Typography variant='body1'>
            Se o ticket for excluído ele ficará indisponível para o usuário.
          </Typography>
        </Dialog>
      );
    }
  };

  const renderTickets = (ticket) => {
    const { image, numero, status, tipo, mensagens, entidade } = ticket;
    return (
      <div className={classes.ticket} key={ticket._id}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.container}>
              <div className={classes.top}>
                <div className={classes.titulo}>
                  <div className={classes.tText}>
                    <Typography
                      variant='h5'
                      gutterBottom
                      align='left'
                      className={classes.number}>
                      # {numero}
                    </Typography>
                  </div>
                  <div className={classes[status]}>
                    <Typography variant='subtitle1' gutterBottom align='right'>
                      Status: [{status}]
                    </Typography>
                    <FontAwesomeIcon
                      icon={
                        status === 'Enviado'
                          ? faCheck
                          : status === 'Analisando'
                          ? faCheckDouble
                          : status === 'Encerrado'
                          ? faCheckCircle
                          : null
                      }
                      className={classes.statusIcon}
                      fixedWidth
                      size='lg'
                    />
                  </div>
                </div>
                <Divider />
                {image && (
                  <div className={classes.containerImage}>
                    <ShowImage
                      ticket={{ ...modalContent }}
                      open={open}
                      handleClose={handleClose}
                    />
                    <img
                      onClick={() => handleClickOpen(ticket)}
                      alt='imagem ticket'
                      src={image}
                      className={classes.imagePreview}
                    />
                  </div>
                )}
                <div className={classes.mensagem}>
                  {mensagens.map((mensagem, index) => {
                    if (index % 2 === 0) {
                      return mensagem ? userMsg(mensagem, entidade) : null;
                    } else {
                      return mensagem ? adminMsg(mensagem) : null;
                    }
                  })}
                </div>

                <div>
                  <Resposta ticket={ticket} />
                </div>
              </div>
              <Divider />
              <div className={classes.bottom}>
                <div className={classes.tipo}>
                  <Typography variant='caption' align='center'>
                    {tipo}
                  </Typography>
                </div>
                <Typography variant='caption' align='center'>
                  Aberto em {formataDataHora(ticket.createdAt)}
                </Typography>
                <Typography variant='caption' align='center'>
                  Última atualização em {formataDataHora(ticket.updatedAt)}
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  const selectView = () => {
    if (loading) {
      return <Spinner text='Carregando Tickets de Suporte' size='2x' noBox />;
    }

    return (
      <div className={classes.ticketsContainer}>
        {data.map((ticket) => renderTickets(ticket))}
        {askTrash()}
      </div>
    );
  };

  return (
    <div className={classes.dashboard}>
      <DashContainer>
        <DashCard
          info='Sem resposta'
          data={enviados.length}
          text='ver novos'
          icon={faCheck}
          action={actions.showEnviados}
        />
        <DashCard
          info='Em Análise'
          data={analisando.length}
          text='ver em análise'
          icon={faCheckDouble}
          action={actions.showAnalisando}
        />
        <DashCard
          info='Encerrados'
          data={encerrados.length}
          text='ver resolvidos'
          icon={faCheckCircle}
          action={actions.showEncerrados}
        />
      </DashContainer>
      {selectView()}
    </div>
  );
}

export default withStyles(styles)(Suporte);
