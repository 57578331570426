import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDharmachakra } from '@fortawesome/free-solid-svg-icons';
import Grow from '@material-ui/core/Grow';

function Overlay(props) {
  const { loading, item, parent } = props;
  const classes = useStyles();
  const [style, setStyle] = useState(null);

  useEffect(() => {
    setStyle({ height: parent.current.clientHeight });
  }, [parent]);

  if (!loading) return null;
  if (!item) return null;
  if (loading !== item) return null;

  return (
    <div className={classes.container}>
      <Grow in={true}>
        <div className={classes.overlay} style={style}>
          <FontAwesomeIcon
            icon={faDharmachakra}
            className={classes.spinner}
            fixedWidth
            size='2x'
            spin
          />
        </div>
      </Grow>
    </div>
  );
}

export default Overlay;
