/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useStyles from './styles';
import Grow from '@material-ui/core/Grow';

function Row(props) {
  const classes = useStyles();

  return (
    <Grow in={true}>
      <div className={classes.listItem}>
        <div className={classes.container}>{props.children}</div>
      </div>
    </Grow>
  );
}

export default Row;
