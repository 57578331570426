/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import SearchField from 'components/_Common/SearchContainer';
import Dialog from 'components/_Common/Dialog';
import * as Actions from '../redux/actions';
import LazyList from 'components/_Common/LazyList';
import Associado from './Associado';
import TopBar from './TopBar';
import { useActions, useSelector } from 'hooks';

function ListaAssociados(props) {
  const { associados } = props;
  const actions = useActions(Actions);
  const { listData } = useSelector('associados');
  const [associado, setAssociado] = useState(null);
  const [data, setData] = useState(listData);

  useEffect(() => {
    setData(listData);
  }, [listData]);

  useEffect(() => {
    actions.changeList(associados);
  }, [associados]);

  console.log('List', listData);

  return (
    <>
      <SearchField
        data={associados}
        setData={actions.changeList}
        placeholder='Pesquise por associados'
        fields={[
          'nome',
          'celular',
          'cpf',
          'email',
          'curso',
          'matricula',
          'nascimento',
          'instituicao',
          'nivel',
          'rg',
          'entidade.apelido',
          'endereco.bairro',
          'endereco.cep',
          'endereco.cidade',
          'endereco.numero',
          'endereco.rua',
          'endereco.uf',
        ]}
        depth={4}>
        <TopBar />
      </SearchField>
      {associado ? (
        <div>
          <Dialog
            title={`Mover ${associado.nome} para a lixeira?`}
            message='O associado será movido para a lixeira e não receberá e-mails e notificações.'
            no='Cancelar'
            yes='SIM'
            action={() => actions.trash(associado)}
            close={() => setAssociado(null)}
          />
        </div>
      ) : null}

      <LazyList data={data} Item={Associado} actions={actions} rest={setAssociado} />
    </>
  );
}

export default ListaAssociados;
