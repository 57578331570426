/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import ResumoGeral from './ResumoGeral';
import ContasHoje from './ContasHoje';
import Grafico from './Grafico';
import Movimentacoes from './Movimentacoes';
import ResumoFluxo from './ResumoFluxo';

const FinanceiroDashboard = (props) => {
  const { classes } = props;

  return (
    <div className={classes.dashboard}>
      <div className={classes.top}>
        <ContasHoje />
        <Grafico />
        <ResumoGeral />
      </div>
      <div className={classes.bottom}>
        <Movimentacoes />
        <ResumoFluxo />
      </div>
    </div>
  );
};

export default withStyles(styles)(FinanceiroDashboard);
