import {
  CANCEL,
  SHOW_PERFIL,
  REQUEST,
  FAILURE,
  SUCCESS,
  CHANGE_VIEW,
  CHANGE_LIST,
  FILTRA_PLANO,
  FILTRO_GERENCIAL,
  FILTRO_LOCALIZACAO,
  FILTRA_STATUS,
  ORDENA,
  SHOW_SELECT,
  SET_SELECT,
  SHOW_CONFIRM,
} from './types';
import { getHandledData, filtraStatus } from './helpers';

const INITIAL_STATE = {
  loading: false,
  load: false,
  entidades: [],
  entidade: {},
  pagosAtivos: [],
  pagosExpirados: [],
  apenasFree: [],
  view: 'lista',
  listData: [],
  status: 'Pagos&Ativos',
  plano: 'todos',
  gerencial: 'todos',
  localizacao: 'todos',
  ordem: 'AZ',
  showSelect: {
    type: '#',
    value: [],
  },
  modalConfirm: {
    type: '#',
    show: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case CHANGE_VIEW:
      return { ...state, view: payload };

    case CHANGE_LIST: {
      let { listData } = payload;
      if (listData === state.entidades) {
        listData = getHandledData({ ...state });
      }
      return { ...state, listData, view: 'LISTA' };
    }

    case SHOW_PERFIL:
      return {
        ...state,
        view: payload.view,
        entidade: { ...payload.entidade },
      };

    case REQUEST:
      return { ...state, loading: true };

    case SUCCESS:
      const { entidades, view } = payload;
      const pagosAtivos = filtraStatus('Pagos&Ativos', entidades);
      const pagosExpirados = filtraStatus('Pagos&Expirados', entidades);
      const apenasFree = filtraStatus('apenasFree', entidades);
      const semConta = filtraStatus('noUser', entidades);
      // fix loading & refresh page not show associados
      const listData = getHandledData({
        ...state,
        entidades,
        pagosAtivos,
        pagosExpirados,
        apenasFree,
        semConta,
      });
      return {
        ...state,
        view,
        listData,
        entidades,
        entidade: { ...payload.entidade },
        pagosAtivos,
        pagosExpirados,
        semConta,
        apenasFree,
        loading: false,
        edit: false,
        showSelect: {
          type: '#',
          value: [],
        },
        modalConfirm: {
          type: '#',
          show: false,
        },
      };

    case FAILURE:
      return { ...state, loading: false };

    case FILTRA_STATUS: {
      const status = payload;
      const listData = getHandledData({ ...state, status });
      return { ...state, status, listData, view: 'LISTA' };
    }

    case FILTRA_PLANO: {
      const plano = payload;
      const listData = getHandledData({ ...state, plano, status: '' });
      return { ...state, plano, listData };
    }

    case FILTRO_GERENCIAL: {
      const { gerencial, sellers } = payload;
      const listData = getHandledData({
        ...state,
        gerencial,
        sellers,
        status: '',
      });
      return { ...state, gerencial, sellers, listData };
    }

    case FILTRO_LOCALIZACAO: {
      const { localizacao } = payload;
      const listData = getHandledData({
        ...state,
        localizacao,
        status: '',
      });
      return { ...state, localizacao, listData };
    }

    case ORDENA: {
      const ordem = payload;
      const listData = getHandledData({ ...state, ordem });
      console.log('listData', listData);
      return { ...state, ordem, listData };
    }

    case SHOW_SELECT: {
      return { ...state, showSelect: payload };
    }

    case SET_SELECT: {
      const { action, associado } = payload;
      let update = state.showSelect.value;

      if (action) update.push(associado);
      else update = update.filter(({ _id }) => _id.toString() !== associado._id.toString());
      return {
        ...state,
        showSelect: { type: state.showSelect.type, value: update },
      };
    }

    case SHOW_CONFIRM: {
      return {
        ...state,
        modalConfirm: payload,
      };
    }

    case CANCEL:
      return {
        ...state,
        view: 'lista',
        entidade: {},
        showSelect: {
          type: '#',
          value: [],
        },
        modalConfirm: {
          type: '#',
          show: false,
        },
      };

    default:
      return state;
  }
};
