import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';

const ExcluirDialog = (props) => {
  const { actions, cupom, open } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={actions.cancel}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          Excluir o cupom {cupom.codigo}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Novos sócios não poderão mais utilizar este cupom e não será mais
            possível verificar estatísticas do mesmo.
            <br />
            <br />
            Seu cupom será movido para a lixera.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={actions.cancel} color="primary" autoFocus>
            Cancelar
          </Button>
          <Button onClick={() => actions.trash(cupom)} color="secondary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  const store = state.cupons;
  return {
    cupom: store.cupom,
    showTrash: store.showExcluir,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileDialog()(ExcluirDialog));
