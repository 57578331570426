import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faEye,
  faEyeSlash,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import format from 'date-fns/format';
import styles from './styles';

class PlanoCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: this.props.plano.status,
      destaque: this.props.plano.destaque,
    };
  }

  verificaCondicoes = () => {
    const { classes, plano } = this.props;

    let label = null;
    let labelMes = plano.duracao === 1 ? 'mês' : 'meses';
    if (plano.tipoDuracao === 'Até que o usuário cancele') {
      label = 'até você cancelar';
    } else {
      label = `por ${plano.duracao} ${labelMes}`;
    }

    if (plano.validade && plano.tipoDuracao === 'Data Fixa') {
      label = `até ${format(new Date(plano.validade), 'dd/MM/yyyy')}`;
    }

    if (plano.tipoPagamento === 'Pagamento Recorrente') {
      return (
        <div className={classes.infos}>
          <Typography variant="caption" align="center">
            {plano.tipoPagamento} {plano.freqPagamento}
          </Typography>
          <Typography variant="caption" align="center">
            Assinatura válida {label}
          </Typography>
        </div>
      );
    }

    if (plano.tipoPagamento === 'Pagamento Único') {
      return (
        <div className={classes.infos}>
          <Typography variant="caption" align="center">
            Pagamento único, em até 12x no cartão
          </Typography>
          <Typography variant="caption" align="center">
            Assinatura válida {label}
          </Typography>
        </div>
      );
    }

    if (plano.tipoPagamento === 'Plano Gratuito') {
      return (
        <div className={classes.infos}>
          <Typography variant="caption" align="center">
            Nenhum pagamento necessário!
          </Typography>
          <Typography variant="caption" align="center">
            Assinatura válida {label}
          </Typography>
        </div>
      );
    }
  };

  render() {
    const { classes, plano } = this.props;
    const { status } = this.state;
    return (
      <Card className={classes.card}>
        <div className={status ? classes.hActive : classes.hInactive}>
          <div className={classes.titulo}>
            <Typography
              variant="h4"
              align="center"
              className={status ? classes.activeText : classes.inactiveText}>
              {plano.titulo}
            </Typography>
            <div className={classes.slogan}>
              <Typography
                variant="subtitle2"
                align="center"
                className={status ? classes.activeText : classes.inactiveText}>
                {plano.slogan}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.avatar}>
          <Avatar
            aria-label="Avatar"
            onClick={this.handleStatus}
            className={status ? classes.avatarAtivo : classes.avatarInativo}>
            {status ? (
              <FontAwesomeIcon icon={faEye} fixedWidth />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} fixedWidth />
            )}
          </Avatar>
        </div>

        <div className={classes.topOptions}>
          <span className={classes.destaque}>
            <FontAwesomeIcon
              icon={faStar}
              className={plano.destaque ? classes.star : classes.starInativo}
              fixedWidth
            />
          </span>
        </div>

        <div className={classes.beneficios}>
          <Typography variant="h6" align="center" gutterBottom>
            BENEFÍCIOS
          </Typography>
          <Divider variant="middle" />
          {plano.beneficios.map((beneficio) => (
            <Typography
              component="p"
              variant="overline"
              gutterBottom
              className={classes.beneficio}>
              <div className={classes.benefIcon}>
                <FontAwesomeIcon
                  icon={faCheck}
                  className={classes.benefTick}
                  fixedWidth
                />
              </div>
              <div className={classes.benefText}>{beneficio}</div>
            </Typography>
          ))}
        </div>

        <div className={classes.footer}>
          <Typography variant="h4" className={classes.valor} align="center">
            R$ {plano.valor.toFixed(2)}
          </Typography>

          {this.verificaCondicoes()}
        </div>
      </Card>
    );
  }
}

export default withStyles(styles)(PlanoCard);
