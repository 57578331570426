import {
  REPLY_REQUEST,
  REPLY_SUCCESS,
  REPLY_FAILURE,
  TRASH_SUCCESS,
  LIST_SUCCESS,
  CANCEL,
  SHOW_TRASH,
  SHOW_ENVIADOS,
  SHOW_ENCERRADOS,
  SHOW_ANALISANDO,
} from './types';

const INITIAL_STATE = {
  tickets: [],
  loading: true,
  loadingReply: false,
  trash: false,
  ticketToRide: {},
  showEnviados: true,
  showEncerrados: false,
  showAnalisando: false,
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case SHOW_ENVIADOS:
      return {
        ...state,
        showEnviados: true,
        showEncerrados: false,
        showAnalisando: false,
      };

    case SHOW_ENCERRADOS:
      return {
        ...state,
        showEnviados: false,
        showEncerrados: true,
        showAnalisando: false,
      };

    case SHOW_ANALISANDO:
      return {
        ...state,
        showEnviados: false,
        showEncerrados: false,
        showAnalisando: true,
      };

    case SHOW_TRASH:
      return {
        ...state,
        trash: !state.trash,
        ticketToRide: { ...payload },
      };

    case REPLY_REQUEST:
      return {
        ...state,
        loadingReply: true,
      };

    case REPLY_SUCCESS:
      return { ...state, loadingReply: false, tickets: [...payload] };

    case REPLY_FAILURE:
      return { ...state, loadingReply: false, tickets: [...payload] };

    case TRASH_SUCCESS:
      return {
        ...state,
        tickets: [...payload],
        trash: false,
        ticketToRide: null,
      };
    case LIST_SUCCESS:
      return { ...state, tickets: [...payload], loading: false };

    case CANCEL:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
