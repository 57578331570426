import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { formataData, formataHMS } from '_helpers/utils';
import Switch from 'components/_Common/SimpleSwitch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faFileSignature,
  faSkullCrossbones,
} from '@fortawesome/free-solid-svg-icons';
import Dialog from 'components/_Common/Dialog';

function PerfilRight(props) {
  const { classes, entidade, assinaturas, actions } = props;

  const [open, setOpen] = useState(false);
  const [openDel, setOpenDel] = useState(false);

  // Ordena Assinaturas pela criação
  let assOrdem = assinaturas.sort((a, b) => a.createdAt > b.createdAt);

  // Get Assinatura Ativa Pagas s/ free
  let assinatura = assOrdem.find(
    a => a.valor.original !== 0 && a.status === 'Ativo',
  );

  // Get Plano Free
  if (!assinatura) {
    assinatura = assinaturas.find(
      a => a.valor.original === 0 && a.status === 'Ativo',
    );
  }

  const plano = assinatura.plano;

  const trashDialog = () => {
    if (!open) return null;
    return (
      <Dialog
        title={`Mover ${entidade.nome} para a lixeira?`}
        message='A Entidade será movido para a lixeira, não terá mais acesso ao sistema e receberá e-mails e notificações.'
        no='Cancelar'
        yes='SIM'
        action={() => actions.trash(entidade)}
        close={() => setOpen(false)}
      />
    );
  };

  const delDialog = () => {
    if (!openDel) return null;
    return (
      <Dialog
        title={`Excluir PERMANENTEMENTE ${entidade.nome} ?`}
        message='ATENÇÃO, esta ação NÃO É REVERSÍVEL. Todos os dados da Entidade serão excluídos, incluindo conta/login, notificações, etc. Os associados serão mantidos, mas movidos para a Atlética do Farol caso não tenham nenhuma outra associação.'
        no='Cancelar'
        yes='EXCLUIR DEFINITIVAMENTE'
        action={() => actions.del(entidade)}
        close={() => setOpen(false)}
      />
    );
  };

  return (
    <div className={classes.right}>
      <div className={classes.group}>
        <div className={classes.group}>
          <Typography variant='subtitle1' className={classes.label}>
            Acesso ao sistema
          </Typography>

          <div className={classes.info}>
            {entidade.user ? (
              <Switch
                label={entidade.user.blocked ? 'BLOQUEADO' : 'GARANTIDO'}
                field='liberar'
                value={!entidade.user.blocked}
                color={entidade.user.blocked ? 'secondary' : 'primary'}
                action={() => actions.changeAccess(entidade)}
              />
            ) : (
              <Typography variant='subtitle1' color='secondary'>
                Usuário não registrado
              </Typography>
            )}
          </div>
        </div>
      </div>

      <div className={classes.group}>
        <Typography variant='subtitle1' className={classes.label}>
          Status e-mail
        </Typography>
        <Typography variant='subtitle1' className={classes.info}>
          {entidade.user && entidade.user.isVerified
            ? 'Verificado'
            : 'Verificação pendente'}
        </Typography>
      </div>

      <Divider className={classes.divider} />

      <div className={classes.group}>
        <Typography variant='subtitle1' className={classes.label}>
          Plano vigente
        </Typography>
        <Typography variant='subtitle1' className={classes.info}>
          {plano.titulo.toUpperCase()}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant='subtitle1' className={classes.label}>
          Assinado em
        </Typography>
        <Typography variant='subtitle1' className={classes.info}>
          {formataData(assinatura.createdAt)}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant='subtitle1' className={classes.label}>
          Válido até
        </Typography>
        <Typography variant='subtitle1' className={classes.info}>
          {formataData(assinatura.validade)}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant='subtitle1' className={classes.label}>
          Assinaturas
        </Typography>
        <Typography variant='subtitle1' className={classes.info}>
          {assinaturas.length}
        </Typography>
      </div>

      <div className={classes.btn}>
        <Button
          variant='outlined'
          // onClick={() => setOpen(!open)}
          color='primary'
          className={classes.button}
        >
          <FontAwesomeIcon
            icon={faFileSignature}
            className={classes.buttonIcon}
            fixedWidth
            size='lg'
          />
          GERENCIAR ASSINATURAS
        </Button>
      </div>

      <Divider className={classes.divider} />

      <div className={classes.group}>
        <Typography variant='caption' className={classes.label}>
          Criado em
        </Typography>
        <Typography variant='caption' className={classes.info}>
          {formataData(entidade.createdAt)} às {formataHMS(entidade.createdAt)}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant='caption' className={classes.label}>
          Última atualização
        </Typography>
        <Typography variant='caption' className={classes.info}>
          {formataData(entidade.updatedAt)} às {formataHMS(entidade.updatedAt)}
        </Typography>
      </div>

      <div className={classes.action}>
        <Button
          variant='contained'
          onClick={() => setOpen(!open)}
          color='secondary'
          className={classes.button}
        >
          <FontAwesomeIcon
            icon={faTrash}
            className={classes.buttonIcon}
            fixedWidth
            size='lg'
          />
          MOVER PARA LIXEIRA
        </Button>
      </div>

      <div className={classes.btn}>
        <Button
          variant='contained'
          size='small'
          onClick={() => setOpenDel(!openDel)}
          className={classes.button}
        >
          <FontAwesomeIcon
            icon={faSkullCrossbones}
            className={classes.buttonIcon}
            fixedWidth
          />
          EXCLUSAO DEFINITIVA
        </Button>
      </div>

      {trashDialog()}
      {delDialog()}
    </div>
  );
}

export default PerfilRight;
