/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

function AddCreditos(props) {
  const { entidade, actions } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [toAdd, setToAdd] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const creditos = parseInt(entidade.creditos ?? 0, 10);

  const handleSoma = (value) => {
    const val = value && value !== '' ? parseInt(value, 10) : 0;
    setToAdd(val);
  };

  const handleClick = () => {
    actions.addCreditos(entidade, toAdd);
    setOpen(false);
  };

  const total = creditos + toAdd;
  return (
    <>
      {open && (
        <Dialog
          open={open}
          keepMounted
          fullScreen={fullScreen}
          onClose={() => setOpen(false)}
          maxWidth={'xl'}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
          className={classes.dialog}>
          <DialogTitle id='alert-dialog-slide-title' className={classes.modalTitle}>
            Adicionar Créditos para {entidade.nome}
          </DialogTitle>
          <DialogContent className={classes.modalBg}>
            <TextField
              label='Quantidade para adicionar'
              value={toAdd}
              fullWidth
              onChange={(e) => handleSoma(e.target.value)}
              variant='outlined'
              type='tel'
              helperText={`Total após adição: ${total}`}
            />
          </DialogContent>
          <DialogActions className={classes.modalBg}>
            <Button variant='text' color='default' onClick={() => setOpen(false)}>
              fechar
            </Button>
            <Button variant='text' color='secondary' onClick={handleClick}>
              adicionar
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Tooltip title='Créditos' placement='top'>
        <span>
          <IconButton
            size='small'
            variant='text'
            onClick={() => setOpen(!open)}
            color='primary'
            className={classes.buttonOptCredit}>
            <div className={classes.creditos}>{creditos}</div>
            <FontAwesomeIcon icon={faCoins} className={classes.pullLeft} fixedWidth size='sm' />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
}

export default AddCreditos;
