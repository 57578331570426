import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faUpload, faTimesCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import useStyles from './styles';
import { resizeImageAsDataURL } from '_helpers/utils';
import Dropzone from 'react-dropzone';
import { getSafe } from '_helpers/utils';

function ImageUploader(props) {
  const {
    field,
    disabled,
    formik,
    text,
    alt,
    show, // boolean show image
    styles, // set your styles content
    star,
  } = props;
  const preview = show ?? false;
  const classes = styles ?? useStyles();
  const value = getSafe(formik.values, field);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file instanceof File) {
      // Creating reader instance from FileReader() API
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        // Image reference
        const resize = new Image();
        resize.onload = function () {
          // redimensiona e converte imagem em base64
          const image = resizeImageAsDataURL(resize, 512);
          formik.setFieldTouched(field, true, false);
          formik.setFieldValue(field, image);
        };
        resize.src = reader.result;
        setError(false);
        setSuccess(true);
      };
    } else {
      setSuccess(false);
      setError(true);
    }
  };

  console.log('img value', value);

  const showImage = () => {
    const src = value && typeof value === 'object' ? value.src : value;
    return (
      <>
        {star ? (
          <div className={classes.starOnImg}>
            <FontAwesomeIcon icon={faStar} fixedWidth size='sm' />
          </div>
        ) : null}
        <div className={classes.containerValue}>
          <img alt={alt} src={src} className={classes.imageValue} />
        </div>
      </>
    );
  };

  const containerClass = success ? classes.hasImage : classes.container;

  const defaultChildren = () => {
    return (success || (value && value !== '')) && preview ? (
      showImage()
    ) : (
      <div className={classes.noImg}>
        {star ? (
          <div className={classes.star}>
            <FontAwesomeIcon icon={faStar} fixedWidth size='sm' />
          </div>
        ) : null}
        <FontAwesomeIcon icon={faImage} fixedWidth size='2x' />
        <div className={classes.uploadText}>
          <FontAwesomeIcon icon={faUpload} className={classes.upIcon} fixedWidth size='xs' />
          {text || 'IMAGEM'}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.dropContainer}>
      <Dropzone
        onDrop={onDrop}
        accept='image/jpeg, image/png, image/jpg'
        // maxSize={10500000}
        multiple={false}
        noDrag
        disabled={disabled}>
        {({ getRootProps, getInputProps, isDragReject }) => (
          <div className={containerClass} {...getRootProps()}>
            <div className={classes.actionBtn}>
              <input {...getInputProps()} />
              {props?.children ?? defaultChildren()}
            </div>
          </div>
        )}
      </Dropzone>
      {error ? (
        <div className={classes.error}>
          <div className={classes.upIcon}>
            <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
          </div>
          <div className={classes.errorText}>Envie apenas JPEG, JPG ou PNG.</div>
        </div>
      ) : null}
    </div>
  );
}

export default ImageUploader;
