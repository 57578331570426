/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faIdCard,
  faMapMarkedAlt,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';

const Sellers = (props) => {
  const { classes, seller } = props;

  const docID = () => {
    if (seller.docID && seller.status === 'Documentos em análise') {
      return (
        <span className={classes.emAnalise}>
          <FontAwesomeIcon
            icon={faIdCard}
            className={classes.fIcon}
            fixedWidth
            size='lg'
          />
          Documento de identidade em análise
        </span>
      );
    }

    return (
      <span className={classes.emAnalise}>
        <FontAwesomeIcon
          icon={faIdCard}
          className={classes.fIcon}
          fixedWidth
          size='lg'
        />
        Documento de identidade não foi enviado
      </span>
    );
  };

  const docAdrs = () => {
    if (seller.docAddress && seller.status === 'Documentos em análise') {
      return (
        <span className={classes.emAnalise}>
          <FontAwesomeIcon
            icon={faMapMarkedAlt}
            className={classes.fIcon}
            fixedWidth
            size='lg'
          />
          Comprovante de residência em análise
        </span>
      );
    }

    return (
      <span className={classes.emAnalise}>
        <FontAwesomeIcon
          icon={faMapMarkedAlt}
          className={classes.fIcon}
          fixedWidth
          size='lg'
        />
        Comprovante de residência não foi enviado
      </span>
    );
  };

  const docCNPJ = () => {
    if (seller.docCNPJ && seller.status === 'Documentos em análise') {
      return (
        <span className={classes.emAnalise}>
          <FontAwesomeIcon
            icon={faFileAlt}
            className={classes.fIcon}
            fixedWidth
            size='lg'
          />
          Cartão CNPJ em análise
        </span>
      );
    }

    return (
      <span className={classes.emAnalise}>
        <FontAwesomeIcon
          icon={faFileAlt}
          className={classes.fIcon}
          fixedWidth
          size='lg'
        />
        Cartão CNPJ não foi enviado
      </span>
    );
  };

  return (
    <>
      <div className={classes.doc}>{docID()}</div>
      <div className={classes.doc}>{docAdrs()}</div>
      {seller.type === 'business' ? (
        <div className={classes.doc}>{docCNPJ()}</div>
      ) : null}
    </>
  );
};

export default withStyles(styles)(Sellers);
