import { REQUEST, SUCCESS, FAILURE, CANCEL } from './types';

const INITIAL_STATE = {
  loading: false,
  sellers: [],
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case REQUEST:
      return { ...state, loading: true };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        sellers: payload.sellers || state.sellers,
      };
    case FAILURE:
      return { ...state, loading: false };

    case CANCEL:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
