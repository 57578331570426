const values = {
  url: '',
  titulo: '',
  categoria: '',
  descricaoPedido: '',
  validade: '',
  imagem: '',
  descricao: '',
  cartao: true,
  boleto: true,
  exibeTotal: false,
  pagadorDefine: false,
  valorMinimo: 6,
  metaArrecadacao: 6,
  valor: 6,
  parcelas: 1,
  taxaAdm: '',
};

// if (process.env.NODE_ENV !== 'production') {
//   values.url = 'teste';
//   values.titulo = 'Produto Teste';
//   values.categoria = '299';
//   values.descricaoPedido = 'Recebimento por Link de Cobrança';
//   values.validade = new Date();
//   values.imagem = '';
//   values.descricao = '<strong>PRODUTO TOP</strong>';
//   values.cartao = true;
//   values.boleto = true;
//   values.exibeTotal = true;
//   values.pagadorDefine = true;
//   values.valorMinimo = 149.9;
//   values.valor = 280.95;
//   values.parcelas = 6;
//   values.taxaAdm = 10;
// }

export default values;
