export default (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  cardHeader: {
    padding: theme.spacing(1, 2),
  },

  left: {
    flex: 1,
    marginRight: '0.5em',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
    },
  },

  right: {
    flex: 1,
    marginLeft: '0.5em',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
      marginTop: '1em',
    },
  },

  list: {
    // flex: 2,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },

  item: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eaeaea',
    },
  },

  buttons: {
    // display: 'flex',
  },

  button: {
    flex: 1,
    margin: theme.spacing(0.5, 0),
  },

  pullLeft: {
    position: 'absolute',
    left: theme.spacing(2),
  },

  pullRight: {
    position: 'absolute',
    right: theme.spacing(2),
  },
});
