/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { getSafe } from '_helpers/utils';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import brLocale from 'date-fns/locale/pt-BR';

const FormDateField = (props) => {
  const { label, field, formik, disabled } = props;
  const value = getSafe(formik.values, field);
  const touched = true;
  const error = getSafe(formik.errors, field, false);
  const disablePast = props.disablePast || false;
  const minDate = props.minDate || null;
  const xs = props.xs || 12;
  const md = props.md || 12;
  const { setFieldTouched, setFieldValue } = formik;
  const required = props.required || false;

  const handleChange = (date) => {
    setFieldTouched(field, true, false);
    setFieldValue(field, date);
  };

  return useMemo(
    () => (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
        <Grid item xs={xs} md={md}>
          <KeyboardDatePicker
            autoOk={true}
            variant='inline'
            id={field}
            name={field}
            required={required}
            error={Boolean(touched) && Boolean(error)}
            label={label}
            disablePast={disablePast}
            disableToolbar
            fullWidth
            value={value || null}
            onChange={handleChange}
            inputVariant='outlined'
            disabled={disabled}
            minDate={minDate}
            format='dd/MM/yyyy'
            KeyboardButtonProps={{ 'aria-label': label }}
            invalidDateMessage='Data inválida'
          />
        </Grid>
      </MuiPickersUtilsProvider>
    ),
    [value, error],
  );
};

export default FormDateField;
