import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import useStyles from './styles';

function SwitchLabels(props) {
  const {
    label,
    field,
    value,
    disabled,
    action,
    color,
    labelPlacement,
  } = props;

  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{
        label: classes.label,
        root: classes.root,
        labelPlacementStart: classes.labelPlacementStart,
      }}
      id={field}
      label={label}
      disabled={disabled}
      labelPlacement={labelPlacement ? labelPlacement : 'start'}
      control={
        <Switch
          checked={value}
          onChange={() => action()}
          value={value}
          color={color}
          className={classes.input}
        />
      }
    />
  );
}

export default SwitchLabels;
