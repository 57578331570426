import React from 'react';
import useStyles from './styles';
import format from 'date-fns/format';
import Typography from '@material-ui/core/Typography';

function Condicoes(props) {
  const { plano } = props;
  const { assinatura, pagamento } = plano;
  const classes = useStyles();

  let labelMes = assinatura.duracao === 1 ? 'mês' : 'meses';
  let label = `por ${assinatura.duracao} ${labelMes}`;

  if (assinatura.validade && assinatura.tipo === 'Data Fixa') {
    label = `até ${format(new Date(assinatura.validade), 'dd/MM/yyyy')}`;
  }

  if (pagamento.tipo === 'Pagamento Recorrente') {
    return (
      <div className={classes.infos}>
        <Typography variant='caption' align='center'>
          {pagamento.tipo} {pagamento.frequencia}
        </Typography>
        <Typography variant='caption' align='center'>
          Assinatura válida {label}
        </Typography>
      </div>
    );
  }

  if (pagamento.tipo === 'Pagamento Único') {
    return (
      <div className={classes.infos}>
        <Typography variant='caption' align='center'>
          Pagamento único, em até 12x no cartão
        </Typography>
        <Typography variant='caption' align='center'>
          Assinatura válida {label}
        </Typography>
      </div>
    );
  }

  if (pagamento.tipo === 'Plano Gratuito') {
    return (
      <div className={classes.infos}>
        <Typography variant='caption' align='center'>
          Nenhum pagamento necessário!
        </Typography>
        <Typography variant='caption' align='center'>
          Assinatura válida {label}
        </Typography>
      </div>
    );
  }

  return null;
}

export default Condicoes;
