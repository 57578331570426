export default (theme) => ({
  card: {
    marginBottom: '3em',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '1em',
    },
  },

  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },

  top: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },

  topInfo: {
    flex: 1,
    display: 'flex',
  },

  bottom: {
    flex: 3,
    display: 'flex',
  },

  tipo: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  nivel: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  commentColor: {
    color: theme.palette.primary.main,
    marginRight: '0.5em',
  },

  titulo: {
    flex: 4,
    display: 'flex',
  },

  data: {
    flex: 1,
    lineHeight: '0',
    textAlign: 'center',
  },

  dataInfo: {
    lineHeight: '1.2em',
    textAlign: 'center',
  },

  info: {
    flex: 1,
    display: 'flex',
  },

  notificacao: {
    // flex: 1,
    padding: '0.5em',
    width: 'calc(40vw - 73px)',
    // maxWidth: 287,
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      marginTop: '1em',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100vw - 73px)',
    },
    [theme.breakpoints.up('sm')]: {
      // margin: '0em 1em',
      // marginTop: '-2em',
      marginBottom: '1em',
    },
  },

  chip: {
    fontSize: '1em',
    fontWeight: '500',
    marginBottom: theme.spacing(1),
    // marginRight: '2em',
    // alignSelf: 'flex-start',
  },

  badgeUtil: {
    backgroundColor: 'black',
    margin: theme.spacing(2),
  },

  badgeDisp: {
    background: theme.palette.primary.main,
    margin: theme.spacing(2),
  },

  open: {
    color: theme.palette.secondary.main,
  },

  closed: {
    color: theme.palette.primary.main,
  },

  actions: {
    display: 'flex',
    justifyContent: 'center',
  },

  mr: {
    marginRight: '0.3em',
  },
});
