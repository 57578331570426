import React from 'react';
import { Formik } from 'formik';
import { useActions, useSelector } from 'hooks';
import * as Actions from '../redux/actions';
import validationSchema from './validation';
import useStyles from './styles';
import Form from './form';
import values from './defaultFormValues';

const NovoPlano = (props) => {
  window.scrollTo(0, 0);
  const classes = useStyles();
  const actions = useActions(Actions);
  const { loading } = useSelector('planos');

  return (
    <div className={classes.formContainer}>
      <Formik
        render={(props) => (
          <Form
            handleCancel={actions.cancel}
            title={'Criar novo plano'}
            loading={loading.create}
            {...props}
          />
        )}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          actions.create(values);
        }}
      />
    </div>
  );
};

export default NovoPlano;
