export const SHOW_CREATE = 'PARCEIRO_SHOW_CREATE';

export const SHOW_EDIT = 'PARCEIRO_SHOW_EDIT';

export const SHOW_TRASH = 'SHOW_TRASH';

export const REQUEST = 'PARCEIRO_REQUEST';
export const SUCCESS = 'PARCEIRO_SUCCESS';
export const FAILURE = 'PARCEIRO_FAILURE';

export const CANCEL = 'PARCEIRO_CANCEL';
