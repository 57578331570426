/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const ResumoGeral = (props) => {
  const { classes } = props;

  const { receberHoje } = useSelector(({ financeiro }) => ({
    ...financeiro,
  }));

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <FontAwesomeIcon
          icon={faCalendarDay}
          // className={classes.pullLeft}
          fixedWidth
          // size="lg"
        />{' '}
        Hoje
      </div>
      <div className={classes.infos}>
        <div className={classes.recLabel}>A receber</div>
        <div className={classes.recValue}>R$ {receberHoje.toFixed(2)}</div>
        <div className={classes.payLabel}>A pagar</div>
        <div className={classes.payValue}>R$ 0</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ResumoGeral);
