import { makeStyles } from '@material-ui/core/styles';

const green = '#59D58D';

export default makeStyles((theme) => ({
  list: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  listItem: {
    padding: '1em 0.5em',
    display: 'flex',
    background: 'white',
    flexDirection: 'column',
    margin: '0.5em 0',
    borderRadius: '4px',
    boxShadow: '2px 2px 7px -4px hsla(0, 0%, 0%, 0.2)',

    '&:hover': {
      background: '#eaeaea',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      // padding: '1em 2em',
    },
  },

  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
  },
}));
