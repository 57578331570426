/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Lista from './Lista';
import Perfil from './Perfil';
import DashContainer from 'components/_Common/DashContainer';
import DashCard from 'components/_Common/DashCard';
import { faUsers, faUserCheck, faUserTimes } from '@fortawesome/free-solid-svg-icons';
import * as Actions from './redux/actions';
import Spinner from 'components/_Common/Spinner';
import { useActions, useSelector } from 'hooks';

const Associados = (props) => {
  const { classes, data } = props;

  const actions = useActions(Actions);
  const { lista, perfil, associados, associado, loading } = useSelector('associados');

  const [table, setTable] = useState(data ? data : associados);

  useEffect(() => {
    if (associados.length === 0) {
      actions.loadAssociados();
    }
  }, []);

  useEffect(() => {
    setTable(data ? data : associados);
  }, [associados]);

  useEffect(() => {
    return () => {
      actions.cancel();
    };
  }, []);

  const sistema = associados.filter((associado) => (associado.user ? true : false));

  const farol = associados.filter((associado) => (associado.user ? false : true));

  const handleView = (view) => {
    if (!view) setTable(associados);
    if (view === 'sistema') setTable(sistema);
    if (view === 'farol') setTable(farol);
    actions.showLista();
  };

  function selectView() {
    if (loading) {
      return <Spinner text='Processando...' size='2x' noBox />;
    }
    if (lista) return <Lista associados={table} />;
    if (perfil) return <Perfil associado={associado} />;

    return <Lista associados={table} />;
  }

  if (data) {
    return selectView();
  }

  return (
    <div className={classes.container}>
      <DashContainer>
        <DashCard
          info='Associados'
          data={associados.length}
          text='ver todos'
          icon={faUsers}
          action={() => handleView(null)}
        />
        <DashCard
          info='No sistema'
          data={sistema.length}
          text='ver todos'
          icon={faUserCheck}
          action={() => handleView('sistema')}
        />
        <DashCard
          info='Sem conta'
          data={farol.length}
          text='ver todos'
          icon={faUserTimes}
          action={() => handleView('farol')}
        />
      </DashContainer>

      {selectView()}
    </div>
  );
};

export default withStyles(styles)(Associados);
