import React from 'react';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { isValidCep } from '@brazilian-utils/validators';
import { FormGroup, FormTextField } from 'components/_Common/Form';
import { consultaCep, formataCep } from '_helpers/utils';
import useStyles from './styles';

const Form = (props) => {
  const {
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldTouched,
    setFieldValue,
    setErrors,
  } = props;

  const classes = useStyles();

  const change = async (name, e) => {
    e.persist();
    const value = e.target.value;
    setFieldTouched(name, true, false);
    if (name === 'endereco.cep') {
      const cep = formataCep(value);
      setFieldValue(name, cep);
      if (isValidCep(cep)) {
        const endereco = await consultaCep(cep);
        if (endereco.erro) {
          errors.cep = 'CEP não existe';
          setErrors(errors);
        } else {
          setFieldValue('endereco.bairro', endereco.bairro);
          setFieldTouched('endereco.bairro', true, false);
          setFieldValue('endereco.uf', endereco.uf);
          setFieldTouched('endereco.uf', true, false);
          setFieldValue('endereco.rua', endereco.logradouro);
          setFieldTouched('endereco.rua', true, false);
          setFieldValue('endereco.cidade', endereco.localidade);
          setFieldTouched('endereco.cidade', true, false);
        }
      }
    } else {
      handleChange(e);
    }
  };

  const formProps = {
    errors,
    touched,
    values: props.values,
    change,
    setFieldTouched,
    setFieldValue,
  };

  return (
    <form className={classes.root}>
      <FormGroup>
        <FormTextField
          label='CEP'
          field='endereco.cep'
          xs={12}
          md={4}
          formik={formProps}
          type='tel'
        />

        <FormTextField
          label='Logradouro'
          field='endereco.rua'
          xs={12}
          md={10}
          formik={formProps}
          type='text'
        />

        <FormTextField
          label='Número'
          field='endereco.numero'
          xs={12}
          md={2}
          formik={formProps}
          type='tel'
        />

        <FormTextField
          label='Complemento'
          field='endereco.complemento'
          xs={12}
          md={6}
          formik={formProps}
          type='text'
        />

        <FormTextField
          label='Bairro'
          field='endereco.bairro'
          xs={12}
          md={6}
          formik={formProps}
          type='text'
        />

        <FormTextField
          label='Município'
          field='endereco.cidade'
          xs={12}
          md={6}
          formik={formProps}
          type='text'
        />
        <FormTextField
          label='UF'
          field='endereco.uf'
          xs={12}
          md={6}
          formik={formProps}
          type='text'
        />
      </FormGroup>
      <Divider className={classes.divider} />
      <Button
        size='large'
        variant='contained'
        onClick={() => handleSubmit()}
        color='primary'
        className={classes.button}>
        <FontAwesomeIcon
          icon={faMapMarkedAlt}
          className={classes.fIconButton}
          fixedWidth
          size='lg'
        />
        Salvar
      </Button>
    </form>
  );
};

export default Form;
