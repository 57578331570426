/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import useStyles from './styles';
import Grow from '@material-ui/core/Grow';
import MiniAvatar from 'components/_Common/MiniAvatar';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faCheck } from '@fortawesome/free-solid-svg-icons';
import PayMethodIcon from 'components/_Common/PayMethodIcon';
import PayStatusLabel from 'components/_Common/PayStatusLabel';
import PayError from 'components/_Common/PayError';
import { formataData } from '_helpers/utils';
import { getComprador } from 'components/Financeiro/_helpers';

const Pedido = (props) => {
  const classes = useStyles();
  const pedido = props.data;
  let { entidade, cliente } = pedido;

  const { actions } = props;

  const comprador = getComprador(pedido);

  if ((!entidade && !cliente) || (entidade === null && cliente === null)) {
    entidade = {
      nome: '[ENTIDADE EXCLUÍDA PERMANENTEMENTE]',
      apelido: '',
      avatar: '',
      user: {
        email: '',
      },
    };
  }

  return (
    <Grow in={true}>
      <div className={classes.listItem}>
        <div className={classes.container}>
          <div className={classes.containerNumero}>
            <Tooltip title='Número do pedido' placement='top'>
              <div className={classes.numero}>{pedido.numero}</div>
            </Tooltip>
            <Tooltip title='Criado em' placement='bottom'>
              <div className={classes.data}>{formataData(pedido.createdAt)}</div>
            </Tooltip>
          </div>

          <div className={classes.containerDados}>
            {comprador && (
              <>
                <div className={classes.avatar}>
                  <MiniAvatar entidade={comprador} />
                </div>
                <div className={classes.dataText}>
                  <div className={classes.nome}>{comprador?.nome ?? 'Não identificado'}</div>
                  <div className={classes.nome}>{comprador?.cpf ?? 'Não identificado'}</div>
                  <div className={classes.email}>{comprador?.email ?? 'Não identificado'}</div>
                </div>
              </>
            )}
          </div>

          <div className={classes.descricao}>{pedido.descricao}</div>

          <div className={classes.containerValor}>
            <div className={classes.valor}>R$ {pedido.valor.toFixed(2)}</div>
            <Tooltip title='Vencimento' placement='bottom'>
              <div className={classes.vencimento}>
                <FontAwesomeIcon
                  icon={faCalendarDay}
                  className={classes.vctoIcon}
                  fixedWidth
                  size='md'
                />
                {formataData(pedido.vencimento)}
              </div>
            </Tooltip>
          </div>

          <div className={classes.metodo}>
            <PayMethodIcon pedido={pedido} />
          </div>

          <div className={classes.status}>
            <PayStatusLabel pedido={pedido} />
            {pedido.status === 'paid' ? (
              <div className={classes.pagoEm}>
                <FontAwesomeIcon icon={faCheck} className={classes.paidIcon} fixedWidth />
                {formataData(pedido.updatedAt)}
              </div>
            ) : null}
          </div>
        </div>
        {!pedido.status || pedido.status === 'erro' ? (
          <div className={classes.error}>
            <PayError pedido={pedido} actions={actions} />
          </div>
        ) : null}
      </div>
    </Grow>
  );
};

export default memo(Pedido);
