import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import useStyles from './styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAnchor,
  faTicketAlt,
  faBullhorn,
  faUserTag,
  faAddressCard,
  faCalendarAlt,
  faUsers,
  faGem,
  faDollarSign,
  faTags,
  faTrashAlt,
  faUserFriends,
  faUserTie,
  faUndo,
  faBarcode,
} from '@fortawesome/free-solid-svg-icons';
import { faGg } from '@fortawesome/free-brands-svg-icons';

const MenuList = (props) => {
  const { closeMenu } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const classes = useStyles();

  function handleListItemClick(event, index) {
    setSelectedIndex(index);
    closeMenu();
  }

  return (
    <List>
      <Link to='/dashboard' component={RouterLink} underline='none'>
        <ListItem
          selected={selectedIndex === 0}
          button
          key='Dashboard'
          className={classes.itemColor}
          onClick={(event) => handleListItemClick(event, 0)}>
          <Tooltip title='Dashboard' placement='right'>
            <ListItemIcon>
              <FontAwesomeIcon icon={faAnchor} className={classes.iconColor} fixedWidth size='lg' />
            </ListItemIcon>
          </Tooltip>
          <ListItemText disableTypography>Dashboard</ListItemText>
        </ListItem>
      </Link>

      <Link to='/planos' component={RouterLink} underline='none'>
        <ListItem
          selected={selectedIndex === 1}
          button
          key='Planos'
          className={classes.itemColor}
          onClick={(event) => handleListItemClick(event, 1)}>
          <Tooltip title='Planos' placement='right'>
            <ListItemIcon>
              <FontAwesomeIcon icon={faGem} className={classes.iconColor} fixedWidth size='lg' />
            </ListItemIcon>
          </Tooltip>
          <ListItemText disableTypography>Planos</ListItemText>
        </ListItem>
      </Link>

      <Link to='/cupons' component={RouterLink} underline='none'>
        <ListItem
          selected={selectedIndex === 2}
          button
          key='Cupons'
          className={classes.itemColor}
          onClick={(event) => handleListItemClick(event, 2)}>
          <Tooltip title='Cupons' placement='right'>
            <ListItemIcon>
              <FontAwesomeIcon icon={faTags} className={classes.iconColor} fixedWidth size='lg' />
            </ListItemIcon>
          </Tooltip>
          <ListItemText disableTypography>Cupons</ListItemText>
        </ListItem>
      </Link>

      <Link to='/entidades' component={RouterLink} underline='none'>
        <ListItem
          selected={selectedIndex === 3}
          button
          key='Entidades'
          className={classes.itemColor}
          onClick={(event) => handleListItemClick(event, 3)}>
          <Tooltip title='Entidades' placement='right'>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faUserFriends}
                className={classes.iconColor}
                fixedWidth
                size='lg'
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText disableTypography>Entidades</ListItemText>
        </ListItem>
      </Link>

      <Link to='/associados' component={RouterLink} underline='none'>
        <ListItem
          selected={selectedIndex === 4}
          button
          key='Associados'
          className={classes.itemColor}
          onClick={(event) => handleListItemClick(event, 4)}>
          <Tooltip title='Associados' placement='right'>
            <ListItemIcon>
              <FontAwesomeIcon icon={faUsers} className={classes.iconColor} fixedWidth size='lg' />
            </ListItemIcon>
          </Tooltip>
          <ListItemText disableTypography>Associados</ListItemText>
        </ListItem>
      </Link>

      <Link to='/notificacoes' component={RouterLink} underline='none'>
        <ListItem
          selected={selectedIndex === 5}
          button
          key='Notificações'
          className={classes.itemColor}
          onClick={(event) => handleListItemClick(event, 5)}>
          <Tooltip title='Notificações' placement='right'>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faBullhorn}
                className={classes.iconColor}
                fixedWidth
                size='lg'
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText disableTypography>Notificações</ListItemText>
        </ListItem>
      </Link>

      <Link to='/parceiros' component={RouterLink} underline='none'>
        <ListItem
          selected={selectedIndex === 6}
          button
          key='Parceiros'
          className={classes.itemColor}
          onClick={(event) => handleListItemClick(event, 6)}>
          <Tooltip title='Parceiros' placement='right'>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faUserTag}
                className={classes.iconColor}
                fixedWidth
                size='lg'
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText disableTypography>Parceiros</ListItemText>
        </ListItem>
      </Link>

      <Link to='/financeiro' component={RouterLink} underline='none'>
        <ListItem
          selected={selectedIndex === 7}
          button
          key='Financeiro'
          className={classes.itemColor}
          onClick={(event) => handleListItemClick(event, 7)}>
          <Tooltip title='Financeiro' placement='right'>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faDollarSign}
                className={classes.iconColor}
                fixedWidth
                size='lg'
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText disableTypography>Financeiro</ListItemText>
        </ListItem>
      </Link>

      <Link to='/pagamentos' component={RouterLink} underline='none'>
        <ListItem
          selected={selectedIndex === 8}
          button
          key='Pagamento'
          className={classes.itemColor}
          onClick={(event) => handleListItemClick(event, 7)}>
          <Tooltip title='Pagamentos' placement='right'>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faBarcode}
                className={classes.iconColor}
                fixedWidth
                size='lg'
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText disableTypography>Pagamentos</ListItemText>
        </ListItem>
      </Link>

      <Link to='/cancelamentos' component={RouterLink} underline='none'>
        <ListItem
          selected={selectedIndex === 7}
          button
          key='Financeiro'
          className={classes.itemColor}
          onClick={(event) => handleListItemClick(event, 7)}>
          <Tooltip title='Cancelar pedidos' placement='right'>
            <ListItemIcon>
              <FontAwesomeIcon icon={faUndo} className={classes.iconColor} fixedWidth size='lg' />
            </ListItemIcon>
          </Tooltip>
          <ListItemText disableTypography>Cancelar pedidos</ListItemText>
        </ListItem>
      </Link>

      <Link to='/link-cobranca' component={RouterLink} underline='none'>
        <ListItem
          selected={selectedIndex === 9}
          button
          key='LinkCobranca'
          className={classes.itemColor}
          onClick={(event) => handleListItemClick(event, 8)}>
          <Tooltip title='Links de Cobrança' placement='right'>
            <ListItemIcon>
              <FontAwesomeIcon icon={faGg} className={classes.iconColor} fixedWidth size='lg' />
            </ListItemIcon>
          </Tooltip>
          <ListItemText disableTypography>Links de Cobrança</ListItemText>
        </ListItem>
      </Link>

      <Link to='/sellers' component={RouterLink} underline='none'>
        <ListItem
          selected={selectedIndex === 10}
          button
          key='Sellers'
          className={classes.itemColor}
          onClick={(event) => handleListItemClick(event, 9)}>
          <Tooltip title='Sellers' placement='right'>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faUserTie}
                className={classes.iconColor}
                fixedWidth
                size='lg'
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText disableTypography>Sellers</ListItemText>
        </ListItem>
      </Link>

      <Link to='/agenda' component={RouterLink} underline='none' className={classes.disabled}>
        <ListItem button key='Agenda' className={classes.itemColorDisabled} onClick={closeMenu}>
          <Tooltip title='Agenda' placement='right'>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faCalendarAlt}
                className={classes.iconColorDisabled}
                fixedWidth
                size='lg'
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText disableTypography>Agenda</ListItemText>
        </ListItem>
      </Link>

      <Link to='/carteirinhas' component={RouterLink} underline='none' className={classes.disabled}>
        <ListItem
          button
          key='Carteirinhas'
          className={classes.itemColorDisabled}
          onClick={closeMenu}>
          <Tooltip title='Carteirinhas' placement='right'>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faAddressCard}
                className={classes.iconColorDisabled}
                fixedWidth
                size='lg'
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText disableTypography>Carteirinhas</ListItemText>
        </ListItem>
      </Link>

      <Link to='/eventos' component={RouterLink} underline='none' className={classes.disabled}>
        <ListItem button key='Eventos' className={classes.itemColorDisabled} onClick={closeMenu}>
          <Tooltip title='Eventos' placement='right'>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faTicketAlt}
                className={classes.iconColorDisabled}
                fixedWidth
                size='lg'
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText disableTypography>Eventos</ListItemText>
        </ListItem>
      </Link>

      <Link to='/lixeira' component={RouterLink} underline='none'>
        <ListItem
          selected={selectedIndex === 14}
          button
          key='Lixeira'
          className={classes.itemColor}
          onClick={(event) => handleListItemClick(event, 14)}>
          <Tooltip title='Lixeira' placement='right'>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faTrashAlt}
                className={classes.iconColor}
                fixedWidth
                size='lg'
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText disableTypography>Lixeira</ListItemText>
        </ListItem>
      </Link>
    </List>
  );
};

export default MenuList;
