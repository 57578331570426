import { send } from '_helpers/api.config';

const apiURL = '/api/admin/cupom';

// lista todos os cupons
export const list = async () => {
  const endpoint = `${apiURL}/list`;
  const verb = 'GET';
  const data = null;
  return send(verb, endpoint, data, false);
};

// lista cupons do plano
export const listPlano = async (plano) => {
  const endpoint = `${apiURL}/list/${plano}`;
  const verb = 'GET';
  const data = null;
  return send(verb, endpoint, data, false);
};

// cria novo cupom
export const create = async (cupom) => {
  const endpoint = apiURL;
  const verb = 'POST';
  const data = cupom;
  return send(verb, endpoint, data, false);
};

// edita cupom
export const edit = async (cupom) => {
  const endpoint = `${apiURL}/${cupom._id}`;
  const verb = 'PUT';
  const data = cupom;
  return send(verb, endpoint, data, false);
};

// inverte o status atual
export const mudarStatus = async (cupom) => {
  const endpoint = `${apiURL}/${cupom._id}/status`;
  const verb = 'PUT';
  const data = cupom;
  return send(verb, endpoint, data, false);
};

// soft delete
export const trash = async (cupom) => {
  const endpoint = `${apiURL}/${cupom._id}`;
  const verb = 'DELETE';
  const data = cupom;
  return send(verb, endpoint, data, false);
};

// verifica se código já existe
export const check = async (codigo) => {
  const endpoint = `${apiURL}/${codigo}/check`;
  const verb = 'GET';
  const data = codigo;
  const res = await send(verb, endpoint, data, false);
  if (res.error) {
    return res.error;
  }
  return false;
};
