export default (theme) => ({
  container: {
    flex: 2,
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    margin: '1em',
    padding: '1em',
  },
});
