import {
  SHOW_CREATE,
  CANCEL,
  SHOW_EDIT,
  REQUEST,
  SUCCESS,
  FAILURE,
} from './types';

const INITIAL_STATE = {
  parceiros: [],
  parceiro: {},
  edit: false,
  trash: false,
  create: false,
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SHOW_CREATE:
      return { ...state, create: true, edit: false };

    case SHOW_EDIT:
      return {
        ...state,
        edit: true,
        create: false,
        parceiro: { ...payload },
      };

    case REQUEST:
      return { ...state, loading: true };
    case FAILURE:
      return { ...state, loading: true };
    case SUCCESS:
      return {
        ...state,
        edit: false,
        create: false,
        loading: false,
        parceiros: [...payload.parceiros],
        parceiro: {},
      };

    case CANCEL:
      return {
        ...state,
        parceiro: {},
        edit: false,
        trash: false,
        create: false,
        loading: false,
      };

    default:
      return state;
  }
};
