export default (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    margin: '1em',
    padding: '1em',
  },

  title: {
    margin: '0',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
    color: 'grey',
  },

  box: {
    flex: 1,
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center',
    marginTop: '1em',
    borderBottom: '1px solid #eaeaea',
  },

  text: {
    flex: 1,
    fontSize: '1.2em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em',
    },
  },

  value: {
    flex: 1,
    fontWeight: '500',
    fontSize: '1.2em',
    marginLeft: '5em',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
      textAlign: 'right',
    },
  },

  icon: {
    color: '#cacaca',
    marginRight: '0.2em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8em',
    },
  },
});
