import * as Yup from 'yup';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import isAfter from 'date-fns/isAfter';

Yup.addMethod(Yup.object, 'servico', function () {
  return this.test('servico', 'Parâmetros inválidos', function (value) {
    if (!value) return true;
    return Yup.object().shape({
      cota: Yup.number().min(0, 'Não pode ser menor que 0.').nullable(),
      valor: Yup.string('').min(0, 'Não pode ser menor que 0.').nullable(),
    });
  });
});

Yup.addMethod(Yup.object, 'financeiro', function () {
  return this.test('servico', 'Parâmetros inválidos', function (value) {
    if (!value) return true;
    return Yup.object().shape({
      transacao: Yup.number().min(0, 'Não pode ser menor que 0.').nullable(),
      avista: Yup.string('').min(0, 'Não pode ser menor que 0.').nullable(),
      parcelado: Yup.string('').min(0, 'Não pode ser menor que 0.').nullable(),
    });
  });
});

Yup.addMethod(Yup.object, 'saque', function () {
  return this.test('saque', 'Parâmetros inválidos', function (value) {
    if (!value) return true;
    return Yup.object().shape({
      cortesia: Yup.number().min(0, 'Não pode ser menor que 0.').nullable(),
      ted: Yup.string('').min(0, 'Não pode ser menor que 0.').nullable(),
    });
  });
});

const validationSchema = Yup.object({
  titulo: Yup.string('')
    .min(1, 'Muito curto')
    .max(80, 'Muito longo')
    .required('* Obrigatório'),

  slogan: Yup.string('').min(3, 'Muito curto').max(80, 'Muito longo'),

  beneficios: Yup.array().test(
    'array',
    'Informe pelo menos um benefício',
    function (value) {
      if (!value) return false;
      if (value[0] === '') return false;
      return true;
    },
  ),

  valor: Yup.number().when('pagamento.tipo', {
    is: (val) => val === 'Pagamento Recorrente' || val === 'Pagamento Único',
    then: Yup.number()
      .min(1, 'Valor incorreto')
      .max(9999, 'Valor incorreto')
      .required('* Obrigatório'),
  }),

  pagamento: Yup.object().shape({
    tipo: Yup.string('')
      .oneOf(
        ['Pagamento Recorrente', 'Pagamento Único', 'Plano Gratuito'],
        'Selecione uma das opções',
      )
      .required('* Obrigatório'),
    frequencia: Yup.string('').when('pagamento.tipo', {
      is: (val) => val === 'Pagamento Recorrente',
      then: Yup.string('')
        .oneOf(['Mensal', 'Semestral', 'Anual'], 'Selecione uma das opções')
        .required('* Obrigatório'),
    }),
  }),

  assinatura: Yup.object().shape({
    tipo: Yup.string('')
      .oneOf(
        [
          'Mensal',
          'Bimestral',
          'Trimestral',
          'Semestral',
          'Anual',
          'Personalizado',
          'Data Fixa',
          'Até que o usuário cancele',
        ],
        'Selecione uma opção válida',
      )
      .required('* Obrigatório'),

    duracao: Yup.number()
      .when('assinatura.tipo', {
        is: (val) => val === 'Personalizado',
        then: Yup.number()
          .min(1, 'Duração incorreta')
          .max(24, 'Duração incorreta, máximo 24')
          .required('* Obrigatório'),
      })
      .nullable(),

    validade: Yup.string('')
      .when('assinatura.tipo', {
        is: (val) => val === 'Data Fixa',
        then: Yup.string('')
          .test('teste-data-fixa', 'Data inválida', function (value) {
            const result = parse(value, 'dd/MM/yyyy', new Date());
            if (isValid(result)) {
              // a data não deve ser hoje ou anterior
              return isAfter(result, new Date());
            }
            return false;
          })
          .required('* Obrigatório'),
      })
      .nullable(),
  }),

  servicos: Yup.object().shape({
    carteirinha: Yup.object().servico(),
    lojinha: Yup.object().servico(),
    parceiros: Yup.object().servico(),
    planos: Yup.object().servico(),
    cupons: Yup.object().servico(),
    clube: Yup.object().servico(),
    site: Yup.object().servico(),
    financeiro: Yup.object().servico(),
    associados: Yup.object().servico(),
    manual: Yup.object().servico(),
    atividades: Yup.object().servico(),
    sms: Yup.object().servico(),
    mailing: Yup.object().servico(),
    notifSistema: Yup.object().servico(),
    notifPopUp: Yup.object().servico(),
    notifPush: Yup.object().servico(),
  }),

  taxas: Yup.object().shape({
    boleto: Yup.object().financeiro(),
    cartao: Yup.object().financeiro(),
    pix: Yup.object().financeiro(),
    saque: Yup.object().saque(),
  }),
});

export default validationSchema;
