import { send } from '_helpers/api.config';

const apiURL = '/api/admin/notificacoes';

// lista todas as notificacoes da entidade
export const list = async () => {
  const endpoint = `${apiURL}/list`;
  const verb = 'GET';
  const data = null;
  const res = await send(verb, endpoint, data);
  return res.data.notificacoes;
};

// cria nova notificacao
export const create = async (notificacao) => {
  const endpoint = apiURL;
  const verb = 'POST';
  const data = notificacao;
  return send(verb, endpoint, data);
};

// edita notificacao
export const edit = async (notificacao) => {
  const endpoint = `${apiURL}/${notificacao._id}`;
  const verb = 'PUT';
  const data = notificacao;
  return send(verb, endpoint, data);
};

// soft delete
export const trash = async (notificacao) => {
  const endpoint = `${apiURL}/${notificacao._id}`;
  const verb = 'DELETE';
  const data = notificacao;
  return send(verb, endpoint, data);
};
