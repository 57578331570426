export default (theme) => ({
  card: {
    width: 285,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2em',
    [theme.breakpoints.up('sm')]: {
      marginRight: '1em',
      marginLeft: '1em',
      marginBottom: '2em',
    },
  },

  status: {
    fontSize: '10px',
  },

  button: {
    color: theme.palette.primary.dark,
  },

  mr: {
    marginRight: theme.spacing(1),
  },

  chips: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },

  hActive: {
    display: 'flex',
    backgroundColor: theme.palette.primary.dark,
    paddingBottom: '2em',
  },

  hInactive: {
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
    paddingBottom: '2em',
  },

  avatar: {
    position: 'relative',
    left: 'calc(50% - 25px)',
    top: '-20px',
    cursor: 'pointer',
  },

  titulo: {
    flex: 1,
    padding: theme.spacing(1),
    paddingBottom: 0,
  },

  tituloInativo: {
    flex: 1,
    padding: theme.spacing(1),
    paddingBottom: 0,
  },

  activeText: {
    color: 'white',
  },

  inactiveText: {
    color: 'grey',
  },

  avatarAtivo: {
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
    zIndex: 1,
  },

  avatarInativo: {
    backgroundColor: theme.palette.secondary.light,
    cursor: 'pointer',
    zIndex: 1,
  },

  topOptions: {
    position: 'relative',
    top: '-2.5em',
    display: 'flex',
  },

  destaque: {
    flex: 1,
    display: 'flex',
    margin: theme.spacing(2),
    justifyContent: 'flex-start',
  },

  menu: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },

  star: {
    color: 'gold',
    cursor: 'pointer',
  },

  starInativo: {
    color: 'lightgrey',
    cursor: 'pointer',
  },

  beneficios: {
    marginTop: '-3em',
    paddingTop: '0',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  beneficio: {
    display: 'flex',
    lineHeight: '1.2em',
    marginTop: '1em',
    fontWeight: '500',
  },

  benefIcon: {
    marginLeft: '1em',
    alignSelf: 'center',
  },

  benefTick: {
    marginRight: '0.5em',
    color: theme.palette.secondary.main,
  },

  benefText: {
    marginLeft: '1em',
  },

  infos: {
    marginTop: '1em',
    display: 'flex',
    flexDirection: 'column',
  },

  actions: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
  },
});
