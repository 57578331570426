export default (theme) => ({
  formContainer: {
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '30%',
    },
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: 'auto',
    display: 'flex',
    marginBottom: '2em',
  },

  title: {
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '50%',
    },
  },

  button: {
    marginLeft: '0.5em',
  },
});
