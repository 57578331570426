import {
  CANCEL,
  REQUEST,
  SUCCESS,
  FAILURE,
  SHOW_LISTA,
  SHOW_PERFIL,
  SHOW_EDIT,
  CHANGE_LIST,
  ORDENA,
  FILTRO_CURSO,
  FILTRO_LOCALIZACAO,
} from './types';
import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';

export const cancel = () => ({ type: CANCEL });
export const showLista = () => ({ type: SHOW_LISTA });
export const showPerfil = (perfil) => ({ type: SHOW_PERFIL, payload: perfil });
export const showEdit = () => ({ type: SHOW_EDIT });

export const changeList = (listData) => ({
  type: CHANGE_LIST,
  payload: { listData },
});

export const ordena = (ordem) => ({ type: ORDENA, payload: ordem });

export const filtroCurso = (curso) => ({ type: FILTRO_CURSO, payload: { curso } });

export const filtroLocalizacao = (localizacao) => ({
  type: FILTRO_LOCALIZACAO,
  payload: { localizacao },
});

export const loadAssociados = () => {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    // ***SERVICE CALL***
    const res = await service.list();
    if (res.success) {
      const { associados } = res.data;
      dispatch({ type: SUCCESS, payload: associados });
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: FAILURE });
    }
  };
};

export const edit = (associado) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    // ***SERVICE CALL***
    const res = await service.edit(associado);
    if (res.success) {
      const { associado, associados } = res.data;
      dispatch(feedback.success(res.msg));
      dispatch({ type: SHOW_PERFIL, payload: associado });
      dispatch({ type: SUCCESS, payload: associados });
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: FAILURE });
    }
  };
};

export const trash = (associado) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    // ***SERVICE CALL***
    const res = await service.trash(associado);
    if (res.success) {
      const { associados } = res.data;
      dispatch(feedback.success(res.msg));
      dispatch({ type: SUCCESS, payload: associados });
      dispatch({ type: SHOW_LISTA });
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: FAILURE });
    }
  };
};
