import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Menu from '../Menu';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('adminToken') ? (
        <Menu>
          <Component {...props} />
        </Menu>
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

export default PrivateRoute;
