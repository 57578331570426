/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard } from '@fortawesome/free-solid-svg-icons';
import isEnabled from './isEnabled';

function ID(props) {
  const classes = useStyles();
  const { seller } = props;
  const { status, docID } = seller;

  const Icon = () => (
    <FontAwesomeIcon icon={faIdCard} className={classes.fIcon} fixedWidth />
  );

  const Link = (props) => (
    <a
      className={classes.link}
      href={seller.docID}
      target='_blank'
      rel='noopener noreferrer'>
      {props.children}
    </a>
  );

  if (docID && status === 'Documentos em análise') {
    return (
      <Link>
        <span className={classes.emAnalise}>
          <Icon />
          Identificação em análise
        </span>
      </Link>
    );
  }

  if (docID && isEnabled) {
    return (
      <Link>
        <span className={classes.approved}>
          <Icon />
          Identificação aprovada
        </span>
      </Link>
    );
  }

  return (
    <span className={classes.missing}>
      <Icon />
      Identificação não enviada
    </span>
  );
}

export default ID;
