import React from 'react';
import useStyles from './styles';
// import MarketPlace from 'components/Marketplace';
import Score from './Score';
import List from './Score/List';

function Dashboard(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Score />
      <List />
    </div>
  );
}

export default Dashboard;
