import * as Yup from "yup";
import format from "date-fns/format";
import parse from "date-fns/parse";
import isValid from "date-fns/isValid";
import isAfter from "date-fns/isAfter";

const validationSchema = Yup.object({
  codigo: Yup.string("")
    .min(1, "Muito curto")
    .max(12, "Muito longo")
    .required("* Obrigatório"),

  tipo: Yup.string("").required("* Obrigatório"),

  desconto: Yup.number()
    .when("tipo", {
      is: val => val === "Valor Fixo",
      then: Yup.number()
        .min(0, "Desconto não pode ser negativo")
        .max(1000, "Desconto máximo de R$ 1000")
        .required("* Obrigatório")
    })
    .when("tipo", {
      is: val => val === "Porcentagem",
      then: Yup.number()
        .min(0, "O desconto não pode ser negativo")
        .max(100, "O desconto não pode ser maior do que 100%")
        .required("* Obrigatório")
    })
    .required("* Obrigatório"),

  quantidade: Yup.number()
    .min(1, "Mínimo 1")
    .required("* Obrigatório"),

  validade: Yup.string("")
    .test("teste-data-fixa", "Data inválida", function(value) {
      const date = new Date(value);
      if (isValid(date)) {
        // a data não deve ser hoje ou anterior
        return isAfter(date, new Date());
      }
      return false;
    })
    .required("* Obrigatório")
});

export default validationSchema;
