/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Venda from './Vendas/RowVenda';
import useStyles from './styles';
import { useSelector } from 'hooks';

function PagamentosDoLink(props) {
  const { link } = props;
  const classes = useStyles();
  const { pedidos } = useSelector('financeiro');
  const vendas = pedidos.filter(
    (p) => p.emitido_por === 'Link Pagamento' && p.linkCobranca === link._id,
  );
  return (
    <div className={classes.container}>
      {vendas.map((venda) => {
        return <Venda data={venda} key={venda._id} />;
      })}
    </div>
  );
}

export default PagamentosDoLink;
