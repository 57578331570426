import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    width: 285,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2em',
    [theme.breakpoints.up('sm')]: {
      marginRight: '1em',
      marginLeft: '1em',
      marginBottom: '2em',
    },
  },

  status: {
    fontSize: '10px',
  },

  button: {
    color: theme.palette.primary.dark,
  },

  mr: {
    marginRight: theme.spacing(1),
  },

  chips: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },

  hActive: {
    display: 'flex',
    backgroundColor: theme.palette.primary.dark,
    paddingBottom: '0.5em',
  },

  hInactive: {
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
    paddingBottom: '0.5em',
  },

  titulo: {
    flex: 1,
    padding: theme.spacing(1),
    paddingBottom: 0,
  },

  tituloInativo: {
    flex: 1,
    padding: theme.spacing(1),
    paddingBottom: 0,
  },

  activeText: {
    color: 'white',
  },

  inactiveText: {
    color: 'grey',
  },

  avatarAtivo: {
    color: theme.palette.primary.main,
    zIndex: 1,
  },

  avatarInativo: {
    color: theme.palette.secondary.main,
    zIndex: 1,
  },

  destaque: {
    flex: 1,
    display: 'flex',
    margin: theme.spacing(2),
    justifyContent: 'flex-start',
  },

  menu: {
    marginTop: '0.5em',
    flex: 1,
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: '1em',
  },

  star: {
    color: 'gold',
    cursor: 'pointer',
  },

  starInativo: {
    color: 'lightgrey',
    cursor: 'pointer',
  },

  beneficios: {
    paddingTop: '0',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  beneficio: {
    display: 'flex',
    lineHeight: '1.2em',
    marginTop: '1em',
    fontWeight: '500',
  },

  benefIcon: {
    marginLeft: '1em',
    alignSelf: 'center',
  },

  benefTick: {
    marginRight: '0.5em',
    color: theme.palette.secondary.main,
  },

  benefText: {
    marginLeft: '1em',
  },

  servicos: {
    paddingTop: '0',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  infos: {
    marginTop: '1em',
    marginBottom: '1em',
    display: 'flex',
    flexDirection: 'column',
  },
}));
