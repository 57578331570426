import axios from 'axios';
import { capitalizeFirstLetter } from '_helpers/utils';
import * as local from '_helpers/local';

const token = local.adminToken();

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: token,
    'Content-type': 'application/json',
  },
});

export async function send(verb, endpoint, data, updateStorage = true) {
  const config = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: local.adminToken(),
      'Content-Type': 'application/json',
    },
  };

  let res = '';

  try {
    switch (verb) {
      case 'GET':
        res = await api.get(endpoint, config);
        break;
      case 'POST':
        res = await api.post(endpoint, data, config);
        break;
      case 'PUT':
        res = await api.put(endpoint, data, config);
        break;
      case 'DELETE':
        res = await api.delete(endpoint, config);
        break;
      default:
        break;
    }
    console.log(res);
    if (updateStorage) {
      await updateLocalStorage(res);
    }
    return {
      success: true,
      msg: res.data.msg,
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return errorHandler(error);
  }
}

export async function upload(endpoint, data) {
  const uploadConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: local.adminToken(),
      'Content-Type': 'multipart/form-data;',
    },
  };
  let res = '';

  try {
    res = await api.post(endpoint, data, uploadConfig);
    return { success: true, msg: res.data.msg, data: res.data };
  } catch (error) {
    return errorHandler(error);
  }
}

export async function updateLocalStorage(res) {
  if (res.data) {
    const { data } = res;
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        // evitando utilizar funçoes não definidas
        if (key !== 'success' && key !== 'msg') {
          try {
            // cria o nome da funcao dinamicamente
            const name = `set${capitalizeFirstLetter(key)}`;
            local[name](data[key]);
            // local[fn.name](data[key]);
            console.log('local storage updated');
          } catch (error) {
            const name = `set${capitalizeFirstLetter(key)}`;
            // console.log(name);
            // console.log(error);
          }
        }
      }
    }
  }
}

function errorHandler(error) {
  const { response } = error;
  console.log(error.response);

  if (!response) {
    return { error: 'Tente novamente em alguns instantes' };
  }

  if (response.status === 400) {
    return { error: response.data.error };
  }

  // auto logout if 401 response returned from api
  // if (response.status === 401) {
  //   window.location.reload(true);
  // }

  return false;
}

export const fetchPlanos = async () => {
  const endpoint = `/api/admin/plano`;
  const verb = 'GET';
  const data = null;
  const config = {
    headers: { 'Content-Type': 'application/json' },
  };
  const res = await send(verb, endpoint, data, config);
  return res.data.planos;
};
