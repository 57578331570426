import { send } from '_helpers/api.config';

const apiURL = '/api/admin/associado';

export const list = async () => {
  const endpoint = `${apiURL}`;
  const verb = 'GET';
  const data = null;
  const res = await send(verb, endpoint, data, false);
  return res;
};

// edita o perfil de um associado
export const edit = async (associado) => {
  const endpoint = `${apiURL}/${associado._id}`;
  const verb = 'PUT';
  const data = associado;
  return send(verb, endpoint, data, false);
};

// soft delete
export const trash = async (associado) => {
  const endpoint = `${apiURL}/${associado._id}`;
  const verb = 'DELETE';
  const data = associado;
  return send(verb, endpoint, data, false);
};
