import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserNinja } from '@fortawesome/free-solid-svg-icons';
import { getSafe } from '_helpers/utils';
import styles from '../../styles';

function PreviewMessage(props) {
  const { classes, formik } = props;
  const mensagem = getSafe(formik.values, 'mensagem');
  const image = getSafe(formik.values, 'imagem');

  return (
    <>
      <div className={classes.mensagemWrapper} key='preview-message'>
        <div className={classes.mensagemChat}>
          <Typography variant='caption' align='right'>
            Ninja do Suporte
          </Typography>
          {image && (
            <div className={classes.containerImage}>
              <img
                alt='preview imagem ticket'
                title='preview imagem ticket'
                src={image}
                className={classes.imagePreview}
              />
            </div>
          )}
          <Typography className={classes.msgText}>
            {mensagem !== '' ? mensagem : '...'}
          </Typography>
          <Typography variant='caption' align='right'>
            pré-visualização
          </Typography>
        </div>
        <div className={classes.avatarCapitao}>
          <FontAwesomeIcon icon={faUserNinja} fixedWidth size='lg' />
        </div>
      </div>
      <br />
    </>
  );
}

export default withStyles(styles)(PreviewMessage);
