export default (theme) => ({
  container: {
    marginBottom: '10em',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1em',
  },

  sellers: {
    display: 'flex',
  },

  seller: {
    flexBasis: '50%',
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    margin: '1em',
    padding: '1em',
    paddingLeft: '1.5em',
  },

  left: {
    flexBasis: '65%',
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid #E2E2E2',
  },

  right: {
    flexBasis: '35%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '1em',
  },

  name: {
    fontSize: '2em',
  },

  email: {
    marginTop: '0.5em',
  },

  icon: {
    marginTop: '1em',
    paddingRight: '2em',
  },

  doc: {
    marginTop: '1em',
  },

  fIcon: {
    // color: '#bababa',
    marginRight: '0.5em',
  },

  details: {
    fontWeight: '100',
    marginBottom: '0.2em',
  },

  bwrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  balance: {
    fontWeight: '500',
    display: 'flex',
  },

  blabel: {
    flexBasis: '50%',
  },

  bvalue: {
    flexBasis: '50%',
  },

  dateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.875em',
  },

  dlabel: {
    color: '#bababa',
    fontWeight: '100',
  },

  date: {
    color: '#bababa',
    fontWeight: '600',
  },

  label: {
    fontWeight: '100',
  },

  switch: {
    marginTop: '0.5em',
  },

  slabel: {
    fontWeight: '100',
    marginTop: '0.5em',
  },

  status: {
    fontWeight: '500',
  },

  tipo: {
    fontWeight: '100',
    marginBottom: '0.2em',
  },

  info: {
    fontWeight: '500',
  },
});
