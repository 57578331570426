import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.2em',
    marginTop: '0.5em',
  },

  label: {
    flex: 1,
    fontWeight: '100',
    color: 'grey',
    marginRight: '0.5em',
  },

  info: {
    flex: 1,
  },
}));
