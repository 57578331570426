import React from 'react';
import useStyles from './styles';
import Save from './Save';
import Cancel from './Cancel';

export { Save, Cancel };

export default function Submit(props) {
  const classes = useStyles();
  return <div className={classes.container}>{props.children}</div>;
}
