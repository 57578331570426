import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    borderRadius: '4px',
    paddingTop: '1em',
    '&:hover': {
      background: '#eff8ff',
      cursor: 'pointer',
    },
  },

  mobile: {
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '4px',
    marginTop: '2em',
    alignContent: 'flex-end',
    '&:hover': {
      background: '#eff8ff',
      cursor: 'pointer',
    },
  },

  top: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '0.5em',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      order: 2,
      flexDirection: 'row-reverse',
    },
  },

  icon: {
    color: '#787878de',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      marginRight: '0.5em',
      flex: 1,
      color: '#fafafa',
      justifyContent: 'flex-end',
    },
  },

  data: {
    fontSize: '0.75em',
    padding: '1px 5px 0px 5px',
    marginLeft: '0.5em',
    fontWeight: '600',
    color: theme.palette.secondary.dark,
    borderRadius: '25px',
    border: '1px solid',
    borderColor: theme.palette.secondary.dark,
    [theme.breakpoints.down('sm')]: {
      padding: '2px 5px 0px 5px',
      marginRight: '1em',
      fontSize: '1em',
      flex: 1,
      textAlign: 'center',
    },
  },

  label: {
    fontSize: '0.9em',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: '500',
    lineHeight: '1.5',
    whiteSpace: 'normal',
    letterSpacing: '0.02857em',
    marginBottom: '0.3em',
    [theme.breakpoints.down('sm')]: {
      flex: 2,
      fontSize: '1.2em',
      order: 0,
    },
  },

  divider: {
    height: '1px',
    width: '100%',
    background: '#383838',
    order: 1000,
    marginTop: '1em',
  },
}));
