import sort from 'fast-sort';

export function filtraPlano(plano, data) {
  if (!plano || plano === 'todos') return data;

  if (plano === 'pagosAtivos') {
    return data.filter(({ assinaturas }) =>
      assinaturas.find((a) => a.valor.original !== 0 && a.status === 'Ativo'),
    );
  }

  if (plano === 'pagosExpirados') {
    return data.filter(({ assinaturas }) =>
      assinaturas.find((a) => a.valor.original !== 0 && a.status === 'Expirada'),
    );
  }

  if (plano === 'apenasFree') {
    return data.filter(({ assinaturas }) => {
      const ativas = assinaturas.filter((a) => a.status === 'Ativo');
      return ativas.length === 1;
    });
  }

  return data.filter(
    ({ assinaturas }) => assinaturas.filter((a) => a.plano._id === plano).length > 0,
  );
}

export function handleSort(ordem, data) {
  switch (ordem) {
    case 'AZ':
      return sort(data).asc('nome');
    case 'ZA':
      return sort(data).desc('nome');
    case 'asc':
      return sort(data).asc('qtyAssociados');
    case 'desc':
      return sort(data).desc('qtyAssociados');
    case 'new':
      return sort(data).desc('createdAt');
    case 'old':
      return sort(data).asc('createdAt');
    // por ultimo acesso
    case 'lastAccess':
      return sort(data).desc((e) => e?.user?.lastAccess);
    default:
      return data;
  }
}

export function filtroGerencial(status, data, sellers) {
  if (status === 'todos') return data;
  if (status === 'noUser') {
    return data.filter((x) => (x.user ? !x.user.isVerified : true));
  }

  if (status === 'activeSellers') {
    return data.filter(({ _id }) =>
      sellers.find((s) => s.entidade._id.toString() === _id.toString()),
    );
  }
}

// filtro por localização UF
export function filtroLocalizacao(status, data) {
  if (status === 'todos') return data;
  console.log('Status', status);
  return data.filter((e) => e?.endereco?.uf.toUpperCase() === status);
}

export function filtraStatus(status, data) {
  if (status === 'todos') return data;
  if (status === 'noUser') {
    return data.filter((x) => (x.user ? !x.user.isVerified : true));
  }

  if (status === 'Pagos&Ativos') {
    return data.filter(({ assinaturas }) =>
      assinaturas.find((a) => a.valor.original !== 0 && a.status === 'Ativo'),
    );
  }

  if (status === 'Pagos&Expirados') {
    return data.filter(({ assinaturas }) =>
      assinaturas.find((a) => a.valor.original !== 0 && a.status === 'Expirada'),
    );
  }

  if (status === 'apenasFree') {
    return data.filter(({ assinaturas }) => {
      const ativas = assinaturas.filter((a) => a.status === 'Ativo');
      return ativas.length === 1;
    });
  }

  return data.filter((x) => x.assinaturas.find((a) => a.status === status));
}

export function getHandledData(state) {
  const {
    plano,
    gerencial,
    localizacao,
    sellers,
    ordem,
    status,
    entidades,
    pagosAtivos,
    pagosExpirados,
    apenasFree,
    semConta,
  } = state;
  let data = entidades;

  if (status === 'Pagos&Ativos') data = pagosAtivos;
  if (status === 'Pagos&Expirados') data = pagosExpirados;
  if (status === 'apenasFree') data = apenasFree;
  if (status === 'noUser') data = semConta;

  const dataPlano = filtraPlano(plano, data);
  const dataGerencial = filtroGerencial(gerencial, dataPlano, sellers);
  const dataLocalizacao = filtroLocalizacao(localizacao, dataGerencial);
  const res = handleSort(ordem, dataLocalizacao);
  return res;
}
