import { send } from '_helpers/api.config';

const apiURL = '/api/site';

export const loadLojas = async () => {
  const endpoint = `${apiURL}/farol/lojas`;
  const verb = 'GET';
  const data = null;
  return send(verb, endpoint, data);
};
