import React from 'react';
import useStyles from 'components/Planos/Novo/styles';
import { Formik } from 'formik';
import { useSelector, useActions } from 'hooks';
import * as Actions from './redux/actions';
import validationSchema from './Novo/validation';
import Form from './Novo/form';
import defValues from './Novo/defaultFormValues';

const EditaPlano = (props) => {
  window.scrollTo(0, 0);
  const classes = useStyles();
  const actions = useActions(Actions);
  const { plano, loading } = useSelector('planos');
  const values = {
    ...defValues,
    ...plano,
    taxas: { ...defValues.taxas, ...plano.taxas },
  };

  console.log('Values', values);

  return (
    <div className={classes.formContainer}>
      <Formik
        render={(props) => (
          <Form
            handleCancel={actions.cancel}
            title={'Editar Plano'}
            loading={loading.edit}
            {...props}
          />
        )}
        initialValues={{ ...values }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          actions.edit(values);
        }}
      />
    </div>
  );
};

export default EditaPlano;
