import { send } from '_helpers/api.config';

const apiURL = '/api/admin/plano';

// lista todos os planos
export const list = async (plano) => {
  const endpoint = apiURL;
  const verb = 'GET';
  const data = plano;
  return send(verb, endpoint, data, false);
};

// cria novo plano
export const create = async (plano) => {
  const endpoint = apiURL;
  const verb = 'POST';
  const data = plano;
  return send(verb, endpoint, data, false);
};

// edita plano
export const edit = async (plano) => {
  const endpoint = `${apiURL}/${plano._id}`;
  const verb = 'PUT';
  const data = plano;
  return send(verb, endpoint, data, false);
};

// soft delete
export const trash = async (plano) => {
  const endpoint = `${apiURL}/${plano._id}`;
  const verb = 'DELETE';
  const data = plano;
  return send(verb, endpoint, data, false);
};

// o plano atual ganha destaque e os outros perdem
export const destaque = async (plano) => {
  const endpoint = `${apiURL}/destaque/${plano._id}`;
  const verb = 'PUT';
  const data = plano;
  return send(verb, endpoint, data, false);
};

// inverte o status atual
export const mudarStatus = async (plano) => {
  const endpoint = `${apiURL}/status/${plano._id}`;
  const verb = 'PUT';
  const data = plano;
  return send(verb, endpoint, data, false);
};
