import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    transition: 'all ease 300ms',
    '&:hover': {
      background: '#eef7ff',
      paddingLeft: '1em',
    },
  },

  label: {
    fontSize: '1em',
    fontWeight: '500',
    flex: 1,
  },

  input: {
    textAlign: 'right',
  },

  labelPlacementStart: {
    marginLeft: '0',
  },
}));
