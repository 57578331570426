import React from 'react';
import { FormGroup, FormTextField } from 'components/_Common/Form';
import Switch from 'components/_Common/SwitchWithObj';
import useStyles from './styles';

function Servico(props) {
  const { label, name, unit, rest } = props;
  const { servicos, formProps, handleChange } = rest;
  const classes = useStyles();

  if (!servicos[name]) return null;

  return (
    <div className={classes.switch}>
      <div className={classes.left}>
        <Switch
          label={label}
          name={name}
          handleChange={handleChange}
          data={servicos}
        />
      </div>
      <div className={classes.right}>
        {unit && servicos[name].enabled ? (
          <FormGroup>
            <FormTextField
              label='Cota Grátis'
              field={`servicos.${name}.cota`}
              xs={12}
              md={6}
              formik={formProps}
              type='tel'
              helperText='ilimitado'
            />
            <FormTextField
              label={`Créditos por ${unit}`}
              field={`servicos.${name}.creditos`}
              xs={12}
              md={6}
              formik={formProps}
              helperText='não permite compra'
            />
          </FormGroup>
        ) : (
          props.children
        )}
      </div>
    </div>
  );
}

export default Servico;
