import { CANCEL, REQUEST, FAILURE, SUCCESS, CHANGE_VIEW } from './types';

const INITIAL_STATE = {
  loading: false,
  sellers: [],
  view: 'sellers',
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case CHANGE_VIEW:
      return { ...state, view: payload };

    case REQUEST:
      return { ...state, loading: true };

    case SUCCESS:
      return {
        ...state,
        loading: false,
        sellers: [...payload.sellers],
      };

    case FAILURE:
      return { ...state, loading: false };

    case CANCEL:
      return {
        ...state,
        view: 'sellers',
        sellers: [],
      };

    default:
      return state;
  }
};
