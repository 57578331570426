import React from 'react';
import useStyles from 'components/Planos/Novo/styles';
import { Formik } from 'formik';
import * as Actions from './redux/actions';
import validationSchema from './Novo/validation';
import Form from './Novo/form';
import { useSelector, useActions } from 'hooks';

const CopyPlano = (props) => {
  window.scrollTo(0, 0);
  const classes = useStyles();
  const actions = useActions(Actions);
  const { plano, loading } = useSelector('planos');

  const values = {
    titulo: plano.titulo,
    slogan: plano.slogan,
    beneficios: plano.beneficios,
    pagamento: plano.pagamento,
    assinatura: plano.assinatura,
    servicos: plano.servicos,
    cobranca: plano.cobranca,
    valor: plano.valor,
    contrato: plano.contrato,
    taxas: plano.taxas,
  };

  return (
    <div className={classes.formContainer}>
      <Formik
        render={(props) => (
          <Form
            handleCancel={actions.cancel}
            title={'Replicar Plano'}
            loading={loading.edit}
            {...props}
          />
        )}
        initialValues={{ ...values }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          actions.create(values);
        }}
      />
    </div>
  );
};

export default CopyPlano;
