import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  icon: {
    marginLeft: '0.5em',
  },

  gg: {
    marginRight: '0.5em',
  },

  title: {
    fontSize: '2em',
    marginBottom: '1em',
  },

  text: {
    fontWeight: '100',
    fontSize: '1.5em',
    marginBottom: '1em',
  },

  link: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '6em 0',
  },

  linkText: {
    marginTop: '1em',
  },

  urlValue: {
    marginRight: '-8px',
    color: 'lightgrey',
  },

  button: {
    marginTop: '2em',
    marginBottom: '4em',
  },
}));
