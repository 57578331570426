export default (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: 'auto',
    display: 'flex',
    marginBottom: '2em',
  },

  list: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  listItem: {
    padding: '1em 0.5em',
    display: 'flex',
    '&:hover': {
      background: '#eaeaea',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  avatar: {
    width: '50px',
    textAlign: 'left',
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50px',
    },
  },

  dados: {
    display: 'flex',
    flex: 2,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5em',
    },
  },

  dataText: {},

  defaults: {
    display: 'flex',
    flex: 1,
  },

  input: {
    flex: 1,
    marginRight: '1em',
  },

  nome: {
    fontWeight: '500',
  },

  email: {
    color: 'grey',
  },
});
