/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { List, WindowScroller, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import Row from './Row';
import Col from './Col';
import Box from './Box';

export { Row, Col, Box };

function LazyList(props) {
  const { data, Item, actions, rest, classes } = props;

  const getCache = () => {
    return new CellMeasurerCache({
      fixedWidth: true,
      fixedHeight: false,
    });
  };

  let cache = getCache();

  const [ref, setRef] = useState(null);

  useEffect(() => {
    cache = getCache();
    if (ref) {
      window.scrollTo(0, 0);
    }
  }, [data]);

  const rowRenderer = (args) => {
    const { key, index, style, parent } = args;
    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        <div key={key} style={style}>
          <Item data={data[index]} actions={actions} rest={rest} key={key} classes={classes} />
        </div>
      </CellMeasurer>
    );
  };

  return (
    <WindowScroller>
      {({ height, width, isScrolling, registerChild, scrollTop }) => (
        <div ref={registerChild}>
          <List
            height={height}
            width={width}
            autoHeight
            autoWidth
            isScrolling={isScrolling}
            rowCount={data.length}
            deferredMeasurementCache={cache}
            rowHeight={cache.rowHeight}
            rowRenderer={rowRenderer}
            scrollTop={scrollTop}
            ref={setRef}
          />
        </div>
      )}
    </WindowScroller>
  );
}

export default LazyList;
