/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Venda from './Vendas/RowVenda';
import useStyles from './styles';

function PagamentosDoLink(props) {
  const classes = useStyles();
  const { pedidos } = props;

  return (
    <div className={classes.container}>
      {pedidos.map((venda) => {
        return <Venda data={venda} key={venda._id} />;
      })}
    </div>
  );
}

export default PagamentosDoLink;
