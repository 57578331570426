/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useStyles from './styles';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit, faCommentDollar } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import AddCreditos from './AddCreditos';

function Opcoes(props) {
  const { entidade, actions } = props;
  const classes = useStyles();

  return (
    <div className={classes.opcoes}>
      <AddCreditos entidade={entidade} actions={actions} />

      <Tooltip title='Pedidos da Entidade' placement='top'>
        <span>
          <IconButton size='small' variant='text' color='primary' className={classes.buttonOpt}>
            <FontAwesomeIcon
              icon={faCommentDollar}
              className={classes.pullLeft}
              fixedWidth
              size='sm'
            />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title='Editar' placement='top'>
        <span>
          <IconButton
            size='small'
            variant='text'
            onClick={() => actions.showPerfil(entidade)}
            color='primary'
            className={classes.buttonOpt}>
            <FontAwesomeIcon icon={faUserEdit} className={classes.pullLeft} fixedWidth size='sm' />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
}

export default Opcoes;
