import React from 'react';
import { FormGroup, MoneyTextField } from 'components/_Common/Form';
import Switch from 'components/_Common/SwitchWithObj';
import useStyles from './styles';

function ItemFinanceiro(props) {
  const { label, name, rest } = props;
  const { taxas, formProps, handleChange } = rest;
  const classes = useStyles();

  const showBoleto = () => {
    if (name === 'boleto' && taxas?.boleto?.enabled) {
      return (
        <FormGroup>
          <MoneyTextField
            prefix='R$'
            label='Transação'
            field='taxas.boleto.transacao'
            xs={12}
            md={4}
            formik={formProps}
            helperText='Sem custo'
          />
          <MoneyTextField
            prefix='%'
            label='Taxa'
            field='taxas.boleto.taxa'
            xs={12}
            md={4}
            formik={formProps}
            helperText='Sem custo'
          />
        </FormGroup>
      );
    }
  };

  const showCartao = () => {
    if (name === 'cartao' && taxas?.cartao?.enabled) {
      return (
        <FormGroup>
          <MoneyTextField
            prefix='R$'
            label='Transação'
            field='taxas.cartao.transacao'
            xs={12}
            md={4}
            formik={formProps}
          />
          <MoneyTextField
            prefix='%'
            label='À Vista'
            field='taxas.cartao.avista'
            xs={12}
            md={4}
            formik={formProps}
          />
          <MoneyTextField
            prefix='%'
            label='Ao mês'
            field='taxas.cartao.parcelado'
            xs={12}
            md={4}
            formik={formProps}
          />
        </FormGroup>
      );
    }
  };

  const showPix = () => {
    if (name === 'pix' && taxas?.pix?.enabled) {
      return (
        <FormGroup>
          <MoneyTextField
            prefix='R$'
            label='Transação'
            field='taxas.pix.transacao'
            xs={12}
            md={4}
            formik={formProps}
            helperText='Sem custo'
          />
          <MoneyTextField
            prefix='%'
            label='Taxa'
            field='taxas.pix.taxa'
            xs={12}
            md={4}
            formik={formProps}
            helperText='Sem custo'
          />
        </FormGroup>
      );
    }
  };

  const showSaque = () => {
    if (name === 'saque' && taxas?.saque?.enabled) {
      return (
        <FormGroup>
          <MoneyTextField
            prefix=''
            label='Saques Gratuitos'
            field='taxas.saque.cortesia'
            xs={12}
            md={4}
            formik={formProps}
          />
          <MoneyTextField
            prefix='R$'
            label='Custo por TED adicional'
            field='taxas.saque.ted'
            xs={12}
            md={4}
            formik={formProps}
          />
        </FormGroup>
      );
    }
  };
  return (
    <div className={classes.switch}>
      <div className={classes.leftCbr}>
        <Switch
          label={label}
          name={name}
          handleChange={handleChange}
          data={taxas}
        />
      </div>
      <div className={classes.rightCbr}>
        {showBoleto()}
        {showCartao()}
        {showPix()}
        {showSaque()}
      </div>
    </div>
  );
}

export default ItemFinanceiro;
