import React from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStyles from './styles';
import { getSafe } from '_helpers/utils';

export default function SelectOptions(props) {
  const {
    options, // for select field
    field,
    formik, // for form
  } = props;
  const classes = useStyles();
  const value = getSafe(formik.values, field);
  const handleChange = formik.change;

  const [open, setOpen] = React.useState(false);

  const setIcon = (icon, color) => {
    return (
      <FontAwesomeIcon
        className={color && classes[color]}
        icon={icon}
        fixedWidth
        size='sm'
      />
    );
  };

  const selectHandleClose = () => {
    setOpen(false);
  };

  const selectHandleOpen = () => {
    setOpen(true);
  };

  const setIconStatus = (status) => {
    const option = options.find((opt) => opt.value === status);
    return setIcon(option.icon, status);
  };

  return (
    <FormControl className={classes.formControl}>
      <Tooltip title='Mudar status'>
        <IconButton
          className={classes.iconButton}
          aria-label='mudar status'
          onClick={selectHandleOpen}>
          {value && setIconStatus(value)}
        </IconButton>
      </Tooltip>
      <Select
        className={classes.hidden}
        open={open}
        onClose={selectHandleClose}
        onOpen={selectHandleOpen}
        value={value}
        onChange={handleChange.bind(null, field)}
        input={<OutlinedInput name={field} id={field} />}>
        >
        {options.map((option, index) => (
          <MenuItem
            value={option.value ?? option}
            key={option.value ?? option + index}>
            {option.value ?? option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
