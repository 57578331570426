import {
  REQUEST,
  SUCCESS,
  FAILURE,
  CANCEL,
  CHANGE_VIEW,
  SET_CONTAS_HOJE,
} from './types';

const INITIAL_STATE = {
  loading: false,
  view: 'DASHBOARD',
  sellers: [],
  pedidos: [],
  receberHoje: 0,
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case REQUEST:
      return { ...state, loading: true };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        sellers: payload.sellers || state.sellers,
        pedidos: payload.pedidos || state.pedidos,
      };
    case FAILURE:
      return { ...state, loading: false };

    case CHANGE_VIEW:
      return { ...state, view: payload };

    case SET_CONTAS_HOJE:
      return { ...state, receberHoje: payload.receber };

    case CANCEL:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
