/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './redux/actions';
import {
  faFileInvoiceDollar,
  faChartLine,
  faExchangeAlt,
  faThList,
} from '@fortawesome/free-solid-svg-icons';
import Dashboard from './Dashboard';
import DashContainer from 'components/_Common/DashContainer';
import DashCard from 'components/_Common/DashCard';
import styles from './styles';
import Pedidos from './Pedidos';
import isToday from 'date-fns/isToday';

const Financeiro = (props) => {
  const { classes } = props;
  const actions = bindActionCreators(Actions, useDispatch());
  const { view, pedidos } = useSelector(({ financeiro }) => ({
    ...financeiro,
  }));

  useEffect(() => {
    actions.loadEntidades();
    actions.listPedidos();
  }, []);

  useEffect(() => {
    return () => {
      actions.cancel();
    };
  }, []);

  useEffect(() => {
    if (pedidos.length !== 0) {
      let receberHoje = pedidos.reduce((acc, pedido) => {
        if (isToday(new Date(pedido.vencimento))) {
          return acc + pedido.valor;
        }
      });
      if (!receberHoje) receberHoje = 0;
      actions.setContasHoje(receberHoje, 0);
    }
  }, [pedidos]);

  const selectView = () => {
    switch (view) {
      case 'DASHBOARD':
        return <Dashboard />;
      case 'PEDIDOS':
        return <Pedidos />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className={classes.dashboard}>
      <DashContainer>
        <DashCard
          info='Saldo'
          data='R$ 0'
          text='dashboard'
          icon={faFileInvoiceDollar}
          action={() => actions.changeView('DASHBOARD')}
        />
        <DashCard
          info='Fluxo de Caixa'
          data={'0'}
          text='gerenciar'
          icon={faChartLine}
          action={() => actions.changeView('FLUXOCAIXA')}
        />
        <DashCard
          info='Transferências'
          data={'0'}
          text='transferir dinheiro'
          icon={faExchangeAlt}
          action={null}
        />

        <DashCard
          data={pedidos.length}
          info='Pedidos'
          text='Gerenciar'
          icon={faThList}
          action={() => actions.changeView('PEDIDOS')}
        />
      </DashContainer>

      <div className={classes.container}>{selectView()}</div>
    </div>
  );
};

export default withStyles(styles)(Financeiro);
