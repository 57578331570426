import * as Yup from 'yup';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import isAfter from 'date-fns/isAfter';

const validationSchema = Yup.object({
  descricao: Yup.string('')
    .min(1, 'Muito curto')
    .max(60, 'Muito longo')
    .required('* Obrigatório'),

  valor: Yup.number()
    .typeError('Valor incorreto')
    .min(5, 'Valor mínimo R$ 5,00')
    .required('* Obrigatório'),

  vencimento: Yup.string('')
    .test('date', 'Data inválida', function(value) {
      const result = parse(value, 'dd/MM/yyyy', new Date());
      if (isValid(result)) {
        // a data não deve ser hoje ou anterior
        return isAfter(result, new Date());
      }
      return false;
    })
    .required('* Obrigatório'),

  list: Yup.array().of(
    Yup.object().shape({
      valor: Yup.number()
        .typeError('Valor incorreto')
        .min(5, 'Valor mínimo R$ 5,00')
        .required('* Obrigatório'),

      vencimento: Yup.string('')
        .test('teste-data-fixa', 'Data inválida', function(value) {
          const result = parse(value, 'dd/MM/yyyy', new Date());
          if (isValid(result)) {
            // a data não deve ser hoje ou anterior
            return isAfter(result, new Date());
          }
          return false;
        })
        .required('* Obrigatório'),
    }),
  ),
});

export default validationSchema;
