import { REQUEST, SUCCESS, FAILURE } from './types';

const INITIAL_STATE = {
  scores: [],
  pesos: {
    acesso: 1,
    logo: 1,
    capaLoja: 1,
    seller: 1,
    fluxos: 1,
    parceiros: 1,
    produtos: 1,
    planos: 1,
    associados: 1,
    notificacoes: 1,
    atividades: 1,
    vendaManual: 1,
    comprasFarol: 1,
    vendaOnline: 1,
    vendaPlano: 1,
    totalVendido: 1,
    splitFarol: 1,
    capaSite: 1,
    carteirinhas: 1,
  },
  loading: true,
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        loading: true,
        ...payload,
      };

    case SUCCESS:
      return {
        ...state,
        loading: false,
        scores: payload?.scores ? [...payload.scores] : state.scores,
        pesos: payload?.pesos ? { ...payload.pesos } : state.pesos,
      };

    case FAILURE:
      return {
        ...state,
        loading: false,
        ...payload,
      };

    default:
      return state;
  }
};
