import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  list: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  listItem: {
    padding: '1em 0.5em',
    display: 'flex',
    background: 'white',
    margin: '0.5em 0',
    alignItems: 'center',
    boxShadow: '2px 2px 7px -4px hsla(0, 0%, 0%, 0.2)',
    '&:hover': {
      background: '#eaeaea',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: '1em',
    },
  },

  firstBox: {
    display: 'flex',
    flex: 2,
  },

  opcoes: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '1em',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2em',
      justifyItems: 'space-between',
    },
  },

  buttonOpt: {
    marginRight: '0.5em',
    [theme.breakpoints.down('sm')]: {
      marginRight: '1em',
    },
  },

  infoBox: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      marginBottom: '2em',
      marginLeft: '2em',
    },
  },

  url: {
    fontSize: '0.8em',
    fontWeight: '500',
  },

  nome: {
    fontWeight: '500',
  },

  dado: {
    color: 'grey',
  },

  dadoSm: {
    color: 'grey',
    lineHeight: '1em',
  },

  avatar: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '50px',
    textAlign: 'left',
    marginLeft: '10px',
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50px',
    },
  },

  ativo: {
    textAlign: 'center',
    width: '80px',
    lineHeight: '1em',
    fontSize: '0.875em',
    padding: '2px 10px',
    border: `1px solid ${theme.palette.green.main}`,
    borderRadius: '25px',
    fontWeight: '500',
    color: theme.palette.green.main,
    textTransform: 'uppercase',
    marginTop: '0.5em',
  },

  inativo: {
    textAlign: 'center',
    width: '80px',
    lineHeight: '1em',
    fontSize: '0.875em',
    padding: '2px 10px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '25px',
    fontWeight: '500',
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    marginTop: '0.5em',
  },

  icon: {
    color: 'lightgrey',
    marginRight: '0.5em',
  },
}));
