import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import useStyles from './styles';

function Beneficios(props) {
  const classes = useStyles();
  const { plano } = props;

  return (
    <div className={classes.beneficios}>
      <Typography variant='h6' align='center' gutterBottom>
        BENEFÍCIOS
      </Typography>
      <Divider variant='middle' />
      {plano.beneficios.map((beneficio, index) => (
        <Typography
          key={index}
          component='p'
          variant='overline'
          gutterBottom
          className={classes.beneficio}>
          <span className={classes.benefIcon}>
            <FontAwesomeIcon
              icon={faCheck}
              className={classes.benefTick}
              fixedWidth
            />
          </span>
          <span className={classes.benefText}>{beneficio}</span>
        </Typography>
      ))}
    </div>
  );
}

export default Beneficios;
