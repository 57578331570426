import { send } from '../../../_helpers/api.config';

const config = {
  headers: { 'Content-Type': 'application/json' },
};

const apiURL = '/api/entidade/cadastro/';

// edita dados da entidade
export const edit = async (entidade) => {
  const endpoint = `${apiURL}`;
  const verb = 'PUT';
  const data = entidade;
  return send(verb, endpoint, data, config);
};
