export const REPLY_REQUEST = 'SUPORTE_REPLY_REQUEST';
export const REPLY_SUCCESS = 'SUPORTE_REPLY_SUCCESS';
export const REPLY_FAILURE = 'SUPORTE_REPLY_FAILURE';

export const TRASH_REQUEST = 'SUPORTE_TRASH_REQUEST';
export const TRASH_SUCCESS = 'SUPORTE_TRASH_SUCCESS';
export const TRASH_FAILURE = 'SUPORTE_TRASH_FAILURE';

export const LIST_REQUEST = 'SUPORTE_LIST_REQUEST';
export const LIST_SUCCESS = 'SUPORTE_LIST_SUCCESS';
export const LIST_FAILURE = 'SUPORTE_LIST_FAILURE';

export const SHOW_ENVIADOS = 'SUPORTE_SHOW_ENVIADOS';
export const SHOW_ENCERRADOS = 'SUPORTE_SHOW_ENCERRADOS';
export const SHOW_ANALISANDO = 'SUPORTE_SHOW_ANALISANDO';
export const SHOW_TRASH = 'SUPORTE_SHOW_TRASH';

export const CANCEL = 'SUPORTE_CANCEL';
