import React from 'react';
import useStyles from './styles';
import {
  FormSection,
  SectionText,
  FormSelectField,
  MoneyTextField,
} from 'components/_Common/Form';

function OpcoesCobranca(props) {
  const { pagamento, form } = props;
  const classes = useStyles();

  return (
    <FormSection classes={classes} title='Opções de pagamento'>
      <SectionText>
        Configure o tipo de pagamento, a frequência e o valor a ser pago pela
        Entidade ao Farol para utilizar a plataforma quando filiada neste plano.
      </SectionText>
      <FormSelectField
        label='Tipo do Pagamento'
        field='pagamento.tipo'
        xs={12}
        md={4}
        formik={form}
        options={['Pagamento Único', 'Plano Gratuito']}
      />

      {pagamento.tipo === 'Pagamento Recorrente' ? (
        <>
          <FormSelectField
            label='Frequência do Pagamento'
            field='pagamento.frequencia'
            xs={12}
            md={4}
            formik={form}
            options={['Mensal', 'Semestral', 'Anual']}
          />
          <MoneyTextField
            prefix='R$'
            label='Valor'
            field='valor'
            xs={12}
            md={4}
            formik={form}
          />
        </>
      ) : null}

      {pagamento.tipo === 'Pagamento Único' ? (
        <MoneyTextField
          prefix='R$'
          label='Valor'
          field='valor'
          xs={12}
          md={4}
          formik={form}
        />
      ) : null}
    </FormSection>
  );
}

export default OpcoesCobranca;
