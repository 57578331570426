import {
  SHOW_PERFIL,
  CANCEL,
  REQUEST,
  FAILURE,
  SUCCESS,
  CHANGE_VIEW,
  CHANGE_LIST,
  FILTRA_PLANO,
  FILTRO_GERENCIAL,
  FILTRO_LOCALIZACAO,
  FILTRA_STATUS,
  ORDENA,
  SHOW_SELECT,
  SET_SELECT,
  SHOW_CONFIRM,
  ADD_CREDITOS,
} from './types';
import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';

export const showPerfil = (entidade) => ({
  type: SHOW_PERFIL,
  payload: { view: 'perfil', entidade },
});
export const cancel = () => ({ type: CANCEL });
export const changeView = (view) => ({ type: CHANGE_VIEW, payload: view });

export const changeList = (listData) => ({
  type: CHANGE_LIST,
  payload: { view: 'LISTA', listData },
});

export const loadEntidades = () => {
  return sendRequest(service.list, null, false);
};

export const changeAccess = (entidade) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    // ***SERVICE CALL***
    const res = await service.changeAccess(entidade.user);
    if (res.success) {
      const { entidades } = res.data;
      entidade.user.blocked = !entidade.user.blocked;
      dispatch(feedback.success(res.msg));
      dispatch({
        type: SUCCESS,
        payload: { entidades, entidade },
      });
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: FAILURE });
    }
  };
};

export const edit = (entidade) => {
  return sendRequest(service.edit, entidade);
};

export const trash = (entidade) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    // ***SERVICE CALL***
    const res = await service.trash(entidade);
    if (res.success) {
      const { entidades } = res.data;
      dispatch({ type: CANCEL });
      dispatch(feedback.success(res.msg));
      dispatch({
        type: SUCCESS,
        payload: {
          entidades,
          entidade: {},
        },
      });
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: FAILURE });
    }
  };
};

export const del = (entidade) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    // ***SERVICE CALL***
    const res = await service.del(entidade);
    if (res.success) {
      const { entidades } = res.data;
      dispatch({ type: CANCEL });
      dispatch(feedback.success(res.msg));
      dispatch({
        type: SUCCESS,
        payload: {
          entidades,
          entidade: {},
        },
      });
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: FAILURE });
    }
  };
};

function sendRequest(serviceName, params, notif = true) {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    // ***SERVICE CALL***
    const res = await serviceName({ ...params });
    if (res.success) {
      if (notif) dispatch(feedback.success(res.data.msg));
      dispatch({ type: SUCCESS, payload: res.data });
    } else {
      if (notif) dispatch(feedback.error(res.error));
      dispatch({ type: FAILURE });
    }
  };
}

export const filtraPlano = (plano) => ({ type: FILTRA_PLANO, payload: plano });
export const filtroGerencial = (gerencial, sellers) => ({
  type: FILTRO_GERENCIAL,
  payload: { gerencial, sellers },
});

export const filtroLocalizacao = (localizacao) => ({
  type: FILTRO_LOCALIZACAO,
  payload: { localizacao },
});

export const filtraStatus = (status) => ({
  type: FILTRA_STATUS,
  payload: status,
});
export const ordena = (ordem) => ({ type: ORDENA, payload: ordem });

/**
 * Mostra check box para ações em massa
 */
export const showCheckBox = (type, value) => ({
  type: SHOW_SELECT,
  payload: { type, value },
});

/**
 * Adiciona ou remove associado da seleção
 */
export const setSelected = (associado, action) => ({
  type: SET_SELECT,
  payload: { associado, action },
});

/**
 * Mostra modal de confirmação de ação em massa
 */
export const showConfirm = (type) => ({
  type: SHOW_CONFIRM,
  payload: { type, show: true },
});

export const closeConfirm = () => ({
  type: SHOW_CONFIRM,
  payload: { type: '#', show: false },
});

/**
 * Exclui muitos associados
 * @param {array} associados
 */
export const manyTrash = (associados) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    // ***SERVICE CALL***
    const res = await service.deleteAssociados(associados);

    if (res.success) {
      dispatch(feedback.success(res.msg));
      dispatch({
        type: SUCCESS,
        payload: {
          associados: res.data.associados,
          view: 'LISTA',
        },
      });
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: FAILURE });
    }
  };
};

/**
 * Edita o nivel de varios associados
 */
export const manyEditNivel = (associados, nivel) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    // ***SERVICE CALL***
    const res = await service.editNivelAssociados(associados, nivel);
    if (res.success) {
      dispatch(feedback.success(res.msg));
      dispatch({
        type: SUCCESS,
        payload: {
          associados: res.data.associados,
          view: 'LISTA',
        },
      });
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: FAILURE });
    }
  };
};

export const addCreditos = (entidade, toAdd) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    // ***SERVICE CALL***
    const res = await service.addCreditos(entidade, toAdd);
    if (res.success) {
      const { entidades } = res.data;
      dispatch(feedback.success(res.msg));
      dispatch({ type: SUCCESS, payload: { entidades } });
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: FAILURE });
    }
  };
};
