import React from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignOutAlt,
  faUserCircle,
  faEnvelope,
  faBars,
  faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';
import Link from '@material-ui/core/Link';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import * as local from '_helpers/local';
import useStyles from './styles';

function CustomAppBar(props) {
  const { open, handleDrawerOpen, handleMobileMenuOpen } = props;
  const classes = useStyles();

  let nome = '';
  try {
    const admin = local.admin();
    nome = admin.nome;
  } catch (error) {
    console.log(error);
  }

  return (
    <AppBar
      position='fixed'
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}>
      <Toolbar disableGutters={!open}>
        <IconButton
          color='inherit'
          aria-label='Abrir Menu'
          onClick={handleDrawerOpen}
          className={clsx(classes.drawerMenuButton, {
            [classes.hide]: open,
          })}>
          <FontAwesomeIcon
            icon={faBars}
            fixedWidth
            size='sm'
            className={classes.iconBlack}
          />
        </IconButton>
        <Link to='/dashboard' component={RouterLink} underline='none'>
          <img
            src='/logo.png'
            alt='Farol Universiátio'
            width='100'
            className={classes.logo}
          />
        </Link>
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          <Link to='/suporte' component={RouterLink} underline='none'>
            <IconButton color='inherit'>
              <Badge badgeContent={props.newTickets} color='secondary'>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  fixedWidth
                  size='sm'
                  className={classes.iconBlack}
                />
              </Badge>
            </IconButton>
          </Link>

          <Link to='/conta' component={RouterLink} underline='none'>
            <IconButton className={classes.iconBlack} color='inherit'>
              <FontAwesomeIcon icon={faUserCircle} fixedWidth size='sm' />
            </IconButton>
          </Link>

          <Link to='/conta' component={RouterLink} underline='none'>
            <IconButton className={classes.apelido} color='inherit'>
              {nome || null}
            </IconButton>
          </Link>

          <IconButton
            className={classes.iconBlack}
            color='inherit'
            onClick={() => props.history.push('/')}>
            <FontAwesomeIcon icon={faSignOutAlt} fixedWidth size='sm' />
          </IconButton>
        </div>
        <div className={classes.sectionMobile}>
          <IconButton
            className={classes.iconBlack}
            aria-haspopup='true'
            onClick={handleMobileMenuOpen}
            color='inherit'>
            <FontAwesomeIcon icon={faEllipsisV} fixedWidth size='sm' />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default withRouter(CustomAppBar);
