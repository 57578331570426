/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useSelector, useActions } from 'hooks';
import * as Actions from 'components/Cancelamentos/redux/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSearch, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { onlyNumbers, formataData } from '_helpers/utils';

import useStyles from './styles';

function Cancelamentos(props) {
  const classes = useStyles();
  const [numero, setNumero] = useState('');
  const actions = useActions(Actions);
  const { pedido, loading } = useSelector('cancelamentos');

  console.log(pedido);

  const renderPedido = () => {
    if (!pedido) return null;
    const {
      numero,
      emitido_por,
      vencimento,
      descricao,
      valor,
      ip,
      metodo,
      status,
      pagoEm,
      bandeira,
      parcelas,
      cupom,
      desconto,
      valorTaxa,
      valorTransacao,
      valorTaxaAdm,
      valorRecebido,
      valorPago,
      createdAt,
    } = pedido;

    return (
      <div className={classes.containerPedido}>
        <div className={classes.top}>
          <div className={classes.pedido}>
            <div className={classes.box}>
              <div className={classes.label}>Pedido:</div>
              <div className={classes.info}> #{numero}</div>
            </div>
            <div className={classes.box}>
              <div className={classes.label}>Status:</div>
              <div className={classes.info}> {status}</div>
            </div>

            <div className={classes.box}>
              <div className={classes.label}>Valor Total: </div>
              <div className={classes.info}> R$ {valor.toFixed(2)}</div>
            </div>

            <div className={classes.box}>
              <div className={classes.label}>Método:</div>
              <div className={classes.info}> {metodo}</div>
            </div>

            {metodo === 'Cartão' && (
              <div className={classes.box}>
                <div className={classes.label}>Bandeira: </div>
                <div className={classes.info}> {bandeira}</div>
              </div>
            )}

            <div className={classes.box}>
              <div className={classes.label}>Vencimento:</div>
              <div className={classes.info}> {formataData(vencimento)}</div>
            </div>

            <div className={classes.box}>
              <div className={classes.label}>Criado em:</div>
              <div className={classes.info}> {formataData(createdAt)}</div>
            </div>

            <div className={classes.box}>
              <div className={classes.label}>Emitido Por:</div>
              <div className={classes.info}> {emitido_por}</div>
            </div>
          </div>

          <div className={classes.detalhes}>
            <div className={classes.box}>
              <div className={classes.label}>Pagamento:</div>
              <div className={classes.info}> {formataData(pagoEm)}</div>
            </div>

            <div className={classes.box}>
              <div className={classes.label}>Desconto: </div>
              <div className={classes.info}> R$ {desconto.toFixed(2)}</div>
            </div>

            <div className={classes.box}>
              <div className={classes.label}>Cupom: </div>
              <div className={classes.info}>{cupom}</div>
            </div>

            <div className={classes.box}>
              <div className={classes.label}>Valor Pago: </div>
              <div className={classes.info}> R$ {valorPago.toFixed(2)}</div>
            </div>

            <div className={classes.box}>
              <div className={classes.label}>Valor Recebido: </div>
              <div className={classes.info}> R$ {valorRecebido.toFixed(2)}</div>
            </div>

            <div className={classes.box}>
              <div className={classes.label}>Taxa: </div>
              <div className={classes.info}> R$ {valorTaxa.toFixed(2)}</div>
            </div>

            <div className={classes.box}>
              <div className={classes.label}>Transação: </div>
              <div className={classes.info}> R$ {valorTransacao.toFixed(2)}</div>
            </div>

            <div className={classes.box}>
              <div className={classes.label}>Taxa Administrativa: </div>
              <div className={classes.info}> R$ {valorTaxaAdm.toFixed(2)}</div>
            </div>
            <div className={classes.box}>
              <div className={classes.label}>Parcelas: </div>
              <div className={classes.info}>{parcelas}</div>
            </div>

            <div className={classes.box}>
              <div className={classes.label}>Método: </div>
              <div className={classes.info}>{metodo}</div>
            </div>

            <div className={classes.box}>
              <div className={classes.label}>IP:</div>
              <div className={classes.info}> {ip}</div>
            </div>

            <div className={classes.box}>
              <div className={classes.label}>Descrição:</div>
              <div className={classes.info}> {descricao}</div>
            </div>
          </div>
        </div>

        <div className={classes.bottom}>
          <div className={classes.helper}>
            Atenção: Estorno do valor apenas via dashboard do gateway de pagamento!
          </div>
          <div className={classes.buttonCancel}>
            <Button
              size='large'
              variant='contained'
              disabled={!pedido || loading}
              onClick={() => actions.cancelaPedido(pedido)}
              color='secondary'
              startIcon={
                loading ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className={classes.loading}
                    fixedWidth
                    size='lg'
                    spin
                  />
                ) : (
                  <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth size='lg' />
                )
              }>
              cancelar
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.findText}>Cancelamento de pedidos</div>
      <div className={classes.boxFind}>
        <TextField
          name='find pedido'
          // helperText={touched ? error : helperText}
          // error={Boolean(touched) && Boolean(error)}
          label='Número do Pedido'
          value={numero}
          onChange={(e) => setNumero(onlyNumbers(e.target.value))}
          variant='outlined'
          type='tel'
          placeholder={props.placeholder}
          className={classes.textField}
        />
        <Button
          size='large'
          variant='contained'
          disabled={numero.length < 4}
          onClick={() => actions.getPedido(numero)}
          color='primary'
          className={classes.button}
          startIcon={
            loading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                className={classes.loading}
                fixedWidth
                size='lg'
                spin
              />
            ) : (
              <FontAwesomeIcon icon={faSearch} fixedWidth size='lg' />
            )
          }>
          Buscar
        </Button>
      </div>
      {renderPedido()}
    </div>
  );
}

export default Cancelamentos;
