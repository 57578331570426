export function getComprador(pedido) {
  let comprador = {
    nome: '',
    email: '',
    cpf: '',
    avatar: '',
    type: '',
  };
  if (pedido?.entidade?.nome) {
    comprador.nome = pedido.entidade?.nome;
    comprador.cpf = pedido.entidade?.apelido;
    comprador.email = pedido.entidade?.user?.email;
    comprador.avatar = pedido.entidade?.avatar;
    comprador.type = 'Entidade';
  } else if (pedido?.associado?.nome) {
    comprador.nome = pedido.associado?.nome;
    comprador.cpf = pedido.associado?.cpf;
    comprador.email = pedido.associado?.user?.email;
    comprador.avatar = pedido.associado?.avatar;
    comprador.type = 'Associado';
  } else if (pedido?.cliente?.nome) {
    comprador = pedido?.cliente;
    comprador.email = pedido?.cliente?.email;
    comprador.cpf = pedido?.cliente?.taxID;
    comprador.avatar = '';
    comprador.type = 'Cliente';
  } else {
    comprador.nome = '[ENTIDADE EXCLUÍDA PERMANENTEMENTE]';
    comprador.type = 'Indefinido';
  }
  return comprador;
}
