/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

function SimpleModal(props) {
  const { title, button, background } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {open && (
        <Dialog
          open={open}
          keepMounted
          fullScreen={fullScreen}
          onClose={() => setOpen(false)}
          maxWidth={'lg'}
          fullWidth
          aria-labelledby='dm-title'
          aria-describedby='dm-desc'>
          <DialogTitle className={classes.modalTitle}>{title}</DialogTitle>
          <DialogContent className={classes.modalBg} style={{ background }}>
            {props.children}
          </DialogContent>
          <DialogActions className={classes.modalBg}>
            <Button
              size='large'
              variant='contained'
              color='secondary'
              onClick={() => setOpen(false)}>
              fechar
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Button
        disabled={props?.disabled?.value ?? false}
        size={button?.size || 'large'}
        fullWidth={button?.fullWidth || true}
        variant={button?.variant || 'contained'}
        onClick={() => setOpen(true)}
        color={button?.color}
        className={button?.className}
        startIcon={button?.startIcon && <FontAwesomeIcon icon={button.startIcon} />}
        endIcon={button?.endIcon && <FontAwesomeIcon icon={button.endIcon} />}>
        {props?.disabled?.value ? props?.disabled?.text : button?.text}
      </Button>
    </>
  );
}

export default SimpleModal;
