import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import validationSchema from './validation';
import Form from './form';
import TitleBar from 'components/_Common/TitleBar';
import { formataData, formataHMS } from '_helpers/utils';
import * as local from '_helpers/local';
import styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

function Perfil(props) {
  window.scrollTo(0, 0);
  const { classes } = props;
  const admin = local.admin();

  return (
    <div className={classes.container}>
      <Paper className={classes.profile}>
        <div className={classes.avatar}>
          <FontAwesomeIcon
            icon={faUser}
            className={classes.avatarPlaceholder}
            fixedWidth
            size="4x"
          />
        </div>
        <Typography variant="h5" className={classes.nome} align="center">
          {admin.nome}
        </Typography>
        <Typography variant="h6" className={classes.nivel} align="center">
          Administrador do Sistema
        </Typography>
        <Typography variant="caption" className={classes.nivel} align="center">
          Farol Universitário
        </Typography>

        <Divider variant="middle" />

        <Typography
          variant="caption"
          className={classes.created}
          align="center">
          Última atualização:{' '}
          {`${formataData(admin.updatedAt)} às ${formataHMS(admin.updatedAt)}`}
        </Typography>

        <Typography
          variant="caption"
          className={classes.created}
          align="center"
          gutterBottom>
          Administrador desde{' '}
          {`${formataData(admin.createdAt)} às ${formataHMS(admin.createdAt)}`}
        </Typography>
      </Paper>

      <Paper className={classes.edit}>
        <TitleBar title="Meus Dados" />
        <Formik
          render={(props) => <Form {...props} />}
          initialValues={admin}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            props.actions.edit(values);
          }}
        />
      </Paper>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(Perfil));
