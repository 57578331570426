import { LOGIN_REQUEST, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from './types';

import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';
import { loadAssociados } from 'components/Associados/redux/actions';
import { loadPlanos } from 'components/Planos/redux/actions';
import { loadEntidades } from 'components/Entidades/redux/actions';
import { loadParceiros } from 'components/Parceiros/redux/actions';
import { loadSellers } from 'components/Sellers/redux/actions';
import { listPedidos } from 'components/Financeiro/redux/actions';

const request = () => ({ type: LOGIN_REQUEST });
const success = () => ({ type: LOGIN_SUCCESS });
const failure = () => ({ type: LOGIN_FAILURE });

export const login = (credentials) => {
  const { email, password } = credentials;
  return async (dispatch) => {
    dispatch(request());
    const res = await service.login(email, password);
    if (res.success) {
      dispatch(success());
      dispatch(loadAssociados());
      dispatch(loadPlanos());
      dispatch(loadEntidades());
      dispatch(loadParceiros());
      dispatch(loadSellers());
      dispatch(listPedidos());
    } else {
      dispatch(feedback.warning(res.error));
      dispatch(failure(res.error));
    }
  };
};

export const logout = () => {
  // service.logout();
  // if (window.location.pathname !== '/') window.location.reload();
  return { type: LOGOUT };
};
