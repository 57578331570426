export default (theme) => ({
  pullLeft: {
    position: 'absolute',
    left: theme.spacing(2),
  },

  container: {
    marginBottom: '8em',
  },
});
