import React from 'react';

function Type(props) {
  const { seller } = props;
  if (seller.type === 'individual') {
    return <>CPF {seller.taxpayer_id}</>;
  }
  return <>CNPJ {seller.ein}</>;
}

export default Type;
