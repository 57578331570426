import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './redux/actions';
import DashContainer from 'components/_Common/DashContainer';
import DashCard from 'components/_Common/DashCard';
import {
  faUsers,
  faGem,
  faUserEdit,
  faFileInvoiceDollar,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons';

function Dashboard() {
  const actions = bindActionCreators(Actions, useDispatch());

  const { entidades, entidade } = useSelector(({ entidades }) => ({
    ...entidades,
  }));

  return (
    <DashContainer>
      <DashCard
        info="Entidades"
        data={entidades.length}
        text="voltar"
        icon={faUserFriends}
        action={actions.cancel}
      />
      <DashCard
        info="Associados"
        data={entidade.qtyAssociados}
        text="ver associados"
        icon={faUsers}
        action={() => actions.changeView('associados')}
      />
      <DashCard
        info="Planos"
        data={entidade.qtyPlanos}
        text="ver planos"
        icon={faGem}
        action={() => actions.changeView('planos')}
      />
      <DashCard
        info="Informações financeiras"
        text="gerenciar financeiro"
        icon={faFileInvoiceDollar}
        action={() => actions.changeView('financeiro')}
      />

      <DashCard
        info="Perfil completo"
        text="ver ou editar"
        icon={faUserEdit}
        action={() => actions.showPerfil(entidade)}
      />
    </DashContainer>
  );
}

export default Dashboard;
