/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as EntActions from '../redux/actions';
import * as FinActions from './redux/actions';
import DashContainer from 'components/_Common/DashContainer';
import DashCard from 'components/_Common/DashCard';
import {
  faUsers,
  faGem,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons';
import Sellers from './Sellers';

function Dashboard(props) {
  const entActions = bindActionCreators(EntActions, useDispatch());
  const finActions = bindActionCreators(FinActions, useDispatch());

  const { entidade } = useSelector(({ entidades }) => ({
    ...entidades,
  }));

  const { view, sellers } = useSelector(({ financeiroEntidade }) => ({
    ...financeiroEntidade,
  }));

  useEffect(() => {
    finActions.listSellers(entidade);
  }, []);

  const selectView = () => {
    switch (view) {
      case 'sellers':
        return <Sellers />;
      default:
        return <Sellers />;
    }
  };

  return (
    <>
      <DashContainer>
        <DashCard
          info='Voltar para perfil da Entidade'
          text='perfil'
          icon={faUserFriends}
          action={entActions.cancel}
        />
        <DashCard
          info='Contas Digitais'
          data={sellers.length}
          text='ver contas'
          icon={faUserFriends}
          action={() => finActions.changeView('sellers')}
        />
        <DashCard
          info='Movimentações'
          data={entidade.qtyAssociados}
          text='ver extrato'
          icon={faUsers}
          action={() => finActions.changeView('movimentacoes')}
        />
        <DashCard
          info='Cobranças'
          data={entidade.qtyPlanos}
          text='emitir cobrança'
          icon={faGem}
          action={() => finActions.changeView('cobranca')}
        />
      </DashContainer>
      {selectView()}
    </>
  );
}

export default Dashboard;
