import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import {
  FormSection,
  SectionText,
  FormTextField,
  FormSelectField,
  MoneyTextField,
  CancelarOuSalvar,
  DateField
} from "components/_Common/Form";
import { formataValor, formataDataInput } from "_helpers/utils";

const Form = props => {
  const {
    values: { tipo },
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleCancel,
    setFieldTouched,
    setFieldValue,
    isValid,
    classes
  } = props;

  const change = (name, e) => {
    e.persist();
    const value = e.target.value;
    setFieldTouched(name, true, false);
    if (name === "desconto") {
      setFieldValue(name, formataValor(value, 5));
    } else if (name === "codigo") {
      setFieldValue(name, value.toUpperCase().slice(0, 12));
    } else {
      handleChange(e);
    }
  };

  const saveProps = {
    isValid,
    handleSubmit,
    handleCancel
  };

  const formProps = {
    errors,
    touched,
    values: props.values,
    change,
    setFieldTouched,
    setFieldValue
  };

  const desconto = () => {
    if (tipo === "Porcentagem") {
      return (
        <MoneyTextField
          prefix="%"
          label="Desconto"
          field="desconto"
          xs={12}
          md={4}
          formik={formProps}
        />
      );
    }

    if (tipo === "Valor Fixo") {
      return (
        <MoneyTextField
          prefix="R$"
          label="Desconto"
          field="desconto"
          xs={12}
          md={4}
          formik={formProps}
        />
      );
    }
  };

  return (
    <form>
      <CancelarOuSalvar save={saveProps} />

      <FormSection classes={classes} title={props.title}>
        <SectionText>
          <strong>Indisponínel para edição</strong>
        </SectionText>
        <FormTextField
          disabled={true}
          label="Código do Cupom"
          field="codigo"
          xs={12}
          md={12}
          formik={formProps}
        />
      </FormSection>

      <FormSection classes={classes} title="Desconto">
        <FormSelectField
          label="Tipo do desconto"
          field="tipo"
          xs={12}
          md={4}
          formik={formProps}
          options={["Porcentagem", "Valor Fixo"]}
        />
        {desconto()}
      </FormSection>

      <FormSection classes={classes} title="Plano">
        <SectionText>
          <strong>Indisponínel para edição</strong>
        </SectionText>
        <FormTextField
          disabled={true}
          label=""
          field="plano"
          xs={12}
          md={12}
          formik={formProps}
        />
      </FormSection>

      <FormSection classes={classes} title="Quantidade">
        <SectionText>
          Configure quantas vezes este cupom pode ser utilizado.
        </SectionText>

        <FormTextField
          label="Quantidade"
          field="quantidade"
          xs={12}
          md={12}
          formik={formProps}
          type="tel"
        />
      </FormSection>

      <FormSection classes={classes} title="Validade">
        <SectionText>
          Defina até quando este cupom pode ser resgatado.
        </SectionText>
        <DateField
          label="Validade"
          field="validade"
          xs={12}
          md={12}
          formik={formProps}
          minDate={new Date()}
        />
      </FormSection>

      <CancelarOuSalvar save={saveProps} />
    </form>
  );
};

export default withStyles(styles)(Form);
