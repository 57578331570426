import React from 'react';
import ID from './ID';
import CNPJ from './CNPJ';
import Address from './Address';
import useStyles from './styles';

const Documentos = (props) => {
  const { seller } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ID seller={seller} />
      <CNPJ seller={seller} />
      <Address seller={seller} />
    </div>
  );
};

export default Documentos;
