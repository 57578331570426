import React from 'react';
import useStyles from './styles';
import {
  FormSection,
  SectionText,
  FormTextField,
  FormSelectField,
  DateField,
} from 'components/_Common/Form';

function DuracaoAssinatura(props) {
  const { assinatura, pagamento, form } = props;
  const classes = useStyles();
  const disabled = pagamento.tipo === 'Plano Gratuito';

  return (
    <FormSection classes={classes} title='Duração da assinatura'>
      <SectionText>
        Defina por quanto tempo este plano e seus benefícios serão válidos para
        a Entidade. O período começa a valer a quando o pagamento da Entidade
        for confirmado.
      </SectionText>

      <FormSelectField
        disabled={disabled}
        label='Duração do Plano'
        field='assinatura.tipo'
        xs={12}
        md={4}
        formik={form}
        options={[
          'Mensal',
          'Bimestral',
          'Trimestral',
          'Semestral',
          'Anual',
          'Personalizado',
          'Data Fixa',
          'Até que o usuário cancele',
        ]}
      />

      {assinatura.tipo === 'Personalizado' ? (
        <FormTextField
          disabled={disabled}
          label='Duração em Meses'
          field='assinatura.duracao'
          xs={12}
          md={4}
          formik={form}
          type='tel'
        />
      ) : null}

      {assinatura.tipo === 'Data Fixa' ? (
        <DateField
          disabled={disabled}
          label='Valido até'
          field='assinatura.validade'
          xs={12}
          md={4}
          formik={form}
          type='tel'
        />
      ) : null}
    </FormSection>
  );
}

export default DuracaoAssinatura;
