export default (theme) => ({
  avatar: {
    width: '40px',
    height: 'auto',
  },

  center: {
    color: 'white',
    textAlign: 'center',
  },
});
