import React from 'react';
import useStyles from './styles';
import useImageStyles from './imgStyles';
import { FormTextField, DateField, MoneyTextField, FormSelectField } from 'components/_Common/Form';
import Submit, { Save, Cancel } from 'components/_Common/Submit';
import Editor from 'components/_Common/WysiwygEditor';
import ValidaLink from './ValidaLink';
import { useSelector } from 'hooks';
import Grid from '@material-ui/core/Grid';
import ImageUpload from 'components/_Common/ImageUpload';
import categorias from './categorias';
import Switch from 'components/_Common/SimpleSwitch';
import { formataValor } from '_helpers/utils';

function Form(props) {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleCancel,
    setFieldTouched,
    setFieldValue,
    isValid,
    isEdit,
  } = props;

  console.log(values);

  const classes = useStyles();
  const imgClasses = useImageStyles();
  const { loader, linkIsUnique } = useSelector('linkCobranca');
  const loading = isEdit ? loader.edit : loader.create;

  const change = (name, e) => {
    e.persist();
    const value = e.target.value;
    setFieldTouched(name, true, false);
    if (name === 'url') {
      let url = value.replace(/[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]\-/gi, '');
      if (url[0] !== '-' && url[url.length - 1] !== 0) {
        setFieldValue(name, url);
      }
    } else if (name === 'valor' || name === 'valorMinimo') {
      setFieldValue(name, formataValor(value, 6));
    } else if (name === 'metaArrecadacao') {
      setFieldValue(name, formataValor(value, 6));
    } else {
      handleChange(e);
    }
  };

  const handleSwitchPagador = () => {
    setFieldTouched('pagadorDefine', true, false);
    setFieldValue('pagadorDefine', !values.pagadorDefine);
  };

  const handleExibeTotal = () => {
    setFieldTouched('exibeTotal', true, false);
    setFieldValue('exibeTotal', !values.exibeTotal);
  };

  const handleCartao = () => {
    setFieldTouched('cartao', true, false);
    setFieldValue('cartao', !values.cartao);
  };

  const handleBoleto = () => {
    setFieldTouched('boleto', true, false);
    setFieldValue('boleto', !values.boleto);
  };

  const formProps = {
    errors,
    touched,
    values: props.values,
    change,
    setFieldTouched,
    setFieldValue,
  };

  if (!linkIsUnique) {
    return <ValidaLink form={formProps} />;
  }

  return (
    <form>
      <div className={classes.seuLink}>farol.fun/checkout/{values.url}</div>
      <Grid container spacing={2} className={classes.formGrid}>
        <Grid container spacing={2} className={classes.topFields}>
          <FormTextField
            label='O que você está cobrando?'
            field='titulo'
            xs={12}
            md={8}
            placeholder='Título ou Nome do produto / serviço'
            formik={formProps}
          />

          <FormSelectField
            label='Categoria'
            field='categoria'
            xs={12}
            md={4}
            formik={formProps}
            options={categorias}
            disabled={isEdit}
          />

          <FormTextField
            label='Descritivo do Pedido'
            field='descricaoPedido'
            xs={12}
            md={8}
            placeholder='Texto curto descritivo no Pedido (controle interno)'
            formik={formProps}
          />

          <DateField
            label='Validade do Link'
            field='validade'
            xs={12}
            md={4}
            formik={formProps}
            type='tel'
          />
        </Grid>

        <div className={classes.imagem}>
          <ImageUpload
            field='imagem'
            alt='preview-image'
            formik={{ ...formProps }}
            show
            styles={imgClasses}
          />
        </div>

        <div className={classes.editor}>Descrição personalizada</div>

        <Editor
          field='descricao'
          value={values.descricao}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
        />

        <div className={classes.switches}>
          <div className={classes.switchBox}>
            <div className={classes.switch}>
              <Switch
                label='Aceitar pagamentos por Cartão'
                field='cartao'
                value={values.cartao}
                color='secondary'
                action={handleCartao}
              />
            </div>
            <div className={classes.switchHelper}>
              Habilita a opção de parcelamento. Note que a parcela mínima é R$ 6.00.
            </div>
          </div>

          <div className={classes.switchBox}>
            <div className={classes.switch}>
              <Switch
                label='Aceitar pagamentos por Boleto'
                field='boleto'
                value={values.boleto}
                color='secondary'
                action={handleBoleto}
              />
            </div>
            <div className={classes.switchHelper}>
              Compras por Boleto sempre serão sempre à vista.
            </div>
          </div>

          <div className={classes.switchBox}>
            <div className={classes.switch}>
              <Switch
                label='Exibir valor total arrecadado'
                field='exibeTotal'
                value={values.exibeTotal}
                color='secondary'
                action={handleExibeTotal}
                disabled={isEdit}
              />
            </div>
            <div className={classes.switchHelper}>
              Exibe o total arrecadado ná página do link / checkout. Útil para transparência em
              campanhas de arrecadação e ações sociais. Habilita a opção de cobrar Taxa
              Administrativa (não obrigatória).
            </div>
          </div>

          <div className={classes.switchBox}>
            <div className={classes.switch}>
              <Switch
                label='O pagador vai definir o valor do pagamento'
                field='pagadorDefine'
                value={values.pagadorDefine}
                color='secondary'
                action={handleSwitchPagador}
                disabled={isEdit}
              />
            </div>
            <div className={classes.switchHelper}>
              Permite que o pagador defina o valor do pagamento no momento do checkout. Ao habilitar
              esta opção, você deve informar um valor mínimo que aceitará receber através deste
              link.
            </div>
          </div>
        </div>

        <Grid container spacing={2}>
          {values.pagadorDefine && (
            <MoneyTextField
              prefix='R$'
              label='Valor Mínimo'
              field='valorMinimo'
              placeholder='Valor Mínimo que o comprador pode pagar'
              xs={12}
              md={6}
              formik={formProps}
            />
          )}
          {!values.pagadorDefine && (
            <MoneyTextField
              prefix='R$'
              label='Valor da Cobrança'
              placeholder='O Valor que o comprador deverá pagar'
              field='valor'
              xs={12}
              md={6}
              formik={formProps}
            />
          )}
          {values.cartao && (
            <FormSelectField
              label='Parcelamento'
              placeholder='Apenas no Cartão'
              field='parcelas'
              xs={12}
              md={3}
              formik={formProps}
              options={[
                { label: 'À vista', value: 1 },
                { label: 'Até 2x', value: 2 },
                { label: 'Até 3x', value: 3 },
                { label: 'Até 4x', value: 4 },
                { label: 'Até 5x', value: 5 },
                { label: 'Até 6x', value: 6 },
              ]}
            />
          )}
          {values.exibeTotal && (
            <>
              <MoneyTextField
                prefix='%'
                label='Taxa Administrativa'
                placeholder='0'
                field='taxaAdm'
                xs={12}
                md={3}
                formik={formProps}
              />
              <MoneyTextField
                prefix='R$'
                label='Meta De Arrecadação'
                field='metaArrecadacao'
                placeholder='Meta De Arrecadação'
                xs={12}
                md={6}
                formik={formProps}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Submit>
        <Cancel loading={loading} cancel={handleCancel} />
        <Save isValid={isValid} loading={loading} submit={handleSubmit} />
      </Submit>
    </form>
  );
}

export default Form;
