import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';

const ExcluirDialog = (props) => {
  const { actions, notificacao, open } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={actions.cancel}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          Excluir a notificação?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            A notificação não será mais exibida e nem permanecerá no histórico.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={actions.cancel} color="primary" autoFocus>
            Cancelar
          </Button>
          <Button onClick={() => actions.trash(notificacao)} color="secondary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  const store = state.notificacoes;
  return {
    notificacao: store.notificacao,
    showTrash: store.showExcluir,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileDialog()(ExcluirDialog));
