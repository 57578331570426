import React from 'react';
import { Formik } from 'formik';
import validationSchema from './validation';
import { withStyles } from '@material-ui/core/styles';
import { formataData } from '_helpers/utils';
import * as Actions from '../redux/actions';
import { useActions, useSelector } from 'hooks';
import styles from './styles';
import Form from './form';

const NovoCupom = props => {
  window.scrollTo(0, 0);
  const { classes } = props;
  const actions = useActions(Actions);
  const { planos } = useSelector('cupons');

  return (
    <div className={classes.formContainer}>
      <Formik
        render={props => (
          <Form
            title='Novo Cupom'
            handleCancel={actions.cancel}
            planos={planos}
            {...props}
          />
        )}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={values => {
          actions.create(values);
        }}
      />
    </div>
  );
};

const values = {
  plano: '',
  codigo: '',
  tipo: '',
  desconto: '',
  quantidade: '',
  validade: '',
  status: '',
};

export default withStyles(styles)(NovoCupom);
