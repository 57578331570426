import { send } from '_helpers/api.config';

export const login = async (email, password) => {
  try {
    await send('POST', '/api/login', { email, password });
    return { success: true };
  } catch (error) {
    return erroHandler(error);
  }
};

export const logout = () => {
  // remove user from local storage to log user out
  localStorage.clear();
};

function erroHandler(error) {
  const { response } = error;
  console.log(error.response);
  if (!response) {
    return { error: 'Tente novamente em alguns instantes' };
  }
  if (response.status === 400) {
    return { error: response.data.error };
  }
  // auto logout if 401 response returned from api
  if (response.status === 401) {
    logout();
    window.location.reload(true);
  }
  return false;
}
