/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import MuiButton from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function ButtonLabel(props) {
  const { disabled, loading, label, icon, args, onClick, iconPosition } = props;

  const handleClick = useCallback(() => {
    if (args) {
      onClick({ ...args });
    } else {
      onClick();
    }
  }, [onClick, args]);

  const spinner = () => (
    <FontAwesomeIcon icon={faSpinner} fixedWidth size='lg' pulse style={{ marginRight: '0.5em' }} />
  );

  const labelIcon = () => {
    if (icon) {
      return (
        <>
          {iconPosition === 'after' ? label : null}
          <FontAwesomeIcon icon={icon} fixedWidth size='lg' style={{ marginRight: '0.5em' }} />
          {!iconPosition || iconPosition !== 'after' ? label : null}
        </>
      );
    }
    return label;
  };

  return (
    <Tooltip placement='top' title={props.tooltip || label}>
      <MuiButton
        disabled={disabled || loading}
        size={props.size || 'large'}
        fullWidth={props.fullWidth || true}
        variant={props.variant || 'contained'}
        onClick={handleClick}
        color={props.color || 'primary'}
        className={props.className}>
        {loading ? spinner() : labelIcon()}
      </MuiButton>
    </Tooltip>
  );
}
