export const REQUEST = 'ASSOCIADO_REQUEST';
export const SUCCESS = 'ASSOCIADO_SUCCESS';
export const FAILURE = 'ASSOCIADO_FAILURE';

export const SHOW_LISTA = 'ASSOCIADO_SHOW_LISTA';
export const SHOW_PERFIL = 'ASSOCIADO_SHOW_PERFIL';
export const SHOW_EDIT = 'ASSOCIADO_SHOW_EDIT';
export const CHANGE_LIST = 'ASSOCIADO_CHANGE_LIST';

export const FILTRO_CURSO = 'ASSOCIADO_FILTRO_CURSO';
export const FILTRO_LOCALIZACAO = 'ASSOCIADO_FILTRO_LOCALIZACAO';
export const ORDENA = 'ASSOCIADO_ORDENA';

export const CANCEL = 'ASSOCIADO_CANCEL';
