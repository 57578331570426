/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useStyles from './styles';
import { formataData } from '_helpers/utils';
import { formatCpf } from '@brazilian-utils/formatters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faIdCard,
  faSignInAlt,
  faUserTie,
  faAt,
} from '@fortawesome/free-solid-svg-icons';

const DadosRepresentante = (props) => {
  const { entidade } = props;
  const {
    representante: { nome, email, cpf },
  } = entidade;

  const classes = useStyles();

  return (
    <div className={classes.dadosRepresentante}>
      <div className={classes.dado}>
        <FontAwesomeIcon
          icon={faUserTie}
          className={classes.icon}
          fixedWidth
          size='sm'
        />
        {nome}
      </div>

      <div className={classes.dado}>
        <FontAwesomeIcon
          icon={faAt}
          className={classes.icon}
          fixedWidth
          size='sm'
        />
        {email}
      </div>

      <div className={classes.dado}>
        <FontAwesomeIcon
          icon={faIdCard}
          className={classes.icon}
          fixedWidth
          size='sm'
        />
        {formatCpf(cpf)}
      </div>

      <div className={classes.dado}>
        <FontAwesomeIcon
          icon={faSignInAlt}
          className={classes.icon}
          fixedWidth
          size='sm'
        />
        {formataData(entidade.createdAt)}
      </div>
    </div>
  );
};
export default DadosRepresentante;
