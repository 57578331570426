/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useStyles from './styles';
import { useSelector, useActions, useFetch } from 'hooks';
import * as Actions from './redux/actions';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Novo from './Novo';
import MeusLinks from './MeusLinks';
import { Tab, TabBar } from 'components/_Common/TabBar';
import Loader from 'components/_Common/Loader';
import Editar from './Editar';
import Expirados from './Expirados';
import Pagamentos from './Pagamentos';

function LinkCobranca(props) {
  const classes = useStyles();
  const actions = useActions(Actions);
  const { view, views, links, loader } = useSelector('linkCobranca');
  useFetch({ links });
  const { pedidos } = useSelector('financeiro');
  useFetch({ pedidos });
  const { changeView } = actions;

  const expirados = links.filter((l) => !l.status);
  const ativos = links.filter((l) => l.status);

  const vendas = pedidos.filter((p) => p.emitido_por === 'Link Pagamento');

  const showView = () => {
    if (loader.list) return <Loader />;
    switch (view) {
      case 'NOVO':
        return <Novo />;
      case 'MEUS LINKS':
        return <MeusLinks links={ativos} />;
      case 'PAGAMENTOS':
        return <Pagamentos pedidos={vendas} />;
      case 'EXPIRADOS':
        return <Expirados links={expirados} />;
      case 'EDITAR':
        return <Editar />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.container}>
      <TabBar active={views.findIndex((v) => v === view)}>
        <Tab
          label='Ativos'
          icon={faPlus}
          data={ativos.length}
          action={() => changeView('MEUS LINKS')}
        />
        <Tab
          label='Pagamentos Recebidos'
          icon={faPlus}
          data={vendas.length}
          action={() => changeView('PAGAMENTOS')}
        />
        <Tab
          label='Inativos'
          icon={faPlus}
          data={expirados.length}
          action={() => changeView('EXPIRADOS')}
        />
        <Tab label='Criar Link' icon={faPlus} action={() => changeView('NOVO')} />
      </TabBar>

      {showView()}
    </div>
  );
}

export default LinkCobranca;
