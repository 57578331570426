import {
  SHOW_CREATE,
  SHOW_COPY,
  CANCEL,
  REQUEST,
  SUCCESS,
  FAILURE,
  SHOW_EDIT,
  SHOW_TRASH,
  COMPACT,
} from './types';
import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';

export const cancel = () => ({ type: CANCEL });
export const showCreate = () => ({ type: SHOW_CREATE });
export const showCopy = (plano) => ({ type: SHOW_COPY, payload: plano });
export const showEdit = (plano) => ({ type: SHOW_EDIT, payload: plano });
export const showTrash = (plano) => ({ type: SHOW_TRASH, payload: plano });
export const compact = () => ({ type: COMPACT });

export const loadPlanos = () => {
  const loading = { get: true };
  return async (dispatch) => {
    dispatch({ type: REQUEST, payload: { loading } });
    // ***SERVICE CALL***
    const res = await service.list();
    if (res.success) {
      const { planos } = res.data;
      dispatch({ type: SUCCESS, payload: { planos } });
    } else {
      dispatch({ type: FAILURE });
    }
  };
};

export const create = (plano) => {
  const loading = { create: true };
  return async (dispatch) => {
    dispatch({ type: REQUEST, payload: { loading } });
    // ***SERVICE CALL***
    const res = await service.create(plano);
    if (res.success) {
      const { planos } = res.data;
      dispatch({ type: SUCCESS, payload: { planos } });
      dispatch(feedback.success(res.data.msg));
    } else {
      dispatch({ type: FAILURE });
      dispatch(feedback.error(res.error));
    }
  };
};

export const edit = (plano) => {
  const loading = { edit: true };
  return async (dispatch) => {
    dispatch({ type: REQUEST, payload: { loading } });
    // ***SERVICE CALL***
    const res = await service.edit(plano);
    if (res.success) {
      const { planos } = res.data;
      dispatch({ type: SUCCESS, payload: { planos } });
      dispatch(feedback.success(res.data.msg));
    } else {
      dispatch({ type: FAILURE });
      dispatch(feedback.error(res.error));
    }
  };
};

export const trash = (plano) => {
  const loading = { id: plano._id };
  return async (dispatch) => {
    dispatch({ type: REQUEST, payload: { loading } });
    // ***SERVICE CALL***
    const res = await service.trash(plano);
    if (res.success) {
      const { planos } = res.data;
      dispatch({ type: SUCCESS, payload: { planos } });
      dispatch(feedback.success(res.data.msg));
    } else {
      dispatch({ type: FAILURE });
      dispatch(feedback.error(res.error));
    }
  };
};

export const mudarDestaque = (plano) => {
  const loading = { id: plano._id };
  return async (dispatch) => {
    dispatch({ type: REQUEST, payload: { loading } });
    // ***SERVICE CALL***
    const res = await service.destaque(plano);
    if (res.success) {
      const { planos } = res.data;
      dispatch({ type: SUCCESS, payload: { planos } });
      dispatch(feedback.success(res.data.msg));
    } else {
      dispatch({ type: FAILURE });
      dispatch(feedback.error(res.error));
    }
  };
};

export const mudarStatus = (plano) => {
  const loading = { id: plano._id };
  return async (dispatch) => {
    dispatch({
      type: REQUEST,
      payload: { loading },
    });
    // ***SERVICE CALL***
    const res = await service.mudarStatus(plano);
    if (res.success) {
      const { planos } = res.data;
      dispatch({
        type: SUCCESS,
        payload: { planos },
      });
      dispatch(feedback.success(res.data.msg));
    } else {
      dispatch({ type: FAILURE });
      dispatch(feedback.error(res.error));
    }
  };
};
