/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { getSafe } from '_helpers/utils';

const FormSelectField = (props) => {
  const { label, field, formik, xs, md, options, disabled } = props;
  const value = getSafe(formik.values, field);
  const touched = true;
  const error = getSafe(formik.errors, field, false);
  const change = formik.change;
  const [labelWidth, setLabelWidth] = useState(0);

  const InputLabelRef = useRef();
  useEffect(() => {
    setLabelWidth(ReactDOM.findDOMNode(InputLabelRef.current).offsetWidth);
  }, []);

  return useMemo(
    () => (
      <Grid item xs={xs ? xs : 12} md={md ? md : 12}>
        <FormControl
          disabled={disabled}
          variant='outlined'
          fullWidth
          error={Boolean(touched) && Boolean(error)}>
          <InputLabel htmlFor={field} ref={InputLabelRef}>
            {label}
          </InputLabel>
          <Select
            value={value}
            onChange={change.bind(null, field)}
            input={
              <OutlinedInput labelWidth={labelWidth} name={field} id={field} />
            }>
            {options.map((option, index) => (
              <MenuItem
                value={option.value ? option.value : option}
                key={option + index}>
                {option.label ? option.label : option}
              </MenuItem>
            ))}
          </Select>
          {touched && error ? (
            <FormHelperText>{touched ? error : ''}</FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
    ),
    [value, error, labelWidth, disabled],
  );
};

export default FormSelectField;
