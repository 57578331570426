import { EDIT_REQUEST, EDIT_SUCCESS, EDIT_FAILURE } from './types';
import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';

export const edit = (cupom) => {
  return async (dispatch) => {
    dispatch({ type: EDIT_REQUEST });
    // ***SERVICE CALL***
    const res = await service.edit(cupom);
    if (res.success) {
      dispatch(feedback.success(res.msg));
      dispatch({ type: EDIT_SUCCESS });
      localStorage.setItem('entidade', JSON.stringify(res.data.data));
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: EDIT_FAILURE });
    }
  };
};
