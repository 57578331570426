import React from 'react';
import Servico from './Servico';

function Servicos(props) {
  const { servicos, formProps, setFieldValue } = props;

  const handleChange = (name, servc) => {
    const fieldEnabled = `servicos.${name}.enabled`;
    if (servc.enabled) {
      setFieldValue(fieldEnabled, true);
    } else {
      setFieldValue(fieldEnabled, false);
    }
  };

  const rest = {
    handleChange: handleChange,
    servicos,
    formProps,
  };

  return (
    <>
      <Servico
        label='Permitir Cadastro de Associados'
        name='associados'
        rest={rest}
        unit='UNIDADE'
      />

      <Servico
        label='Cadastro de Associado Manual ou Planilha'
        name='manual'
        rest={rest}
        unit='UNIDADE'
      />

      <Servico label='Enviar Atividades por e-mail' name='atividades' rest={rest} unit='ENVIO' />

      <Servico label='Enviar SMS' name='sms' rest={rest} unit='ENVIO' />

      <Servico label='Enviar Mailing' name='mailing' rest={rest} unit='ENVIO' />

      <Servico
        label='Enviar Notificações via Sistema'
        name='notifSistema'
        rest={rest}
        unit='ENVIO'
      />

      <Servico
        label='Enviar Notificações POP UP (e-mail)'
        name='notifPopup'
        rest={rest}
        unit='ENVIO'
      />

      <Servico label='Enviar Notificações PUSH' name='notifPush' rest={rest} unit='ENVIO' />

      <Servico label='Carteirinha Física' name='carteirinhaFisica' rest={rest} unit='UNIDADE' />

      <Servico label='Emitir Carteirinha DIGITAL' name='carteirinha' rest={rest}>
        Libera a criação e emisssão da Carteirinha DIGITAL de Associado. Permite que os Associados
        pagantes criem e utilizem a carteirinha.
      </Servico>

      <Servico label='Criar Planos' name='planos' rest={rest}>
        Permite a criação de Planos de Associação pela Entidade.
      </Servico>

      <Servico label='Criar Cupons para Planos' name='cupons' rest={rest}>
        Permite a criação de Cupons de Desconto para compra dos planos pelos Associados.
      </Servico>

      <Servico label='Criar Site / Página' name='site' rest={rest}>
        Permite a criação do site / página da Entidade.
      </Servico>

      <Servico label='Cadastrar Parceiros' name='parceiros' rest={rest}>
        Permite que a Entidade cadastre, gerencie e exiba seus Parceiros para os Associados
        pagantes.
      </Servico>

      <Servico label='Criar Lojinha' name='lojinha' rest={rest}>
        Permite que a Entidade crie e gerencie a sua Lojinha Virtual.
      </Servico>

      <Servico label='Oferecer Clube de Descontos' name='clube' rest={rest}>
        Exibe o Clube de Benefícios & Vantagens para os Associados pagantes da Entidade.
      </Servico>

      <Servico label='Permitir Gerenciador Financeiro' name='financeiro' rest={rest}>
        Habilita o uso do Gerenciador Financeiro e criação de Vendedores / Sellers para a Entidade.
      </Servico>

      <Servico label='Permitir Venda de Ingressos' name='ingressos' rest={rest}>
        Habilita a venda de ingressos pela Entidade.
      </Servico>
    </>
  );
}

export default Servicos;
