import { send } from '_helpers/api.config';

const apiURL = '/api/admin/suporte';

export const list = async () => {
  const endpoint = apiURL;
  const verb = 'GET';
  const data = null;
  return send(verb, endpoint, data);
};

export const reply = async (ticket) => {
  const endpoint = `${apiURL}/${ticket._id}`;
  const verb = 'PUT';
  const data = ticket;
  return send(verb, endpoint, data);
};

export const trash = async (ticket) => {
  const endpoint = `${apiURL}/${ticket._id}`;
  const verb = 'DELETE';
  const data = ticket;
  return send(verb, endpoint, data);
};
