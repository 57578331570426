import { send } from '_helpers/api.config';

const apiURL = '/api/admin/financeiro';

// procura um pedido
export const getPedido = async (numero) => {
  const endpoint = `${apiURL}/pedido/find/${numero}`;
  const verb = 'GET';
  const data = null;
  return send(verb, endpoint, data);
};

// cancela um pedido
export const cancelaPedido = async (pedido) => {
  const endpoint = `${apiURL}/pedido/cancela/${pedido._id}`;
  const verb = 'PUT';
  const data = { pedido };
  return send(verb, endpoint, data);
};
