import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  formContainer: {
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '70%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '60%',
    },
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: 'auto',
    display: 'flex',
    marginBottom: '2em',
  },

  title: {
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '70%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '60%',
    },
  },

  beneficio: {
    marginBottom: '1em',
  },

  button: {
    marginLeft: '0.5em',
  },

  cancelarOuSalvar: {
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      marginRight: '15%',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: '25%',
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: '35%',
    },
  },

  switch: {
    display: 'flex',
    flexBasis: '100%',
    alignItems: 'center',
    padding: '0.5em 0',
    transition: 'all ease 300ms',
    borderRadius: '4px',
    '&:hover': {
      background: '#eef7ff',
      fontWeight: '500',
    },
  },

  left: {
    flex: 1,
  },

  right: {
    flex: 1,
  },

  leftCbr: {
    flex: 1,
  },

  rightCbr: {
    flex: 3,
  },

  tipoRecorrente: {
    marginLeft: '0.5em',
    color: theme.palette.primary.dark,
  },

  benefError: {
    color: 'red',
    margin: '-0.5em 0 1em 1em',
    fontSize: '0.875em',
  },
}));
