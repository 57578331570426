export default (theme) => ({
  card: {
    marginBottom: '3em',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '1em',
    },
  },

  cardInativo: {
    marginBottom: '3em',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '1em',
    },
    backgroundColor: '#0000001a',
  },

  title: {
    flex: 2,
  },

  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },

  left: {
    flex: 2,
    marginRight: '0.5em',
  },

  right: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },

  cupom: {
    // flex: 1,
    padding: '0.5em',
    width: 'calc(100vw - 73px)',
    maxWidth: 287,
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
    [theme.breakpoints.up('sm')]: {
      // margin: '0em 1em',
      marginTop: '-2em',
    },
  },

  chip: {
    fontSize: '1em',
    fontWeight: '500',
    marginBottom: theme.spacing(1),
    // marginRight: '2em',
    // alignSelf: 'flex-start',
  },

  badgeUtil: {
    backgroundColor: 'black',
    margin: theme.spacing(2),
  },

  badgeDisp: {
    background: theme.palette.primary.main,
    margin: theme.spacing(2),
  },

  quantidade: {
    fontSize: '0.7em',
    fontWeight: '300',
  },

  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
