/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import useStyles from './styles';
import Grow from '@material-ui/core/Grow';
import MiniAvatar from 'components/_Common/MiniAvatar';
import { formataData } from '_helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import Opcoes from './Opcoes';

function LinkRow(props) {
  const classes = useStyles();
  const link = props.data;
  const {
    validade,
    status,
    pagadorDefine,
    valor,
    valorMinimo,
    taxaAdm,
    totalArrecadado,
    acessos,
    boleto,
    cartao,
    parcelas,
  } = link;

  const baseURL =
    process.env.NODE_ENV === 'production' ? 'https://farol.fun' : 'http://localhost:3006';
  return (
    <Grow key={link._id} in={true}>
      <div key={link._id} className={classes.listItem}>
        <div className={classes.firstBox}>
          <div className={classes.avatar}>
            <MiniAvatar linkCbr={link} />
          </div>
          <div className={classes.dataText}>
            <div className={classes.nome}>{link.titulo}</div>
            <a
              href={`${baseURL}/checkout/${link.url}`}
              target='_blank'
              rel='noopener noreferrer'
              className={classes.url}>
              https://farol.fun/checkout/{link.url}
            </a>
            {status && <div className={classes.ativo}>ativo</div>}
            {!status && <div className={classes.inativo}>inativo</div>}
          </div>
        </div>
        <div className={classes.infoBox}>
          {pagadorDefine ? (
            <>
              <div className={classes.dado}>Pagador define o Valor</div>
              <div className={classes.dado}>Mínimo R$ {valorMinimo?.toFixed(2)}</div>
            </>
          ) : (
            <>
              <div className={classes.dado}>Valor R$ {valor?.toFixed(2)}</div>
            </>
          )}
        </div>
        <div className={classes.infoBox}>
          <div className={classes.dado}>R$ {totalArrecadado?.toFixed(2)} recebido</div>
          {pagadorDefine && taxaAdm && (
            <div className={classes.dado}>Taxa Adm {taxaAdm?.toFixed(2)}%</div>
          )}
        </div>
        {(boleto || cartao) && (
          <div className={classes.infoBox}>
            {boleto && <div className={classes.dadoSm}>Aceita boleto</div>}
            {cartao && <div className={classes.dadoSm}>Aceita cartão</div>}
            {parcelas > 1 ? (
              <div className={classes.dadoSm}>Aceita parcelar</div>
            ) : (
              <div className={classes.dadoSm}>Apenas à vista</div>
            )}
          </div>
        )}

        <div className={classes.infoBox}>
          <div className={classes.dado}>{acessos} acessos</div>
          {validade && (
            <div className={classes.dado}>
              <FontAwesomeIcon icon={faClock} className={classes.icon} fixedWidth />
              {formataData(validade)}
            </div>
          )}
        </div>

        <Opcoes link={link} />
      </div>
    </Grow>
  );
}

export default memo(LinkRow);
