const values = {
  nome: '',
  telefone: '',
  whatsapp: '',
  avatar: '',
  entidades: false,
  associados: false,
  site: '',
  facebook: '',
  instagram: '',
  youtube: '',
  twitch: '',
  twitter: '',
  detalhes: '',
};

export default values;
