import { useMemo } from 'react';
import Fuse from 'fuse.js';
// const removeSpecialCharacters = (str) => {
//   return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
// };
export default function useSearch(data, fields) {
  // const test = data.map((item) => Object.values(item));
  // const newData = [...data];
  // const arr = newData.map((item) => {
  //   const onlyFieldsObj = {};
  //   fields.forEach((key) => {
  //     onlyFieldsObj[key] = item[key];
  //   });
  //   return onlyFieldsObj;
  // });

  // console.log('arr', arr);

  return useMemo(() => {
    const options = {
      threshold: 0.3,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 2,
      keys: fields,
    };
    return new Fuse(data, options);
  }, [data, fields]);
}
