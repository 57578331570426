/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import useStyles from './styles';
import Grow from '@material-ui/core/Grow';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faCheck, faGem } from '@fortawesome/free-solid-svg-icons';
import MiniAvatar from 'components/_Common/MiniAvatar';
import PayMethodIcon from 'components/_Common/PayMethodIcon';
import PayStatusLabel from 'components/_Common/PayStatusLabel';
import { formataData, formataCelular } from '_helpers/utils';
import { formatCpf, formatCnpj } from '@brazilian-utils/formatters';

function MinhasVendas(props) {
  const classes = useStyles();
  const pedido = props.data;
  const valorFinal = pedido.valor - pedido.valorTaxa - pedido.valorTransacao;
  const { status, pagoEm } = pedido;

  const paidArr = ['paid', 'succeeded'];
  const isPaid = (status, pagoEm) => {
    if (paidArr.includes(status)) return true;
    if (pagoEm) return true;
    return false;
  };

  const dataPago = pedido.pagoEm ?? pedido.updatedAt;

  const definePagador = () => {
    const { cliente, associado, entidade } = pedido;
    const pag = {
      nome: '',
      email: '',
      cpf: '',
      cnpj: '',
      telefone: '',
      avatar: '',
    };
    if (pedido.cliente) {
      pag.nome = cliente.nome;
      pag.email = cliente.email;
      pag.cpf = cliente.taxID;
      pag.telefone = cliente.mobile;
    }
    if (pedido.associado) {
      pag.nome = associado.nome;
      pag.email = associado.email;
      pag.cpf = associado.cpf;
      pag.telefone = associado.celular;
      pag.avatar = associado.avatar;
    }
    if (pedido.entidade) {
      pag.nome = entidade.nome;
      pag.email = entidade.user.email;
      pag.cpf = entidade.representante.cpf;
      pag.cnpj = entidade.cnpj;
      pag.telefone = entidade.celular;
      pag.avatar = entidade.avatar;
    }
    return pag;
  };
  const pagador = definePagador();

  if (pagador.nome === '') {
    pagador.nome = '[ Associado ou Entidade excluído ]';
  }

  return (
    <Grow in={true}>
      <div className={classes.listItem}>
        <div className={classes.container}>
          <div className={classes.containerNumero}>
            <Tooltip title='Número do pedido' placement='top'>
              <div className={classes.numero}>{pedido.numero}</div>
            </Tooltip>
            <Tooltip title='Criado em' placement='bottom'>
              <div className={classes.data}>{formataData(pedido.createdAt)}</div>
            </Tooltip>
          </div>

          <div className={classes.containerDados}>
            <div className={classes.avatar}>
              <MiniAvatar associado={pagador} icon={faGem} />
            </div>
            <div className={classes.dataText}>
              <Tooltip title='Nome do pagador' placement='top'>
                <div className={classes.nome}>{pagador.nome}</div>
              </Tooltip>
              <Tooltip title='Telefone' placement='top'>
                <div className={classes.infoData}>
                  {pagador.telefone && formataCelular(pagador.telefone)}
                </div>
              </Tooltip>
              <Tooltip title='CPF' placement='top'>
                <div className={classes.infoData}>{pagador.cpf && formatCpf(pagador.cpf)}</div>
              </Tooltip>
              {pagador.cnpj && (
                <Tooltip title='CNPJ' placement='top'>
                  <div className={classes.infoData}>{formatCnpj(pagador.cnpj)}</div>
                </Tooltip>
              )}
              <Tooltip title='Email' placement='top'>
                <div className={classes.infoData}>{pagador.email}</div>
              </Tooltip>

              {pedido.metodo === 'Cartão' ? (
                <div className={classes.box}>
                  <div className={classes.label}>Parcelas</div>
                  <div className={classes.info}>
                    {pedido.parcelas}x de R$ {pedido.valorParcela.toFixed(2)}
                  </div>
                </div>
              ) : null}

              {pedido.cupom ? (
                <div className={classes.box}>
                  <div className={classes.label}>Desconto</div>
                  <div className={classes.info}>
                    {pedido.desconto.toFixed(2)} ({pedido.cupom})
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className={classes.descricao}>{pedido.descricao}</div>

          <div className={classes.containerValor}>
            <div className={classes.valor}>R$ {valorFinal.toFixed(2)}</div>
            <Tooltip title='Vencimento' placement='bottom'>
              <div className={classes.vencimento}>
                <FontAwesomeIcon icon={faCalendarDay} className={classes.vctoIcon} fixedWidth />
                {formataData(pedido.vencimento)}
              </div>
            </Tooltip>
          </div>

          <div className={classes.metodo}>
            <PayMethodIcon pedido={pedido} />
          </div>

          <div className={classes.status}>
            <PayStatusLabel pedido={pedido} />
            {isPaid(status, pagoEm) ? (
              <Tooltip title='Data do Pagamento' placement='bottom'>
                <div className={classes.pagoEm}>
                  <FontAwesomeIcon icon={faCheck} className={classes.paidIcon} fixedWidth />
                  {formataData(dataPago)}
                </div>
              </Tooltip>
            ) : null}
          </div>
        </div>
      </div>
    </Grow>
  );
}

export default MinhasVendas;
