/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useStyles from './styles';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

const Email = (props) => {
  const { entidade } = props;
  const user = entidade.user
    ? entidade.user
    : {
        isVerified: false,
        email: 'excluído',
      };

  const classes = useStyles();

  return (
    <div className={classes.email}>
      {user.email}
      {user.isVerified ? (
        <Tooltip title='E-mail verificado' placement='right'>
          <span>
            <FontAwesomeIcon
              icon={faCheck}
              className={classes.iconVerified}
              fixedWidth
              size='sm'
            />
          </span>
        </Tooltip>
      ) : (
        <Tooltip title='E-mail não verificado' placement='right'>
          <span>
            <FontAwesomeIcon
              icon={faTimes}
              className={classes.iconNotVerified}
              fixedWidth
              size='sm'
            />
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default Email;
