import React from 'react';
import { Formik } from 'formik';
import validationSchema from './validation';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';
import styles from './styles';
import Form from './form';
import { formataData } from '_helpers/utils';

const EditarCupom = props => {
  const { classes } = props;

  window.scrollTo(0, 0);
  const actions = bindActionCreators(Actions, useDispatch());
  const { cupom } = useSelector(({ cupons }) => ({
    ...cupons,
  }));
  const values = { ...cupom };
  values.plano = cupom.plano.titulo;
  return (
    <div className={classes.formContainer}>
      <Formik
        render={props => (
          <Form title='Editar Cupom' handleCancel={actions.cancel} {...props} />
        )}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={values => {
          actions.edit(values);
        }}
      />
    </div>
  );
};

export default withStyles(styles)(EditarCupom);
