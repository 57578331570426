import * as Yup from 'yup';

const validationSchema = Yup.object({
  status: Yup.string('').required('* Obrigatório'),
  mensagem: Yup.string('')
    .min(10, 'Texto muito curto')
    .max(1000, 'Texto muito longo'),
});

export default validationSchema;
