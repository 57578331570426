import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  servico: {
    display: 'flex',
    lineHeight: '1.2em',
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    fontWeight: '500',
    '&:nth-child(odd)': {
      background: '#bee1fe3b',
    },
  },

  servcIcon: {
    marginLeft: '1.7em',
    alignSelf: 'center',
  },

  servcTick: {
    color: theme.palette.secondary.main,
  },

  servcText: {
    minHeigth: '30px !important',
    fontWeight: '500',
    marginLeft: '1em',
    marginRight: '1em',
    fontSize: '0.9em',
    padding: '0.5em 0',
    transition: 'all ease 300ms',
    '&:hover': {
      fontWeight: '500',
    },
  },

  disabled: {},
}));
