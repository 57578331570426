import {
  SHOW_CREATE,
  CREATE_SUCCESS,
  SHOW_EDIT,
  EDIT_SUCCESS,
  SHOW_TRASH,
  TRASH_SUCCESS,
  TRASH_FAILURE,
  CANCEL,
} from './types';

const INITIAL_STATE = {
  showCreate: false,
  showEdit: false,
  showTrash: false,
  notificacao: {},
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SHOW_CREATE:
      return { ...state, showCreate: true };
    case CREATE_SUCCESS:
      return { ...state, showCreate: false };
    case SHOW_EDIT:
      return {
        ...state,
        showEdit: true,
        notificacao: payload,
      };
    case EDIT_SUCCESS:
      return { ...state, showEdit: false, notificacao: {} };
    case SHOW_TRASH:
      return { ...state, showTrash: true, notificacao: payload };
    case TRASH_SUCCESS:
      return { ...state, showTrash: false };
    case TRASH_FAILURE:
      return { ...state, showTrash: false };
    case CANCEL:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
