import React from 'react';
import useStyles from './Novo/styles';
import { Formik } from 'formik';
import { useSelector, useActions } from 'hooks';
import * as Actions from './redux/actions';
import validationSchema from './Novo/validation';
import Form from './Novo/form';
import defValues from './Novo/defaultFormValues';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGg } from '@fortawesome/free-brands-svg-icons';

const EditaLinkCobranca = (props) => {
  window.scrollTo(0, 0);
  const classes = useStyles();
  const actions = useActions(Actions);
  const { link } = useSelector('linkCobranca');
  const values = { ...defValues, ...link };
  values.categoria = values.categoria.code;
  return (
    <div className={classes.formContainer}>
      <div className={classes.pageTitle}>
        <FontAwesomeIcon icon={faGg} className={classes.gg} fixedWidth size='lg' />
        <div>Editar Link de Cobrança</div>
      </div>
      <Formik
        render={(props) => <Form handleCancel={actions.cancel} isEdit {...props} />}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          actions.edit(values);
        }}
      />
    </div>
  );
};

export default EditaLinkCobranca;
