import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  container: {
    marginBottom: "10em",
    display: "flex",
    flexDirection: "column",
    marginTop: "1em"
  },

  sellers: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  },

  seller: {
    flexBasis: "70%",
    display: "flex",
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    margin: "1em",
    padding: "1em",
    paddingLeft: "1.5em",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      flexBasis: "100%",
      margin: "1em 0",
      padding: "1em"
    }
  },

  left: {
    flexBasis: "60%",
    display: "flex",
    flexDirection: "column",
    borderRight: "1px solid #E2E2E2",
    [theme.breakpoints.down("sm")]: {
      borderRight: "unset",
      flexBasis: "100%"
    }
  },

  right: {
    flexBasis: "40%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1em",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
      flexBasis: "100%",
      marginTop: "2em"
    }
  },

  wrapper: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },

  name: {
    fontSize: "1.5em",
    lineHeight: "1em",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },

  email: {
    marginLeft: "0.2em",
    marginBottom: "2em",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },

  icon: {
    marginTop: "2em",
    paddingRight: "2em",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0em"
    }
  },

  doc: {
    marginTop: "1em"
  },

  fIcon: {
    // color: '#bababa',
    marginRight: "0.5em"
  },

  details: {
    fontWeight: "100",
    marginBottom: "0.2em",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1em"
    }
  },

  bwrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "0 0 1em 0"
  },

  balance: {
    fontWeight: "500",
    display: "flex",
    justifyContent: "space-between"
  },

  blabel: {
    flexBasis: "50%"
  },

  bvalue: {
    flexBasis: "50%",
    textAlign: "right"
  },

  dateWrapper: {
    display: "flex",
    fontSize: "0.875em",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      flexDirection: "column"
    }
  },

  dlabel: {
    color: "#bababa",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      fontWeight: "100"
    }
  },

  date: {
    color: "#bababa",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: "0.2em"
    }
  },

  label: {
    fontWeight: "100"
  },

  tipo: {
    flex: 1,
    fontWeight: "100",
    marginBottom: "0.2em",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: "0"
    }
  },

  info: {
    flex: 2,
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },

  newSeller: {
    textAlign: "center",
    marginTop: "1em",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2em"
    }
  },

  newSellerIcon: {
    marginRight: "0.5em"
  },

  button: {
    marginBottom: "1em",
    [theme.breakpoints.down("sm")]: {
      margin: "1em 0 2em 0"
    }
  },

  form: {
    marginTop: "2em",
    paddingRight: "2em"
  },

  aviso: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center"
  },

  entidade: {
    color: theme.palette.primary.dark,
    fontWeight: "500"
  },
  // for seller sem entidade
  entidadeError: {
    color: "red"
  },

  padrao: {
    marginBottom: "1em",
    fontWeight: "100"
  },

  buttonDel: {
    marginTop: "1em"
  }
}));
