/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearchDollar,
  faCircle,
  faDollarSign,
  faMinus,
  faPlus,
  faEquals,
} from '@fortawesome/free-solid-svg-icons';

const ResumoFluxo = (props) => {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <FontAwesomeIcon icon={faSearchDollar} fixedWidth /> Previsão de Caixa
        de 01/08 até 31/08
      </div>

      <div className={classes.box}>
        <div className={classes.text}>
          <FontAwesomeIcon
            icon={faDollarSign}
            className={classes.icon}
            fixedWidth
          />{' '}
          Saldo Anterior
        </div>
        <div className={classes.value}>R$ 0</div>
      </div>

      <div className={classes.box}>
        <div className={classes.text}>
          <FontAwesomeIcon icon={faMinus} className={classes.icon} fixedWidth />{' '}
          Despesas
        </div>
        <div className={classes.value}>R$ 0</div>
      </div>

      <div className={classes.box}>
        <div className={classes.text}>
          <FontAwesomeIcon icon={faPlus} className={classes.icon} fixedWidth />{' '}
          Receitas
        </div>
        <div className={classes.value}>R$ 0</div>
      </div>

      <div className={classes.box}>
        <div className={classes.text}>
          <FontAwesomeIcon icon={faMinus} className={classes.icon} fixedWidth />{' '}
          A pagar
        </div>
        <div className={classes.value}>R$ 0</div>
      </div>

      <div className={classes.box}>
        <div className={classes.text}>
          <FontAwesomeIcon icon={faPlus} className={classes.icon} fixedWidth />{' '}
          A receber
        </div>
        <div className={classes.value}>R$ 0</div>
      </div>

      <div className={classes.box}>
        <div className={classes.text}>
          <FontAwesomeIcon
            icon={faCircle}
            className={classes.icon}
            fixedWidth
          />{' '}
          Caixa atual
        </div>
        <div className={classes.value}>R$ 0</div>
      </div>

      <div className={classes.box}>
        <div className={classes.text}>
          <FontAwesomeIcon
            icon={faEquals}
            className={classes.icon}
            fixedWidth
          />{' '}
          Previsão
        </div>
        <div className={classes.value}>R$ 0</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ResumoFluxo);
