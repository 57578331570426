export default (theme) => ({
  card: {
    // maxWidth: 240,
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 300,
    },
    margin: theme.spacing(1),
    // flex: '0 0 auto',
  },

  actions: {
    display: 'flex',
  },

  pullLeft: {
    position: 'absolute',
    left: theme.spacing(2),
  },

  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: 'auto',
    display: 'flex',
    marginBottom: '2em',
  },

  button: {
    marginLeft: '0.5em',
  },

  cancelarOuSalvar: {
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
