/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useStyles from './styles';
import { formataCelular, checkWhatsApp } from '_helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGem,
  faGraduationCap,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const DadosEntidade = (props) => {
  const { entidade } = props;
  const { qtyPlanos, qtyAssociados } = entidade;

  const classes = useStyles();

  return (
    <div className={classes.dadosEntidade}>
      <div className={classes.dado}>
        <FontAwesomeIcon
          icon={faGem}
          className={classes.icon}
          fixedWidth
          size='sm'
        />
        {qtyPlanos} planos
      </div>

      <div className={classes.dado}>
        <FontAwesomeIcon
          icon={faGraduationCap}
          className={classes.icon}
          fixedWidth
          size='sm'
        />
        {qtyAssociados} associados
      </div>

      <div className={classes.dado}>
        <FontAwesomeIcon
          icon={faPhone}
          className={classes.icon}
          fixedWidth
          size='sm'
        />
        {formataCelular(entidade.fone.telefone1)}
      </div>

      <div className={classes.dado}>
        <a
          href={`https://api.whatsapp.com/send?phone=${checkWhatsApp(
            entidade.fone.whatsapp,
          )}`}
          target='_blank'
          rel='noopener noreferrer'>
          <FontAwesomeIcon
            icon={faWhatsapp}
            className={classes.icon}
            fixedWidth
            size='sm'
          />
          {formataCelular(entidade.fone.whatsapp)}
        </a>
      </div>
    </div>
  );
};
export default DadosEntidade;
