import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  formControlSelect: {
    width: 285,
    marginRight: '1em',
    [theme.breakpoints.down('sm')]: {
      width: 260,
      marginLeft: '1em',
      marginTop: '1em',
    },
  },

  formControl: {
    width: 180,
    marginRight: '1em',
    [theme.breakpoints.down('sm')]: {
      width: 260,
      marginTop: '1em',
      marginLeft: '1em',
    },
  },

  select: {
    flex: 1,
  },

  filtro: {},

  ordenar: {},

  periodo: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: 260,
      marginLeft: '0.5em',
    },
  },

  inicio: {
    width: 160,
    marginRight: '1em',
    [theme.breakpoints.down('sm')]: {
      width: 260,
      marginTop: '1em',
      marginLeft: '0.5em',
    },
  },

  fim: {
    width: 160,
    marginRight: '1em',
    [theme.breakpoints.down('sm')]: {
      width: 260,
      marginTop: '1em',
      marginLeft: '0.5em',
    },
  },

  menuItem: {
    fontWeight: '500',
  },

  countUsers: {
    flex: 0.5,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    '& > *': {
      margin: '0 auto',
    },
  },

  btnCancelar: {
    flex: 0.5,
  },

  btnConfirm: {
    flex: 0.5,
  },
  modalError: {
    color: 'red',
  },
}));
