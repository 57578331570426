import {
  SHOW_CREATE,
  CANCEL,
  REQUEST,
  SUCCESS,
  FAILURE,
  SHOW_EDIT,
  SHOW_TRASH,
} from './types';
import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';

export const cancel = () => ({ type: CANCEL });
export const showCreate = () => ({ type: SHOW_CREATE });
export const showEdit = (parceiro) => ({ type: SHOW_EDIT, payload: parceiro });
export const showTrash = (parceiro) => ({
  type: SHOW_TRASH,
  payload: parceiro,
});

export const loadParceiros = () => {
  return sendRequest(service.list, null, false);
};

export const create = (parceiro) => {
  return sendRequest(service.create, parceiro);
};

export const edit = (parceiro) => {
  return sendRequest(service.edit, parceiro);
};

export const trash = (parceiro) => {
  return sendRequest(service.trash, parceiro);
};

export const mudarDestaque = (parceiro) => {
  return sendRequest(service.destaque, parceiro);
};

export const mudarStatus = (parceiro) => {
  return sendRequest(service.mudarStatus, parceiro);
};

function sendRequest(serviceName, params, notif = true) {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    // ***SERVICE CALL***
    const res = await serviceName({ ...params });
    if (res.success) {
      if (notif) dispatch(feedback.success(res.data.msg));
      dispatch({ type: SUCCESS, payload: res.data });
    } else {
      if (notif) dispatch(feedback.error(res.error));
      dispatch({ type: FAILURE });
    }
  };
}
