import { send } from '_helpers/api.config';

const apiURL = '/api/admin/financeiro/boletos';

// lista os links
export const load = async () => {
  const endpoint = apiURL;
  const verb = 'GET';
  return send(verb, endpoint, null, false);
};

// retoma vendas
export const start = async (pedido) => {
  const endpoint = `${apiURL}/${pedido._id}`;
  const verb = 'PUT';
  const data = null;
  return send(verb, endpoint, data, false);
};
