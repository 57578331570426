import { send } from '_helpers/api.config';

const apiURL = '/api/admin/links';

// lista os links
export const load = async () => {
  const endpoint = apiURL;
  const verb = 'GET';
  return send(verb, endpoint, null, false);
};

// verifica se o link está disponível
export const checkUnique = async (url) => {
  const endpoint = `${apiURL}/is-unique/${url}`;
  const verb = 'GET';
  return send(verb, endpoint, null, false);
};

// cria novo link de cobrança
export const create = async (link) => {
  const endpoint = apiURL;
  const verb = 'POST';
  const data = link;
  return send(verb, endpoint, data, false);
};

// edita link
export const edit = async (link) => {
  const endpoint = `${apiURL}/${link._id}`;
  const verb = 'PUT';
  const data = link;
  return send(verb, endpoint, data, false);
};

// soft delete
export const trash = async (link) => {
  const endpoint = `${apiURL}/${link._id}`;
  const verb = 'DELETE';
  const data = null;
  return send(verb, endpoint, data, false);
};

// pausa vendas
export const pause = async (link) => {
  const endpoint = `${apiURL}/pause/${link._id}`;
  const verb = 'PUT';
  const data = null;
  return send(verb, endpoint, data, false);
};

// retoma vendas
export const start = async (link) => {
  const endpoint = `${apiURL}/start/${link._id}`;
  const verb = 'PUT';
  const data = null;
  return send(verb, endpoint, data, false);
};
