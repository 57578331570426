import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  form: {
    color: 'white',
  },

  cssLabel: {
    color: 'white',
    '&$cssFocused': {
      color: 'white',
    },
  },

  cssFocused: {},

  cssUnderline: {
    borderBottomColor: 'white',

    '&:after': {
      borderBottomColor: 'white',
    },
  },

  cssOutlinedInput: {
    color: 'white',
  },
  notchedOutline: {},
}));
