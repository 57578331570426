/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

function SwitchLabels(props) {
  return useMemo(() => {
    const { label, handleChange, data, name } = props;
    let item = data[name];
    return (
      <div style={{ marginLeft: '0.5em' }}>
        <FormControlLabel
          control={
            <Switch
              checked={item.enabled}
              onChange={() =>
                handleChange(name, { ...item, enabled: !item.enabled })
              }
              value='checkedB'
              color='primary'
            />
          }
          label={label}
        />
      </div>
    );
  }, [props.data[props.name]]);
}

export default SwitchLabels;
