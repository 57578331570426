import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: '-1em',
    marginBottom: '1em',
  },

  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: '1201 !important',
  },

  mobile: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    paddingLeft: '100px',
    flewGrow: 'grow',
    height: '80%',
  },

  grow: {
    flex: 1,
    flewGrow: 'grow',
  },

  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: '#424242',
    zIndex: '1201',
    cursor: 'pointer',
  },

  active: {
    height: '2px',
    width: '100%',
    background: theme.palette.secondary.main,
    animation: 'makeItGrow 0.15s',
    [theme.breakpoints.down('sm')]: {
      order: 1000,
      marginTop: '1em',
    },
  },

  notActive: {
    height: '2px',
  },
}));
