import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Dialog from 'components/_Common/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AvatarParceiro from './Avatar';
import {
  faEdit,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faStar,
  faEllipsisV,
  faPhoneAlt,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faTwitch,
  faTwitter,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import styles from './styles';
import { checkHTTP, checkWhatsApp } from '_helpers/utils';

class ParceiroCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      open: false,
      excluir: false,
      status: this.props.parceiro.status,
      destaque: this.props.parceiro.destaque,
      detalhes: false,
    };
  }

  handleToggle = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  handleExcluir = (confirmation) => {
    const { parceiro } = this.props;
    if (confirmation) {
      this.props.actions.trash(parceiro);
    }
    this.setState({ excluir: false });
  };

  handleClose = (event, action) => {
    const { parceiro, actions } = this.props;
    if (this.anchorEl.contains(event.target)) return;
    if (action === 'editar') {
      actions.showEdit(parceiro);
    }
    if (action === 'excluir') {
      this.setState({ excluir: true, open: false });
    }
    this.setState({ open: false });
  };

  handleStatus = () => {
    const { parceiro } = this.props;
    this.props.actions.mudarStatus(parceiro);
    this.setState({ status: !this.state.status });
  };

  handleDestaque = () => {
    const { parceiro } = this.props;
    this.props.actions.mudarDestaque(parceiro);
    this.setState({ destaque: !this.state.destaque });
  };

  renderMenu = () => {
    const { open } = this.state;
    const { classes } = this.props;
    return (
      <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={this.handleClose}>
                <MenuList>
                  <MenuItem onClick={(e) => this.handleClose(e, 'editar')}>
                    <FontAwesomeIcon
                      icon={faEdit}
                      className={classes.mr}
                      fixedWidth
                    />
                    Editar
                  </MenuItem>

                  <MenuItem onClick={(e) => this.handleClose(e, 'excluir')}>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className={classes.mr}
                      fixedWidth
                    />
                    Excluir
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  renderMsg = () => {
    return (
      <span
        dangerouslySetInnerHTML={{ __html: this.props.parceiro.detalhes }}
      />
    );
  };

  showDetalhes = () => {
    const { detalhes } = this.state;
    const { parceiro } = this.props;
    if (detalhes) {
      return (
        <Dialog
          title={parceiro.nome}
          no="Fechar"
          yes="Ok, obrigado"
          action={() => this.setState({ detalhes: false })}
          close={() => this.setState({ detalhes: false })}>
          {this.renderMsg()}
        </Dialog>
      );
    }
  };

  render() {
    const { classes, parceiro } = this.props;

    const { open, excluir, status } = this.state;
    return (
      <Card className={classes.card}>
        {this.renderMenu()}
        <div className={classes.topOptions}>
          <span className={classes.destaque}>
            <FontAwesomeIcon
              icon={faStar}
              className={parceiro.destaque ? classes.star : classes.starInativo}
              fixedWidth
              size="lg"
              onClick={this.handleDestaque}
            />
          </span>

          <span
            aria-label="Avatar"
            onClick={this.handleStatus}
            className={status ? classes.avatarAtivo : classes.avatarInativo}>
            {status ? (
              <FontAwesomeIcon icon={faEye} fixedWidth size="lg" />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} fixedWidth size="lg" />
            )}
          </span>

          <span className={classes.menu}>
            <IconButton
              buttonRef={(node) => {
                this.anchorEl = node;
              }}
              aria-owns={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={this.handleToggle}>
              <FontAwesomeIcon icon={faEllipsisV} fixedWidth size="xs" />
            </IconButton>
          </span>
        </div>

        <Divider />

        <AvatarParceiro parceiro={parceiro} />

        <div className={classes.info}>
          <div>
            <Typography variant="h5" className={classes.nome} align="center">
              {parceiro.nome}
            </Typography>
          </div>

          <div className={classes.telefone}>
            {parceiro.telefone ? (
              <div>
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  fixedWidth
                  className={classes.mr}
                />
                {parceiro.telefone}
              </div>
            ) : null}
          </div>

          <div className={classes.site}>
            {parceiro.site ? (
              <a
                className={classes.link}
                href={checkHTTP(parceiro.site)}
                target="_blank"
                rel="noopener noreferrer">
                {parceiro.site}
              </a>
            ) : null}

            <div className={classes.detalhes}>
              {parceiro.detalhes ? (
                <div>
                  <Button
                    // size="large"
                    fullWidth
                    // variant="outlined"
                    onClick={() =>
                      this.setState({ detalhes: !this.state.detalhes })
                    }
                    className={classes.button}>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className={classes.pullLeft}
                      fixedWidth
                    />
                    Ver mais informações
                  </Button>
                  {this.showDetalhes()}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <CardActions disableSpacing>
            <div className={classes.actions}>
              {parceiro.whatsapp ? (
                <div>
                  <a
                    className={classes.link}
                    href={`https://wa.me/${checkWhatsApp(parceiro.whatsapp)}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faWhatsapp} fixedWidth size="lg" />
                  </a>
                </div>
              ) : null}

              {parceiro.facebook ? (
                <div>
                  <a
                    className={classes.link}
                    href={`https://facebook.com/${parceiro.facebook}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebookF} fixedWidth size="lg" />
                  </a>
                </div>
              ) : null}

              {parceiro.instagram ? (
                <div>
                  <a
                    className={classes.link}
                    href={`https://instagram.com/${parceiro.instagram}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} fixedWidth size="lg" />
                  </a>
                </div>
              ) : null}

              {parceiro.youtube ? (
                <div>
                  <a
                    className={classes.link}
                    href={`https://youtube.com/channel/${parceiro.youtube}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faYoutube} fixedWidth size="lg" />
                  </a>
                </div>
              ) : null}

              {parceiro.twitch ? (
                <div>
                  <a
                    className={classes.link}
                    href={`https://twitch.com/${parceiro.twitch}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitch} fixedWidth size="lg" />
                  </a>
                </div>
              ) : null}

              {parceiro.twitter ? (
                <div>
                  <a
                    className={classes.link}
                    href={`https://twitter.com/${parceiro.twitter}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter} fixedWidth size="lg" />
                  </a>
                </div>
              ) : null}
            </div>
          </CardActions>

          {excluir ? (
            <Dialog
              title="Tem certeza que deseja excluir o parceiro?"
              message="Este parceiro não será mais exibido."
              no="Não"
              yes="Sim, excluir"
              confirm={true}
              action={this.handleExcluir}
              close={() => this.setState({ excluir: false })}
            />
          ) : null}
        </div>
      </Card>
    );
  }
}

export default withStyles(styles)(ParceiroCard);
