import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  nome: {
    flex: 1,
    textAlign: 'center',
    fontWeight: '500',
    width: '100%',
    marginTop: '1em',
    [theme.breakpoints.down('sm')]: {
      order: 2,
      flexBasis: '100%',
      width: '100%',
      margin: '0.5em 0',
    },
  },

  valor: {
    fontSize: '1.75em',
    fontWeight: '500',
  },

  header: {
    fontWeight: '500',
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    marginRight: '20px',
  },

  info: {
    textAlign: 'center',
    fontWeight: '500',
  },

  colScore: {
    textAlign: 'center',
    marginRight: '1em',
  },

  topScore: {
    textAlign: 'center',
    display: 'flex',
    borderBottom: '1px solid lightgrey',
  },

  score: {
    textAlign: 'center',
    flex: 1,
    fontSize: '1.75em',
    fontWeight: '600',
    color: theme.palette.secondary.main,
  },
}));
