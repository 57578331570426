/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import MiniAvatar from 'components/_Common/MiniAvatar';
import useStyles from './styles';
import { Row, Col, Box } from 'components/_Common/LazyList';
import { useSelector } from 'hooks';

function Entidade(props) {
  const classes = useStyles();
  const { scores, loading, pesos } = useSelector('scores');
  if (loading) return 'Recalculando...';
  const { entidade, specs, score } = props.data;
  const {
    associados,
    atividades,
    comprasFarol,
    fluxos,
    notificacoes,
    parceiros,
    planos,
    produtos,
    seller,
    splitFarol,
    totalVendido,
    vendaManual,
    vendaOnline,
    vendaPlano,
  } = specs;
  return (
    <Row>
      <Col flex={1} className={classes.colScore}>
        <div className={classes.topScore}>
          <MiniAvatar entidade={entidade} />
          <div className={classes.score}>{score}</div>
        </div>
        <div className={classes.nome}>{entidade.apelido}</div>
      </Col>

      <Col flex={2}>
        <div className={classes.header}>Uso Geral</div>
        <Box label='Parceiros' info={parceiros} infoClass={classes.info} />
        <Box label='Notificacoes' info={notificacoes} infoClass={classes.info} />
        <Box label='Atividades' info={atividades} infoClass={classes.info} />
        <Box label='Carteiras' info={seller} infoClass={classes.info} />
        <Box label='CGF' info={fluxos} infoClass={classes.info} />
      </Col>
      <Col flex={2}>
        <div className={classes.header}>Associações</div>
        <Box label='Associados' info={associados} infoClass={classes.info} />
        <Box label='Associações Vendidas' info={vendaPlano} infoClass={classes.info} />
        <Box label='Planos Ativos' info={planos} infoClass={classes.info} />
      </Col>
      <Col flex={2}>
        <div className={classes.header}>Loja</div>
        <Box label='Produtos Ativos' info={produtos} infoClass={classes.info} />
        <Box label='Vendas Online' info={vendaOnline} infoClass={classes.info} />
        <Box label='Vendas Manuais' info={vendaManual} infoClass={classes.info} />
      </Col>
      <Col flex={2.5}>
        <div className={classes.header}>Resultados</div>
        <Box
          label='Total em Compras'
          info={`R$ ${comprasFarol.toFixed(2)}`}
          infoClass={classes.info}
        />
        <Box
          label='Total Vendido'
          info={`R$ ${totalVendido.toFixed(2)}`}
          infoClass={classes.info}
        />
        <Box label='Split Total' info={`R$ ${splitFarol.toFixed(2)}`} infoClass={classes.info} />
        <Box
          label='Lucro Bruto'
          info={`R$ ${(splitFarol + comprasFarol).toFixed(2)}`}
          infoClass={classes.info}
        />
      </Col>
    </Row>
  );
}

export default Entidade;
