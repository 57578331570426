import { REQUEST, SUCCESS, FAILURE } from './types';
import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';

export const loadScores = () => {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    const res = await service.loadScores();
    if (res.success) {
      dispatch({
        type: SUCCESS,
        payload: { scores: res.data.scores, pesos: { ...res.data.pesos } },
      });
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: FAILURE, payload: res.error });
    }
  };
};

export const setPesos = (pesos) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    const res = await service.setPesos(pesos);
    if (res.success) {
      dispatch({ type: SUCCESS, payload: { pesos: res.data.pesos } });
    } else {
      dispatch(feedback.error(res.error));
      dispatch({ type: FAILURE, payload: res.error });
    }
  };
};
