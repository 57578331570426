export default (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginBottom: '3em',
    [theme.breakpoints.up('sm')]: {
      // margin: '1em',
      // marginTop: '0em',
    },
  },

  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },

  mb: {
    marginBottom: '10em',
  },
});
