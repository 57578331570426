import React from 'react';

function Name(props) {
  const { seller } = props;
  if (seller.type === 'individual') {
    return (
      <>
        {seller.first_name} {seller.last_name}
      </>
    );
  }
  return <>CNPJ {seller.business_name}</>;
}

export default Name;
