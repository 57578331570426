import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { formatCpf } from '@brazilian-utils/formatters';
import { formataCelular, formataData } from '_helpers/utils';
import Divider from '@material-ui/core/Divider';

function PerfilCenter(props) {
  const { classes, associado, actions } = props;

  return (
    <div className={classes.center}>
      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          E-mail
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {associado.email}
        </Typography>
      </div>

      {associado.celular ? (
        <div className={classes.group}>
          <Typography variant="subtitle1" className={classes.label}>
            Celular
          </Typography>
          <Typography variant="subtitle1" className={classes.info}>
            {formataCelular(associado.celular)}
          </Typography>
        </div>
      ) : null}

      {associado.cpf ? (
        <div className={classes.group}>
          <Typography variant="subtitle1" className={classes.label}>
            CPF
          </Typography>
          <Typography variant="subtitle1" className={classes.info}>
            {formatCpf(associado.cpf)}
          </Typography>
        </div>
      ) : null}

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          RG
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {associado.rg} - {associado.expeditorRG}
        </Typography>
      </div>

      {associado.nascimento ? (
        <div className={classes.group}>
          <Typography variant="subtitle1" className={classes.label}>
            Nascimento
          </Typography>
          <Typography variant="subtitle1" className={classes.info}>
            {formataData(associado.nascimento)}
          </Typography>
        </div>
      ) : null}

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          Curso
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {associado.curso}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          Matrícula
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {associado.matricula}
        </Typography>
      </div>

      <Divider className={classes.divider} />

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          CEP
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {associado.endereco ? associado.endereco.cep : ''}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          Logradouro
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {associado.endereco ? associado.endereco.rua : ''},{' '}
          {associado.endereco ? associado.endereco.numero : ''}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          Complemento
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {associado.endereco ? associado.endereco.complemento : ''}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          Bairro
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {associado.endereco ? associado.endereco.bairro : ''}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant="subtitle1" className={classes.label}>
          Município
        </Typography>
        <Typography variant="subtitle1" className={classes.info}>
          {associado.endereco ? associado.endereco.cidade : ''} -{' '}
          {associado.endereco ? associado.endereco.uf : ''}
        </Typography>
      </div>

      <div className={classes.action}>
        <Button
          variant="outlined"
          onClick={() => actions.showEdit()}
          color="primary"
          className={classes.button}>
          <FontAwesomeIcon
            icon={faUserEdit}
            className={classes.buttonIcon}
            fixedWidth
            size="lg"
          />
          editar dados cadastrais
        </Button>
      </div>
    </div>
  );
}

export default PerfilCenter;
