import isBefore from 'date-fns/isBefore';

function useLoteAtual(produto) {
  if (!produto) return false;
  const lotes = produto.lotes;
  if (!lotes || lotes.length === 0) return false;
  for (const lote of lotes) {
    const vendidos = lote.vendidos ?? 0;
    const data = new Date(lote.data);
    const now = new Date();
    if (vendidos < lote.quantidade) {
      if (isBefore(now, data)) {
        const index = lotes.findIndex(({ _id }) => _id === lote._id);
        return {
          lote,
          index,
          label: `${index + 1}º Lote`,
        };
      }
    }
  }
  return false;
}

export default useLoteAtual;
