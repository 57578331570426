import sort from 'fast-sort';
import { replaceSpecialChars } from '_helpers/utils';

export function handleSort(ordem, data) {
  switch (ordem) {
    case 'AZ':
      return sort(data).asc((a) => a.nome.normalize('NFD').replace(/[^a-zA-Zs]/g, ''));
    case 'ZA':
      return sort(data).desc((a) => a.nome.normalize('NFD').replace(/[^a-zA-Zs]/g, ''));
    case 'new':
      return sort(data).desc('createdAt');
    case 'old':
      return sort(data).asc('createdAt');
    // por ultimo acesso
    case 'lastAccess':
      return sort(data).desc((e) => e?.user?.lastAccess);
    default:
      return data;
  }
}

export function filtraCurso(curso, data) {
  if (!curso || curso === 'todos') return data;

  return data.filter((a) => {
    if (a?.curso) {
      const getCurso = replaceSpecialChars(a.curso).toUpperCase();
      return getCurso === curso;
    }
    return false;
  });
}

export function filtroGerencial(status, data, sellers) {
  if (status === 'todos') return data;
  if (status === 'noUser') {
    return data.filter((x) => (x.user ? !x.user.isVerified : true));
  }

  if (status === 'activeSellers') {
    return data.filter(({ _id }) =>
      sellers.find((s) => s.entidade._id.toString() === _id.toString()),
    );
  }
}

// filtro por localização UF
export function filtroLocalizacao(status, data) {
  if (status === 'todos') return data;
  console.log('Status', status);
  return data.filter((e) => e?.endereco?.uf.toUpperCase() === status);
}

export function filtraStatus(status, data) {
  if (status === 'todos') return data;
  if (status === 'noUser') {
    return data.filter((x) => (x.user ? !x.user.isVerified : true));
  }

  if (status === 'Pagos&Ativos') {
    return data.filter(({ assinaturas }) =>
      assinaturas.find((a) => a.valor.original !== 0 && a.status === 'Ativo'),
    );
  }

  if (status === 'Pagos&Expirados') {
    return data.filter(({ assinaturas }) =>
      assinaturas.find((a) => a.valor.original !== 0 && a.status === 'Expirada'),
    );
  }

  if (status === 'apenasFree') {
    return data.filter(({ assinaturas }) => {
      const ativas = assinaturas.filter((a) => a.status === 'Ativo');
      return ativas.length === 1;
    });
  }

  return data.filter((x) => x.assinaturas.find((a) => a.status === status));
}

export function getHandledData(state) {
  const { curso, localizacao, ordem, associados } = state;
  let data = associados;

  const dataCurso = filtraCurso(curso, data);
  const dataLocalizacao = filtroLocalizacao(localizacao, dataCurso);
  const res = handleSort(ordem, dataLocalizacao);
  return res;
}
