import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1em',
  },

  cancel: {
    marginRight: '1em',
  },

  icon: {
    marginRight: '0.5em',
  },
  spinner: {
    margin: '0 1em',
    color: theme.palette.primary.main,
  },
}));
