/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useStyles from './styles';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faPause,
  faEdit,
  faShareAltSquare,
  faPlay,
  faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import { useActions } from 'hooks';
import * as Actions from 'components/LinkCobranca/redux/actions';
import SimpleModal from 'components/_Common/SimpleModal';
import Pagamentos from '../Pagamentos';

function Opcoes(props) {
  const { link } = props;
  const actions = useActions(Actions);
  const classes = useStyles();
  const shareUrl = `https://farol.fun/checkout/${link.url}`;

  return (
    <div className={classes.opcoes}>
      <Tooltip title='Ver quem pagou' placement='top'>
        <span>
          <SimpleModal
            title='Pagamentos do Link'
            button={{
              variant: 'text',
              size: 'small',
              color: 'primary',
              startIcon: faFileInvoiceDollar,
              // text: 'Buceta',
            }}>
            <Pagamentos link={link} />
          </SimpleModal>
          {/* <IconButton
            size='small'
            variant='text'
            onClick={() => actions.shareMsg(shareUrl)}
            color='primary'
            className={classes.buttonOpt}>
            <FontAwesomeIcon
              icon={faFileInvoiceDollar}
              className={classes.pullLeft}
              fixedWidth
              size='sm'
            />
          </IconButton> */}
        </span>
      </Tooltip>
      <Tooltip title='Compartilhar' placement='top'>
        <span>
          <IconButton
            size='small'
            variant='text'
            onClick={() => actions.shareMsg(shareUrl)}
            color='primary'
            className={classes.buttonOpt}>
            <FontAwesomeIcon
              icon={faShareAltSquare}
              className={classes.pullLeft}
              fixedWidth
              size='sm'
            />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title='Editar' placement='top'>
        <span>
          <IconButton
            size='small'
            variant='text'
            onClick={() => actions.changeView('EDITAR', link, link.url)}
            color='primary'
            className={classes.buttonOpt}>
            <FontAwesomeIcon icon={faEdit} className={classes.pullLeft} fixedWidth size='sm' />
          </IconButton>
        </span>
      </Tooltip>

      {link.status ? (
        <Tooltip title='Desativar' placement='top'>
          <span>
            <IconButton
              size='small'
              variant='text'
              onClick={() => actions.pause(link)}
              color='primary'
              className={classes.buttonOpt}>
              <FontAwesomeIcon icon={faPause} className={classes.pullLeft} fixedWidth size='sm' />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <Tooltip title='Ativar' placement='top'>
          <span>
            <IconButton
              size='small'
              variant='text'
              onClick={() => actions.start(link)}
              color='primary'
              className={classes.buttonOpt}>
              <FontAwesomeIcon icon={faPlay} className={classes.pullLeft} fixedWidth size='sm' />
            </IconButton>
          </span>
        </Tooltip>
      )}

      <Tooltip title='Excluir' placement='top'>
        <span>
          <IconButton
            size='small'
            variant='text'
            onClick={() => actions.trash(link)}
            color='secondary'
            className={classes.buttonOpt}>
            <FontAwesomeIcon icon={faTrash} className={classes.pullLeft} fixedWidth size='sm' />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
}

export default Opcoes;
