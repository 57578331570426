export const SHOW_CREATE = 'PLANO_SHOW_CREATE';
export const SHOW_COPY = 'PLANO_SHOW_COPY';
export const REQUEST = 'PLANO_REQUEST';
export const SUCCESS = 'PLANO_SUCCESS';
export const FAILURE = 'PLANO_FAILURE';

export const SHOW_EDIT = 'PLANO_SHOW_EDIT';

export const SHOW_TRASH = 'SHOW_TRASH';
export const COMPACT = 'COMPACT';

export const CANCEL = 'PLANO_CANCEL';
