import React from 'react';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Servico(props) {
  const classes = useStyles();
  const { item, label, icon } = props;

  let disabled = false;
  if (item && !item.enabled) disabled = true;

  return (
    <div className={disabled ? classes.disabled : classes.servico}>
      <span className={classes.servcIcon}>
        <FontAwesomeIcon
          icon={icon}
          className={classes.servcTick}
          fixedWidth
          size='lg'
        />
      </span>
      <span className={classes.servcText}>{label}</span>
    </div>
  );
}

export default Servico;
