import React, { useState, useEffect, useRef } from 'react';
import sort from 'fast-sort';
import useStyles from './styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import * as Actions from 'components/Associados/redux/actions';
import { useSelector, useActions, useFetch } from 'hooks';
import { replaceSpecialChars } from '_helpers/utils';

export default function Filtro(props) {
  const classes = useStyles();
  const actions = useActions(Actions);
  const { curso, associados } = useSelector('associados');
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = (event) => {
    actions.filtroCurso(event.target.value);
  };

  const options = [];

  for (const associado of associados) {
    const curso = associado?.curso ?? null;
    if (curso) {
      // Trata caracteres Especiais
      const add = replaceSpecialChars(curso);
      if (!options.includes(add.toUpperCase())) {
        if (add !== ' ' && add !== '') options.push(add.toUpperCase());
      }
    }
  }

  const cursos = sort(options).asc();

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel ref={inputLabel} id='filtra-curso'>
          Curso
        </InputLabel>
        <Select
          labelId='filtra-curso'
          id='planos'
          value={curso}
          onChange={handleChange}
          labelWidth={labelWidth}>
          <MenuItem value={'todos'}>Todos</MenuItem>
          {cursos.map((curso) => (
            <MenuItem value={curso}>{curso}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
