import { REQUEST, SUCCESS, FAILURE, CANCEL, CHANGE_VIEW } from './types';
import * as service from './service';
import * as feedback from 'components/_Common/Feedback/redux/actions';

export { loadEntidades } from 'components/Entidades/redux/actions';
export const cancel = () => ({ type: CANCEL });
export const credenciamento = (view) => ({ type: CHANGE_VIEW, payload: view });
export const changeView = (view) => ({ type: CHANGE_VIEW, payload: view });

export const loadSellers = () => {
  return sendRequest(service.listSellers, null, true);
};

export const trashSeller = (seller) => {
  return sendRequest(service.trashSeller, seller, false);
};

function sendRequest(serviceName, params, noFeedback) {
  return async (dispatch) => {
    dispatch({ type: REQUEST });
    // ***SERVICE CALL***
    const res = await serviceName({ ...params });
    if (res.success) {
      dispatch({ type: SUCCESS, payload: res.data });
      if (!noFeedback) dispatch(feedback.success(res.data.msg));
    } else {
      dispatch({ type: FAILURE });
      if (!noFeedback) dispatch(feedback.error(res.error));
    }
  };
}
