import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    marginTop: '1em',
  },

  boxFind: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1em',
  },

  textField: {
    margin: '1em',
  },

  button: {
    margin: '1em',
  },

  findText: {
    fontWeight: '100',
    fontSize: '2em',
  },

  containerPedido: {
    display: 'flex',
    flexDirection: 'column',
  },

  top: {
    display: 'flex',
    flex: 1,
    background: 'white',
    borderRadius: '4px',
    marginBottom: '1em',
  },

  bottom: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },

  buttonCancel: {
    margin: '1em',
    justifyContent: 'center',
  },

  helper: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '500',
    color: theme.palette.secondary.main,
  },

  pedido: {
    display: 'flex',
    padding: '1em',
    flexDirection: 'column',
    flex: 1,
  },

  detalhes: {
    flex: 1,
    display: 'flex',
    background: 'white',
    padding: '1em',
    borderRadius: '4px',
    flexDirection: 'column',
  },

  row: {
    display: 'flex',
    margin: '0.5em 0',
  },

  box: {
    flexBasis: '25%',
    display: 'flex',
    alignItems: 'center',
    minHeight: '30px',
  },

  label: {
    flex: 1,
    marginRight: '0.5em',
  },

  info: {
    flex: 2,
    fontWeight: '500',
    fontSize: '1.5em',
  },
}));
