import { send } from '_helpers/api.config';

const apiURL = '/api/admin/financeiro';

// lista todos os pedidos emitidos pelo capitao
export const listPedidos = async () => {
  const endpoint = `${apiURL}/pedidos`;
  const verb = 'GET';
  const data = null;
  return send(verb, endpoint, data);
};

// sellers
export const listSellers = async () => {
  const endpoint = `${apiURL}/sellers`;
  const verb = 'GET';
  const data = null;
  return send(verb, endpoint, data);
};

// cria cobranças em lote
export const cobrancaEmLote = async (cobrancas) => {
  const endpoint = `${apiURL}/cobranca/lote`;
  const verb = 'POST';
  const data = cobrancas;
  return send(verb, endpoint, data);
};

// soft delete pedido
export const trash = async (pedido) => {
  const endpoint = `${apiURL}/pedido/${pedido._id}`;
  const verb = 'DELETE';
  const data = null;
  return send(verb, endpoint, data);
};

// reemitir pedido
export const reemitir = async ({ pedido, descricao, valor, vencimento }) => {
  const endpoint = `${apiURL}/pedido/${pedido._id}`;
  const verb = 'PUT';
  const data = { descricao, valor, vencimento };
  return send(verb, endpoint, data);
};
