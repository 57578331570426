/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useSelector } from 'react-redux';
import { formataDataHora } from '_helpers/utils';
import Switch from 'components/_Common/SimpleSwitch';
import Documentos from './Documentos';
const Sellers = (props) => {
  const { classes } = props;

  const { sellers } = useSelector(({ financeiroEntidade }) => ({
    ...financeiroEntidade,
  }));

  const { entidade } = useSelector(({ entidades }) => ({
    ...entidades,
  }));

  const getStatus = (status) => {
    if (status === 'enabled') return 'Ativo';
    if (status === 'pending') return 'Pendência de Documentos';
    return status;
  };

  const getType = (type) => {
    if (type === 'individual') return 'Pessoa Física';
    if (type === 'business') return 'Pessoa Jurídica';
    return type;
  };

  const showSellers = () => {
    return sellers.map((seller) => {
      const { email, type, status } = seller;
      return (
        <div className={classes.seller}>
          <div className={classes.left}>
            <div className={classes.name}>
              {seller.first_name} {seller.last_name}
            </div>

            <div className={classes.tipo}>Conta {getType(type)}</div>
            <div className={classes.info}>
              {type === 'individual' ? (
                <>CPF {seller.taxpayer_id}</>
              ) : (
                <>CNPJ {seller.ein}</>
              )}
            </div>
            <div className={classes.info}>{email}</div>

            <div className={classes.slabel}>Status</div>
            <div className={classes.status}>{getStatus(status)}</div>

            <div className={classes.icon}>
              <Documentos seller={seller} />
            </div>
          </div>
          <div className={classes.right}>
            <div className={classes.switch}>
              <Switch
                label='Conta Padrão'
                field='liberar'
                value={true}
                action={null}
                color='primary'
              />
            </div>

            <div className={classes.tipo}>{entidade.nome}</div>

            <div className={classes.details}>Detalhes da conta</div>

            <div className={classes.bwrapper}>
              <div className={classes.balance}>
                <div className={classes.blabel}>Saldo atual</div>
                <div className={classes.bvalue}>R$ 0,00</div>
              </div>

              <div className={classes.balance}>
                <div className={classes.blabel}>À receber</div>
                <div className={classes.bvalue}>R$ 0,00</div>
              </div>
            </div>

            <div className={classes.dateWrapper}>
              <div className={classes.dlabel}>Abertura</div>
              <div className={classes.date}>
                {formataDataHora(seller.createdAt)}
              </div>
            </div>

            <div className={classes.dateWrapper}>
              <div className={classes.dlabel}>Última alteração</div>
              <div className={classes.date}>
                {formataDataHora(seller.updatedAt)}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.sellers}>{showSellers()}</div>
    </div>
  );
};

export default withStyles(styles)(Sellers);
