import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from 'components/_Common/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faCopy,
  faTrashAlt,
  faStar,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import useStyles from './styles';
import { useActions } from 'hooks';
import * as Actions from 'components/Planos/redux/actions';

function Menu(props) {
  const classes = useStyles();
  const { plano } = props;
  const [excluir, setExcluir] = useState(false);

  const actions = useActions(Actions);

  const handleExcluir = (confirmation) => {
    if (confirmation) actions.trash(plano);
    setExcluir(false);
  };

  return (
    <span className={classes.menu}>
      <div>
        <Button
          variant='text'
          onClick={() => actions.mudarDestaque(plano)}
          color='primary'
          className={classes.buttons}>
          <FontAwesomeIcon
            icon={faStar}
            fixedWidth
            className={plano.destaque ? classes.star : classes.starInativo}
          />
        </Button>
      </div>
      <div>
        <Button
          variant='text'
          onClick={() => actions.mudarStatus(plano)}
          color={plano.status ? 'primary' : 'secondary'}
          className={classes.buttons}>
          <FontAwesomeIcon
            icon={plano.status ? faEye : faEyeSlash}
            fixedWidth
            className={
              plano.status ? classes.avatarAtivo : classes.avatarInativo
            }
          />
        </Button>
      </div>
      <div>
        <Button
          variant='text'
          onClick={() => setExcluir(true)}
          color='secondary'
          className={classes.buttons}>
          <FontAwesomeIcon icon={faTrashAlt} fixedWidth />
        </Button>
      </div>
      <div>
        <Button
          variant='text'
          onClick={() => actions.showCopy(plano)}
          color='primary'
          className={classes.buttons}>
          <FontAwesomeIcon icon={faCopy} fixedWidth />
        </Button>
      </div>
      <div>
        <Button
          variant='text'
          onClick={() => actions.showEdit(plano)}
          color='primary'
          className={classes.buttons}>
          <FontAwesomeIcon icon={faEdit} fixedWidth />
        </Button>
      </div>
      {excluir ? (
        <Dialog
          title='Tem certeza que deseja excluir o plano?'
          message='Este plano não estará mais disponível para novas assinaturas e será movido para a lixeira.'
          no='Não'
          yes='Sim, excluir'
          confirm={true}
          action={handleExcluir}
          close={() => setExcluir(false)}
        />
      ) : null}
    </span>
  );
}

export default Menu;
