import { send } from '_helpers/api.config';

const apiURL = '/api/admin/entidade';

export const list = async () => {
  const endpoint = `${apiURL}`;
  const verb = 'GET';
  const data = null;
  return send(verb, endpoint, data, false);
};

export const edit = async (entidade) => {
  const endpoint = `${apiURL}/${entidade._id}`;
  const verb = 'PUT';
  const data = entidade;
  return send(verb, endpoint, data, false);
};

// soft delete
export const trash = async (entidade) => {
  const endpoint = `${apiURL}/${entidade._id}`;
  const verb = 'DELETE';
  const data = entidade;
  return send(verb, endpoint, data, false);
};

// HARD DELETE
export const del = async (entidade) => {
  const endpoint = `${apiURL}/del/${entidade._id}`;
  const verb = 'DELETE';
  const data = entidade;
  return send(verb, endpoint, data, false);
};

export const changeAccess = async (entidade) => {
  const endpoint = `${apiURL}/changeAccess/${entidade._id}`;
  const verb = 'PUT';
  const data = null;
  return send(verb, endpoint, data, false);
};

// edita nivel dos associados
export const deleteAssociados = (associados) => {
  // let endpoint = `${apiURL}/delete/associados`;
  // const verb = 'PUT';
  // const data = {
  //   associados,
  // };
  // return send(verb, endpoint, data);
};

// edita nivel dos associados
export const editNivelAssociados = (associados, nivel) => {
  // let endpoint = `${apiURL}/edit/nivelAssociados`;
  // const verb = 'PUT';
  // const data = {
  //   associados,
  //   nivel,
  // };
  // return send(verb, endpoint, data);
};

export const addCreditos = async (entidade, creditos) => {
  const endpoint = `${apiURL}/creditos/${entidade._id}`;
  const verb = 'PUT';
  const data = { creditos };
  return send(verb, endpoint, data, false);
};
