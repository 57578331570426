import React, { useState, useEffect, useRef } from 'react';
import useStyles from './styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector, useActions, useFetch } from 'hooks';
import * as Actions from 'components/Entidades/redux/actions';

export default function Filtro(props) {
  const classes = useStyles();

  const actions = useActions(Actions);
  const { gerencial } = useSelector('entidades');
  const { sellers } = useSelector('sellers');
  useFetch({ sellers });
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = (event) => {
    actions.filtroGerencial(event.target.value, sellers);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel ref={inputLabel} id='filtra-plano'>
          Gerencial
        </InputLabel>
        <Select
          labelId='filtra-plano'
          id='planos'
          value={gerencial}
          onChange={handleChange}
          labelWidth={labelWidth}>
          <MenuItem value={'todos'}>Todos</MenuItem>
          {/* <MenuItem value={'activeSellers'}>Com Sellers Ativos</MenuItem> */}
          <MenuItem value={'noUser'}>Sem e-mail verificado</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
