import React from "react";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import styles from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRandom } from "@fortawesome/free-solid-svg-icons";
import {
  FormSection,
  SectionText,
  FormTextField,
  FormSelectField,
  MoneyTextField,
  CancelarOuSalvar,
  DateField
} from "components/_Common/Form";
import { formataValor, formataDataInput } from "_helpers/utils";
import { check } from "../redux/service";

const Form = props => {
  const {
    values: { tipo },
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleCancel,
    setFieldTouched,
    setFieldValue,
    setFieldError,
    isValid,
    classes,
    planos
  } = props;

  const change = (name, e) => {
    e.persist();
    const value = e.target.value;
    setFieldTouched(name, true, false);
    if (name === "desconto") {
      setFieldValue(name, formataValor(value, 5));
    } else if (name === "codigo") {
      setFieldValue(
        name,
        value
          .toUpperCase()
          .replace(" ", "")
          .slice(0, 12)
      );
    } else if (name === "validade") {
      setFieldValue(name, formataDataInput(value));
    } else {
      handleChange(e);
    }
  };

  const checkCodigo = async () => {
    const errCodigo = await check(props.values.codigo);
    if (errCodigo) {
      setFieldError("codigo", errCodigo);
    }
  };

  const saveProps = {
    isValid,
    handleSubmit,
    handleCancel
  };

  const formProps = {
    errors,
    touched,
    values: props.values,
    change,
    setFieldTouched,
    setFieldValue
  };

  const geraCodigoRand = () => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setFieldTouched("codigo", true, false);
    setFieldValue("codigo", result);
  };

  const endAdornment = () => {
    return (
      <InputAdornment position="end">
        <IconButton aria-label="Gerar Código" onClick={() => geraCodigoRand()}>
          <FontAwesomeIcon
            icon={faRandom}
            // className={classes.pullLeft}
            fixedWidth
            size="sm"
          />
        </IconButton>
      </InputAdornment>
    );
  };

  const desconto = () => {
    if (tipo === "Porcentagem") {
      return (
        <MoneyTextField
          prefix="%"
          label="Desconto"
          field="desconto"
          xs={12}
          md={6}
          formik={formProps}
        />
      );
    }

    if (tipo === "Valor Fixo") {
      return (
        <MoneyTextField
          prefix="R$"
          label="Desconto"
          field="desconto"
          xs={12}
          md={6}
          formik={formProps}
        />
      );
    }
  };

  return (
    <form>
      <CancelarOuSalvar save={saveProps} />

      <FormSection classes={classes} title={props.title}>
        <SectionText>
          Forneça o código do cupom. O ideal é utilizar um código que represente
          o tipo do cupom, por exemplo: <strong>TOMA10</strong>.
          <br />O código do cupom deve ser único para cada plano.
        </SectionText>
        <FormTextField
          label="Código do Cupom"
          field="codigo"
          xs={12}
          md={12}
          formik={formProps}
          onBlur={checkCodigo}
          styleProps={{ InputProps: { endAdornment: endAdornment() } }}
        />
      </FormSection>

      <FormSection classes={classes} title="Desconto">
        <FormSelectField
          label="Tipo do desconto"
          field="tipo"
          xs={12}
          md={6}
          formik={formProps}
          options={["Porcentagem", "Valor Fixo"]}
        />
        {desconto()}
      </FormSection>

      <FormSection classes={classes} title="Plano">
        <SectionText>
          Escolha para qual plano este cupom poderá ser utilizado
        </SectionText>
        <FormSelectField
          label="Plano"
          field="plano"
          xs={12}
          md={12}
          formik={formProps}
          options={planos.map(plano => {
            return { label: plano.titulo, value: plano._id };
          })}
        />
      </FormSection>

      <FormSection classes={classes} title="Quantidade">
        <SectionText>
          Configure quantas vezes este cupom pode ser utilizado.
        </SectionText>

        <FormTextField
          label="Quantidade"
          field="quantidade"
          xs={12}
          md={12}
          formik={formProps}
          type="tel"
        />
      </FormSection>

      <FormSection classes={classes} title="Validade">
        <SectionText>
          Defina até quando este cupom pode ser resgatado.
        </SectionText>
        <DateField
          label="Validade"
          field="validade"
          xs={12}
          md={12}
          formik={formProps}
          minDate={new Date()}
        />
      </FormSection>

      <FormSection classes={classes} title="Status">
        <SectionText>
          Defina se o cupom já estará valendo a partir de agora. Você poderá
          mudar o status sempre que desejar.
        </SectionText>
        <FormSelectField
          label="O cupom está"
          field="status"
          xs={12}
          md={6}
          formik={formProps}
          options={[
            { label: "Ativo", value: "true" },
            { label: "Inativo", value: "false" }
          ]}
        />
      </FormSection>

      <CancelarOuSalvar save={saveProps} />
    </form>
  );
};

export default withStyles(styles)(Form);
