import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  modalBg: {
    background: '#424242',
  },

  modalTitle: {
    background: '#424242',
    color: '#fafafa',
    lineHeight: '1.1em',
  },
}));
