/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import LazyList from 'components/_Common/LazyList';
import Entidade from './Entidade';
import { useSelector, useFetch } from 'hooks';
import { differenceInCalendarDays } from 'date-fns';
import sort from 'fast-sort';
import Spinner from 'components/_Common/Spinner';

function ScoreEntidade(props) {
  const { scores, loading, pesos } = useSelector('scores');
  useFetch({ pesos });
  useFetch({ scores });

  const scoreCalculado = scores.map((data) => {
    const { entidade, specs } = data;
    if (!entidade) return { entidade, specs, score: 0 };
    let score = 0;
    if (entidade.user?.lastAccess) {
      const diffAccess = differenceInCalendarDays(new Date(entidade.user.lastAccess), new Date());
      if (diffAccess <= 7) {
        score += pesos.acesso;
      }
    }
    if (entidade.avatar) {
      score += pesos.logo;
    }
    if (entidade.loja) {
      const { loja } = entidade;
      if (loja.capa) {
        score += pesos.capaLoja;
      }
    }
    for (const key in pesos) {
      if (pesos.hasOwnProperty(key)) {
        const peso = pesos[key];
        if (specs[key]) {
          score += specs[key] * peso;
        }
      }
    }
    score = parseInt(score / 10);
    return { entidade, specs, score };
  });

  if (loading) {
    return <Spinner text='CARREGANDO DADOS PARA CALCULAR SCORE...' size='4x' noBox />;
  }

  const sortedScore = sort(scoreCalculado).desc('score');
  return <LazyList data={[...sortedScore]} Item={Entidade} />;
}

export default ScoreEntidade;
